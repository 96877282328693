import i18n from '@/utils/i18n';

const aDay = 24 * 60 * 60 * 1000;
const today = new Date();
const tomorrow = new Date(today.getTime() + aDay);
const next7Days = new Date(today.getTime() + 7 * aDay);
const next15Days = new Date(today.getTime() + 15 * aDay);
const next30Days = new Date(today.getTime() + 30 * aDay);
const next90Days = new Date(today.getTime() + 90 * aDay);

export const defaultStaticRanges = [
  {
    label: i18n.t('dateRange.today'),
    range: {
      startDate: today,
      endDate: today,
    },
  },
  {
    label: i18n.t('dateRange.tomorrow'),
    range: {
      startDate: tomorrow,
      endDate: tomorrow,
    },
  },
  {
    label: i18n.t('dateRange.next7Days'),
    range: {
      startDate: today,
      endDate: next7Days,
    },
  },
  {
    label: i18n.t('dateRange.next15Days'),
    range: {
      startDate: today,
      endDate: next15Days,
    },
  },
  {
    label: i18n.t('dateRange.next30Days'),
    range: {
      startDate: today,
      endDate: next30Days,
    },
  },
  {
    label: i18n.t('dateRange.next90Days'),
    range: {
      startDate: today,
      endDate: next90Days,
    },
  },
];
