import type { ReactNode } from 'react';
import { InventoryStateContext } from './context-hooks';
import useInventoryData from '@/hooks/inventory/useInventoryData';

function InventoryContextProvider({ children }: { children: ReactNode }) {
  // initiate all the singletons and effects
  useInventoryData();

  // todo: move the store in here

  return <InventoryStateContext.Provider value={{}}>{children}</InventoryStateContext.Provider>;
}

export { InventoryContextProvider };
