import type { IProductionQuickFilters, IRadioOption } from '@/interfaces/CommonInterfaces';
import { EFilterType } from '@/interfaces/CommonInterfaces';
import type { IProductionsFilters } from '@/interfaces/CommonInterfaces';
import { DefaultDateRangeForEvents, ThreeState, UserStatus } from '@/utils/constants';
import { filterOptions, productionFilterOptions, unassignedPricer, userIsPricer } from './constants';
import { parseISO } from 'date-fns';
import type { PaginationState } from '@tanstack/react-table';
import type { URLSearchParamsInit } from 'react-router-dom';
import type { ClientUserAppProfileResponse, ClientUserAppResponse } from '@/api/clientService/clientServiceApiSchemas';

const PRICER_DEFAULT_SELECTION_OVERRIDE = 'excludePricerSelf';

export const getAppliedPricerFilterOptions = (
  users: ClientUserAppResponse[],
  currentUser: ClientUserAppResponse | undefined,
  searchParams: URLSearchParams,
) => {
  const pricers = searchParams.getAll('pricer');

  const uPricer = { ...unassignedPricer, selected: pricers.includes(unassignedPricer.value) };

  let currentPricer = null;
  if (userIsPricer(currentUser)) {
    currentPricer = currentUser?.username;
  }

  const pricerOptions = users
    .filter((user) => user.status === UserStatus.ACTIVE)
    .map((user) => ({
      label: user.name || '',
      value: user.username || '',
      selected:
        pricers.includes(user.username || '') ||
        (user.username === currentPricer && !pricers.includes(PRICER_DEFAULT_SELECTION_OVERRIDE)),
    }));

  return [uPricer, ...pricerOptions];
};

export const getPricerFilterSelfExcluded = (
  pricers: string[],
  currentUser: ClientUserAppProfileResponse | undefined,
) => {
  return userIsPricer(currentUser) && !pricers.includes(currentUser?.username || '')
    ? PRICER_DEFAULT_SELECTION_OVERRIDE
    : null;
};

export const setFiltersFromSearchParams = (
  searchParams: URLSearchParams,
  filters: IProductionsFilters,
  setFilters: React.Dispatch<React.SetStateAction<IProductionsFilters>>,
  productionQuickFilters: IProductionQuickFilters,
  setProductionQuickFilters: React.Dispatch<React.SetStateAction<IProductionQuickFilters>>,
  setSearchTerm: (value: React.SetStateAction<string>) => void,
  setSelectedDateRange: (value: React.SetStateAction<Date[]>) => void,
) => {
  interface Params {
    [key: string]: string;
  }
  const params: Params = {};
  searchParams.forEach((value: string, key: string) => {
    params[key.toLowerCase()] = value;
  });

  const weekday = searchParams.getAll('weekday');
  const status = searchParams.getAll('status');
  const parking = searchParams.get('parking');
  const noTickets = searchParams.get('noTickets');
  const noComps = params['nocomps'];
  const isFloorHit = params['isfloorhit'];
  const isCeilingHit = params['isceilinghit'];

  filters.eventDay.data.options.forEach((item) => {
    if (weekday.includes(item.value)) {
      item.selected = true;
    }
  });

  filters.status.data.options.forEach((item) => {
    if (status.includes(item.value)) {
      item.selected = true;
    }
  });

  if (parking) filters.isParking.data.selected = ThreeState.find((item) => item.key === parking);
  if (noTickets) filters.isNoTickets.data.selected = ThreeState.find((item) => item.key === noTickets);
  if (noComps) productionQuickFilters.NoComparables.isChecked = noComps.toLowerCase() === 'true';
  if (isFloorHit) productionQuickFilters.IsFloorHit.isChecked = isFloorHit.toLowerCase() === 'true';
  if (isCeilingHit) productionQuickFilters.IsCeilingHit.isChecked = isCeilingHit.toLowerCase() === 'true';

  const urlSearchTerm = searchParams.get('searchTerm');
  if (urlSearchTerm) setSearchTerm(urlSearchTerm);

  setFilters({ ...filters });
  setProductionQuickFilters(productionQuickFilters);

  const startDate = searchParams.get('startDate');
  const endDate = searchParams.get('endDate');
  if (startDate && endDate) setSelectedDateRange([parseISO(startDate), parseISO(endDate)]);
};

export const handleResetFilters = (
  setFilters: React.Dispatch<React.SetStateAction<IProductionsFilters>>,
  setSelectedDateRange: (value: React.SetStateAction<Date[]>) => void,
  setProductionQuickFilters: (value: React.SetStateAction<IProductionQuickFilters>) => void,
  setPagination: (value: React.SetStateAction<PaginationState> | PaginationState) => void,
  pageSize: number,
  setSearchParams: (
    nextInit?: URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit) | undefined,
  ) => void,
  setSearchTerm: (value: React.SetStateAction<string>) => void,
) => {
  setFilters((dirtyFilters) => {
    Object.keys(dirtyFilters).forEach((key: string) => {
      const filterKey = key as keyof IProductionsFilters;
      const filter = dirtyFilters[filterKey];

      switch (filter.type) {
        case EFilterType.OPTIONS:
          filter.data.options.forEach((option) => {
            const defaultFilterOptions = filterOptions[filterKey].data.options;
            let i;
            for (i = 0; i < defaultFilterOptions.length; i++) {
              if (option.value === defaultFilterOptions[i].value) {
                option.selected = defaultFilterOptions[i].selected;
                break;
              }
            }
            if (i === defaultFilterOptions.length) {
              option.selected = false;
            }
          });
          break;
        case EFilterType.RADIO:
          filter.data.selected = (filterOptions[filterKey].data as IRadioOption).selected;
          break;
      }
    });
    return structuredClone(dirtyFilters);
  });

  setSearchParams({});
  setSearchTerm('');
  setSelectedDateRange(DefaultDateRangeForEvents);
  setProductionQuickFilters(productionFilterOptions);
  setPagination({ pageIndex: 0, pageSize });
};
