export default {
  tabs: {
    account: 'Profile',
    accountTooltip: 'Profile overview',
    notifications: 'Notification Settings',
    notificationsTooltip: 'Notification Settings',
  },
  account: {
    uploadButton: 'Upload photo',
    uploadRestriction: 'Allowed JPG, GIF or PNG. Max size of 800K',
  },
  notifications: {
    allEvents: 'All events',
    myAssigned: 'My assigned events',
    none: 'None',
    title: 'Pricing group key indicators',
    hitFloor: {
      title: 'Hit floor',
      message: 'The system default is emailing when hit floor for the pricing groups.',
    },
    hitCeiling: {
      title: 'Hit ceiling',
      message: 'The system default is emailing when hit ceiling for the pricing groups.',
    },
    noComps: {
      title: 'No comps',
      message: 'The system default is emailing when no comparables exists for the pricing group.',
    },
    toast: {
      applying: {
        title: 'Saving',
        message: 'Applying notification preferences changes.',
      },
      success: {
        title: 'Success',
        message: 'Successfully saved notification preferences changes.',
      },
      error: {
        title: 'Oops! Something went wrong!',
        message: 'Failed to save notification preferences changes.',
      },
    },
    system: 'System',
    activity: 'Activity',
    application: 'Application',
    textPricerQXmessage: 'Text when there is a PricerQX system message',
    emailPricerQXmessage: 'Email when there is a PricerQX system message',
    whenSomeoneAddsYou: 'When someone adds you to a project',
    noAskAgain: "Don't ask me again",
    noAskAgainHint: "You can turn off “Don't ask me again” for confirmations",
    text: 'Text',
    email: 'Email',
    applying: {
      title: 'Saving',
      message: 'Applying profile changes.',
    },
    success: {
      title: 'Success',
      message: 'Successfully saved profile changes.',
    },
    error: {
      title: 'Oops! Something went wrong!',
      message: 'Failed to save profile changes.',
    },
  },
  security: {
    removeTrustedDevices: 'Remove all trusted devices on successful password change',
  },
  info: 'Profile Info',
  personalInfo: 'Personal Info',
  buttons: {
    changePassword: 'Change password',
    profile: 'Profile',
    notifications: 'Notifications settings',
    signOut: 'Sign out',
  },
  labels: {
    overview: 'Profile overview',
    activity: 'Activity',
  },
};
