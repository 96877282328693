import type { IDataField, TField } from '@/interfaces/CommonInterfaces';
import { PriceAdjustmentType, groupShareOptionsTypes, groupShareOptions } from '@/utils/constants';
import InputValidations from '@/utils/InputValidations';
import type { OrganizationPricingDefaultDto } from '@/api/pricer/pricerApiSchemas';
import { formatCurrencyInput } from '@/utils/helpers';
import {
  adjustments,
  units,
  autoPriceSimulateOption,
  enableDisableOption,
  requiredOption,
  shareUnshareOption,
  mapCeilingPricingMethod,
  mapNoCompPricingMethod,
  mapFloorPricingMethod,
} from '@/context/pricing-mode/constants';
import { emptyDataField, INITIAL_STATE, orgDefaultDataField, zeroInputValue } from '../constants';

export enum FieldType {
  ADJUSTMENT = 'adjustment',
  ADJUSTMENT_AMOUNT = 'adjustmentAmount',
  FLOOR = 'floorValue',
  CEILING = 'ceilingValue',
  GROUP_ADJUSTMENT = 'groupAdjustment',
  GROUP_ADJUSTMENT_AMOUNT = 'groupAdjustmentAmount',
  SHARE_TG_AMOUNT = 'shareTicketGroupsAmount',
}

export const enum REDUCER_ACTION_TYPE {
  SET_INPUT_VALIDATIONS,
  CHANGE_INPUT,
  SET_ADJUSTMENT,
  SET_UNIT,
  SET_FLOOR_TOGGLE,
  SET_FLOOR_UNIT,
  SET_CEILING_TOGGLE,
  SET_CEILING_UNIT,
  SET_CEILING_REQUIRED,
  SET_GROUP_ADJUSTMENT,
  SET_GROUP_UNIT,
  SET_SHARE_AUTO_PRICE_BUTTON,
  SET_SHARE_ANCHOR_BUTTON,
  SET_SHARE_TG_BUTTON,
  SET_SHARE_TG_UNIT,
  SET_PUSH_POS_BUTTON,
  SET_ADVANCE_FLOOR_BUTTON,
  SET_ADVANCE_CEILING_BUTTON,
  SET_ADVANCE_NOCOMPS_BUTTON,
  SET_ADVANCE_NOCOMPS_RADIO,
  LOAD_STATE_FROM_ORG_DEFAULTS,
  RESET,
  SET_SHARE_TG_INPUT_ACTIVE,
  SET_ADVANCE_NOCOMPS_RADIO_ACTIVE,
  SET_FLOOR_OPTION_ACTIVE,
  SET_CEILING_OPTION_ACTIVE,
  SET_ERROR,
}

type InputAction = {
  type: REDUCER_ACTION_TYPE.CHANGE_INPUT;
  payload: { name: string; value: string };
};

type InputValidationAction = {
  type: REDUCER_ACTION_TYPE.SET_INPUT_VALIDATIONS;
  payload: {
    [key: string]: { value: string; valid: boolean };
  };
};

type SetDataFieldAction = {
  type:
    | REDUCER_ACTION_TYPE.SET_ADJUSTMENT
    | REDUCER_ACTION_TYPE.SET_UNIT
    | REDUCER_ACTION_TYPE.SET_FLOOR_UNIT
    | REDUCER_ACTION_TYPE.SET_CEILING_UNIT
    | REDUCER_ACTION_TYPE.SET_CEILING_REQUIRED
    | REDUCER_ACTION_TYPE.SET_GROUP_ADJUSTMENT
    | REDUCER_ACTION_TYPE.SET_GROUP_UNIT
    | REDUCER_ACTION_TYPE.SET_SHARE_AUTO_PRICE_BUTTON
    | REDUCER_ACTION_TYPE.SET_SHARE_ANCHOR_BUTTON
    | REDUCER_ACTION_TYPE.SET_SHARE_TG_BUTTON
    | REDUCER_ACTION_TYPE.SET_SHARE_TG_UNIT
    | REDUCER_ACTION_TYPE.SET_PUSH_POS_BUTTON
    | REDUCER_ACTION_TYPE.SET_ADVANCE_FLOOR_BUTTON
    | REDUCER_ACTION_TYPE.SET_ADVANCE_CEILING_BUTTON
    | REDUCER_ACTION_TYPE.SET_ADVANCE_NOCOMPS_BUTTON;
  payload: IDataField;
};

type SetError = {
  type: REDUCER_ACTION_TYPE.SET_ERROR;
  payload: boolean;
};

type SetPricingMethodNoCompAction = {
  type: REDUCER_ACTION_TYPE.SET_ADVANCE_NOCOMPS_RADIO;
  payload: IDataField | undefined;
};

type SimpleAction = {
  type:
    | REDUCER_ACTION_TYPE.RESET
    | REDUCER_ACTION_TYPE.SET_FLOOR_TOGGLE
    | REDUCER_ACTION_TYPE.SET_CEILING_TOGGLE
    | REDUCER_ACTION_TYPE.SET_SHARE_TG_INPUT_ACTIVE
    | REDUCER_ACTION_TYPE.SET_ADVANCE_NOCOMPS_RADIO_ACTIVE
    | REDUCER_ACTION_TYPE.SET_FLOOR_OPTION_ACTIVE
    | REDUCER_ACTION_TYPE.SET_CEILING_OPTION_ACTIVE;
};

type StateLoadAction = {
  type: REDUCER_ACTION_TYPE.LOAD_STATE_FROM_ORG_DEFAULTS;
  payload: OrganizationPricingDefaultDto | undefined;
};

export type ReducerAction =
  | InputAction
  | InputValidationAction
  | SimpleAction
  | SetDataFieldAction
  | StateLoadAction
  | SetPricingMethodNoCompAction
  | SetError;

const getValidatedInput = (name: string, value: string): { value: string; valid: boolean | undefined } => {
  if (name === FieldType.FLOOR && value === '0.00') {
    return { value, valid: false };
  }

  if (
    name === FieldType.ADJUSTMENT_AMOUNT ||
    name === FieldType.CEILING ||
    name === FieldType.FLOOR ||
    name === FieldType.GROUP_ADJUSTMENT_AMOUNT
  ) {
    return { value, valid: value === '' ? undefined : InputValidations.isNumberWithDecimalOrScientific(value) };
  }

  return { value, valid: undefined };
};

const handleDeSelect = (field: IDataField | undefined, stateValue: IDataField | undefined): IDataField => {
  if (!field) return emptyDataField;
  if (field.key === stateValue?.key) {
    return emptyDataField;
  } else {
    return field;
  }
};

export const reducer = (state: typeof INITIAL_STATE, action: ReducerAction): typeof INITIAL_STATE => {
  switch (action.type) {
    case REDUCER_ACTION_TYPE.CHANGE_INPUT:
      const { name, value } = action.payload;

      const currentValue = (state[name as keyof typeof INITIAL_STATE] as TField).value;
      const validatedInput = getValidatedInput(name, value);
      const isDirty = state.isDirty || currentValue !== value;
      return {
        ...state,
        [name]: { value: validatedInput.value, valid: validatedInput.valid },
        isDirty,
      };
    case REDUCER_ACTION_TYPE.SET_INPUT_VALIDATIONS:
      return { ...state, ...action.payload };
    case REDUCER_ACTION_TYPE.SET_ADJUSTMENT:
      const adjustValue: any = {};
      if (action.payload.key === PriceAdjustmentType.MATCH) {
        adjustValue[FieldType.ADJUSTMENT_AMOUNT] = zeroInputValue;
      }
      return {
        ...state,
        ...{ adjustment: handleDeSelect(action.payload, state.adjustment) },
        ...adjustValue,
        isDirty: true,
      };
    case REDUCER_ACTION_TYPE.SET_UNIT:
      return { ...state, unit: handleDeSelect(action.payload, state.unit), isDirty: true };
    case REDUCER_ACTION_TYPE.SET_FLOOR_TOGGLE:
      return { ...state, floorOptionToggle: !state.floorOptionToggle, isDirty: true };
    case REDUCER_ACTION_TYPE.SET_FLOOR_UNIT:
      return { ...state, floorUnit: handleDeSelect(action.payload, state.floorUnit), isDirty: true };
    case REDUCER_ACTION_TYPE.SET_CEILING_TOGGLE:
      return { ...state, ceilingOptionToggle: !state.ceilingOptionToggle, isDirty: true };
    case REDUCER_ACTION_TYPE.SET_CEILING_UNIT:
      return { ...state, ceilingUnit: handleDeSelect(action.payload, state.ceilingUnit), isDirty: true };
    case REDUCER_ACTION_TYPE.SET_CEILING_REQUIRED:
      return { ...state, ceilingRequired: handleDeSelect(action.payload, state.ceilingRequired), isDirty: true };
    case REDUCER_ACTION_TYPE.SET_GROUP_ADJUSTMENT:
      return { ...state, groupAdjustment: handleDeSelect(action.payload, state.groupAdjustment), isDirty: true };
    case REDUCER_ACTION_TYPE.SET_GROUP_UNIT:
      return { ...state, groupUnit: handleDeSelect(action.payload, state.groupUnit), isDirty: true };
    case REDUCER_ACTION_TYPE.SET_SHARE_AUTO_PRICE_BUTTON:
      return {
        ...state,
        shareAutoPriceEnabled: handleDeSelect(action.payload, state.shareAutoPriceEnabled),
        isDirty: true,
      };
    case REDUCER_ACTION_TYPE.SET_SHARE_ANCHOR_BUTTON:
      return { ...state, shareAnchor: handleDeSelect(action.payload, state.shareAnchor), isDirty: true };
    case REDUCER_ACTION_TYPE.SET_SHARE_TG_BUTTON:
      const shareOption = handleDeSelect(action.payload, state.shareTicketGroups);
      return {
        ...state,
        shareTicketGroups: shareOption,
        shareTicketGroupsActive: shareOption.key === groupShareOptionsTypes.CUSTOM,
        isDirty: true,
      };
    case REDUCER_ACTION_TYPE.SET_SHARE_TG_UNIT:
      return {
        ...state,
        shareTicketGroupsUnit: handleDeSelect(action.payload, state.shareTicketGroupsUnit),
        isDirty: true,
      };
    case REDUCER_ACTION_TYPE.SET_PUSH_POS_BUTTON:
      return { ...state, pushPosAutoButton: handleDeSelect(action.payload, state.pushPosAutoButton), isDirty: true };
    case REDUCER_ACTION_TYPE.SET_ADVANCE_FLOOR_BUTTON:
      return { ...state, advanceFloorRadio: handleDeSelect(action.payload, state.advanceFloorRadio), isDirty: true };
    case REDUCER_ACTION_TYPE.SET_ADVANCE_CEILING_BUTTON:
      return {
        ...state,
        advanceCeilingRadio: handleDeSelect(action.payload, state.advanceCeilingRadio),
        isDirty: true,
      };
    case REDUCER_ACTION_TYPE.SET_ADVANCE_NOCOMPS_BUTTON:
      return {
        ...state,
        advanceNoCompsButton: handleDeSelect(action.payload, state.advanceNoCompsButton),
        isDirty: true,
      };
    case REDUCER_ACTION_TYPE.SET_ADVANCE_NOCOMPS_RADIO:
      return {
        ...state,
        advanceNoCompsRadio: handleDeSelect(action.payload, state.advanceNoCompsRadio),
        isDirty: true,
      };
    case REDUCER_ACTION_TYPE.SET_SHARE_TG_INPUT_ACTIVE:
      return { ...state, shareTicketGroupsActive: !state.shareTicketGroupsActive, isDirty: true };
    case REDUCER_ACTION_TYPE.LOAD_STATE_FROM_ORG_DEFAULTS:
      const orgDefaults = action.payload ?? {};
      if (orgDefaults) {
        const { isCeilingRequired } = orgDefaults;
        const {
          ceiling,
          ceilingType,
          floor,
          floorType,
          increment,
          incrementType,
          incrementDirection,
          groupOffset,
          groupOffsetType,
          groupOffsetDirection,
          isShareOptionEnabled,
          shareOptionsType,
          customSharingMethod,
          customSharingAmount,
          shareAnchor,
          pricingGroupRequestType,
          floorPricingMethod,
          ceilingPricingMethod,
          noCompPricingMethod,
        } = orgDefaults.pricingDefaults ?? {};

        return {
          ...INITIAL_STATE,
          floorValue: floor
            ? (getValidatedInput(FieldType.FLOOR, formatCurrencyInput(floor?.toString())) as unknown as TField)
            : INITIAL_STATE.floorValue,
          ceilingValue: ceiling
            ? (getValidatedInput(FieldType.CEILING, formatCurrencyInput(ceiling?.toString())) as unknown as TField)
            : INITIAL_STATE.ceilingValue,
          adjustmentAmount: increment
            ? (getValidatedInput(
                FieldType.ADJUSTMENT_AMOUNT,
                formatCurrencyInput(increment?.toString()),
              ) as unknown as TField)
            : INITIAL_STATE.adjustmentAmount,
          groupAdjustmentAmount: groupOffset
            ? (getValidatedInput(
                FieldType.GROUP_ADJUSTMENT_AMOUNT,
                formatCurrencyInput(groupOffset?.toString()),
              ) as unknown as TField)
            : INITIAL_STATE.groupAdjustmentAmount,
          adjustment: !incrementDirection
            ? emptyDataField
            : incrementDirection?.toLowerCase() === adjustments[0].name.toLowerCase()
              ? adjustments[0]
              : adjustments[1],
          unit: !incrementType ? emptyDataField : incrementType === units[0].key ? units[0] : units[1],
          floorOptionToggle: floorType ? true : false,
          floorUnit: !floorType ? emptyDataField : floorType === units[0].key ? units[0] : units[1],
          ceilingOptionToggle: ceilingType ? true : false,
          ceilingUnit: !ceilingType ? emptyDataField : ceilingType === units[0].key ? units[0] : units[1],
          ceilingRequired:
            isCeilingRequired === undefined
              ? emptyDataField
              : isCeilingRequired
                ? requiredOption[0]
                : requiredOption[1],
          groupAdjustment: !groupOffsetDirection
            ? emptyDataField
            : groupOffsetDirection.toLowerCase() === adjustments[0].key
              ? adjustments[0]
              : adjustments[1],
          groupUnit: !groupOffsetType ? emptyDataField : groupOffsetType === units[0].key ? units[0] : units[1],
          shareAutoPriceEnabled:
            isShareOptionEnabled === undefined
              ? emptyDataField
              : isShareOptionEnabled
                ? enableDisableOption[0]
                : enableDisableOption[1],
          shareAnchor:
            shareAnchor === undefined ? emptyDataField : shareAnchor ? shareUnshareOption[0] : shareUnshareOption[1],
          shareTicketGroups: !groupShareOptions
            ? emptyDataField
            : groupShareOptions.find((option) => option.name.toLowerCase() === shareOptionsType?.toLowerCase()),
          shareTicketGroupsUnit: !customSharingMethod
            ? emptyDataField
            : customSharingMethod === units[0].name
              ? units[0]
              : units[1],
          shareTicketGroupsAmount: customSharingAmount
            ? { value: customSharingAmount.toString(), valid: true }
            : INITIAL_STATE.shareTicketGroupsAmount,
          pushPosAutoButton: autoPriceSimulateOption.find(
            (option) => option.key.toLocaleLowerCase() === pricingGroupRequestType?.toLocaleLowerCase(),
          ),
          advanceFloorRadio: !floorPricingMethod
            ? orgDefaultDataField
            : {
                name: floorPricingMethod,
                key: mapFloorPricingMethod(floorPricingMethod),
              },
          advanceCeilingRadio: !ceilingPricingMethod
            ? orgDefaultDataField
            : {
                name: ceilingPricingMethod,
                key: mapCeilingPricingMethod(ceilingPricingMethod),
              },
          advanceNoCompsButton: !noCompPricingMethod ? emptyDataField : enableDisableOption[0],
          shareTicketGroupsActive: shareOptionsType?.toLowerCase() === groupShareOptionsTypes.CUSTOM.toLowerCase(),
          advanceNoCompsRadio: !noCompPricingMethod
            ? orgDefaultDataField
            : {
                name: noCompPricingMethod,
                key: mapNoCompPricingMethod(noCompPricingMethod),
              },
        };
      } else {
        return INITIAL_STATE;
      }
    case REDUCER_ACTION_TYPE.RESET:
      return { ...INITIAL_STATE, isDirty: true };
    case REDUCER_ACTION_TYPE.SET_ERROR:
      return { ...state, isError: action.payload };
    default:
      return state;
  }
};
