import i18n from '@/utils/i18n';

export const columnNames = {
  eventId: i18n.t('pricingApplyTemplatePage.columns.eventId'),
  tbd: i18n.t('pricingApplyTemplatePage.columns.tbd'),
  marketListing: i18n.t('pricingApplyTemplatePage.columns.marketListing'),
  marketAverage: i18n.t('pricingApplyTemplatePage.columns.marketAverage'),
  percentSold: i18n.t('pricingApplyTemplatePage.columns.percentSold'),
};

export const columnIds = {
  selector: { id: 'selector' },
  name: { id: 'name', dbName: 'EventName', label: i18n.t('pricingApplyTemplatePage.columns.name') },
  eventDate: {
    id: 'eventDate',
    dbName: 'EventDate',
    label: i18n.t('pricingApplyTemplatePage.columns.eventDate'),
  },
  myListing: {
    id: 'myListing',
    dbName: 'MyListings',
    label: i18n.t('pricingApplyTemplatePage.columns.myListing'),
  },
  myAvgPrice: {
    id: 'myAvgPrice',
    dbName: 'MyAvgPrice',
    label: i18n.t('pricingApplyTemplatePage.columns.myAvgPrice'),
  },
  sold: { id: 'sold', dbName: 'Sold', label: i18n.t('pricingApplyTemplatePage.columns.sold') },
};
