import { useCallback, useEffect, useRef } from 'react';
import { useMutation } from '@tanstack/react-query';
import type { PostCalculatePriceVariables } from '@/api/pricer/pricerApiComponents';
import { fetchPostCalculatePrice } from '@/api/pricer/pricerApiComponents';
import { usePricerApiContext } from '@/api/pricer/pricerApiContext';
import type { PriceCalculationRequestDto } from '@/api/pricer/pricerApiSchemas';
import { getComparablesFromFilters } from '@/context/pricing-mode/filter-constants';
import {
  useMainSliceInventoryStore,
  useMutationsSliceInventoryActions,
  useMutationsSliceInventoryStore,
} from '@/stores/inventory/inventory-store';

export const calculatePriceMutationKey = ['fetchPostCalculatePrice'];
function useCalculatedPriceQuery() {
  const { setCalculatedPrice, setIsPriceCalculating } = useMutationsSliceInventoryActions();
  const calculatedPrice = useMutationsSliceInventoryStore('calculatedPrice');
  const isPriceCalculating = useMutationsSliceInventoryStore('isPriceCalculating');
  const { fetcherOptions } = usePricerApiContext();
  const calculatePriceAbortControllerRef = useRef<AbortController | null>(null);
  const { mutate: calculatePrice, reset: resetOriginal } = useMutation({
    mutationKey: calculatePriceMutationKey,
    mutationFn: (variables: PostCalculatePriceVariables) => {
      calculatePriceAbortControllerRef.current?.abort();
      calculatePriceAbortControllerRef.current = new AbortController();
      setIsPriceCalculating(true);
      setCalculatedPrice(undefined);

      return fetchPostCalculatePrice(
        { ...fetcherOptions, ...variables },
        calculatePriceAbortControllerRef.current?.signal,
      ).catch((error) => {
        // ignore abort errors
        if (error.stack?.name !== 'AbortError') {
          throw error;
        }
        return undefined;
      });
    },
    onSettled: (data, error, variables) => {
      setIsPriceCalculating(false);
      if (error) {
        setCalculatedPrice(undefined);
        return;
      }
      setCalculatedPrice(data);
    },
  });

  const resetCalculatedPrice = useCallback(() => {
    setCalculatedPrice(undefined);
    resetOriginal();
  }, [resetOriginal]);

  const abortCalculatePrice = useCallback(() => {
    calculatePriceAbortControllerRef.current?.abort();
    if (isPriceCalculating) {
      resetCalculatedPrice();
    }
  }, [isPriceCalculating, calculatePriceAbortControllerRef.current, resetCalculatedPrice]);

  // cancel on unmount
  useEffect(() => {
    return () => {
      calculatePriceAbortControllerRef.current?.abort();
    };
  }, []);

  const selectedProduction = useMainSliceInventoryStore('selectedProduction');
  const filters = useMainSliceInventoryStore('marketFilters');

  const calculatePriceApi = (req: PriceCalculationRequestDto) => {
    if (selectedProduction?.productionId) {
      req.productionId = selectedProduction?.productionId;
    }

    if (filters) {
      req.marketGroupCriteria = getComparablesFromFilters(filters);
    }

    calculatePrice({
      body: req,
    });
  };

  return {
    // api
    calculatePrice: calculatePriceApi,
    resetCalculatedPrice,
    abortCalculatePrice,

    // state
    calculatedPrice,
  };
}

export default useCalculatedPriceQuery;
