import { createBrowserRouter } from 'react-router-dom';
import { Suspense } from 'react';
import {
  ForgotPassword,
  OrganizationRequest,
  OrgDefaults,
  Profile,
  RoleBasedAccRoute,
  Settings,
  ValidateInvitation,
  Components,
} from '../routes';
import { ErrorPage } from '../routes/errors';
import i18n from '@/utils/i18n';
import { ConfigurationContextProvider } from '@/context/configuration/context';
import { AppModeIsDrive } from '@/utils/constants';
import SignOut from '../routes/SignOut';
import MsalProviderInitializer from '../routes/MsalProviderInitializer';
import { NotificationsContextProvider } from '@/context/notifications/context';
import { predefinedRoutes } from './constants';
import Root from '@/routes/Root';
import Notifications from '@/routes/settings/Notifications';
import UserNotifications from '../routes/profile/UserNotifications';
import UserAccount from '@/routes/profile/UserAccount';
import { LoadingOverlay } from '@/components/common/loading';
import { ErrorFallback } from '@/components/common';
import { ErrorBoundary } from 'react-error-boundary';
import { isDevEnvironment } from '@/utils/envConfig';
import { InventoryContextProvider } from '@/context/inventory/context';

const renderAppBasedRoute = (component: React.ReactNode, isRouteDenied = false) => {
  return isRouteDenied ? (
    <ErrorPage
      errorCode={i18n.t('pageNotFound.code')}
      title={i18n.t('pageNotFound.title')}
      description={i18n.t('pageNotFound.description')}
      technicalDetails="Route denied"
    />
  ) : (
    component
  );
};

const lazyComponent = (component: React.ReactNode) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Suspense fallback={<LoadingOverlay size="lg" />}>{component}</Suspense>
    </ErrorBoundary>
  );
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: (
      <ErrorPage
        errorCode={i18n.t('pageNotFound.code')}
        title={i18n.t('pageNotFound.title')}
        description={i18n.t('pageNotFound.description')}
        technicalDetails="Error at Root level"
      />
    ),
    children: [
      {
        errorElement: (
          <ErrorPage
            errorCode={i18n.t('pageNotFound.code')}
            title={i18n.t('pageNotFound.title')}
            description={i18n.t('pageNotFound.description')}
            technicalDetails="Error at root children level"
          />
        ),
        children: [
          {
            index: true,
            element: <RoleBasedAccRoute routeProps={predefinedRoutes.events} />,
          },
          {
            path: predefinedRoutes.events.path.slice(1),
            element: <RoleBasedAccRoute routeProps={predefinedRoutes.events} />,
          },
          {
            path: 'inventory/:id?',
            element: (
              <InventoryContextProvider>
                <RoleBasedAccRoute routeProps={predefinedRoutes.inventory} />
              </InventoryContextProvider>
            ),
          },
          {
            path: predefinedRoutes.templates.path.slice(1),
            element: <RoleBasedAccRoute routeProps={predefinedRoutes.templates} />,
          },
          {
            path: predefinedRoutes.templatePage.path.slice(1),
            element: <RoleBasedAccRoute routeProps={predefinedRoutes.templatePage} />,
          },
          {
            path: 'settings',
            element: renderAppBasedRoute(lazyComponent(<Settings />), AppModeIsDrive),

            children: [
              {
                errorElement: (
                  <ErrorPage
                    errorCode={i18n.t('pageNotFound.code')}
                    title={i18n.t('pageNotFound.title')}
                    description={i18n.t('pageNotFound.description')}
                    technicalDetails="error at settings children"
                  />
                ),
                children: [
                  {
                    element: renderAppBasedRoute(
                      <RoleBasedAccRoute routeProps={predefinedRoutes.companyInfo} />,
                      AppModeIsDrive,
                    ),

                    index: true,
                  },
                  {
                    path: 'billing',
                    element: renderAppBasedRoute(
                      <RoleBasedAccRoute routeProps={predefinedRoutes.billing} />,
                      AppModeIsDrive,
                    ),
                  },
                  {
                    path: 'notifications',
                    element: <Notifications />,
                  },
                  {
                    path: 'pos',
                    element: renderAppBasedRoute(
                      <RoleBasedAccRoute routeProps={predefinedRoutes.pos} />,
                      AppModeIsDrive,
                    ),
                  },
                  {
                    path: 'marketplace',
                    element: renderAppBasedRoute(<RoleBasedAccRoute routeProps={predefinedRoutes.marketplace} />),
                  },
                  {
                    path: 'users',
                    element: renderAppBasedRoute(
                      <RoleBasedAccRoute routeProps={predefinedRoutes.users} />,
                      AppModeIsDrive,
                    ),
                  },
                ],
              },
            ],
          },
          {
            path: 'payments',
            element: <RoleBasedAccRoute routeProps={predefinedRoutes.paymentsAndBilling} />,
          },
          {
            path: 'subscription',
            element: <RoleBasedAccRoute routeProps={predefinedRoutes.subscription} />,
          },
          {
            path: 'profile',
            element: lazyComponent(<Profile />),
            children: [
              {
                errorElement: (
                  <ErrorPage
                    errorCode={i18n.t('pageNotFound.code')}
                    title={i18n.t('pageNotFound.title')}
                    description={i18n.t('pageNotFound.description')}
                    technicalDetails="error at profile children"
                  />
                ),
                children: [
                  {
                    element: renderAppBasedRoute(lazyComponent(<UserAccount />), AppModeIsDrive),
                    index: !AppModeIsDrive,
                  },
                  {
                    path: 'notifications',
                    element: <UserNotifications />,
                    index: AppModeIsDrive,
                  },
                ],
              },
            ],
          },
          {
            path: 'OrganizationDefaults',
            element: renderAppBasedRoute(lazyComponent(<OrgDefaults />)),

            children: [
              {
                errorElement: (
                  <ErrorPage
                    errorCode={i18n.t('pageNotFound.code')}
                    title={i18n.t('pageNotFound.title')}
                    description={i18n.t('pageNotFound.description')}
                    technicalDetails="error at settings children"
                  />
                ),
                children: [
                  {
                    element: renderAppBasedRoute(<RoleBasedAccRoute routeProps={predefinedRoutes.pricing} />),
                    index: true,
                  },
                  {
                    element: renderAppBasedRoute(<RoleBasedAccRoute routeProps={predefinedRoutes.MarketComparables} />),
                    path: 'marketComparables',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/request-access',
    element: <ConfigurationContextProvider>{lazyComponent(<OrganizationRequest />)}</ConfigurationContextProvider>,
    errorElement: (
      <ErrorPage
        errorCode={i18n.t('pageNotFound.code')}
        title={i18n.t('pageNotFound.title')}
        description={i18n.t('pageNotFound.description')}
        technicalDetails="error request access level"
      />
    ),
  },
  {
    path: '/forgot-password',
    element: <NotificationsContextProvider>{lazyComponent(<ForgotPassword />)}</NotificationsContextProvider>,
    errorElement: (
      <ErrorPage
        errorCode={i18n.t('pageNotFound.code')}
        title={i18n.t('pageNotFound.title')}
        description={i18n.t('pageNotFound.description')}
        technicalDetails="error request access level"
      />
    ),
  },
  {
    path: '/validate-invitation',
    element: <MsalProviderInitializer>{lazyComponent(<ValidateInvitation />)}</MsalProviderInitializer>,
    errorElement: (
      <ErrorPage
        errorCode={i18n.t('pageNotFound.code')}
        title={i18n.t('pageNotFound.title')}
        description={i18n.t('pageNotFound.description')}
        technicalDetails="error validate inv level"
      />
    ),
  },
  {
    path: '/signout',
    element: <SignOut />,
    errorElement: (
      <ErrorPage
        errorCode={i18n.t('pageNotFound.code')}
        title={i18n.t('pageNotFound.title')}
        description={i18n.t('pageNotFound.description')}
        technicalDetails="Singout error"
      />
    ),
  },
  {
    path: '/components',
    element: isDevEnvironment ? (
      lazyComponent(<Components />)
    ) : (
      <ErrorPage errorCode="101" title="Component error" description="Something went wrong with the components" />
    ),
    errorElement: (
      <ErrorPage errorCode="101" title="Component error" description="Something went wrong with the components" />
    ),
  },
]);

export default router;
