import { useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { AppModeIsDrive, Role } from '@/utils/constants';
import { useUserApi, useUserState } from '@/context/user/context-hooks';
import { useOrganizationContext } from '@/context/organization/context-hooks';
import type { AppMenuItems, SideBarMenu, SideBarMenuItem } from '@/interfaces/CommonInterfaces';
import { useCommonContext } from '@/context/common/context-hooks';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { predefinedRoutes, SidebarMenuItems } from '@/nav/constants';
import { Tooltip } from '@/components/common';
import { QuickLinks } from '@/components/sidebar/quick-links';
import NavMenuPrefs from '@/components/NavigationMenu/NavMenuPrefs';
import logoSBDriveMain from '@/assets/images/base/logo-sbdrive-powerby.svg';
import logoShort from '@/assets/images/base/logo_short.svg';
import logoSBDriveMini from '@/assets/images/base/logo-sbdrive-powerby-mini.svg';
import logoShortWhite from '@/assets/images/base/short-white-version.svg';
import NavDelimiter from '@/components/NavigationMenu/NavDelimiter';
import ExpandedMenu from '@/components/NavigationMenu/ExpandedMenu';
import { NotificationsDropMenu } from '@/components/common/notifications';
import ProfileDropMenu from '@/components/profile/ProfileDropMenu';
import { useEventListener } from 'usehooks-ts';
import { usePosStateContext } from '@/context/pos/context-hooks';
import { getPosLogo, getPosType } from '@/assets/assetFinder';
import NavLinkIfPermitted from '@/components/common/NavLinkIfPermitted';
import { TooltipPosition } from '@/components/common/tooltip/constants';
import type { Placement } from '@floating-ui/react';
import i18n from '@/utils/i18n';

interface iProps {
  containerCssClasses?: {
    [key: string]: boolean;
  };
  isNavBarHorizontal?: boolean;
  isNavBarVertical?: boolean;

  isNavBarOnTop?: boolean;
  isNavBarOnBottom?: boolean;
  isNavBarOnLeft?: boolean;
  isNavBarOnRight?: boolean;
  isFloatingNavigation?: boolean;
}
function NavMenuContainer({
  containerCssClasses,
  isNavBarVertical,
  isNavBarHorizontal,
  isNavBarOnRight,
  isNavBarOnBottom,
  isNavBarOnLeft,
  isNavBarOnTop,
  isFloatingNavigation,
}: iProps) {
  const {
    preferences: { navigation },
    isNavigationMinimized,
  } = useUserState();
  const { setNavigationMinimized } = useUserApi();

  const [mouseLeftNavigation, setMouseLeftNavigation] = useState(false);
  const [minimizeTimeout, setMinimizeTimeout] = useState<NodeJS.Timeout | null>(null);
  const isNavigationMinimizeAndFloat = isNavigationMinimized && isFloatingNavigation;
  const shouldNavigationMinimize = navigation.autoMinimize && isFloatingNavigation;

  useEffect(() => {
    if (mouseLeftNavigation) {
      if (isNavigationMinimizeAndFloat) {
        return;
      }
      setMinimizeTimeout(
        setTimeout(() => {
          if (shouldNavigationMinimize) {
            setNavigationMinimized(true);
          }
        }, 2_000),
      );
    } else {
      if (minimizeTimeout) {
        clearTimeout(minimizeTimeout);
      }
    }

    return () => {
      if (minimizeTimeout) {
        clearTimeout(minimizeTimeout);
      }
    };
  }, [mouseLeftNavigation]);

  const { organization } = useOrganizationContext();

  const [menuItems, setMenuItems] = useState<SideBarMenu | null>(null);
  const { rbacFeatures } = useCommonContext();

  const location = useLocation();

  const [tooltipPosition, setTooltipPosition] = useState<Placement>(TooltipPosition.Bottom);

  const { user } = useUserState();

  const isUserAdmin = useMemo(() => {
    return user?.roles?.find((role) => role.key === Role.ADMIN);
  }, [user]);

  useEffect(() => {
    if (isNavBarOnTop || isNavBarOnBottom) {
      setTooltipPosition(TooltipPosition.Bottom);
    } else if (isNavBarOnLeft) {
      setTooltipPosition(TooltipPosition.Right);
    } else {
      setTooltipPosition(TooltipPosition.Left);
    }
  }, [navigation.location]);

  useEffect(() => {
    if (!rbacFeatures) return;

    if (rbacFeatures.menuItems?.length) {
      const appsItems = SidebarMenuItems.apps.items.filter((item) => {
        return rbacFeatures.menuItems?.find((menuItem) => menuItem.key === item.key);
      }) as SideBarMenuItem[];

      let companyInfoItems: SideBarMenuItem[] = [];
      if (!AppModeIsDrive && !isUserAdmin && predefinedRoutes.companyInfo.subItems?.length) {
        companyInfoItems = predefinedRoutes.companyInfo.subItems.filter((subItem) => {
          return rbacFeatures.menuItems?.find((menuItem) => menuItem.key === subItem.key);
        });
      }

      const settingsItems =
        AppModeIsDrive && isUserAdmin
          ? ([predefinedRoutes.pricing] as SideBarMenuItem[])
          : (SidebarMenuItems.systemSettings.items.filter((item) => {
              if (item.key === predefinedRoutes.companyInfo.key && companyInfoItems.length) {
                return true;
              }
              return rbacFeatures.menuItems?.find((menuItem) => menuItem.key === item.key);
            }) as SideBarMenuItem[]);

      if (companyInfoItems.length) {
        const companyInfoIndex = settingsItems.findIndex((item) => item.key === predefinedRoutes.companyInfo.key);
        if (companyInfoIndex > -1) {
          settingsItems[companyInfoIndex].subItems = companyInfoItems;
        }
      }

      const activeMenuItems = {
        apps: {
          items: appsItems,
        },
        systemSettings: {
          items: settingsItems,
        },
      };

      setMenuItems(activeMenuItems);
    }
  }, [rbacFeatures]);

  function renderMenuGroup(menu: AppMenuItems | undefined) {
    if (!menu?.items || menu?.items.length === 0) return;

    return (
      <ul className="flex h-full flex-row">
        {menu.items.map((item: SideBarMenuItem) => (
          <li key={item.path} className="h-full">
            <Tooltip message={item.name} position={tooltipPosition}>
              <NavLink
                to={item.path}
                className={({ isActive }) => {
                  const isEventsIndexPage = location.pathname === '/' && item.path === predefinedRoutes.events.path;
                  const isNestedPath =
                    location.pathname === '/settings/users' && item.path === predefinedRoutes.companyInfo.path;
                  const isCurrent = (isActive || isEventsIndexPage) && !isNestedPath;
                  return clsx('flex h-full items-center px-1', {
                    [styles.menuActive]: isCurrent,
                    'hidden sm:flex': !isCurrent && !isNavigationMinimizeAndFloat && isNavBarHorizontal,
                    'hidden vlg:flex': !isCurrent && !isNavigationMinimizeAndFloat && isNavBarVertical,
                    hidden: !isCurrent && isNavigationMinimizeAndFloat,
                  });
                }}
              >
                {item.icon ? (
                  <item.icon
                    className={clsx('text-txt-primary', {
                      '-rotate-90': isNavBarOnRight || isNavBarOnLeft,
                      'h-6 w-6': !isNavigationMinimizeAndFloat,
                      'h-3 w-3': isNavigationMinimizeAndFloat,
                    })}
                  />
                ) : null}
              </NavLink>
            </Tooltip>
          </li>
        ))}
      </ul>
    );
  }

  function renderQuickLinks() {
    return (
      <ul
        className={clsx('hidden h-full flex-row', {
          'md:flex': isNavBarHorizontal,
          'vlg:flex': isNavBarVertical,
        })}
      >
        {QuickLinks.filter((x) => !AppModeIsDrive || x.showInDrive).map((item) => (
          <li key={item.url} className="h-full">
            <Tooltip message={item.label} position={tooltipPosition}>
              <Link to={item.url} className={clsx('flex h-full w-8 items-center px-1')} target="_blank">
                <img
                  src={item.icon}
                  className={clsx('h-5 w-5', {
                    '-rotate-90': isNavBarOnRight || isNavBarOnLeft,
                  })}
                  alt={item.label}
                />
              </Link>
            </Tooltip>
          </li>
        ))}
      </ul>
    );
  }

  function noClicker() {
    return isNavigationMinimizeAndFloat ? <div className="absolute inset-0 z-10 "></div> : null;
  }

  const refNavMenu = useRef<HTMLDivElement>(null);

  useEventListener('mousedown', (event) => {
    if (shouldNavigationMinimize && !refNavMenu.current?.contains(event.target as Node)) {
      setNavigationMinimized(true);
    }
  });

  const { systemSettings, apps } = menuItems ?? {};
  const { posIntegrations } = usePosStateContext();

  const [activePOSSourceType, setActivePOSSourceType] = useState<string | null>(null);
  useEffect(() => {
    if (posIntegrations?.length) {
      const activePos = posIntegrations.find((pos) => pos.isPosValid);
      if (activePos) {
        setActivePOSSourceType(getPosType(activePos));
      }
    }
  }, [posIntegrations, systemSettings?.items]);

  return (
    <div
      ref={refNavMenu}
      className={clsx(
        styles.container,
        {
          'inset-0 h-9 transition-all duration-300': !isFloatingNavigation,
          'border border-primary-main': isFloatingNavigation,
          'h-9 rounded-md': isFloatingNavigation && !isNavigationMinimizeAndFloat,
          '-mt-0.5 h-5 px-8': isFloatingNavigation && isNavigationMinimizeAndFloat,
          'rounded-b-xl': isFloatingNavigation && isNavigationMinimizeAndFloat && (isNavBarOnTop || isNavBarOnRight),
          'rounded-t-xl': isFloatingNavigation && isNavigationMinimizeAndFloat && (isNavBarOnBottom || isNavBarOnLeft),
          'cursor-pointer transition-all duration-300 hover:scale-125 hover:ring-2': isNavigationMinimizeAndFloat,
        },
        containerCssClasses,
      )}
      onClick={(ev) => {
        if (isNavigationMinimizeAndFloat) {
          setNavigationMinimized(false);
        }
      }}
      onMouseLeave={() => {
        setMouseLeftNavigation(true);
      }}
      onMouseEnter={() => {
        setMouseLeftNavigation(false);
      }}
    >
      <div
        className={clsx('relative flex h-full items-center gap-4 pr-4', {
          'overflow-hidden': isNavigationMinimizeAndFloat,
        })}
      >
        {noClicker()}
        <div className="flex flex-row items-center">
          <NavMenuPrefs />
          {!isNavigationMinimizeAndFloat ? (
            <img
              src={AppModeIsDrive ? logoSBDriveMain : logoShort}
              alt="logo"
              className=" w-[4.25rem]  min-w-[4.25rem]"
            />
          ) : null}
          {isNavigationMinimizeAndFloat ? (
            <img
              src={AppModeIsDrive ? logoSBDriveMini : logoShortWhite}
              alt="logo"
              className=" w-[2.75rem] min-w-[2.75rem]"
            />
          ) : null}
        </div>
        {!isNavigationMinimizeAndFloat ? <NavDelimiter /> : null}
        {!isNavigationMinimizeAndFloat ? (
          <Tooltip message={organization?.name} position={tooltipPosition}>
            <h1
              className={clsx(styles.orgName, 'hidden', {
                'sm:block': isNavBarHorizontal,
                'vlg:block': isNavBarVertical,
              })}
            >
              {organization?.name || 'Organization name'}
            </h1>
          </Tooltip>
        ) : null}
        {!isNavigationMinimizeAndFloat ? (
          <NavDelimiter
            className={clsx('hidden', {
              'sm:block': isNavBarHorizontal,
              'vlg:block': isNavBarVertical,
            })}
          />
        ) : null}
      </div>
      <div
        className={clsx('relative flex items-center', {
          'overflow-hidden': isNavigationMinimizeAndFloat,
        })}
      >
        {noClicker()}
        <div className="h-full">{renderMenuGroup(apps)}</div>
        {!isNavigationMinimizeAndFloat ? (
          <NavDelimiter
            className={clsx('mx-4 hidden', {
              'sm:block': isNavBarHorizontal,
              'vlg:block': isNavBarVertical,
            })}
          />
        ) : null}
        {systemSettings?.items.length ? (
          <>
            <div className="h-full">{renderMenuGroup(systemSettings)}</div>
            {!isNavigationMinimizeAndFloat ? (
              <NavDelimiter
                className={clsx('mx-4 hidden', {
                  'md:block': isNavBarHorizontal,
                  'vlg:block': isNavBarVertical,
                })}
              />
            ) : null}
          </>
        ) : null}
        {!isNavigationMinimizeAndFloat ? <div className="h-full">{renderQuickLinks()}</div> : null}
        {!isNavigationMinimizeAndFloat ? <NavDelimiter className="mx-4" /> : null}
        {isNavigationMinimizeAndFloat ? <NavDelimiter className="mx-2 !border-other-delimiter" /> : null}
        <div className="flex flex-row items-center pr-2">
          <div className="flex flex-row-reverse items-center">
            {!isNavigationMinimizeAndFloat && activePOSSourceType ? (
              <NavLinkIfPermitted
                hasPermission={systemSettings?.items.includes(predefinedRoutes.companyInfo)}
                to={predefinedRoutes.pos.path}
                className={clsx('mr-2 hidden h-full items-center', {
                  'sm:flex': isNavBarHorizontal,
                  'vlg:block': isNavBarVertical,
                })}
              >
                <Tooltip message={i18n.t('navigation.tooltips.pos')}>
                  <img
                    src={getPosLogo(activePOSSourceType)}
                    className={clsx('h-6 min-w-6 ', {
                      '-rotate-90': isNavBarVertical,
                    })}
                    alt=""
                  />
                </Tooltip>
              </NavLinkIfPermitted>
            ) : null}
            {!isNavigationMinimizeAndFloat && apps?.items ? (
              <ExpandedMenu pages={apps?.items} systemPages={systemSettings?.items} />
            ) : null}
          </div>

          <NotificationsDropMenu />
          {!isNavigationMinimizeAndFloat ? <ProfileDropMenu /> : null}
        </div>
      </div>
    </div>
  );
}

const styles = {
  container: 'fixed flex flex-row justify-between bg-black z-10',
  orgName: 'text-sm font-bold uppercase text-txt-primary max-w-16 lg:max-w-sm xl:max-w-xl truncate',
  menuActive: 'bg-gradient-to-r from-primary-main to-primary-alt-med',
  expandMenuButton: 'my-2 mr-2 h-5 w-5 origin-center transform text-txt-primary transition-all',
  expandedMenuContainer:
    'absolute right-0 z-10 mt-6 w-52 bg-paper-card py-1 drop-shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none',
};

export default NavMenuContainer;
