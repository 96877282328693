export default {
  notifications: {
    applying: {
      title: '',
      message: 'Saving organization pricing defaults.',
    },
    success: {
      title: '',
      message: 'Successfully saved organization pricing defaults.',
    },
    error: {
      title: '',
      message: 'Failed to save organization pricing defaults.',
    },
  },
  infoMessage:
    'Set the organization-wide org defaults that helps all organization members quickly work on pricing. Any changes to org defaults will be applied to new pricing groups created afterwards. To reset back to the system defaults, select “Restore System Defaults” ',
  labels: {
    restore: 'Restore System Defaults',
    inputToolTip: 'Enter numbers only',
  },
  pricingCriteria: {
    title: 'Pricing criteria settings options',
    criteriaOffset: {
      title: 'Criteria Offset',
      type: 'Type',
      method: 'Method',
      amount: 'Amount (Optional)',
      message:
        'The system default is to decrease by dollar amount. Anchor ticket group’s suggested price will be offset based on the criteria offset from the active market comparable listings.',
    },
    floorPrice: {
      title: 'Floor price value',
      message:
        'System default is to disable. When enabled, a floor price will be calculated and provided based on your setting.',
      method: 'Method',
      calculationValue: 'Calculation value',
      error: {
        noMethod: 'Method is required',
        noValue: 'Calculation value is required',
        empty: 'Required fields',
        lessThanCeiling: 'Floor price must be less than ceiling price',
      },
    },
    ceilingPrice: {
      title: 'Ceiling price value',
      requirementTitle: 'Ceiling value requirement',
      message:
        'System default is to disable. When enabled, a ceiling price will be calculated and provided based on your setting.',
      requirementMessage: 'System default is required for pricing group',
      error: {
        noMethod: 'Method is required',
        noValue: 'Calculation value is required',
        empty: 'Required fields',
      },
      Required: 'Ceiling is required for pricing group',
      Optional: 'Pricing group can be created without ceiling',
    },
    groupOffset: {
      title: 'Group Offset',
      message:
        'The system default is to decrease by dollar amount. Each ticket group’s suggested price will be offset from the anchor by ranking order based on the group offset amount.',
    },
    orgDefaults: {
      apply: 'Apply org default setting.',
      default: 'Org default applied.',
      none: 'No org default created.',
      offsetDirection: 'Offset direction: %{value}',
      offsetUnit: 'Offset unit: %{value}',
      offsetAmount: 'Offset amount: %{value}',
      floor: 'Floor option to use %{value}',
      floorPercent: 'Floor: %{value}% of cost',
      ceiling: 'Ceiling option to use %{value}',
      ceilingPercent: 'Ceiling: %{value}% of cost;',
      noComps: 'No comps option to %{value}',
      groupOffsetDirection: 'Group offset direction: %{value}',
      groupOffsetUnit: 'Group offset unit: %{value}',
      groupOffsetAmount: 'Group offset amount: %{value}',
      isShareOptionEnabled: 'Share enabled: %{value}',
      shareAnchor: 'Share anchor: %{value}',
      shareOptionsType: 'Share options type: %{value}',
      customSharingMethod: 'Custom sharing method: %{value}',
      customSharingAmount: 'Custom sharing amount: %{value}',
      pricingGroupRequestType: 'Push option: %{value}',
    },
  },
  shareOption: {
    title: 'Share options',
    autoPrice: {
      title: 'Pricer QX controls sharing when auto-pricing',
      message: 'The system default is enabled. When disabled, market drip is controlled by pricer manually.',
    },
    shareAnchor: {
      title: 'Sharing anchor ticket group',
      message:
        'System default is to share. When unshared, the anchor ticket will not be shared regardless of other options selected.',
    },
    shareGroups: {
      title: 'Sharing ticket groups',
      message:
        'System default is to share all. Sharing is based on the rankings in a pricing group when selecting even, odds, or custom.',
      method: 'Method',
      ticketGroups: 'Ticket groups',
      error: {
        noMethod: 'Method is required',
        noValue: 'Ticket groups is required',
        empty: 'Required fields',
      },
      options: {
        ALL: 'Share all ticket groups',
        EVEN: 'Share even ranking ticket groups',
        ODD: 'Share odd ranking ticket groups',
        CUSTOM: 'Share specified ticket groups based on the ranking',
      },
      methods: {
        '#': 'Use number',
        '%': 'Use percentage',
      },
    },
  },
  pushOption: {
    title: 'Push option',
    updatePos: {
      title: 'Updating POS list price',
      message:
        'The system default is Auto price to update POS list price. When selecting Simulate, PRQX monitors the market for suggesting the price without updating POS list price.',
    },
  },
  advanceOption: {
    title: 'Advanced settings options',
    useOrgDefault: 'Use system default',
    hitFloor: {
      title: 'Floor option when Suggested price hits floor',
      message: 'The system default is to use Floor price.',
      useFloor: 'Use Floor Price',
      randomizeFloor: 'Randomize the pricing when hits floor',
    },
    hitCeiling: {
      title: 'Ceiling option when Suggested price hits ceiling',
      message: 'The system default is to use Ceiling price.',
      useCeiling: 'Use Ceiling Price',
      randomizeCeiling: 'Randomize the pricing when hits ceiling',
    },
    noComps: {
      title: 'No comps option',
      message: 'The system default is to freeze pricing.',
      freezePricing: 'Freeze pricing until new comps',
      useFloorAnchor: 'Use Floor Price as anchor',
      useCeilingAnchor: 'Use Ceiling Price as anchor',
      fixCeiling: 'Fix all prices at ceiling price',
      randomizeCeiling: 'Randomize pricing off ceiling price',
      ceilingWarning: 'Pricing criteria with optional ceiling enabled will require ceiling to be entered',
    },
  },
};
