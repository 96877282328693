/* eslint-disable max-lines */
export default {
  default: {
    title: 'Request error',
    message: 'Something went wrong, please try again later.',
  },
  '000-001': {
    title: 'Unexpected Exception',
    message: 'Unexpected System Exception.',
  },
  '000-002': {
    title: 'Not Null',
    message: 'Field must not be null',
  },
  '000-003': {
    title: 'Not Empty',
    message: 'Field must not be empty',
  },
  '000-004': {
    title: 'Not Equal',
    message: 'Fields must not be equal',
  },
  '	000-005': {
    title: 'Equal',
    message: 'Fields must be equal',
  },
  '	000-006': {
    title: 'Length',
    message: 'Field length is not valid',
  },
  '	000-007': {
    title: 'Max Length',
    message: 'Field length is too long',
  },
  '	000-008': {
    title: 'Min Length',
    message: 'Field length is too short',
  },
  '	000-009': {
    title: 'Less Than',
    message: 'Value must be less than',
  },
  '	000-010': {
    title: 'Less Than Or Equal',
    message: 'Value must be less than or equal to',
  },
  '	000-011': {
    title: 'Greater Than',
    message: 'Value must be greater than',
  },
  '	000-012': {
    title: 'Greater Than Or Equal',
    message: 'Value must be greater than or equal to',
  },
  '	000-013': {
    title: 'Predicate',
    message: 'Custom predicate validation failed',
  },
  '	000-014': {
    title: 'Regular Expression',
    message: 'Regular expression validation failed',
  },
  '	000-015': {
    title: 'Email',
    message: 'Email is not valid',
  },
  '	000-016': {
    title: 'Credit Card',
    message: 'Credit card number is not valid',
  },
  '	000-017': {
    title: 'Enum',
    message: 'Value is not a valid enumeration value',
  },
  '	000-018': {
    title: 'Enum Name',
    message: 'Value is not a valid enumeration name',
  },
  '	000-019': {
    title: 'Empty',
    message: 'Value must be empty',
  },
  '	000-020': {
    title: 'Null',
    message: 'Value must be null',
  },
  '	000-021': {
    title: 'Exclusive Between',
    message: 'Value must be between exclusive range',
  },
  '	000-022': {
    title: 'Inclusive Between',
    message: 'Value must be between inclusive range',
  },
  '	000-023': {
    title: 'Precision Scale',
    message: 'Value does not meet precision/scale requirements',
  },
  '001-001': {
    title: 'Member Password Invalid',
    message:
      'Sign in failed! Please verify the username and password to ensure they are correct, and try again. Contact your system admin if you think there is an issue.',
  },
  '001-002': {
    title: 'Member Username Invalid',
    message:
      'Sign in failed! Please verify the username and password to ensure they are correct, and try again. Contact your system admin if you think there is an issue.',
  },
  '001-003': {
    title: 'Invalid Password Strength',
    message:
      'Strong passwords required. Password must be a minimum of 8 characters containing at least 1 upper case letter, 1 number and 1 special character',
  },
  '001-004': {
    title: 'System Sign In Service Failed',
    message:
      'Oh No! Something went wrong! Please try again. If you continue to have issues, please contact PricerQX Support.',
  },
  '001-005': {
    title: 'Multiple Sign Ins Detected',
    message:
      'Multiple sign ins detected! We see that you are already logged into PRQX from another device. Logging in now will sign you out from all other devices. If you do not want to sign off the other device, select "Back to sign in". Otherwise click Sign in.',
  },
  '001-006': {
    title: 'MFA Failed',
    message:
      'Invalid or Expired Code! Please verify the code and try again, or request a new code. Contact your system admin if you think there is an issue.',
  },
  '001-007': {
    title: 'Password Mismatch',
    message: 'Password mismatch! Please check your entries and try again.',
  },
  '001-008': {
    title: 'Invalid phone number format',
    message: 'Invalid phone number format.',
  },
  '001-009': {
    title: 'Username not unique',
    message: 'Username taken! We see that someone has taken that username. Please try another name.',
  },
  '001-010': {
    title: 'Invalid email format',
    message: 'Invalid email format.',
  },
  '001-011': {
    title: 'T&C - Did not Agree',
    message: 'You must view and agree to the terms and conditions to continue.',
  },
  '001-012': {
    title: 'Name should be set for an member invitation',
    message: 'Name should be set for an member invitation.',
  },
  '001-013': {
    title: 'Email should be set for an member invitation',
    message: 'Email should be set for an member invitation.',
  },
  '001-014': {
    title: 'Email needs to be valid for an member invitation',
    message: 'Email needs to be valid for an member invitation.',
  },
  '001-015': {
    title: 'Organization id should be set for an member invitation',
    message: 'Organization id should be set for an member invitation.',
  },
  '001-016': {
    title: 'Token should be set for an member invitation validation',
    message: 'Token should be set for an member invitation validation.',
  },
  '001-017': {
    title: 'Invitation Rejected - "Invitation token is deleted',
    message: 'Invitation rejected - "Invitation token is deleted"',
  },
  '001-018': {
    title: 'Invitation Rejected - "Invitation token is already used"',
    message: 'Invitation rejected - "Invitation token is already used".',
  },
  '001-019': {
    title: 'Invitation Rejected - "Invitation token is expired"',
    message: 'Invitation rejected - "Invitation token is expired".',
  },
  '001-020': {
    title: 'Invitation Rejected - "Invitation token is invalid"',
    message: 'Invitation rejected - "Invitation token is invalid".',
  },
  '001-021': {
    title: 'Member does not have an invitation',
    message: 'Member does not have an invitation.',
  },
  '001-022': {
    title: 'Error deleting invitation token',
    message: 'Error deleting invitation token.',
  },
  '001-023': {
    title: 'Error Generating Invitation Token',
    message: 'Error generating invitation token.',
  },
  '001-024': {
    title: 'Error Modifying Invitation Token',
    message: 'Error modifying invitation token.',
  },
  '001-025': {
    title: 'Invitation Cannot Be Sent',
    message: 'Invitation cannot be sent.',
  },
  '001-026': {
    title: 'Invitation Code Missing',
    message: 'Invitation code missing.',
  },
  '001-027': {
    title: 'Error Redeeming Invitation Token',
    message: 'Error redeeming invitation token.',
  },
  '001-028': {
    title: 'Error Forcing member Password Reset',
    message: 'Error forcing member password reset.',
  },
  '001-029': {
    title: 'Client member Not Found',
    message: 'Client member not found.',
  },
  '001-030': {
    title: 'Invitation Rejected: Does Not Exist',
    message: 'Invitation rejected: Does Not Exist.',
  },
  '001-031': {
    title: 'Member with Email Already Exists',
    message: 'Member with email already exists.',
  },
  '001-032': {
    title: 'Organization Not Found',
    message: 'Organization not found.',
  },
  '001-033': {
    title: 'Organization Request Already Exists',
    message: 'Organization request already exists.',
  },
  '001-034': {
    title: 'Member Already Been Invited',
    message: 'Member already been invited.',
  },
  '001-035': {
    title: 'Username already been used',
    message: 'Username already been used.',
  },
  '001-036': {
    title: 'Error changing member status',
    message: 'Error changing member status.',
  },
  '001-037': {
    title: 'Organization inactive or deleted',
    message: 'Organization inactive or deleted.',
  },
  '001-038': {
    title: 'Organization Request Not Found',
    message: 'Organization Request Not Found.',
  },
  '001-039': {
    title: 'Role Not Found',
    message: 'Role Not Found.',
  },
  '001-040': {
    title: 'Permission Not Found',
    message: 'Permission Not Found.',
  },
  '001-041': {
    title: 'Member inactive or deleted',
    message: 'Member inactive or deleted.',
  },
  '002-001': {
    title: 'Unexpected System Exception',
    message: 'Unexpected Exception.',
  },
  '002-002': {
    title: 'No data available',
    message: 'No data available.',
  },
  '002-003': {
    title: 'Invalid row sequence',
    message:
      'Could not match a sequence. Please use ComparablesDto.RowInclude to provide the list of row to include instead.',
  },
  '002-004': {
    title: 'Ranks should be sequential with no gaps, no duplications and starting with 0.',
    message: 'Ranks should be sequential with no gaps, no duplications and starting with 0.',
  },
  '002-005': {
    title: 'Pricing Result is required when PricingGroupRequestType is ONE_TIME_PUSH.',
    message: 'PricingResult is required when PricingGroupRequestType is ONE_TIME_PUSH.',
  },
  '002-006': {
    title: 'Ceiling price must be greater than or equal to floor price.',
    message: 'Ceiling price must be greater than or equal to floor price.',
  },
  '002-007': {
    title: 'Invalid metric key value.',
    message: 'Invalid metric key value.',
  },
  '002-008': {
    title: 'PricingGroup with more than one ticketGroup requires a name',
    message: 'Name is required when\nPricing Group has more than one ticket group',
  },
  '002-009': {
    title: 'Name has invalid characters',
    message: 'Name must match\nA-Za-z0-9!#$%&*()@-+{}[]<>?',
  },
  '002-010': {
    title: "Invalid Increment value. Allowed values are 'increase' or 'decrease'.",
    message: "Invalid Increment value. Allowed values are 'increase' or 'decrease'.",
  },
  '002-011': {
    title: "Invalid Increment Method. Allowed values are '%' or '$'.",
    message: "Invalid Increment Method. Allowed values are '%' or '$'.",
  },
  '002-012': {
    title: 'Amount must be greater than 0',
    message: 'Amount must be greater than 0.',
  },
  '002-013': {
    title: 'Invalid Offset Increment',
    message:
      "Invalid Offset Increment. Allowed values are 'increase', 'decrease' or null when only one ticketgroup is present for a pricing group.",
  },
  '002-014': {
    title: 'Invalid Offset Increment Method',
    message:
      "Invalid Offset Increment Method. Allowed values are '%', '$' or null when only one ticketgroup is present for a pricing group.",
  },
  '002-015': {
    title: 'Invalid Offset Amount',
    message: 'Offset Amount must be greater than 0 or null.',
  },
  '002-016': {
    title: 'Invalid Share Options Type',
    message: 'Invalid Share Options Type.',
  },
  '002-017': {
    title: 'TicketGroupId or PricingGroupId error',
    message: 'Either TicketGroupId or PricingGroupId should be set, but not both.',
  },
  '002-018': {
    title: 'TicketGroupId  error',
    message: 'TicketGroupId must be greater than 0 when set.',
  },
  '002-019': {
    title: 'Cannot change price',
    message: 'Cannot change price when auto pricing is enabled',
  },
  '002-020': {
    title: 'Missing TicketGroupId or PricingGroupId',
    message: 'Either TicketGroupId or PricingGroupId must be provided.',
  },
  '002-021': {
    title: 'Missing ListPrice or IsShared',
    message: 'Either ListPrice or IsShared must be provided.',
  },
  '002-022': {
    title: 'Invalid range specified',
    message: 'Invalid range specified.',
  },
  '002-023': {
    title: 'ShareAnchor error',
    message: 'ShareAnchor must be set when enabledSharing is true.',
  },
  '002-024': {
    title: 'Pricing group update error',
    message: 'Existing pricing group cannot be updated as a One-Time update.',
  },
  '002-025': {
    title: 'Pricing group name already exists',
    message: 'Pricing group name already exists.',
  },
  '002-026': {
    title: 'TicketGroup group conflict',
    message: 'Remove unavailable ticket groups or correct the other pricing group.',
  },
  '002-027': {
    title: 'Username Required',
    message: 'Username Required.',
  },
  '002-028': {
    title: 'Cannot change sharing when auto pricing is enabled',
    message: 'Cannot change sharing when auto pricing is enabled.',
  },
  '002-029': {
    title: 'Cannot change price',
    message: 'Cannot change price when auto pricing is enabled.',
  },
  '002-030': {
    title: 'Could Not Deserialize Body',
    message: 'Could Not Deserialize Body.',
  },
  '002-031': {
    title: 'Update values are identical to current state',
    message: 'Update values are identical to current state.',
  },
  '002-032': {
    title: 'Entity not found',
    message: 'Entity not found.',
  },
  '002-033': {
    title: 'Invalid ProductionId',

    message: 'Invalid ProductionId.',
  },
  '002-034': {
    title: 'Pricing group contains sold ticketgroups',
    message: 'Pricing group contains sold ticketgroups.',
  },
  '002-035': {
    title: 'No metrics found',
    message: 'No metrics found.',
  },
  '002-036': {
    title: 'Cannot share or update ticket group price',
    message: 'Cannot share or update ticket group price due to a pending operation.',
  },
  '002-037': {
    title: 'Invalid Pricing Group Request Type',
    message: 'Invalid Pricing Group Request Type.',
  },
  '002-038': {
    title: 'Cannot Find Pricer Organization Inventory',
    message: 'Cannot Find Pricer Organization Inventory.',
  },
  '002-039': {
    title: 'Invalid Change Status',
    message: 'Invalid Change Status.',
  },
  '002-040': {
    title: 'Invalid Revert Data',
    message: 'Invalid Revert Data.',
  },
  '002-041': {
    title: 'Could not save changes',
    message: 'Could not save changes.',
  },
};
