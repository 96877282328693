import type { IMetricsQueryParams } from '@/interfaces/productions/IMetrics';
import { AuthService } from '@/services/AuthService';
import { HttpStatusCodes } from './HttpStatusCodes';
import { UnsavedChangesBrowserManager } from './UnsavedChangesBrowserManager';

export const apiEndpoints = {
  sendInvitation: '/api/Invitation/SendInvitation',
  resendInvitation: '/api/Invitation/ResendInvitation',
  validateInvitation: '/api/Invitation/ValidateInvitationToken',
  cancelInvitation: '/api/Invitation',
  profile: '/api/clientusersapp/profile',
  organizationRequest: '/api/OrganizationsApp/CreateOrgRequest',
  users: '/api/ClientUsersApp',
  userForceResetPassword: '/api/ClientUsersApp/ForceResetPassword',
  userChangePassword: '/api/ClientUsersApp/change-password',
  requestPasswordReset: '/api/ClientUsersApp/reset-password',
  applyPasswordReset: '/api/ClientUsersApp/apply-password-reset',
  validatePasswordReset: '/api/ClientUsersApp/validate-password-reset',
  organization: '/api/organizationsapp',
  pricer: {
    productions: '/v1/client/production',
    assignment: (productionId: string) => `/v1/client/Production/${productionId}/assignment`,
    metrics: (key: string, days: number) => `/v1/client/Metrics/organization-metrics?key=${key}&days=${days}`,
    metricsKeys: {
      all: 'all',
      getmetricssingle: 'getmetricssingle',
      ceilingCount: 'ceiling-count',
      floorCount: 'floor-count',
      ticketGroupsWithinXDays: 'ticket-groups-within-x-days',
      noCriteriaCount: 'no-criteria-count',
      noComparablesCount: 'no-comparables-count',
      totalTicketsAvailable: 'total-tickets-available',
      totalTicketsPerOrg: 'total-tickets-per-org',
      totalTicketsSoldPerOrg: 'total-tickets-sold-per-org',
      lastSoldPrice: 'last-sold-price',
      averageSoldPrice: 'average-sold-price',
      averagePrice: 'average-price',
      marketAveragePrice: 'market-average-price',
    } as IMetricsQueryParams,
    inventoryMetrics: (productionId: string, key: string, days: number) =>
      `/v1/client/Metrics?key=${key}&productionId=${productionId}&days=${days}`,
    inventoryMetricsKeys: {
      all: 'all',
      lastSoldPrice: 'last-sold-price',
      averageSoldPrice: 'average-sold-price',
      averagePrice: 'average-price',
      marketAveragePrice: 'market-average-price',
    },
    marketplace: '/v1/client/Marketplace',
    calculatePrice: '/v1/client/pricing-engine/calculate-price',
    setPricingGroup: '/v1/client/pricing-group',
    getPricingGroup: (pricingGroupId: string) => `/v1/client/pricing-group/${pricingGroupId}`,
    getPricingGroupsByVenue: (venueId: string) => `/v1/client/pricing-group/venue/${venueId}`,
    getPricingGroupsByProduction: (productionId: string) => `/v1/client/pricing-group/production/${productionId}`,
    comparables: '/v1/client/Marketplace/comparables',
    marketplaceProperties: `/v1/client/Marketplace/properties`,
    marketplaceValidation: '/v1/client/Marketplace/comparables/validation',
    attributes: '/v1/client/ticket-attributes',
    share: '/v1/client/pricing-group/share',
  },
  configuration: {
    key: (key: string) => `/api/v1/configuration/${key}`,
    countries: '/api/v1/configuration/countryChoices',
    regions: '/api/v1/configuration/stateChoices',
  },
  pos: {
    credentials: '/v1/client/poscredential',
    all: '/v1/client/poscredential/pointofsales',
    deletePOS: (posOrganizationId: number) => `/v1/client/poscredential/${posOrganizationId}`,
    testConnection: (posOrganizationId: number) => `/v1/client/poscredential/${posOrganizationId}`,
  },
};

/**
 * ClientApi
 * @param path
 */
export function getURL(path = '') {
  const base_url = import.meta.env.REACT_APP_PUBLIC_API_URL;
  return `${base_url}${path}`;
}

/**
 * PricerApi
 * @param path
 */
export function getPricerURL(path = '') {
  const base_url = import.meta.env.REACT_APP_PRICER_ENGINE_URL;
  return `${base_url}${path}`;
}

export function getConfigURL(path = '') {
  const base_url = import.meta.env.REACT_APP_BE_CONFIGURATION_SERVICE_URL;
  return `${base_url}${path}`;
}

export function getPOSIntegrationURL(path = '') {
  const base_url = import.meta.env.REACT_APP_POS_INTEGRATION_URL;
  return `${base_url}${path}`;
}

export function getMarketplaceManagementURL(path = '') {
  const base_url = import.meta.env.REACT_APP_MARKETPLACE_MANAGEMENT_URL;
  return `${base_url}${path}`;
}

const getResultJson = async (response: Response) => {
  if (response.ok) {
    const string = await response.text();
    const json = string === '' ? {} : JSON.parse(string);
    return json;
  } else {
    if (response.status === HttpStatusCodes.Unauthorized) {
      // https://pricerqx.atlassian.net/browse/PRQXDEV-1505
      // no prompt for unsaved changes if session expired
      UnsavedChangesBrowserManager.detach();
      AuthService.logout();
    }
    const errorCode = await response.json();
    if (Array.isArray(errorCode)) throw errorCode;
    throw new Error(errorCode);
  }
};

export async function postAPI(path: string | undefined, body: any) {
  const requestUrl = getURL(path);
  const headers = { Accept: 'text/plain', 'Content-Type': 'application/json' };

  const response = await fetch(requestUrl, {
    method: 'POST',
    headers,
    body: JSON.stringify(body),
  });

  return getResultJson(response);
}
/**
 * @deprecated please use pricerApiComponents hooks
 * @param path
 */
export async function postAuthAPI(path: string | undefined, body: any) {
  const accessToken = await AuthService.getAccessToken();
  const requestUrl = getURL(path);
  const headers = { Authorization: `Bearer ${accessToken}`, Accept: 'text/plain', 'Content-Type': 'application/json' };

  const response = await fetch(requestUrl, {
    method: 'POST',
    headers,
    body: JSON.stringify(body),
  });

  return getResultJson(response);
}
/**
 * @deprecated please use clientApi hooks
 * @param path
 * @param params
 */
export async function getAPI(path: string | undefined, params: string = '') {
  const requestUrl = getURL(path) + params;
  const headers = { Accept: 'text/plain' };

  const response = await fetch(requestUrl, {
    method: 'GET',
    headers,
  });

  return getResultJson(response);
}
/**
 * @deprecated please use clientApi hooks
 * @param path
 * @param params
 */
export async function getAuthAPI(path: string | undefined, params: string = '') {
  const accessToken = await AuthService.getAccessToken();
  const requestUrl = getURL(path) + params;
  const headers = { Authorization: `Bearer ${accessToken}`, Accept: 'text/plain' };

  const response = await fetch(requestUrl, {
    method: 'GET',
    headers,
  });

  return getResultJson(response);
}
/**
 * @deprecated please use clientApi hooks
 */
export async function putAuthAPI(path: string | undefined, body: any) {
  const accessToken = await AuthService.getAccessToken();
  const requestUrl = getURL(path);
  const headers = { Authorization: `Bearer ${accessToken}`, Accept: 'text/plain', 'Content-Type': 'application/json' };

  const response = await fetch(requestUrl, {
    method: 'PUT',
    headers,
    body: JSON.stringify(body),
  });

  return getResultJson(response);
}

/**
 * @deprecated please use clientApi hooks
 */
export async function delAuthAPI(path: string | undefined) {
  const accessToken = await AuthService.getAccessToken();
  const requestUrl = getURL(path);
  const headers = { Authorization: `Bearer ${accessToken}`, Accept: 'text/plain' };

  const response = await fetch(requestUrl, {
    method: 'DELETE',
    headers,
  });

  return getResultJson(response);
}

/**
 * @deprecated please use pricerApiComponents hooks
 * @param path
 * @param params
 */
export async function getPricerAPI(path: string | undefined, params: string = '') {
  const accessToken = await AuthService.getAccessToken();
  const requestUrl = getPricerURL(path) + params;
  const headers = { Authorization: `Bearer ${accessToken}`, Accept: 'text/plain' };

  const response = await fetch(requestUrl, {
    method: 'GET',
    headers,
  });

  return getResultJson(response);
}
/**
 * @deprecated please use pricerApiComponents hooks
 * @param path
 * @param params
 */
export async function postPricerAPI(path: string | undefined, body: any) {
  const accessToken = await AuthService.getAccessToken();
  const requestUrl = getPricerURL(path);
  const headers = { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' };

  const response = await fetch(requestUrl, {
    method: 'POST',
    headers,
    body: JSON.stringify(body),
  });

  return getResultJson(response);
}
/**
 * @deprecated please use pricerApiComponents hooks
 * @param path
 * @param params
 */
export async function putPricerAPI(path: string | undefined, body: any) {
  const accessToken = await AuthService.getAccessToken();
  const requestUrl = getPricerURL(path);
  const headers = { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' };

  const response = await fetch(requestUrl, {
    method: 'PUT',
    headers,
    body: JSON.stringify(body),
  });

  return getResultJson(response);
}

/**
 * @deprecated please use pricerApiComponents hooks
 * @param path
 * @param params
 */
export async function deletePricerAPI(path: string | undefined, body: any) {
  const accessToken = await AuthService.getAccessToken();
  const requestUrl = getPricerURL(path);
  const headers = { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' };

  const response = await fetch(requestUrl, {
    method: 'DELETE',
    headers,
    body: JSON.stringify(body),
  });

  return getResultJson(response);
}

export async function getConfigAPI(path: string | undefined, params: string = '') {
  const requestUrl = getConfigURL(path) + params;
  const headers = { Accept: 'text/plain' };

  const response = await fetch(requestUrl, {
    method: 'GET',
    headers,
  });

  return getResultJson(response);
}

export async function getPOSIntegrationAPI(path: string | undefined, params: string = '') {
  const accessToken = await AuthService.getAccessToken();
  const requestUrl = getPOSIntegrationURL(path) + params;
  const headers = { Authorization: `Bearer ${accessToken}`, Accept: 'text/plain' };

  const response = await fetch(requestUrl, {
    method: 'GET',
    headers,
  });

  return getResultJson(response);
}

export async function deletePOSIntegrationAPI(path: string | undefined, params: string = '') {
  const accessToken = await AuthService.getAccessToken();
  const requestUrl = getPOSIntegrationURL(path) + params;
  const headers = { Authorization: `Bearer ${accessToken}`, Accept: 'text/plain' };

  const response = await fetch(requestUrl, {
    method: 'DELETE',
    headers,
  });

  return getResultJson(response);
}

export async function postPOSIntegrationAPI(path: string | undefined, body: any) {
  const accessToken = await AuthService.getAccessToken();
  const requestUrl = getPOSIntegrationURL(path);
  const headers = { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' };

  const response = await fetch(requestUrl, {
    method: 'POST',
    headers,
    body: JSON.stringify(body),
  });

  return getResultJson(response);
}

export async function putPOSIntegrationAPI(path: string | undefined, body: any) {
  const accessToken = await AuthService.getAccessToken();
  const requestUrl = getPOSIntegrationURL(path);
  const headers = { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' };

  const response = await fetch(requestUrl, {
    method: 'PUT',
    headers,
    body: JSON.stringify(body),
  });

  return getResultJson(response);
}
