import { StepNames } from '@/routes/templates/templatePage/template-page-context-hooks';
import type { PricingTemplateInventoryResponseDtoSelectable } from '@/routes/templates/templatePage/template-page-context-hooks';
import { fetchPostCalculatePrice } from '@/api/pricer/pricerApiComponents';
import { AuthApiFetcherOptions } from '@/api/AuthApiContext';
import { PricingGroupRequestRanksFailedToLoad } from '@/routes/templates/templatePage/template-constants';
import type {
  PricingGroupTicketsResult,
  PricingResultDto,
  PricingTemplateSearchResponseDto,
} from '@/api/pricer/pricerApiSchemas';
import { seriesAsyncParallelBatched } from '@/utils/seriesAsync';
import type { Dispatch, SetStateAction } from 'react';

interface iProps {
  currentStepIndex: number;
  pricingTemplate: PricingTemplateSearchResponseDto | undefined;
  setInventoryForPricingGroups: Dispatch<SetStateAction<PricingTemplateInventoryResponseDtoSelectable[]>>;
}
function useCalculatePriceForInventory({ currentStepIndex, pricingTemplate, setInventoryForPricingGroups }: iProps) {
  function updateRankResults(rankResponse: PricingGroupTicketsResult[]) {
    if (rankResponse.length === 0 || currentStepIndex !== StepNames.ReviewPricingGroups) {
      return;
    }
    setInventoryForPricingGroups((prev) => {
      return prev.map((ticket) => {
        const rankResult = rankResponse.find((rank) => rank.ticketGroupId === ticket.ticketGroupId);
        if (rankResult) {
          return {
            ...ticket,
            loading: false,
            failedCalculatePrice: rankResult.rank === PricingGroupRequestRanksFailedToLoad,
            pricingGroupRequestRankResults: rankResult,
            suggestedPrice: rankResult.price,
          };
        }
        return ticket;
      });
    });
  }

  async function fetchCalculatePrice(
    groupsByProduction: Record<number, PricingTemplateInventoryResponseDtoSelectable[]>,
  ) {
    const { ceilingPrice, floorPrice, ruleSet, marketGroupCriteria } = pricingTemplate!;

    const calcPriceRequests = Object.values(groupsByProduction).map((ticketGroups = []) => {
      return async () => {
        if (currentStepIndex !== StepNames.ReviewPricingGroups) {
          return;
        }
        const calcResponse = await fetchPostCalculatePrice({
          ...AuthApiFetcherOptions,
          body: {
            pricingGroupRequestRanks: ticketGroups?.map((ticket) => {
              return {
                ticketGroupId: ticket.ticketGroupId!,
                rank: ticket.suggestedRank || 0,
              };
            }),
            productionId: ticketGroups[0]?.masterProductionId,
            ceilingPrice,
            floorPrice,
            ruleSet,
            marketGroupCriteria,
          },
        }).catch(() => {
          return {
            pricingGroupRequestRankResults: ticketGroups?.map((ticket) => {
              return {
                ticketGroupId: ticket.ticketGroupId!,
                rank: PricingGroupRequestRanksFailedToLoad,
              };
            }),
          } as PricingResultDto;
        });
        updateRankResults(calcResponse.pricingGroupRequestRankResults || []);
      };
    });

    await seriesAsyncParallelBatched(calcPriceRequests, 10);
  }

  function getPricingGroupCalculationForProduction(productionId: number) {
    setInventoryForPricingGroups((prev) => {
      const selectedInventory = prev.filter(
        (x: PricingTemplateInventoryResponseDtoSelectable) => x.masterProductionId === productionId,
      );
      fetchCalculatePrice({
        [productionId]: selectedInventory,
      });

      return prev.map((ticket: PricingTemplateInventoryResponseDtoSelectable) => {
        if (ticket.masterProductionId === productionId) {
          return {
            ...ticket,
            loading: true,
          };
        }
        return ticket;
      }) as PricingTemplateInventoryResponseDtoSelectable[];
    });
  }

  return {
    fetchCalculatePrice,
    getPricingGroupCalculationForProduction,
  };
}

export default useCalculatePriceForInventory;
