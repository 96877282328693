import type { IDataField } from '@/interfaces/CommonInterfaces';
import type { OrganizationPricingDefaultDto } from '@/api/pricer/pricerApiSchemas';
import { enableDisableOption } from '@/context/pricing-mode/constants';
import { emptyDataField, INITIAL_STATE_MARKET_DEFAULT } from '../constants';

export enum FieldType {
  SPLITS = 'splits',
  DYNAMIC_TOGGLE = 'dynamicToggle',
}

export const enum REDUCER_ACTION_TYPE {
  SET_SPLITS,
  SET_DYNAMIC_TOGGLE,
  LOAD_STATE_FROM_ORG_DEFAULTS,
  RESET,
  SET_ERROR,
}

type SetDataFieldAction = {
  type: REDUCER_ACTION_TYPE.SET_SPLITS;
  payload: IDataField;
};

type SetError = {
  type: REDUCER_ACTION_TYPE.SET_ERROR;
  payload: boolean;
};

type SimpleAction = {
  type: REDUCER_ACTION_TYPE.RESET | REDUCER_ACTION_TYPE.SET_DYNAMIC_TOGGLE;
};

type StateLoadAction = {
  type: REDUCER_ACTION_TYPE.LOAD_STATE_FROM_ORG_DEFAULTS;
  payload: OrganizationPricingDefaultDto | undefined;
};

export type MarketDefaultReducerAction = SimpleAction | SetDataFieldAction | StateLoadAction | SetError;

const handleDeSelect = (field: IDataField | undefined, stateValue: IDataField | undefined): IDataField => {
  if (!field) return emptyDataField;
  if (field.key === stateValue?.key) {
    return emptyDataField;
  } else {
    return field;
  }
};

export const marketDefaultReducer = (
  state: typeof INITIAL_STATE_MARKET_DEFAULT,
  action: MarketDefaultReducerAction,
): typeof INITIAL_STATE_MARKET_DEFAULT => {
  switch (action.type) {
    case REDUCER_ACTION_TYPE.SET_DYNAMIC_TOGGLE:
      return { ...state, splitsDynamicToggle: !state.splitsDynamicToggle, isDirty: true };
    case REDUCER_ACTION_TYPE.SET_SPLITS:
      return { ...state, splitsEnabled: handleDeSelect(action.payload, state.splitsEnabled), isDirty: true };
    case REDUCER_ACTION_TYPE.LOAD_STATE_FROM_ORG_DEFAULTS:
      const orgDefaults = action.payload ?? {};
      if (orgDefaults) {
        const { isSplitsDynamic, isSplitsEnabled } = orgDefaults.marketCriteriaDefaults ?? {};

        return {
          ...INITIAL_STATE_MARKET_DEFAULT,
          splitsDynamicToggle: !!isSplitsDynamic,
          splitsEnabled:
            isSplitsEnabled === undefined
              ? emptyDataField
              : isSplitsEnabled
                ? enableDisableOption[0]
                : enableDisableOption[1],
        };
      } else {
        return INITIAL_STATE_MARKET_DEFAULT;
      }
    case REDUCER_ACTION_TYPE.RESET:
      return { ...INITIAL_STATE_MARKET_DEFAULT, isDirty: true };
    case REDUCER_ACTION_TYPE.SET_ERROR:
      return { ...state, isError: action.payload };
    default:
      return state;
  }
};
