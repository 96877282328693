import { useEffect } from 'react';
import { Button, Divider, SelectionDropDown } from '../../components/common';
import i18n from '@/utils/i18n';
import PageLayout from '../../components/PageLayout';
import useTitle from '@/hooks/useTitle';
import FSRegisterPageProperties from '@/services/fs-page-properties';
import { MdInfoOutline } from 'react-icons/md';
import type { IDataField } from '@/interfaces/CommonInterfaces';
import {
  useNotificationPreferenceApi,
  useNotificationPreferenceState,
} from '@/context/notificationPreferences/context-hooks';
import {
  FieldType,
  REDUCER_ACTION_TYPE,
} from '@/context/notificationPreferences/reducers/notificationPreferencesReducer';

function UserNotifications() {
  const { notificationPreferencesResponse, notificationPreferenceState } = useNotificationPreferenceState();
  const { notificationPreferenceDispatch: dispatch, handleSaveNotificationPreferences } =
    useNotificationPreferenceApi();
  const { hitFloor, hitCeiling, noComps, isDirty } = notificationPreferenceState;
  useTitle({ title: i18n.t('profile.tabs.notifications') });

  useEffect(() => {
    FSRegisterPageProperties(i18n.t('profile.tabs.notifications'));
  }, []);

  const renderPanelHeader = () => {
    return (
      <>
        <span className={styles.headerTitle}>{i18n.t('profile.notifications.title')}</span>
        <Divider className="mb-2" />
      </>
    );
  };

  const renderInfoText = (message: string) => {
    return (
      <div className={styles.infoContainer}>
        <MdInfoOutline className={styles.infoIcon} />
        <span className={styles.infoMessage}>{message}</span>
      </div>
    );
  };

  const handleChange = (preference: IDataField) => {
    switch (preference.type) {
      case FieldType.FLOOR:
        dispatch({ type: REDUCER_ACTION_TYPE.SET_FLOOR, payload: preference });
        break;
      case FieldType.CEILING:
        dispatch({ type: REDUCER_ACTION_TYPE.SET_CEILING, payload: preference });
        break;
      case FieldType.NOCOMPS:
        dispatch({ type: REDUCER_ACTION_TYPE.SET_NOCOMPS, payload: preference });
        break;
    }
  };

  const renderPanelItem = (data: IDataField[], title: string, message: string) => {
    return (
      <div className={styles.panelItem} key={title}>
        <div className="flex-col justify-center gap-6">
          <div className="mb-2 flex items-center justify-between">
            <span className={styles.panelItemTitle}>{title}</span>
            <div className="w-56">
              <SelectionDropDown
                data={data}
                onChange={handleChange}
                selected={data.find((item) => item.selected) ?? undefined}
                variant="outline"
                size="lg"
                radius="md"
              />
            </div>
          </div>
        </div>
        {renderInfoText(message)}
        {title !== i18n.t('profile.notifications.noComps.title') && <Divider className={styles.divider} />}
      </div>
    );
  };

  const renderButtons = () => {
    return (
      <div className={styles.buttonContainer}>
        <Divider />
        <div className={styles.buttons}>
          <div className="flex-1" />
          <Button
            label={i18n.t('buttons.cancel').toUpperCase()}
            type="reset"
            variant="secondary"
            className="mx-4"
            disabled={!isDirty}
            onClick={() => {
              dispatch({ type: REDUCER_ACTION_TYPE.LOAD_PREFERENCES, payload: notificationPreferencesResponse });
            }}
          />
          <Button
            label={i18n.t('buttons.saveChanges').toUpperCase()}
            onClick={() => {
              handleSaveNotificationPreferences(notificationPreferenceState);
            }}
            disabled={!isDirty}
          />
        </div>
      </div>
    );
  };

  return (
    <PageLayout>
      <div className="h-full w-full bg-bck-body">
        <div className={styles.panel}>
          {renderPanelHeader()}
          {[
            {
              value: hitFloor,
              message: i18n.t('profile.notifications.hitFloor.message'),
              name: i18n.t('profile.notifications.hitFloor.title'),
            },
            {
              value: hitCeiling,
              message: i18n.t('profile.notifications.hitCeiling.message'),
              name: i18n.t('profile.notifications.hitCeiling.title'),
            },
            {
              value: noComps,
              message: i18n.t('profile.notifications.noComps.message'),
              name: i18n.t('profile.notifications.noComps.title'),
            },
          ].map((item) => renderPanelItem(item.value ?? [], item.name, item.message))}
        </div>
      </div>
      {renderButtons()}
    </PageLayout>
  );
}

const styles = {
  panel: 'flex flex-col rounded-md bg-paper-card mt-4 mx-2 h-fit w-[38.625rem]',
  panelItem: 'py-2 px-8',
  panelItemTitle: 'text-base font-semibold mt-2',
  divider: 'mt-2',
  infoIcon: 'mr-2 min-h-5 min-w-5 text-info-alt-dark',
  infoMessage: 'line-clamp-2 text-xs text-txt-secondary',
  buttonContainer: 'w-full bg-bck-body',
  buttons: 'mb-2 mr-2 mt-4 flex justify-end',
  headerTitle: 'mx-4 my-4 text-xl font-semibold',
  infoContainer: 'mb-4 mt-2 flex w-3/5 items-start',
};

export default UserNotifications;
