import type { IQueryError } from '@/interfaces/CommonInterfaces';
import { getErrorMessage } from '@/utils/notifications';

interface IProps {
  error: IQueryError | undefined;
}

function ErrorMessage({ error }: IProps) {
  return <h2 className={styles.errorTitle}>{getErrorMessage(error ? error.message : '').message}</h2>;
}

const styles = {
  errorTitle: 'my-8 text-md text-center text-error-main',
};

export default ErrorMessage;
