export default {
  title: 'Marketplace Configuration',
  noResults: 'No marketplace data found',
  completedCredentialsTitle: 'Completed testing credentials',
  completedCredentialsDesc: 'To commit the change, save the credentials.',
  validCredentialsTitle: 'Valid Credentials',
  validCredentialsDesc: 'Credentials are encrypted. You can enter new credentials and test.',
  invalidCredentialsTitle: 'Invalid Credentials',
  buttons: {
    testCredentials: 'Test Credentials',
    addNew: 'Add new Marketplace',
    change: 'Change',
    close: 'Close',
  },
  labels: {
    marketplace: 'Marketplace',
    authentication: 'Authentication credentials',
    infoTitle: 'You can configure Marketplace credentials to operate PRQX system.',
    infoBody: 'Only one Marketplace can be used.',
    connected: 'Connected',
    notConnected: 'Not Connected',
    addNewMarketplace: 'Add new Marketplace',
    editMarketplace: 'Edit Marketplace',
    marketplaceSystem: 'Marketplace system',
    marketplaceName: 'Marketplace name',
    shortName: 'Short name',
    signature: 'Signature',
    ApiToken: 'API token',
    secret: 'Secret',
    ApiKey: 'API Key',
    ApiSecret: 'API Secret',
    OfficeId: 'Office ID',
    AccountId: 'Account ID',
  },
  placeholders: {
    marketplaceName: 'Enter Marketplace name',
    shortName: 'Enter short name',
    selectOne: 'Select one',
    enterApiToken: 'Enter API token',
    enterApiKey: 'Enter API key',
    enterApiSecret: 'Enter API secret',
    enterSignature: 'Enter signature',
    enterOfficeId: 'Enter office ID',
    enterSecret: 'Enter secret',
    enterAccountId: 'Enter account ID',
  },
  columns: {
    sourceType: 'Marketplace',
    name: 'Marketplace name',
    abbreviation: 'Short name',
    validDate: 'Updated',
    updatedBy: 'Updated by',
    isValid: 'Credentials',
    action: 'Action',
  },
  status: {
    valid: 'Valid',
    invalid: 'Invalid',
    testConnection: 'Test connection',
  },
  errors: {
    editLoadTitle: 'Edit Marketplace failed',
    editLoader: 'Error loading Marketplace data, please try again later.',
    name: 'Please enter a valid Marketplace name that is between %{minLength} and %{maxLength} characters long',
    shortName: 'Please enter a valid short name that is between %{minLength} and %{maxLength} characters long',
    signature: 'Signature must be numeric',
  },
  menu: {
    testConnection: 'Test connection',
    editDetails: 'Edit details',
    delete: 'Delete Marketplace',
  },
  notifications: {
    connection: {
      loading: {
        title: 'Testing connection',
        message: 'Testing connection to Marketplace %{name}.',
      },
      success: {
        title: 'Success',
        message: 'Connection test to Marketplace %{name} was successful.',
      },
      error: {
        title: 'Oops! Something went wrong!',
        message: 'Connection test to Marketplace %{name} failed.',
      },
      invalid: {
        title: 'Invalid Credentials',
        message: 'Please enter valid credentials.',
      },
    },
    save: {
      loading: {
        title: 'Saving Marketplace',
        message: 'Saving Marketplace %{name}.',
      },
      success: {
        title: 'Success',
        message: 'Marketplace %{name} was successfully saved.',
      },
      error: {
        title: 'Oops! Something went wrong!',
        message: 'Failed to save Marketplace %{name}.',
      },
    },
    delete: {
      loading: {
        title: 'Deleting',
        message: 'Deleting Marketplace %{name}.',
      },
      success: {
        title: 'Success',
        message: 'Marketplace %{name} was successfully deleted.',
      },
      error: {
        title: 'Oops! Something went wrong!',
        message: 'Failed to delete Marketplace %{name}.',
      },
    },
    deleteModal: {
      title: 'Delete selected Marketplace?',
      statement: 'You have selected to delete Marketplace.',
      description: 'This action will invalidate data and pricing criteria associated to the Marketplace.',
      confirmCTA: 'Confirm Delete',
      inputTitle: 'Type in the Marketplace name to delete',
      inputLabel: 'Marketplace name',
      name: 'Marketplace name',
      shortName: 'Short name',
      updated: 'Updated',
      noMatch: 'Marketplace name does not match',
      inputPlaceholder: 'Enter Marketplace name',
    },
  },
};
