import type { TField } from '@/interfaces/CommonInterfaces';
import type { CustomSharingMethod } from '@/utils/constants';
import {
  PriceAdjustmentType,
  PricingGroupRequestType,
  groupShareOptions,
  PriceAdjustmentMethodType,
} from '@/utils/constants';
import type {
  CalculateDefaultResultDto,
  OrganizationPricingDefaultDto,
  PricingGroupRequest,
} from '@/api/pricer/pricerApiSchemas';
import { formatCurrencyInput } from '@/utils/helpers';
import { adjustments, groupAdjustments, customSharingUnits, units } from '@/context/pricing-mode/constants';
import { zeroInputValue } from '@/context/organization/constants';
import type { ReducerAction } from '../reducer-constants';
import {
  FieldType,
  getValidatedInput,
  getValidatedShareInput,
  INITIAL_STATE,
  REDUCER_ACTION_TYPE,
} from '../reducer-constants';
import {
  loadFromMetadata,
  loadFromOrgDefaults,
  loadFromPricingGroup,
} from '@/context/pricing-mode/reducer-load-constants';

export const reducer = (state: typeof INITIAL_STATE, action: ReducerAction): typeof INITIAL_STATE => {
  switch (action.type) {
    case REDUCER_ACTION_TYPE.CHANGE_INPUT:
      const { name, value } = action.payload;

      const currentValue = (state[name as keyof typeof INITIAL_STATE] as TField).value;
      const isDirty = state.isDirty || currentValue !== value;

      let ceilingOverwrittenByUser = state.ceilingOverwrittenByUser;
      if (name === FieldType.CEILING) {
        ceilingOverwrittenByUser = true;
      }
      let floorOverwrittenByUser = state.floorOverwrittenByUser;
      if (name === FieldType.FLOOR) {
        floorOverwrittenByUser = true;
      }
      return {
        ...state,
        [name]: { value, valid: undefined },
        isDirty,
        isFormValidated: false,
        ceilingOverwrittenByUser,
        floorOverwrittenByUser,
      };
    case REDUCER_ACTION_TYPE.VALIDATE_INPUT:
      return {
        ...state,
        [action.payload.name]: getValidatedInput(action.payload.name, action.payload.value),
        isFormValidated: true,
        lastEditedField: action.payload.name as FieldType,
      };
    case REDUCER_ACTION_TYPE.CHANGE_NAME_INPUT:
      return {
        ...state,
        name: { value: action.payload, valid: undefined },
        isDirty: true,
        isFormValidated: false,
      };
    case REDUCER_ACTION_TYPE.VALIDATE_NAME_INPUT:
      return {
        ...state,
        name: getValidatedInput(FieldType.NAME, action.payload),
        isFormValidated: true,
        lastEditedField: FieldType.NAME,
      };
    case REDUCER_ACTION_TYPE.SET_PRICING_METHOD_NO_COMP:
      return { ...state, noCompPricingMethod: action.payload, isDirty: true };
    case REDUCER_ACTION_TYPE.CHANGE_SHARE_TG_AMOUNT_INPUT:
      if (!state.shareTicketGroupsUnit.key) return state;

      const validatedInput = getValidatedShareInput(
        action.payload,
        state.shareTicketGroupsUnit.key as CustomSharingMethod,
      );
      return {
        ...state,
        shareTicketGroupsAmount: {
          value: validatedInput.value,
          valid: undefined,
        },
        isDirty: state.isDirty || state.shareTicketGroupsAmount.value !== action.payload,
        isFormValidated: false,
      };
    case REDUCER_ACTION_TYPE.VALIDATE_SHARE_TG_AMOUNT_INPUT:
      if (!state.shareTicketGroupsUnit.key) return state;
      return {
        ...state,
        shareTicketGroupsAmount: getValidatedShareInput(
          action.payload,
          state.shareTicketGroupsUnit.key as CustomSharingMethod,
        ),
        isFormValidated: true,
      };

    case REDUCER_ACTION_TYPE.SET_INPUT_VALIDATIONS:
      return { ...state, ...action.payload };
    case REDUCER_ACTION_TYPE.SET_ADJUSTMENT:
      const adjustValue: any = {};
      let isDisabledOffsetAmount = state.isDisabledOffsetAmount;
      if (action.payload.key === PriceAdjustmentType.MATCH) {
        adjustValue[FieldType.ADJUSTMENT_AMOUNT] = zeroInputValue;
        isDisabledOffsetAmount = true;
      } else {
        isDisabledOffsetAmount = false;
      }

      return {
        ...state,
        ...{ adjustment: action.payload },
        ...adjustValue,
        isDirty: true,
        isFormValidated: true,
        isDisabledOffsetAmount,
      };
    case REDUCER_ACTION_TYPE.SET_PUSH_OPTION:
      return {
        ...state,
        pushOption: action.payload,
        isDirty: true,
        pricingGroupNameInputDisabled: action.payload.key === PricingGroupRequestType.ONE_TIME_PUSH,
        saveAsPricingTemplate:
          action.payload.key === PricingGroupRequestType.ONE_TIME_PUSH ? false : state.saveAsPricingTemplate,
      };

    case REDUCER_ACTION_TYPE.SET_SHARE_OPTION:
      return {
        ...state,
        groupShareOption: action.payload,
        isDirty: true,
        isSharingActive: state.isSharingActive === undefined ? true : state.isSharingActive,
      };

    case REDUCER_ACTION_TYPE.SET_SHARE_TG_UNIT:
      return {
        ...state,
        shareTicketGroupsUnit: action.payload,
        shareTicketGroupsAmount: getValidatedShareInput(
          state.shareTicketGroupsAmount.value,
          action.payload.key as CustomSharingMethod,
          false,
        ),
        isDirty: true,
      };
    case REDUCER_ACTION_TYPE.SET_GROUP_ADJUSTMENT:
      return { ...state, groupAdjustment: action.payload, isDirty: true };
    case REDUCER_ACTION_TYPE.SET_UNIT:
      return { ...state, unit: action.payload, isDirty: true };
    case REDUCER_ACTION_TYPE.SET_GROUP_UNIT:
      return { ...state, groupUnit: action.payload, isDirty: true };
    case REDUCER_ACTION_TYPE.TG_SHARING_ACTIVE:
      return { ...state, isSharingActive: !state.isSharingActive, isDirty: true };
    case REDUCER_ACTION_TYPE.SET_PRICING_METHOD_FLOOR:
      return { ...state, floorPricingMethod: action.payload, isDirty: true };
    case REDUCER_ACTION_TYPE.SET_PRICING_METHOD_CEILING:
      return { ...state, ceilingPricingMethod: action.payload, isDirty: true };
    case REDUCER_ACTION_TYPE.RESET_PRICING_METHODS:
      return {
        ...state,
        floorPricingMethod: action.payload.floorPricingMethod,
        ceilingPricingMethod: action.payload.ceilingPricingMethod,
        noCompPricingMethod: action.payload.noCompPricingMethod,
        isDirty: true,
      };
    case REDUCER_ACTION_TYPE.TG_ANCHOR_SHARING:
      return {
        ...state,
        isAnchorShared: !state.isAnchorShared,
        isSharingActive: state.isSharingActive === undefined ? true : state.isSharingActive,
        isDirty: true,
      };
    case REDUCER_ACTION_TYPE.LOAD_STATE_FROM_PRICING_GROUP:
      return loadFromPricingGroup(action.payload as PricingGroupRequest);
    case REDUCER_ACTION_TYPE.LOAD_STATE_FROM_ORG_DEFAULTS:
      return loadFromOrgDefaults(action.payload as CalculateDefaultResultDto);
    case REDUCER_ACTION_TYPE.LOAD_STATE_FROM_METADATA:
      return loadFromMetadata(state, action.payload as PricingGroupRequest);
    case REDUCER_ACTION_TYPE.RESET_OFFSET_INPUTS: {
      const orgDefault = (action.payload as OrganizationPricingDefaultDto) ?? {};
      const { pricingDefaults } = orgDefault;

      if (pricingDefaults) {
        const { increment, incrementDirection, incrementType } = pricingDefaults;
        return {
          ...state,
          adjustmentAmount: getValidatedInput(
            FieldType.ADJUSTMENT_AMOUNT,
            formatCurrencyInput(increment?.toString() ?? ''),
          ),
          adjustment: adjustments.find((item) => item.key === incrementDirection?.toLowerCase()) ?? state.adjustment,
          unit: units.find((item) => item.key === incrementType?.toString()) ?? state.unit,
          ceilingOverwrittenByUser: false,
          floorOverwrittenByUser: false,
          isDirty: true,
          isFormValidated: true,
        };
      } else {
        return INITIAL_STATE;
      }
    }
    case REDUCER_ACTION_TYPE.RESET_FLOOR_CEILING_INPUTS: {
      const orgDefault = (action.payload.orgDefaults as OrganizationPricingDefaultDto) ?? {};
      const { floorPercentage, ceilingPercentage } = action.payload;
      const { pricingDefaults } = orgDefault;
      if (pricingDefaults) {
        const { floor, ceiling, floorType, ceilingType } = pricingDefaults;
        const floorValue = formatCurrencyInput(
          floorType?.toString() === PriceAdjustmentMethodType.PERCENTAGE
            ? floorPercentage ?? ''
            : floor?.toString() ?? '',
        );
        const ceilingValue = formatCurrencyInput(
          ceilingType?.toString() === PriceAdjustmentMethodType.PERCENTAGE
            ? ceilingPercentage ?? ''
            : ceiling?.toString() ?? '',
        );
        return {
          ...state,
          floor: getValidatedInput(FieldType.FLOOR, floorValue),
          ceiling: getValidatedInput(FieldType.CEILING, ceilingValue),
          ceilingOverwrittenByUser: false,
          floorOverwrittenByUser: false,
          isDirty: true,
          isFormValidated: true,
        };
      } else {
        return INITIAL_STATE;
      }
    }
    case REDUCER_ACTION_TYPE.RESET_GROUP_OFFSET_INPUTS: {
      const orgDefault = (action.payload as OrganizationPricingDefaultDto) ?? {};
      const { pricingDefaults } = orgDefault;

      if (pricingDefaults) {
        const { groupOffset, groupOffsetDirection, groupOffsetType } = pricingDefaults;
        return {
          ...state,
          groupAdjustmentAmount: getValidatedInput(
            FieldType.GROUP_ADJUSTMENT_AMOUNT,
            formatCurrencyInput((groupOffset ?? 0).toString()),
          ),
          groupAdjustment:
            groupAdjustments.find((item) => item.key === groupOffsetDirection?.toString()) ?? state.groupAdjustment,
          groupUnit: units.find((item) => item.key === groupOffsetType?.toString()) ?? state.groupUnit,
          isDirty: true,
          isFormValidated: true,
        };
      } else {
        return INITIAL_STATE;
      }
    }
    case REDUCER_ACTION_TYPE.RESET_SHARE_INPUTS: {
      const orgDefault = (action.payload as OrganizationPricingDefaultDto) ?? {};
      const { pricingDefaults } = orgDefault;

      if (pricingDefaults) {
        const { isShareOptionEnabled, shareAnchor, shareOptionsType, customSharingMethod, customSharingAmount } =
          pricingDefaults;
        return {
          ...state,
          isSharingActive: !!isShareOptionEnabled,
          isAnchorShared: !!shareAnchor,
          groupShareOption:
            groupShareOptions.find((item) => item.key.toLowerCase() === shareOptionsType?.toString()) ??
            state.groupShareOption,
          shareTicketGroupsUnit:
            customSharingUnits.find((item) => item.key === customSharingMethod) ?? state.shareTicketGroupsUnit,
          shareTicketGroupsAmount: getValidatedShareInput(
            customSharingAmount?.toString() ?? '',
            customSharingMethod as CustomSharingMethod,
            false,
          ),
          isDirty: true,
          isFormValidated: true,
        };
      } else {
        return INITIAL_STATE;
      }
    }
    case REDUCER_ACTION_TYPE.RESET_PUSH_INPUT: {
      const orgDefault = (action.payload as OrganizationPricingDefaultDto) ?? {};
      const { pricingDefaults } = orgDefault;

      if (pricingDefaults) {
        const { pricingGroupRequestType } = pricingDefaults;
        return {
          ...state,
          pushOption:
            state.allPushOptions.find(
              (option) => option.key?.toLowerCase() === pricingGroupRequestType?.toLowerCase(),
            ) ?? state.pushOption,
          isDirty: true,
        };
      } else {
        return INITIAL_STATE;
      }
    }
    case REDUCER_ACTION_TYPE.SET_SCP_VALUE:
      const oneTimePushOption = state.allPushOptions.find((x) => x.key === PricingGroupRequestType.ONE_TIME_PUSH);
      if (oneTimePushOption) {
        oneTimePushOption.disabled = !action.payload.scp || !action.payload.isCreatePG;
      }
      return { ...state, scpValue: action.payload.scp };
    case REDUCER_ACTION_TYPE.SAVE_AS_PRICING_TEMPLATE:
      return { ...state, saveAsPricingTemplate: !state.saveAsPricingTemplate };
    case REDUCER_ACTION_TYPE.RESET:
      return INITIAL_STATE;
    default:
      return state;
  }
};
