import { cva } from 'cva';

interface IProps {
  className?: string;
  variant?: 'horizontal' | 'vertical' | 'inline-vertical';
  color?: 'divider' | 'border' | 'primary';
  inlineSize?: 'xs' | 'sm' | 'md' | 'lg';
  inlinePadded?: boolean;
}

function Divider({
  className = '',
  variant = 'horizontal',
  color = 'divider',
  inlineSize = 'sm',
  inlinePadded = false,
}: IProps) {
  return (
    <div
      className={styleVariation({
        variant,
        color,
        inlineSize,
        inlinePadded,
        className,
      })}
    />
  );
}

const styleVariation = cva('', {
  variants: {
    color: {
      divider: 'border-divider',
      border: 'border-bck-border/30',
      primary: 'border-primary-main',
    },
    variant: {
      vertical: 'h-full border-l',
      horizontal: 'w-full border-t',
      ['inline-vertical']: 'inline-block border-l',
    },
    inlineSize: {
      xs: '',
      sm: '',
      md: '',
      lg: '',
    },
    inlinePadded: {
      true: '',
    },
  },
  compoundVariants: [
    {
      variant: 'inline-vertical',
      inlineSize: 'xs',
      className: 'h-2',
    },
    {
      variant: 'inline-vertical',
      inlineSize: 'sm',
      className: 'h-3',
    },
    {
      variant: 'inline-vertical',
      inlineSize: 'md',
      className: 'h-5',
    },
    {
      variant: 'inline-vertical',
      inlineSize: 'lg',
      className: 'h-5',
    },
    {
      variant: 'inline-vertical',
      inlinePadded: true,
      className: 'relative -bottom-0.5 ml-2 pr-2',
    },
  ],
});

export default Divider;
