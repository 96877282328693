import { createContext, useContext } from 'react';
import type { INITIAL_STATE, ReducerAction } from './reducers/notificationPreferencesReducer';
import type { GetUserPreferencesResponse } from '@/api/notification/notificationApiComponents';

interface INotificationApi {
  notificationPreferenceDispatch: React.Dispatch<ReducerAction>;
  handleSaveNotificationPreferences: (notificationPreferenceRequest: typeof INITIAL_STATE) => void;
}

interface INotificationState {
  notificationPreferencesResponse: GetUserPreferencesResponse | undefined;
  notificationPreferenceState: typeof INITIAL_STATE;
}

const NotificationPreferenceStateContext = createContext<INotificationState | undefined>(undefined);
const NotificationPreferenceApiContext = createContext<INotificationApi | undefined>(undefined);

const useNotificationPreferenceState = () => {
  const context = useContext(NotificationPreferenceStateContext);
  if (context === undefined) {
    throw new Error('NotificationPreferenceStateContext must be used within a NotificationsPreferenceContextProvider');
  }
  return context;
};

const useNotificationPreferenceApi = () => {
  const context = useContext(NotificationPreferenceApiContext);
  if (context === undefined) {
    throw new Error('NotificationPreferenceApiContext must be used within a NotificationsPreferenceContextProvider');
  }
  return context;
};

export {
  NotificationPreferenceStateContext,
  NotificationPreferenceApiContext,
  useNotificationPreferenceState,
  useNotificationPreferenceApi,
};
export type { INotificationState, INotificationApi };
