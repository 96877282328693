import { keepPreviousData, useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect, useMemo, useState } from 'react';
import { apiEndpoints, postPricerAPI } from '@/utils/api';
import { parseISO, formatDistanceToNow, format } from 'date-fns';
import { getTBD } from '@/utils/date';
import type { INotification, IProductionQuickFilters } from '@/interfaces/CommonInterfaces';
import type { IProductionsFilters } from '@/interfaces/CommonInterfaces';
import useDebounce from '@/hooks/useDebounce';
import { pageSizes, SERVER_DATE_FORMAT, DefaultDateRangeForEvents } from '@/utils/constants';
import type { PaginationState } from '@tanstack/react-table';
import i18n from '@/utils/i18n';
import type { IAssignPricerRequest } from '@/interfaces/productions/IAssignPricerRequest';
import type { GetProductionsInPageQueryParams } from '@/api/pricer/pricerApiComponents';
import { useGetOrganizationPricingSetting, useGetTicketAttributes } from '@/api/pricer/pricerApiComponents';
import type * as Schemas from '@/api/pricer/pricerApiSchemas';
import { getErrorNotification, getLoadingNotification, getSuccessNotification } from '@/utils/notifications';
import { PricerApiContext, PricerStateContext } from './context-hooks';
import type { columnSortType } from './context-hooks';
import { clone } from '@/utils/clone';
import { useNotificationsApi } from '../notifications/context-hooks';
import { usePosStateContext } from '../pos/context-hooks';
import { useOrganizationContext } from '../organization/context-hooks';
import { getFilterCount } from '@/utils/filters';
import type { IProductionQueryParams } from './constants';
import { filterOptions, productionFilterOptions, queryProductions, queryProductionsPage } from './constants';
import { useSearchParams } from 'react-router-dom';
import {
  handleResetFilters,
  setFiltersFromSearchParams,
  getAppliedPricerFilterOptions,
  getPricerFilterSelfExcluded,
} from './helpers';
import { useUserState } from '@/context/user/context-hooks';

function PricerContextProvider({ children }: { children: React.ReactNode }) {
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: pageSizes[4],
  });
  const { user: currentUser } = useUserState();
  const [selectedDateRange, setSelectedDateRange] = useState<Date[]>(DefaultDateRangeForEvents);
  const [pendingPricerAssignments, setPendingPricerAssignments] = useState<string[]>([]);
  const [pendingAssignmentsToRemove, setPendingAssignmentsToRemove] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [recentSearches, setRecentSearches] = useState<string[]>([]);
  const [filters, setFilters] = useState<IProductionsFilters>(structuredClone(filterOptions));
  const [productionQuickFilters, setProductionQuickFilters] =
    useState<IProductionQuickFilters>(productionFilterOptions);
  const [sortColumns, setSortColumns] = useState<columnSortType>({ columnName: 'eventDate', sortDirection: 'Asc' });
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const { pushNotification } = useNotificationsApi();
  const { noPos } = usePosStateContext();
  const { users } = useOrganizationContext();
  const recentSearchesMax = 5;
  const [searchParams, setSearchParams] = useSearchParams();
  const [productionPageState, setProductionPageState] = useState<number | undefined>(undefined);
  const selectedProductionId = searchParams.get('productionId');
  const urlSearchTerm = searchParams.get('searchTerm');

  useEffect(() => {
    setFiltersFromSearchParams(
      searchParams,
      filters,
      setFilters,
      productionQuickFilters,
      setProductionQuickFilters,
      setSearchTerm,
      setSelectedDateRange,
    );
  }, []);

  useEffect(() => {
    debouncedSearchTerm === ''
      ? searchParams.delete('searchTerm')
      : searchParams.set('searchTerm', debouncedSearchTerm);

    setSearchParams(searchParams);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (users.length === 0 || !currentUser) return;

    const pricerFilterOptions = getAppliedPricerFilterOptions(users, currentUser, searchParams);

    setFilters((filters) => {
      return {
        ...filters,
        pricer: { ...filters.pricer, data: { ...filters.pricer.data, options: pricerFilterOptions } },
      };
    });
  }, [users, currentUser]);

  const appliedFiltersCount = useMemo(() => {
    return getFilterCount<IProductionsFilters>(filters, filterOptions);
  }, [filters]);

  const hasProductionSelected = selectedProductionId !== null;
  const { data: productionPage, isPending: isProductionsPagePending } = useQuery({
    queryKey: [
      'productionsPage',
      {
        selectedProductionId,
        searchTerm: urlSearchTerm,
        selectedDateRange,
        pageSize,
        // fixme: review those clones after the upgrade to react-query v5
        filters: clone(filters),
        productionQuickFilters: clone(productionQuickFilters),
        noPos,
        sortColumns: {
          columnName: sortColumns.columnName,
          sortDirection: sortColumns.sortDirection,
        },
      } as GetProductionsInPageQueryParams,
    ],
    queryFn: queryProductionsPage,
    enabled: hasProductionSelected,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (productionPageState !== undefined) return;
    setProductionPageState(productionPage);
  }, [productionPage]);

  const {
    data: productions,
    refetch: refetchProductions,
    fetchNextPage: fetchNextProductionsPage,
    fetchPreviousPage: fetchPreviousProductionsPage,
    isLoading: isProductionsLoading,
    isFetching: isProductionsFetching,
    isSuccess: isProductionsSuccess,
    isError: isProductionsError,
    error: productionsError,
    hasNextPage: productionsHasNextPage,
    hasPreviousPage: productionsHasPreviousPage,
  } = useInfiniteQuery<Schemas.PagedProductionResponseDto>({
    queryKey: [
      'productions',
      {
        searchTerm: debouncedSearchTerm,
        selectedDateRange,
        pageSize,
        // fixme: review those clones after the upgrade to react-query v5
        filters: clone(filters),
        productionQuickFilters: clone(productionQuickFilters),
        noPos,
        sortColumns: {
          columnName: sortColumns.columnName,
          sortDirection: sortColumns.sortDirection,
        },
      } as IProductionQueryParams,
    ],
    queryFn: queryProductions,
    getNextPageParam: (lastPage) => {
      const nextPage = (lastPage.pageNumber ?? 0) + 1;
      return nextPage <= (lastPage.totalPages ?? 0) ? nextPage : undefined;
    },
    getPreviousPageParam: (firstPage) => {
      const previousPage = (firstPage.pageNumber ?? 0) - 1;
      return previousPage > 0 ? previousPage : undefined;
    },
    placeholderData: keepPreviousData,
    enabled: !noPos && ((hasProductionSelected && !isProductionsPagePending) || !hasProductionSelected),
    refetchOnWindowFocus: false,
    initialPageParam: productionPageState || 1,
  });

  useEffect(() => {
    if (pendingAssignmentsToRemove.length > 0) {
      setPendingPricerAssignments(
        pendingPricerAssignments.filter((item) => !pendingAssignmentsToRemove.includes(item)),
      );
      setPendingAssignmentsToRemove([]);
    }
  }, [productions]);

  const productionsData = React.useMemo(() => {
    if (!productions) return { data: [], totalRecords: 0, totalFilteredRecords: 0 };
    const { pages } = productions;

    const flatData = pages?.flatMap((page) => page.data) ?? [];
    const sortedData = flatData.map((item) => {
      const { productionDate, lastModifiedOn, ...rest } = item as any;
      const updated = lastModifiedOn ? formatDistanceToNow(parseISO(lastModifiedOn)) : '';
      const tbd = getTBD(productionDate);
      return { lastModifiedOn: updated, tbd, productionDate, ...rest };
    });
    return {
      data: sortedData,
      totalRecords: pages?.[0]?.totalRecords ?? 0,
      totalFilteredRecords: pages?.[0]?.totalFilteredRecords ?? 0,
    };
  }, [productions]);

  const { data: tgAttributes } = useGetTicketAttributes({});
  const sortedAttributes = useMemo(
    () => tgAttributes?.sort((a, b) => (a.name && b.name ? a.name.localeCompare(b.name) : 1)),
    [tgAttributes],
  );

  const { mutate: assignPricer } = useMutation({
    mutationFn: (req: { reqArgs: IAssignPricerRequest; notification: INotification }) => {
      const { reqArgs } = req;
      setPendingPricerAssignments([...pendingPricerAssignments, reqArgs.productionId.toString()]);
      return postPricerAPI(apiEndpoints.pricer.assignment(reqArgs.productionId.toString()), {
        username: reqArgs.username !== '' ? reqArgs.username : null,
        deletePricer: reqArgs.deletePricer,
      });
    },
    onSettled: (_data, _error, variables) => {
      const { reqArgs } = variables;
      setPendingAssignmentsToRemove((prevState) => [...prevState, reqArgs.productionId.toString()]);
    },
    onSuccess: (_response, variables) => {
      const { reqArgs, notification } = variables;
      const { eventName, productionDate, username, deletePricer } = reqArgs;
      pushNotification(
        getSuccessNotification(
          notification?.id,
          i18n.t('productions.notification.success.title'),
          i18n.t(`productions.notification.success.${deletePricer ? 'messageUnassign' : 'message'}`, {
            productionName: eventName,
            productionDate: productionDate,
            pricerName: username,
          }),
          true,
        ),
      );
      refetchProductions();
    },
    onError: (error, variables) => {
      const { eventName, productionDate, username, deletePricer } = variables.reqArgs;
      pushNotification(
        getErrorNotification(
          variables.notification?.id,
          error,
          true,
          i18n.t('productions.notification.error.title'),
          i18n.t(`productions.notification.error.${deletePricer ? 'messageUnassign' : 'message'}`, {
            productionName: eventName,
            productionDate: productionDate,
            pricerName: username,
          }),
        ),
      );
    },
  });

  const { data: organizationPricingModeData } = useGetOrganizationPricingSetting({});

  const resetFilters = () => {
    handleResetFilters(
      setFilters,
      setSelectedDateRange,
      setProductionQuickFilters,
      setPagination,
      pageSize,
      setSearchParams,
      setSearchTerm,
    );
  };

  const api = useMemo(
    () => ({
      setPagination,
      refetchProductions,
      fetchNextProductionsPage: () => fetchNextProductionsPage(),
      fetchPreviousProductionsPage: () => fetchPreviousProductionsPage(),
      assignPricer: (reqArgs: IAssignPricerRequest) => {
        const { eventName, productionDate, username, deletePricer } = reqArgs;
        const notification = getLoadingNotification(
          'assign-pricer',
          i18n.t('productions.notification.applying.title'),
          i18n.t(`productions.notification.applying.${deletePricer ? 'messageUnassign' : 'message'}`, {
            productionName: eventName,
            productionDate,
            pricerName: username,
          }),
        );
        pushNotification(notification);
        assignPricer({ reqArgs, notification });
      },
      setSelectedDateRange: (range: Date[]) => {
        let startDate = '',
          endDate = '';
        try {
          startDate = format(range[0], SERVER_DATE_FORMAT);
          endDate = format(range[1], SERVER_DATE_FORMAT);
        } catch (e) {}

        searchParams.set('startDate', startDate);
        searchParams.set('endDate', endDate);
        setSearchParams(searchParams);

        setSelectedDateRange(range);
        setPagination({ pageIndex: 0, pageSize });
      },
      getDefaultDateRange: () => DefaultDateRangeForEvents,
      setSearchTerm: (term: string) => {
        setSearchTerm(term);
        setPagination({ pageIndex: 0, pageSize });
      },
      setRecentSearches: (term: string) => {
        setRecentSearches((prev) => {
          if (prev.length >= recentSearchesMax) prev.shift();

          return [...prev.filter((search) => search !== term), term];
        });
      },
      setSortColumns: (columns: columnSortType) => {
        columns.columnName === ''
          ? setSortColumns({ columnName: 'eventDate', sortDirection: 'Asc' })
          : setSortColumns(columns);
        setPagination({ pageIndex: 0, pageSize });
      },
      setFilters: (filters: IProductionsFilters) => {
        const weekday = filters.eventDay.data.options.filter((item) => item.selected).map((item) => item.value);
        const status = filters.status.data.options.filter((item) => item.selected).map((item) => item.value);
        const pricer = filters.pricer.data.options.filter((item) => item.selected).map((item) => item.value);

        const parking = filters.isParking.data.selected?.key;
        const noTickets = filters.isNoTickets.data.selected?.key;

        const excludePricerSelfSelection = getPricerFilterSelfExcluded(pricer, currentUser);
        if (excludePricerSelfSelection) {
          pricer.push(excludePricerSelfSelection);
        }

        setSearchParams({
          ...(weekday && { weekday }),
          ...(status && { status }),
          ...(pricer && { pricer }),
          ...(parking && { parking }),
          ...(noTickets && { noTickets }),
        });
        setFilters(filters);
        setPagination({ pageIndex: 0, pageSize });
      },
      setProductionQuickFilters: (filters: IProductionQuickFilters) => {
        setProductionQuickFilters(filters);
        setPagination({ pageIndex: 0, pageSize });
      },
      resetFilters,
    }),
    [currentUser],
  );

  return (
    <PricerStateContext.Provider
      value={{
        pagination: { pageSize, pageIndex },
        productions: productionsData,
        isProductionsSuccess,
        isProductionsError,
        isProductionsFetching: isProductionsFetching,
        isProductionsLoading,
        productionsError,
        selectedDateRange,
        searchTerm,
        recentSearches,
        filters,
        appliedFiltersCount,
        sortColumns,
        pendingPricerAssignments,
        productionsHasNextPage,
        productionsHasPreviousPage,
        tgAttributes: sortedAttributes,
        productionQuickFilters,
        organizationPricingMode: organizationPricingModeData?.organizationPricingMode,
      }}
    >
      <PricerApiContext.Provider value={api}>{children}</PricerApiContext.Provider>
    </PricerStateContext.Provider>
  );
}

export { PricerContextProvider };
