import { useEffect, useState } from 'react';
import type { ComponentType } from 'react';
import { Button, Divider, DividerTopLabel, ErrorMessage, TextInput } from '../../components/common';
import Avatar from '../../components/profile/Avatar';
import { useUserState, useUserApi } from '@/context/user/context-hooks';
import type { IQueryError, TField } from '@/interfaces/CommonInterfaces';
import i18n from '@/utils/i18n';
import InputFormatting from '@/utils/InputFormatting';
import InputValidations from '@/utils/InputValidations';
import type { IconBaseProps } from 'react-icons';
import { MdAccountBox, MdCorporateFare, MdLock, MdMailOutline, MdPhone } from 'react-icons/md';
import { useMsal } from '@azure/msal-react';
import PageLayout from '../../components/PageLayout';
import useTitle from '@/hooks/useTitle';
import FSRegisterPageProperties from '@/services/fs-page-properties';
import { useOrganizationContext } from '@/context/organization/context-hooks';
import ChangePassword from './ChangePassword';
import type { ClientUserAppProfileResponse } from '@/api/clientService/clientServiceApiSchemas';

enum FieldType {
  Name = 1,
  Phone,
}

function UserAccount() {
  const [isDirty, setIsDirty] = useState(false);
  const [name, setName] = useState<TField>({ value: '', valid: undefined });
  const [phone, setPhone] = useState<TField>({ value: '', valid: undefined });
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('N/A');
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const { user, isError, error } = useUserState();
  const { update } = useUserApi();
  const profile = user;
  const { organization } = useOrganizationContext();
  const { instance } = useMsal();
  useTitle({ title: i18n.t('profile.tabs.account') });

  useEffect(() => {
    FSRegisterPageProperties(i18n.t('profile.tabs.account'));
  }, []);
  const setInitialValues = () => {
    setName({ value: profile?.name ?? '', valid: undefined });
    setEmail(profile?.email ?? '');
    setPhone({ value: profile?.phone ?? '', valid: undefined });
    setCompany(organization?.name ?? 'N/A');

    const roles = profile?.roles && profile.roles.length > 0 ? profile.roles : null;
    const rolesText = roles ? roles.map((role) => role.name).join(', ') : 'N/A';

    setRole(rolesText);
  };

  useEffect(() => {
    if (profile) {
      setInitialValues();
    }
  }, [profile]);

  const validateForm = (): boolean => {
    const isNameValid = InputValidations.isNameValid(name.value);
    const isPhoneValid = InputValidations.isPhoneValid(InputFormatting.removeNonNumber(phone.value));

    setName({ ...name, valid: isNameValid });
    setPhone({ ...phone, valid: isPhoneValid });

    return isNameValid && isPhoneValid;
  };

  const handleChange = (e: any, fieldName: FieldType) => {
    setIsDirty(true);

    switch (fieldName) {
      case FieldType.Name:
        setName({ value: e.target.value, valid: undefined });
        break;
      case FieldType.Phone:
        setPhone({ value: InputFormatting.formatPhoneNumber(e.target.value), valid: undefined });
        break;
    }
  };

  const handleSubmit = () => {
    if (validateForm()) {
      setIsDirty(false);
      const userInfo = {
        ...user,
        name: name.value,
        email: email,
        phone: phone.value,
      } as ClientUserAppProfileResponse;
      update(userInfo);
    }
  };

  const handleCancel = () => {
    setInitialValues();
    setIsDirty(false);
  };

  const handlePasswordChange = () => {
    setOpenChangePassword(true);
  };

  const renderAvatarOptions = () => {
    return (
      <div className={styles.avatarContainer}>
        <Avatar variant="lg" name={profile?.name} />
      </div>
    );
  };

  const renderChangePassword = () => {
    return (
      <div className={styles.resetPassButton} onClick={handlePasswordChange}>
        <MdLock className="mr-1 text-2xl text-primary-main" />
        <span className="text-sm text-primary-main">{i18n.t('profile.buttons.changePassword').toUpperCase()}</span>
      </div>
    );
  };

  const renderInfoLine = (LeadingIcon: ComponentType<IconBaseProps>, label: string, value: string) => {
    return (
      <div className={styles.infoLine}>
        <LeadingIcon className={styles.infoIcon} />
        <span className={styles.infoLabel}>{label}:</span>
        <span className={styles.infoValue}>{value}</span>
      </div>
    );
  };

  const renderButtons = () => {
    return (
      <div className="absolute inset-x-0 bottom-0 bg-paper-card pb-2">
        <Divider variant={'horizontal'} />
        <div className="flex justify-end gap-2 p-4">
          <Button
            label={i18n.t('buttons.cancel').toUpperCase()}
            type="reset"
            variant="secondary"
            className="mx-4"
            onClick={handleCancel}
            disabled={!isDirty}
          />
          <Button label={i18n.t('buttons.saveChanges').toUpperCase()} onClick={handleSubmit} disabled={!isDirty} />
        </div>
      </div>
    );
  };

  const renderProfileForm = () => {
    return (
      <form action="#" method="POST" className={styles.card}>
        <span className={styles.cardTitle}>{i18n.t('profile.labels.overview')}</span>
        <div className={styles.cardContent}>
          {renderAvatarOptions()}
          <div className="ml-6 flex flex-1 flex-col">
            <DividerTopLabel label={i18n.t('profile.personalInfo').toUpperCase()} className="mb-1" />
            <TextInput
              name="name"
              id="name"
              label={i18n.t('input.labels.fullName')}
              variant="filled"
              value={name.value}
              onChange={(e) => handleChange(e, FieldType.Name)}
              className={styles.input}
              colorText={i18n.t('input.error.contactName')}
              color={name.valid === false ? 'error' : 'main'}
              colorTextAsTooltip={false}
            />
            <TextInput
              LeadingIcon={MdPhone}
              name="phone-number"
              id="phone-number"
              label={i18n.t('input.labels.companyPhone')}
              variant="filled"
              colorText={i18n.t('input.error.phone')}
              color={phone.valid === false ? 'error' : 'main'}
              colorTextAsTooltip={false}
              value={phone.value}
              onChange={(e) => handleChange(e, FieldType.Phone)}
              className={styles.input}
            />
            {renderChangePassword()}
          </div>
        </div>

        <div className="mt-8 flex flex-col">
          <DividerTopLabel label={i18n.t('profile.info').toUpperCase()} className="mb-2" />
          {renderInfoLine(MdCorporateFare, i18n.t('input.labels.company'), company)}
          {renderInfoLine(MdAccountBox, i18n.t('input.labels.roles'), role)}
          {renderInfoLine(MdMailOutline, i18n.t('input.labels.companyEmail'), email)}
        </div>

        {renderButtons()}
      </form>
    );
  };

  const renderActivityLog = () => {
    return (
      <div className={styles.card}>
        {/* temporary hidden until activity feature is complete
         <div className={styles.cardTitle}>{i18n.t('profile.labels.activity')}</div>
        <div className={styles.cardContent}>
          <DividerTopLabel label="TODAY" className="mb-2 w-full" />
        </div> */}
      </div>
    );
  };

  return (
    <PageLayout className="bg-bck-body pb-0">
      <div className="h-full w-full bg-bck-body">
        <div className={styles.content}>
          {renderProfileForm()}
          {isError && <ErrorMessage error={error as IQueryError} />}
        </div>
        <ChangePassword onClose={() => setOpenChangePassword(false)} open={openChangePassword} />
      </div>
    </PageLayout>
  );
}

const styles = {
  content: 'flex min-w-[38.667rem] bmd:w-fit w-full h-full p-4',
  card: 'flex flex-col flex-1 bg-paper-card rounded-md p-6 relative',
  cardTitle: 'text-xl text-txt-primary',
  cardContent: 'flex  mt-6 ',
  avatarContainer: 'my-10 flex flex-col items-center',
  input: 'grid-12 col-span-6 col-span-3',
  infoLine: 'flex flex-row items-center w-full mt-2',
  infoIcon: 'fill-primary-text w-5 h-5',
  infoLabel: 'ml-2 text-sm font-semibold text-txt-primary',
  infoValue: 'ml-2 text-sm text-txt-secondary',
  uploadButton: 'text-xs text-txt-primary cursor-pointer mt-2',
  resetPassButton:
    'flex ml-3 mt-2 decoration-none text-txt-primary font-medium items-center self-center cursor-pointer',
};

export default UserAccount;
