import { NavLocationType } from '@/utils/constants';
import { useUserApi, useUserState } from '@/context/user/context-hooks';
import { Transition } from '@headlessui/react';
import NavMenuContainer from '@/components/NavigationMenu/NavMenuContainer';
import NavMenuInvisiblePositioner from '@/components/NavigationMenu/NavMenuInvisiblePositioner';
import { useHotkeys } from 'react-hotkeys-hook';
import { Key } from 'ts-key-enum';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import KeyboardMapItem from '@/components/NavigationMenu/KeyboardMapItem';
import useWindowFocus from 'use-window-focus';
import { KeyboardArrowDownIcon, KeyboardArrowLeftIcon, KeyboardArrowRightIcon, KeyboardArrowUpIcon } from '../icons';

const enterTransition = 'transition ease-out duration-300 transform';
const leaveTransition = 'transition ease-out duration-300 transform';

function NavigationMenu() {
  const {
    preferences: { navigation },
    isNavigationMinimized,
  } = useUserState();
  const isFloatingNavigation = navigation.isFloating;
  const navigationLocation = navigation.location;
  const isAutoMinimize = navigation.autoMinimize;

  const isNavBarOnTop = navigationLocation === NavLocationType.TOP || !isFloatingNavigation;
  const isNavBarOnBottom = navigationLocation === NavLocationType.BOTTOM && isFloatingNavigation;
  const isNavBarOnLeft = navigationLocation === NavLocationType.LEFT && isFloatingNavigation;
  const isNavBarOnRight = navigationLocation === NavLocationType.RIGHT && isFloatingNavigation;
  const isNavigationMinimizeAndFloat = isNavigationMinimized && isFloatingNavigation;

  const { setPreferences, setNavigationMinimized } = useUserApi();
  const [keymapVisible, setKeymapVisible] = useState(false);
  useHotkeys(
    `${Key.Alt}`,
    (ev) => {
      ev.preventDefault();
      setKeymapVisible(ev.type === 'keydown');
    },
    {
      keyup: true,
      keydown: true,
    },
  );

  const windowFocused = useWindowFocus();
  useEffect(() => {
    if (!windowFocused) {
      setKeymapVisible(false);
    }
  }, [windowFocused]);

  const setNavigationLocation = (location: NavLocationType) => {
    setPreferences('navigation', { ...navigation, location });
  };

  useHotkeys(`${Key.Alt}+${Key.ArrowLeft}`, (e) => {
    e.preventDefault();
    setNavigationLocation(NavLocationType.LEFT);
  });

  useHotkeys(`${Key.Alt}+${Key.ArrowRight}`, (e) => {
    e.preventDefault();
    setNavigationLocation(NavLocationType.RIGHT);
  });

  useHotkeys(`${Key.Alt}+${Key.ArrowDown}`, (e) => {
    e.preventDefault();
    setNavigationLocation(NavLocationType.BOTTOM);
  });

  useHotkeys(`${Key.Alt}+${Key.ArrowUp}`, (e) => {
    e.preventDefault();
    setNavigationLocation(NavLocationType.TOP);
  });

  useHotkeys(`${Key.Alt}+F`, (e) => {
    e.preventDefault();
    setPreferences('navigation', { ...navigation, isFloating: !isFloatingNavigation });
  });

  useHotkeys(`${Key.Alt}+M`, (e) => {
    e.preventDefault();
    setPreferences('navigation', { ...navigation, autoMinimize: !isAutoMinimize });
    setNavigationMinimized(!isAutoMinimize);
  });

  return (
    <NavMenuInvisiblePositioner>
      <div
        className={clsx('fixed inset-0 z-20', {
          hidden: !keymapVisible,
        })}
      >
        <div className="absolute left-10 top-12 rounded-xl bg-black bg-opacity-70 p-2">
          <div className="flex flex-col gap-2 text-sm text-white">
            <KeyboardMapItem
              hotkey={<KeyboardArrowLeftIcon className="h-5 w-5" />}
              description="Move Navigation to Left"
            />
            <KeyboardMapItem
              hotkey={<KeyboardArrowRightIcon className="h-5 w-5" />}
              description="Move Navigation to Right"
            />
            <KeyboardMapItem
              hotkey={<KeyboardArrowUpIcon className="h-5 w-5" />}
              description="Move Navigation to Top"
            />
            <KeyboardMapItem
              hotkey={<KeyboardArrowDownIcon className="h-5 w-5" />}
              description="Move Navigation to Bottom"
            />
            <KeyboardMapItem hotkey="F" description="Toggle Floating Navigation" />
            <KeyboardMapItem hotkey="M" description="Toggle Auto Minimize" />
          </div>
        </div>
      </div>
      <Transition
        show={isNavBarOnTop}
        as={NavMenuInvisiblePositioner}
        enter={enterTransition}
        enterFrom=" -translate-y-full opacity-0"
        enterTo="translate-y-0 opacity-100"
        leave={leaveTransition}
        leaveFrom="translate-y-0 opacity-100 "
        leaveTo=" -translate-y-full opacity-0 "
      >
        <NavMenuContainer
          containerCssClasses={{
            'pointer-events-auto': true,
            'left-1/2 -translate-x-1/2 top-0 ': isFloatingNavigation,
          }}
          isNavBarHorizontal={true}
          isNavBarOnTop={true}
          isFloatingNavigation={isFloatingNavigation}
        />
      </Transition>

      <Transition
        show={isNavBarOnLeft}
        as={NavMenuInvisiblePositioner}
        enter={enterTransition}
        enterFrom=" -translate-x-full opacity-0"
        enterTo="translate-x-0 opacity-100"
        leave={leaveTransition}
        leaveFrom="translate-x-0 opacity-100 "
        leaveTo=" -translate-x-full opacity-0 "
      >
        <NavMenuContainer
          containerCssClasses={{
            'top-1/2 -translate-y-1/2 rotate-90 pointer-events-auto': true,
            'left-0 -translate-x-1/2 ': true,
            'ml-5': !isNavigationMinimizeAndFloat,
            'ml-2': isNavigationMinimizeAndFloat,
          }}
          isNavBarVertical={true}
          isNavBarOnLeft={true}
          isFloatingNavigation={true}
        />
      </Transition>

      <Transition
        show={isNavBarOnRight}
        as={NavMenuInvisiblePositioner}
        enter={enterTransition}
        enterFrom=" translate-x-full opacity-0"
        enterTo="translate-x-0 opacity-100"
        leave={leaveTransition}
        leaveFrom="translate-x-0 opacity-100 "
        leaveTo=" translate-x-full opacity-0 "
      >
        <NavMenuContainer
          containerCssClasses={{
            'top-1/2 -translate-y-1/2 rotate-90 pointer-events-auto': true,
            'right-0 translate-x-1/2': true,
            'mr-5': !isNavigationMinimizeAndFloat,
            'mr-2': isNavigationMinimizeAndFloat,
          }}
          isNavBarVertical={true}
          isNavBarOnRight={true}
          isFloatingNavigation={true}
        />
      </Transition>

      <Transition
        show={isNavBarOnBottom}
        as={NavMenuInvisiblePositioner}
        enter={enterTransition}
        enterFrom=" translate-y-full opacity-0"
        enterTo="translate-y-0 opacity-100"
        leave={leaveTransition}
        leaveFrom="translate-y-0 opacity-100 "
        leaveTo=" translate-y-full opacity-0 "
      >
        <NavMenuContainer
          containerCssClasses={{
            'left-1/2 -translate-x-1/2 pointer-events-auto': true,
            'bottom-0.5': !isNavigationMinimizeAndFloat,
            'bottom-0 -mb-0.5': isNavigationMinimizeAndFloat,
          }}
          isFloatingNavigation={true}
          isNavBarHorizontal={true}
          isNavBarOnBottom={true}
        />
      </Transition>
    </NavMenuInvisiblePositioner>
  );
}

export default NavigationMenu;
