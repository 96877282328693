import type { IconType } from 'react-icons';
import type { NotificationsTypes } from '@/utils/constants';
import type { ReactNode } from 'react';
import type { InventoryResponseDtoV2, PricingGroupRequest } from '@/api/pricer/pricerApiSchemas';
import type { GetProductionsQueryParams } from '@/api/pricer/pricerApiComponents';
import type { Placement } from '@floating-ui/react';

export interface TField {
  value: string;
  valid?: boolean;
}

export interface TDataField {
  name: string;

  [key: string]: any;
}

export interface IDataField {
  name: string | null | undefined;
  key: string | null | undefined;

  [key: string]: any;
}

export interface IRadioOption {
  label: string;
  options: IDataField[];
  selected: IDataField | undefined;
}

export interface IYesNoOption {
  label: string;
  options: IDataField[];
  selected: IDataField | undefined;
}

export interface IOptionItem<T> {
  label: string;
  value: T;
  selected: boolean;
  [key: string]: any;
}

export interface IOptionsSet<T> {
  label: string;
  toolTipMessage: string;
  options: IOptionItem<T>[];
}

export enum EFilterType {
  OPTIONS = 'options',
  RADIO = 'radio',
  INPUT = 'input',
  YESNO = 'yesno',
  TOGGLE = 'toggle',
}

export interface IProductionsFilters {
  status: {
    type: EFilterType.OPTIONS;
    data: IOptionsSet<string>;
  };
  eventDay: {
    type: EFilterType.OPTIONS;
    data: IOptionsSet<string>;
  };
  pricer: {
    type: EFilterType.OPTIONS;
    data: IOptionsSet<string>;
  };
  isParking: {
    type: EFilterType.RADIO;
    data: IRadioOption;
  };
  isNoTickets: {
    type: EFilterType.RADIO;
    data: IRadioOption;
  };
}

export interface IInventoryFilters {
  status: {
    type: EFilterType.OPTIONS;
    data: IOptionsSet<string>;
  };
  quantity: {
    type: EFilterType.OPTIONS;
    data: IOptionsSet<number>;
  };
  pricingCriteriaGroup: {
    type: EFilterType.OPTIONS;
    data: IOptionsSet<string>;
  };
  seatType: {
    type: EFilterType.OPTIONS;
    data: IOptionsSet<string>;
  };
  splitRule: {
    type: EFilterType.OPTIONS;
    data: IOptionsSet<string>;
  };
  stockType: {
    type: EFilterType.OPTIONS;
    data: IOptionsSet<string>;
  };
  attributes: {
    type: EFilterType.OPTIONS;
    data: IOptionsSet<string>;
  };
  optionsFilter: {
    type: EFilterType.OPTIONS;
    data: IOptionsSet<string>;
  };
  isShared: {
    type: EFilterType.RADIO;
    data: IRadioOption;
  };
  isInHand: {
    type: EFilterType.RADIO;
    data: IRadioOption;
  };
  isSpeculation: {
    type: EFilterType.RADIO;
    data: IRadioOption;
  };
}

export interface IMarketFilters {
  rowRange?: {
    type: EFilterType.INPUT;
    data: { label: string; option: string };
  };
  rowSequence?: {
    type: EFilterType.INPUT;
    data: { label: string; option: string };
  };
  zones: {
    type: EFilterType.OPTIONS;
    data: IOptionsSet<string>;
  };
  splits: {
    type: EFilterType.OPTIONS;
    data: IOptionsSet<number>;
    dirty: boolean;
  };
  dynamicSplits: {
    type: EFilterType.TOGGLE;
    data: boolean;
    disabled: boolean;
    dirty: boolean;
    defaultEnabled?: boolean;
  };
  sections: {
    type: EFilterType.OPTIONS;
    data: IOptionsSet<string>;
  };
  deliveryMethod: {
    type: EFilterType.OPTIONS;
    data: IOptionsSet<string>;
  };
  attributes: {
    type: EFilterType.OPTIONS;
    data: IOptionsSet<string>;
  };
  lowerOutlierMethod: {
    type: EFilterType.RADIO;
    data: IRadioOption;
  };
  lowerOutlierAmount: {
    type: EFilterType.INPUT;
    data: { label: string; option: string | undefined };
  };
  seatSaver?: {
    type: EFilterType.YESNO;
    data: IYesNoOption;
  };
  showOutliers?: {
    type: EFilterType.TOGGLE;
    data: boolean;
  };
  showMyListings?: {
    type: EFilterType.TOGGLE;
    data: boolean;
  };
}

export interface IQueryError {
  message: string;

  [key: string]: any;
}

export interface IQueryErrorItem {
  propertyName: string;
  errorCode: string;
  errorMessage: string;
}

export interface SideBarMenuItem {
  key: string;
  name: string;
  path: string;
  icon?: IconType;
  subItems?: SideBarMenuItem[];
}

export interface PredefinedMenuItem extends SideBarMenuItem {
  component: JSX.Element;
}

export interface AppMenuItems {
  items: SideBarMenuItem[];
}
export interface SideBarMenu {
  apps?: AppMenuItems;
  systemSettings?: AppMenuItems;
}

export interface INotification {
  id: string;
  title: string;
  message?: string;
  contents?: ReactNode;
  type: NotificationsTypes;
  createdAt: string;
  isRead: boolean;
}

export interface IFilterSimple {
  [key: string]: boolean;
}

export interface IFilterNamed {
  [key: string]: { isChecked: boolean; displayName: string; value?: string };
}

export interface IFilters {
  [key: string]: IFilterSimple | IFilterNamed;
}

export type ProductionsQuickFiltersKeys = Omit<
  GetProductionsQueryParams,
  | 'NoAvailableTicketGroups'
  | 'ProductionId'
  | 'Pricer'
  | 'NoPricer'
  | 'ShowParking'
  | 'ShowSpeculation'
  | 'ProductionStatus'
  | 'DayOfWeek'
  | 'Filter'
  | 'StartDate'
  | 'EndDate'
  | 'SortBy'
  | 'PageNumber'
  | 'PageSize'
  | 'SortColumn'
  | 'SortOrder'
  | 'MasterVenueId'
  | 'Sorting.EventName'
  | 'Sorting.EventDate'
  | 'Sorting.VenueName'
  | 'Sorting.MyListings'
  | 'Sorting.MyAvgPrice'
  | 'Sorting.Sold'
  | 'Sorting.LastSoldPrice'
  | 'Sorting.Ceiling'
  | 'Sorting.Floor'
  | 'Sorting.NoPricingGroupCount'
  | 'Sorting.NoComparablesCount'
  | 'Sorting.Pricer'
  | 'Sorting.MyActiveTickets'
  | 'Sorting.MySoldTickets'
>;
type IProductionFilterLabel = {
  label: string;
  isChecked: boolean;
};

export type IProductionQuickFilters = {
  [key in keyof ProductionsQuickFiltersKeys | 'within90Days']: IProductionFilterLabel;
};

export interface ICellProps {
  value: string | undefined | null;
  subValue?: string | null | undefined;
  markValue?: 'warning' | 'error' | 'success' | 'main';
  valueClassName?: string;
  containerClassName?: string;
  tooltipPosition?: Placement;
  toolTipOffset?: number;
  toolTipMessage?: string;
}

export interface IProductionInsight {
  label: string;
  value?: number;
  formattedValue: number | string;
  change?: number;
  code?: string;
  isSelected?: boolean;
  isDateFilter?: boolean;
  HeaderIcon?: IconType;
  isDisabledType?: boolean;
}

export interface DetailedError {
  status: number;
  stack: {
    payload: {
      propertyName: string;
      errorCode: string;
      errorMessage: string;
    }[];
  };
}

export interface IColumnHeaderLabel {
  label: string;
  subLabel?: string;
  tooltip?: string;
}
export interface PricingGroupRequestExtended extends PricingGroupRequest {
  requestId: number | null;
  // anchor placeholder (non-nullable) used to keep the PG sorted in case of removal of all it's tickets
  placeholderAnchorTicketId?: InventoryResponseDtoV2['ticketGroupId'];
}
