import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { apiEndpoints, getConfigAPI } from '@/utils/api';
import { ConfigurationContext } from './context-hooks';

function ConfigurationContextProvider(props: React.PropsWithChildren<{}>) {
  const { data: countriesData } = useQuery({
    queryKey: ['countries'],
    queryFn: () => getConfigAPI(apiEndpoints.configuration.countries),
  });

  const { data: regionsData } = useQuery({
    queryKey: ['regions'],
    queryFn: () => getConfigAPI(apiEndpoints.configuration.regions),
  });

  const countries = React.useMemo(() => {
    if (!countriesData) return [];
    try {
      const countriesArray = countriesData?.value ? JSON.parse(countriesData.value) : [];
      return countriesArray.map((country: { id: string; name: string }) => ({ key: country.id, name: country.name }));
    } catch (e) {
      console.log('error parsing countriesData', e);
      return [];
    }
  }, [countriesData]);

  const regions = React.useMemo(() => {
    if (!regionsData) return [];

    try {
      const regionsArray = regionsData?.value ? JSON.parse(regionsData.value) : [];
      return regionsArray.map((region: { id: string; name: string }) => ({ key: region.id, name: region.name }));
    } catch (e) {
      console.log('error parsing regionsData', e);
      return [];
    }
  }, [regionsData]);

  return <ConfigurationContext.Provider value={{ countries, regions }} {...props} />;
}

export { ConfigurationContextProvider };
