const onBeforeUnload = (event: any) => {
  event.preventDefault();
  event.returnValue = true;
};

export const UnsavedChangesBrowserManager = {
  attach: () => {
    window.addEventListener('beforeunload', onBeforeUnload);
  },
  detach: () => {
    window.removeEventListener('beforeunload', onBeforeUnload);
  },
};
