import IconButton from '../IconButton';
import type { INotification } from '@/interfaces/CommonInterfaces';
import NotificationIcon from './NotificationIcon';
import { CheckCircleOutlineIcon } from '@/components/icons';
import { ServerIsoToUserDateTimeFormat } from '@/utils/constants';

interface IProps {
  icon?: React.ReactNode;
  notification: INotification;
  onMarkRead: (id: string) => void;
}

function NotificationsMenuItem({ icon, notification, onMarkRead }: IProps) {
  const { type: variant, message, createdAt, isRead, id, contents } = notification;
  const notificationDate = ServerIsoToUserDateTimeFormat(createdAt);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.row}>
          {icon ?? <NotificationIcon variant={variant} />}
          <div className={styles.date}>{notificationDate}</div>
        </div>
        {message ? <div className={styles.message}>{message}</div> : null}
        {contents}
      </div>
      {!isRead && <IconButton WrappedIcon={CheckCircleOutlineIcon} onClick={() => onMarkRead(id)} size="sm" />}
    </div>
  );
}

const styles = {
  container: 'flex items-center justify-between px-4 py-3 border-b border-divider',
  content: 'flex-1 flex flex-col gap-1',
  row: 'flex items-center gap-2',
  date: 'text-xs text-txt-disabled',
  message: 'text-sm  [&_a]:underline',
};

export default NotificationsMenuItem;
