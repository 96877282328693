import { AppModeIsDrive } from '@/utils/constants';
import sbLogo from '@/assets/images/sb_poweredby_prqx_m_d.svg';
import membersLogo from '@/assets/images/base/logo.svg';

interface IProps {
  text: string;
}
function LoadingPage({ text }: IProps) {
  const logo = AppModeIsDrive ? sbLogo : membersLogo;

  return (
    <div className="auth-background scrollbar">
      <header className="error-container">
        <img src={logo} className="error-app-logo" alt="logo" />
        <p className="loading-text">{text}</p>
      </header>
    </div>
  );
}

export default LoadingPage;
