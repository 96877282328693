import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import type { MouseEvent } from 'react';
import Avatar from './Avatar';
import { NavLink } from 'react-router-dom';
import { useUserState } from '@/context/user/context-hooks';
import i18n from '@/utils/i18n';
import { AppModeIsDrive, NavLocationType } from '@/utils/constants';
import { AuthService } from '@/services/AuthService';
import clsx from 'clsx';
import { useCommonContextApi } from '@/context/common/context-hooks';
import NavMenuLocationPanelOnly from '@/components/NavigationMenu/NavMenuLocationPanelOnly';
import { UnsavedChangesBrowserManager } from '@/utils/UnsavedChangesBrowserManager';
import { HiOutlineUserIcon, LogoutIcon } from '../icons';
import { Badge } from '../common';
import { Tooltip } from '@/components/common';

const userNavigation = [{ name: i18n.t('profile.buttons.profile'), href: '/profile', icon: HiOutlineUserIcon }];

function ProfileDropMenu() {
  const { setUserLeavesEditMode } = useCommonContextApi();
  const { user } = useUserState();
  const profile = user;
  const { preferences } = useUserState();
  const isFloatingNavigation = preferences.navigation.isFloating;
  const navigationLocation = preferences.navigation.location;

  const isNavBarOnTop = navigationLocation === NavLocationType.TOP || !isFloatingNavigation;
  const isNavBarOnBottom = navigationLocation === NavLocationType.BOTTOM && isFloatingNavigation;
  const isNavBarOnLeft = navigationLocation === NavLocationType.LEFT && isFloatingNavigation;
  const isNavBarOnRight = navigationLocation === NavLocationType.RIGHT && isFloatingNavigation;

  const handleLogout = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    // prompt user to save changes before logging out
    setUserLeavesEditMode({
      userLeaves: true,
      leaveFunction: () => {
        UnsavedChangesBrowserManager.detach();
        AuthService.logout();
      },
    });
  };

  const renderProfileItem = () => {
    const roles = profile?.roles && profile.roles.length > 0 ? profile.roles : null;
    const rolesText = roles ? roles.map((role) => role.name).join(',') : 'N/A';
    const nameText = profile?.name ? profile.name : 'N/A';
    return (
      <div className="py-1">
        <Menu.Item key={'profile'}>
          {() => (
            <div className={styles.profileInfo}>
              <Avatar variant="md" name={user?.name} />
              <div className={styles.nameContainer}>
                <div className={styles.name}>{nameText}</div>
                <div className={styles.role}>{rolesText}</div>
              </div>
            </div>
          )}
        </Menu.Item>
      </div>
    );
  };

  const renderProfileMenuOptions = () => {
    return (
      <div className="py-1">
        {userNavigation.map((item) => (
          <Menu.Item key={item.name}>
            {({ active }) => (
              <NavLink
                to={AppModeIsDrive ? `${item.href}/notifications` : item.href}
                className={clsx(active ? styles.itemActive : '', styles.itemDefault)}
              >
                <item.icon className={styles.menuIcon} aria-hidden="true" />
                <button>{item.name}</button>
              </NavLink>
            )}
          </Menu.Item>
        ))}
      </div>
    );
  };

  const renderLogoutItem = () => {
    return (
      <div className="py-1">
        <Menu.Item key={'logout'}>
          {({ active }) => (
            <div
              className={clsx('flex justify-between gap-2', active ? styles.itemActive : '', styles.itemDefault)}
              onClick={(e) => handleLogout(e)}
            >
              <div className="flex items-center whitespace-nowrap">
                <LogoutIcon className={styles.menuIcon} aria-hidden="true" />
                <span>{i18n.t('profile.buttons.signOut')}</span>
              </div>
            </div>
          )}
        </Menu.Item>
      </div>
    );
  };

  const renderVersion = () => {
    return (
      <div className="flex items-center justify-between px-4 py-2 text-xs">
        {i18n.t('navigation.version')}
        <Badge size="sm" className="w-fit rounded-[4rem] px-1.5 text-xs" title={import.meta.env.REACT_APP_VERSION} />
      </div>
    );
  };

  return (
    <Menu as="div" className="relative ml-2">
      <Menu.Button
        className={clsx(styles.trigger, {
          '-rotate-90': isNavBarOnRight || isNavBarOnLeft,
        })}
      >
        <Tooltip message={i18n.t('navigation.tooltips.profile')}>
          <div>
            <Avatar name={user?.name} />
          </div>
        </Tooltip>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={clsx(styles.menu, {
            'right-0 mt-2 origin-top-right': isNavBarOnTop,
            'bottom-8 right-0 origin-top-right': isNavBarOnBottom,
            'bottom-8 left-5 origin-bottom-left -rotate-90': isNavBarOnLeft,
            '-bottom-2 right-0 origin-bottom-right -rotate-90': isNavBarOnRight,
          })}
        >
          {renderProfileItem()}
          {renderProfileMenuOptions()}
          <div className="py-1">
            <Menu.Item key={'navLocation'}>
              {({ close }) => (
                <div className="p-3">
                  <NavMenuLocationPanelOnly close={close} />
                </div>
              )}
            </Menu.Item>
          </div>
          {renderVersion()}
          {renderLogoutItem()}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

const styles = {
  trigger: 'flex items-center rounded-full bg-white text-sm ring-white p-0 select-none',
  menu: 'absolute z-10 w-56 rounded-md bg-paper-card py-1 drop-shadow-2xl ring-1 ring-black ring-opacity-5 divide-y divide-divider focus:outline-none',
  itemDefault: 'flex items-center pl-4 pr-3 py-2 text-sm text-txt-primary cursor-pointer',
  itemActive: 'bg-primary-alt-dark',
  profileInfo: 'flex px-4 py-2 text-sm text-txt-primary',
  nameContainer: 'flex flex-col px-3 overflow-hidden',
  name: 'text-txt-primary font-bold truncate',
  role: 'text-txt-secondary w-24 overflow-hidden overflow-ellipsis',
  menuIcon: 'mr-2 h-4 w-4 flex-shrink-0',
};

export default ProfileDropMenu;
