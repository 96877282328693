import { useEffect, useMemo, useState } from 'react';
import { StepNames } from '@/routes/templates/templatePage/template-page-context-hooks';
import type { Step } from '@/routes/templates/templatePage/template-page-context-hooks';
import i18n from '@/utils/i18n';

function useStepWizardForTemplates() {
  const [steps, setSteps] = useState<Step[]>([
    {
      label: i18n.t('pricingApplyTemplatePage.progress.selectEvents'),
      isSelected: true,
      count: '01',
      index: StepNames.SelectProductions,
    },
    {
      label: i18n.t('pricingApplyTemplatePage.progress.selectTicketGroups'),
      isSelected: false,
      count: '02',
      index: StepNames.SelectTicketGroups,
    },
    {
      label: i18n.t('pricingApplyTemplatePage.progress.reviewAndApply'),
      isSelected: false,
      count: '03',
      index: StepNames.ReviewPricingGroups,
    },
    {
      label: 'APPLY_HIDDEN_STEP',
      isSelected: false,
      hidden: true,
      count: '04',
      index: StepNames.ApplyHiddenStep,
    },
  ]);

  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  useEffect(() => {
    setCurrentStepIndex(steps.findLastIndex((step) => step.isSelected));
  }, [steps]);

  const visibleSteps = useMemo(() => {
    return steps.filter((step) => !step.hidden);
  }, [steps]);

  const nextStep = () => {
    setSteps((prev) => {
      const index = currentStepIndex;
      return prev.map((step, i) => {
        if (i <= index) {
          return { ...step, isSelected: true };
        }
        if (i === index + 1) {
          return { ...step, isSelected: true };
        }
        return step;
      });
    });
  };

  const previousStep = (forceToIndex?: number) => {
    setSteps((prev) => {
      const index = forceToIndex || currentStepIndex;
      return prev.map((step, i) => {
        if (i >= index) {
          return { ...step, isSelected: false };
        }
        if (i <= index - 1) {
          return { ...step, isSelected: true };
        }
        return step;
      });
    });
  };

  return {
    currentStepIndex,
    setCurrentStepIndex,
    visibleSteps,

    nextStep,
    previousStep,
  };
}

export default useStepWizardForTemplates;
