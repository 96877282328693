import type { Placement } from '@floating-ui/react';
import type { useTooltip } from '@/components/common/tooltip/useTooltip';

export interface TooltipOptions {
  initialOpen?: boolean;
  placement?: Placement;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  delay?: number;
  toolTipOffset?: number;
}

export type ContextType = ReturnType<typeof useTooltip> | null;

export const TooltipPosition: { [key: string]: Placement } = {
  Top: 'top',
  Right: 'right',
  Left: 'left',
  Bottom: 'bottom',

  TopEnd: 'top-end',
  RightEnd: 'right-end',
  BottomEnd: 'bottom-end',
  LeftEnd: 'left-end',

  TopStart: 'top-start',
  RightStart: 'right-start',
  BottomStart: 'bottom-start',
  LeftStart: 'left-start',
} as const;
