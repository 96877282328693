import { useGetProfile } from '@/api/clientService/clientServiceApiComponents';

function useGetLoggedUser() {
  const { data, isLoading, refetch } = useGetProfile(
    {},
    {
      refetchOnMount: false,
    },
  );

  return {
    user: data,
    isLoading,
    refetch,
  };
}

export default useGetLoggedUser;
