import { useGetTicketGroupsByIdsV2 } from '@/api/pricer/pricerApiComponents';
import { useEffect } from 'react';
import { useMainSliceInventoryActions, useMainSliceInventoryStore } from '@/stores/inventory/inventory-store';

function useGetMultipleTicketGroupsUpdatedQuery() {
  const posSettledChanges = useMainSliceInventoryStore('posSettledChanges');
  const { data: multipleTicketGroupsUpdated } = useGetTicketGroupsByIdsV2(
    { queryParams: { ticketGroupIds: posSettledChanges } },
    { enabled: posSettledChanges.length > 0, refetchOnMount: false },
  );

  const processedInventory = useMainSliceInventoryStore('processedInventory');
  const { setProcessedInventory, setPosSettledChanges } = useMainSliceInventoryActions();
  useEffect(() => {
    if (!multipleTicketGroupsUpdated || multipleTicketGroupsUpdated.length === 0) return;
    const updatedInventory = processedInventory?.map((item) => {
      const updatedItem = multipleTicketGroupsUpdated.find((t) => t.ticketGroupId === item.ticketGroupId);
      if (updatedItem) item = updatedItem;
      return item;
    });
    setProcessedInventory(updatedInventory ?? []);
    setPosSettledChanges([]);
  }, [multipleTicketGroupsUpdated]);
}

export default useGetMultipleTicketGroupsUpdatedQuery;
