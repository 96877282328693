import { PublicClientApplication } from '@azure/msal-browser';
import type { RedirectRequest, EndSessionRequest } from '@azure/msal-browser';
import { msalConfig } from '../authConfig';
import { AppModeIsDrive } from '@/utils/constants';
import type { SilentRequest } from '@azure/msal-browser/dist/request/SilentRequest';
import { KEY_MSALIDTOKEN, KEY_SBAUID } from './auth-service-ids';
import { Constants } from '@azure/msal-common';
import type { AuthenticationResult } from '@azure/msal-common';

export const SCOPES = [
  Constants.OPENID_SCOPE,
  Constants.OFFLINE_ACCESS_SCOPE,
  // client Id
  import.meta.env.REACT_APP_B2C_APP_CLIENT_ID.toString(),
];

export class AuthService extends PublicClientApplication {
  private static instance: AuthService;

  private constructor(config: any) {
    super(config);
  }

  public static getInstance(): AuthService {
    if (!AuthService.instance) {
      AuthService.instance = new AuthService(msalConfig);
    }
    return AuthService.instance;
  }

  private static getActiveAccount(skipErrorThrow: boolean = false) {
    const instance = this.getInstance();
    const activeAccount = instance.getActiveAccount();
    const accounts = instance.getAllAccounts();
    const account = activeAccount || accounts[0];

    if (!account && !skipErrorThrow) {
      throw new Error('No accounts found');
    }

    return account;
  }

  public static logout(restorePage: boolean = true, postLogoutRedirectUri?: string) {
    const account = this.getActiveAccount(true);

    const uri = postLogoutRedirectUri || msalConfig.auth.postLogoutRedirectUri;
    if (!account) {
      window.location.href = uri;
      return Promise.resolve();
    }

    const { pathname, search } = window.location;
    const restoredState: EndSessionRequest = {};
    if (restorePage) {
      restoredState['state'] = pathname + search;
    }

    return this.getInstance().logoutRedirect({
      account,
      postLogoutRedirectUri: uri,
      idTokenHint: localStorage.getItem(KEY_MSALIDTOKEN) ?? '',
      ...restoredState,
    });
  }

  public static sbDriveLogin(): RedirectRequest | null | { redirecting: boolean } {
    const browserUrl = new URL(window.location.href);
    const params = browserUrl.searchParams;
    let sbauid = params.get('sbauid') ?? '';
    const auth = AuthService.getInstance();

    if (sbauid) {
      localStorage.setItem(KEY_SBAUID, sbauid);
      window.location.href = browserUrl.origin + browserUrl.pathname;
      return { redirecting: true };
    }

    sbauid = localStorage.getItem(KEY_SBAUID) ?? '';

    //We check if an authorization redirection is in place or if the user exists if not we cannot continue
    if (!browserUrl.hash.startsWith('#state') && !sbauid && auth.getAllAccounts().length < 1) {
      //Redirecting to the logout url if not login is possible
      window.location.href = `${import.meta.env.REACT_APP_REDIRECT_ON_LOGOUT}`;
      return null;
    }

    return {
      scopes: SCOPES,
      domainHint: import.meta.env.REACT_APP_B2C_DOMAIN_HINT,
      loginHint: sbauid,
      redirectUri: '/',
    };
  }

  public static async forceLogout(onBeforeLogout?: () => void) {
    onBeforeLogout?.();
    return this.logout();
  }

  public static tokenAcquiredOn: number | null = null;

  public static async getAccessToken(onBeforeLogout?: () => void) {
    const account = this.getActiveAccount();

    let request: SilentRequest = {
      scopes: SCOPES,
      account,
    };

    if (AppModeIsDrive) {
      const sbauid = localStorage.getItem(KEY_SBAUID) ?? '';

      request = {
        ...request,
        ...{
          domainHint: import.meta.env.REACT_APP_B2C_DOMAIN_HINT ?? '',
          loginHint: sbauid,
        },
      };
    }

    try {
      const authResult = await this.getTokenSilent(request);
      localStorage.setItem(KEY_MSALIDTOKEN, authResult.idToken ?? '');
      this.tokenAcquiredOn = Date.now();

      return authResult.idToken;
    } catch (e) {
      if (import.meta.env.DEV) {
        console.error(e);
      }
      onBeforeLogout?.();
      return await this.logout();
    }
  }

  // cache request when multiple calls are made
  private static tokenPromise: Promise<AuthenticationResult> | null = null;
  private static getTokenSilent(p: SilentRequest) {
    if (!this.tokenPromise) {
      this.tokenPromise = this.getInstance().acquireTokenSilent(p);
    }
    return this.tokenPromise.then((response) => {
      // reset the promise
      this.tokenPromise = null;
      return response;
    });
  }
}
