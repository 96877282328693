import type { ClientServiceApiContext } from './clientServiceApiContext';
import { AuthApiFetch } from '../AuthApiFetcher';

const baseUrl = import.meta.env.REACT_APP_PUBLIC_API_URL;

export type ClientServiceApiFetcherOptions<TBody, THeaders, TQueryParams, TPathParams> = {
  url: string;
  method: string;
  body?: TBody;
  headers?: THeaders;
  queryParams?: TQueryParams;
  pathParams?: TPathParams;
  signal?: AbortSignal;
  includeAuth?: boolean;
} & ClientServiceApiContext['fetcherOptions'];

export type ErrorWrapper<TError> = TError | { status: 'unknown'; payload: string };

export async function clientServiceApiFetch<
  TData,
  TError,
  TBody extends {} | FormData | undefined | null,
  THeaders extends {},
  TQueryParams extends {},
  TPathParams extends {},
>({
  url,
  method,
  body,
  headers,
  pathParams,
  queryParams,
  signal,
}: ClientServiceApiFetcherOptions<TBody, THeaders, TQueryParams, TPathParams>): Promise<TData> {
  return AuthApiFetch<TData, TError, TBody, THeaders, TQueryParams, TPathParams>({
    baseUrl,
    url,
    method,
    body,
    headers,
    pathParams,
    queryParams,
    signal,
  });
}
