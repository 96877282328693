import { MdCheckCircleOutline } from 'react-icons/md';

interface PasswordRequirementProps {
  label: string;
  checked: boolean;
  id: string;
  name: string;
}
const PasswordRequirement: React.FC<PasswordRequirementProps> = ({ label, checked, id, name }) => {
  return (
    <label className={styles.passwordRequirement}>
      <input type="checkbox" id={id} name={name} disabled={true} defaultChecked={false} className={styles.checkInput} />
      {checked && <MdCheckCircleOutline className={styles.checkmark} />}
      {label}
    </label>
  );
};

const styles = {
  passwordRequirement: '!relative m-0 mb-2 !text-sm !font-normal text-txt-secondary',
  checkInput: 'mr-2 p-2 border-0 rounded-full bg-paper-card text-success-alt-dark',
  checkmark: 'text-success-main absolute left-0 top-0 h-5 w-5 text-opacity-80',
};

export default PasswordRequirement;
