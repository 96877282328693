import clsx from 'clsx';

interface IProps {
  width?: number;
  height?: number;
  className?: string;
  style?: React.CSSProperties;
}

const InventoryIcon = ({ width, height, className = '', style }: IProps) => {
  const widthIn = width || 24;
  const heightIn = height || 24;
  return (
    <svg
      className={clsx('fill-txt-primary', className)}
      style={style}
      width={widthIn}
      height={heightIn}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.7146 1.00022H9.1561L9.06865 1.31783C8.92455 1.84137 8.44538 2.20692 7.90298 2.20692C7.36058 2.20692 6.88131 1.84137 6.73742 1.31783L6.65008 1.00022H4.09156C3.48968 1.00022 3 1.4899 3 2.09178V13.9084C3 14.5103 3.48968 15 4.09156 15H6.64989L6.73734 14.6824C6.88143 14.1589 7.3606 13.7933 7.90289 13.7933C8.4453 13.7933 8.92456 14.1589 9.06856 14.6824L9.15612 14.9998H11.7145C12.3163 14.9998 12.806 14.5101 12.806 13.9082V2.09156C12.806 1.48968 12.3163 1 11.7147 1L11.7146 1.00022ZM4.86867 11.6836H3.77017C3.53135 11.6836 3.33791 11.4901 3.33791 11.2513C3.33791 11.0125 3.53137 10.819 3.77017 10.819H4.86867C5.10749 10.819 5.30094 11.0125 5.30094 11.2513C5.30094 11.49 5.10748 11.6836 4.86867 11.6836ZM7.35051 11.6836H6.252C6.01318 11.6836 5.81974 11.4901 5.81974 11.2513C5.81974 11.0125 6.0132 10.819 6.252 10.819H7.35051C7.58932 10.819 7.78277 11.0125 7.78277 11.2513C7.78277 11.49 7.58942 11.6836 7.35051 11.6836ZM9.83253 11.6836H8.73403C8.49521 11.6836 8.30176 11.4901 8.30176 11.2513C8.30176 11.0125 8.49522 10.819 8.73403 10.819H9.83253C10.0713 10.819 10.2648 11.0125 10.2648 11.2513C10.2648 11.49 10.0714 11.6836 9.83253 11.6836ZM9.69657 7.38813L9.50355 8.97587L7.92806 8.69972L6.3582 9.0068L6.13425 7.42295L5.35713 6.02487L6.79426 5.32241L7.88376 4.15122L8.99593 5.30094L10.4464 5.97529L9.69657 7.38813ZM12.1297 11.6836H11.2161C10.9772 11.6836 10.7838 11.4901 10.7838 11.2513C10.7838 11.0125 10.9773 10.819 11.2161 10.819H12.1297C12.3685 10.819 12.562 11.0125 12.562 11.2513C12.562 11.49 12.3685 11.6836 12.1297 11.6836Z" />
    </svg>
  );
};

export default InventoryIcon;
