import { useGetPricingGroupsByProduction } from '@/api/pricer/pricerApiComponents';
import { useMainSliceInventoryStore } from '@/stores/inventory/inventory-store';

function useFetchPricingGroupsQuery() {
  const selectedProduction = useMainSliceInventoryStore('selectedProduction');
  const {
    data: pricingGroups,
    refetch: refetchPricingGroups,
    isPending,
  } = useGetPricingGroupsByProduction(
    { pathParams: { productionId: Number(selectedProduction?.productionId) } },
    { enabled: Boolean(selectedProduction?.productionId), refetchOnMount: false },
  );

  return {
    pricingGroups,
    refetchPricingGroups,
    isPending,
  };
}

export default useFetchPricingGroupsQuery;
