export default {
  navTitle: 'Members',
  title: 'Manage Members',
  noResults: 'No members found',
  search: 'Name or Email',
  selectRole: 'Select Role',
  columns: {
    user: 'Member',
    email: 'Email',
    phone: 'Phone',
    location: 'Location',
    role: 'Role',
    status: 'Status',
    action: 'Action',
  },
  buttons: {
    export: 'Export',
    bulkAction: 'Bulk Action',
    invite: 'Invite Member',
  },
  menu: {
    settings: 'Member Settings',
    resendInvite: 'Resend Invite',
    cancelInvite: 'Cancel Invite',
    deactivate: 'Deactivate',
    activate: 'Activate',
    deleteUser: 'Delete',
    resetPassword: 'Reset Password',
    sendMessage: 'Send Message',
  },
  invite: {
    addDetails: 'Add Member Details',
    loadingTitle: 'Sending invitation...',
    loadingMessage: 'Please wait while we send the invitation to the member.',
    errorTitle: 'Error inviting the member.',
    error: 'There was an error inviting the member, please try again later.',
    successTitle: 'Successfully invited the member.',
    successMessage: 'We have sent an email to the member with the invitation link.',
    resendSuccessTitle: 'Successfully resent the invitation.',
    resendSuccessMessage: 'We have resent the invitation email to the member.',
    cancelSuccessTitle: 'Successfully cancelled the invitation.',
    cancelSuccessMessage: 'We have cancelled the invitation for the member.',
  },
  update: {
    successTitle: 'Success',
    successMessage: 'Member has been updated successfully.',
    errorTitle: 'Error',
    errorMessage: 'There was an error updating the member, please try again later.',
  },
  resetPassword: {
    successTitle: 'Success',
    successMessage: 'Member password has been reset successfully.',
  },
  changePassword: {
    headerTitle: 'Change Password',
    successTitle: 'Successfully changed password.',
    successMessage: 'Member password has been changed successfully.',
    loadingTitle: 'Changing password...',
    loadingMessage: 'Please wait while we change your password.',
    submitButton: 'Submit Password',
    requirements: {
      title: 'Password Requirements',
      length: 'Minimum 8 characters',
      uppercase: '1 upper case letter',
      number: '1 number',
      special: '1 special character',
      match: 'Passwords match',
    },
  },
  settings: {
    title: 'Member Activity',
    removeImage: 'Remove Image',
    userDetails: 'Member Details',
  },
  rowsPerPage: 'Rows per page:',
  currentRows: '%{from} - %{to} of %{total}',
  status: {
    title: 'Status',
    active: 'Active',
    inactive: 'Inactive',
    pending: 'Pending',
    redeemed: 'Redeemed',
    deleted: 'Deleted',
  },
  allStatuses: 'All',
};
