import { useEffect } from 'react';
import { initiateAppInsightsNoUserNoOrganization } from '@/appinsights';

function useAppInsightsNoUserNoOrganization() {
  useEffect(() => {
    initiateAppInsightsNoUserNoOrganization();
  }, []);
}

export default useAppInsightsNoUserNoOrganization;
