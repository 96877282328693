import { MdClose, MdOutlineNotifications } from 'react-icons/md';
import { Popover, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import i18n from '@/utils/i18n';
import NotificationsMenuItem from './NotificationsMenuItem';
import ctl from '@netlify/classnames-template-literals';
import clsx from 'clsx';
import { useNotificationsApi, useNotificationsState } from '@/context/notifications/context-hooks';
import { NavLocationType } from '@/utils/constants';
import { useUserState } from '@/context/user/context-hooks';
import { CheckCircleOutlineIcon } from '@/components/icons';
import Tooltip from '../tooltip/Tooltip';

function NotificationsDropMenu() {
  const [isFull, setIsFull] = useState(false);
  const { notifications, unreadCount } = useNotificationsState();
  const { readNotification, readAllNotifications } = useNotificationsApi();
  const { preferences, isNavigationMinimized } = useUserState();
  const isFloatingNavigation = preferences.navigation.isFloating;
  const navigationLocation = preferences.navigation.location;

  const isNavBarOnTop = navigationLocation === NavLocationType.TOP || !isFloatingNavigation;
  const isNavBarOnBottom = navigationLocation === NavLocationType.BOTTOM && isFloatingNavigation;
  const isNavBarOnLeft = navigationLocation === NavLocationType.LEFT && isFloatingNavigation;
  const isNavBarOnRight = navigationLocation === NavLocationType.RIGHT && isFloatingNavigation;

  const isAutoMinimizeAndFloat = isNavigationMinimized && isFloatingNavigation;
  const maxMinimizedNotifications = 5;

  const renderTrigger = () => {
    return (
      <Popover.Button
        className={clsx(styles.buttonContainer, {
          '-rotate-90': isNavBarOnRight || isNavBarOnLeft,
        })}
      >
        <Tooltip message={i18n.t('navigation.tooltips.notification')}>
          <div>
            {!isAutoMinimizeAndFloat ? <MdOutlineNotifications className={styles.buttonIcon} /> : null}
            {unreadCount > 0 && (
              <span
                className={clsx(styles.badgeMain, {
                  [styles.badgeFull]: !isAutoMinimizeAndFloat,
                  [styles.badgeAutominimize]: isAutoMinimizeAndFloat,
                })}
              >
                {unreadCount}
              </span>
            )}
          </div>
        </Tooltip>
      </Popover.Button>
    );
  };

  const renderReadAllButton = (close: () => void) => {
    return (
      <div
        className={styles.readAllButton}
        onClick={() => {
          readAllNotifications();
          close();
        }}
      >
        <CheckCircleOutlineIcon className={styles.closeIcon} />
        <div className={styles.markAllText}>{i18n.t('notifications.markAllAsRead')}</div>
      </div>
    );
  };

  const renderCloseButton = (close: () => void) => {
    return (
      <button type="button" className={styles.closeButton} onClick={close}>
        <span className="sr-only">Close</span>
        <MdClose className={styles.closeIcon} aria-hidden="true" />
      </button>
    );
  };

  const renderHeader = (close: () => void) => {
    return (
      <div className={styles.header}>
        <div className={styles.rowContainer}>
          <div className={styles.title}>{i18n.t('notifications.title')}</div>
          <span className={clsx(styles.unread, unreadCount > 0 ? 'text-primary-main' : 'text-txt-primary')}>
            {unreadCount}
          </span>
        </div>
        <div className="flex-1" />
        {renderReadAllButton(close)}
        {renderCloseButton(close)}
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className={clsx(styles.footer)}>
        <div className={styles.footerText} onClick={() => setIsFull(!isFull)}>
          {isFull ? i18n.t('notifications.viewLess').toUpperCase() : i18n.t('notifications.viewAll').toUpperCase()}
        </div>
      </div>
    );
  };

  const renderPanel = () => {
    const sliceIndex = isFull ? notifications.length : maxMinimizedNotifications;
    return (
      <Popover.Panel
        className={clsx(styles.container, {
          [styles.containerFull]: isFull,
          '-right-12 top-5 mt-2': isNavBarOnTop,
          '-right-12 bottom-8': isNavBarOnBottom,
          'bottom-8 left-5 origin-bottom-left -rotate-90': isNavBarOnLeft,
          '-bottom-2 right-0 origin-bottom-right -rotate-90': isNavBarOnRight,
          'max-h-[72vh]': isNavBarOnLeft || isNavBarOnRight,
          'max-h-[97vh]': isNavBarOnTop || isNavBarOnBottom,
        })}
      >
        {({ close }) => (
          <>
            {renderHeader(close)}
            <div className={'scrollbar overflow-y-auto'}>
              {[...notifications]
                .reverse()
                .slice(0, sliceIndex)
                .map((notification) => (
                  <NotificationsMenuItem
                    key={notification.id}
                    notification={notification}
                    onMarkRead={(id: string) => readNotification(id)}
                  />
                ))}
            </div>
            {notifications.length === 0 && (
              <div className="m-auto p-4 text-sm">{i18n.t('notifications.noNotifications')}</div>
            )}
            {notifications.length > maxMinimizedNotifications && renderFooter()}
          </>
        )}
      </Popover.Panel>
    );
  };

  return (
    <Popover
      className={clsx('relative flex items-center', {
        'leading-[0.5rem]': isAutoMinimizeAndFloat,
      })}
    >
      {renderTrigger()}
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
        afterLeave={() => setIsFull(false)}
      >
        {renderPanel()}
      </Transition>
    </Popover>
  );
}

const styles = {
  container: ctl(`
    absolute 
    flex 
    flex-col
    w-96
    z-10 
    overflow-hidden 
    bg-paper-card 
    rounded-md 
    text-txt-primary 
    drop-shadow-2xl 
    ring-1 
    ring-black 
    ring-opacity-5
    mr-2
  `),
  containerFull: 'h-max scrollbar mr-2   ',
  header: 'flex flex-row items-center px-4 py-4 border-b border-divider',
  footer: 'text-sm font-medium text-txt-primary p-4 text-center',
  footerText: 'cursor-pointer text-primary-main',
  title: 'text-xl font-semibold text-txt-primary',
  buttonContainer:
    'rounded-full h-full p-0 text-txt-secondary hover:text-txt-secondary/50 focus:outline-none shadow-none relative inline-flex items-center justify-center',
  buttonIcon: 'h-5 w-5 flex-shrink-0',
  buttonDateText: 'text-sm font-medium text-txt-primary',
  rowContainer: 'flex flex-row items-center text-action-active',
  readAllButton: 'flex flex-row items-center text-action-active hover:text-txt-secondary cursor-pointer',
  markAllText: 'ml-2 text-xs',
  closeButton: 'ml-4 mr-1 flex-shrink-0 inline-flex text-action-active hover:text-txt-secondary',
  closeIcon: 'h-5 w-5',
  badgeMain: 'bg-error-alt-light text-txt-primary select-none',
  badgeFull: 'absolute right-0 top-0 block h-3 w-3 rounded-full text-[0.5rem]',
  badgeAutominimize: 'rounded-full text-tiny p-0.5 min-w-3',
  unread: 'block text-center h-5 w-5 ml-2 rounded-full bg-bck-secondary text-sm ',
};

export default NotificationsDropMenu;
