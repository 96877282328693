import type { TField, IDataField } from '@/interfaces/CommonInterfaces';
import i18n from '@/utils/i18n';
import { HitOrgDefaultPricingMethod } from '@/api/pricer/pricerApiSchemaExtensions';

export const initialInputValue: TField = { value: '', valid: undefined };
export const zeroInputValue: TField = { value: '0.00', valid: true };
export const emptyDataField: IDataField = { name: '', key: null, hideName: true };
export const orgDefaultDataField: IDataField = {
  name: i18n.t('pricingSettings.advanceOption.useOrgDefault'),
  key: HitOrgDefaultPricingMethod,
};

export const INITIAL_STATE: {
  adjustment: IDataField | undefined;
  unit: IDataField | undefined;
  adjustmentAmount: TField;
  floorOptionToggle: boolean;
  floorUnit: IDataField | undefined;
  floorValue: TField;
  ceilingOptionToggle: boolean;
  ceilingUnit: IDataField | undefined;
  ceilingValue: TField;
  ceilingRequired: IDataField | undefined;
  groupAdjustment: IDataField | undefined;
  groupUnit: IDataField | undefined;
  groupAdjustmentAmount: TField;
  shareAutoPriceEnabled: IDataField | undefined;
  shareAnchor: IDataField | undefined;
  shareTicketGroups: IDataField | undefined;
  shareTicketGroupsUnit: IDataField | undefined;
  shareTicketGroupsAmount: TField;
  shareTicketGroupsActive: boolean;
  pushPosAutoButton: IDataField | undefined;
  advanceFloorRadio: IDataField | undefined;
  advanceCeilingRadio: IDataField | undefined;
  advanceNoCompsButton: IDataField | undefined;
  advanceNoCompsRadio: IDataField | undefined;
  splitsDynamic: IDataField | undefined;
  splitsEnabled: IDataField | undefined;
  isDirty: boolean;
  isError: boolean;
} = {
  adjustment: emptyDataField,
  unit: emptyDataField,
  adjustmentAmount: initialInputValue,
  floorOptionToggle: false,
  floorUnit: emptyDataField,
  floorValue: initialInputValue,
  ceilingOptionToggle: false,
  ceilingUnit: emptyDataField,
  ceilingValue: initialInputValue,
  ceilingRequired: emptyDataField,
  groupAdjustment: emptyDataField,
  groupUnit: emptyDataField,
  groupAdjustmentAmount: initialInputValue,
  shareAutoPriceEnabled: emptyDataField,
  shareAnchor: emptyDataField,
  shareTicketGroups: emptyDataField,
  shareTicketGroupsUnit: emptyDataField,
  shareTicketGroupsAmount: { value: '0', valid: undefined },
  shareTicketGroupsActive: false,
  pushPosAutoButton: emptyDataField,
  advanceFloorRadio: orgDefaultDataField,
  advanceCeilingRadio: orgDefaultDataField,
  advanceNoCompsButton: emptyDataField,
  advanceNoCompsRadio: orgDefaultDataField,
  splitsDynamic: emptyDataField,
  splitsEnabled: emptyDataField,
  isDirty: false,
  isError: false,
};

export const INITIAL_STATE_MARKET_DEFAULT: {
  splitsDynamicToggle: boolean;
  splitsEnabled: IDataField | undefined;
  isDirty: boolean;
  isError: boolean;
} = {
  splitsDynamicToggle: false,
  splitsEnabled: emptyDataField,
  isDirty: false,
  isError: false,
};
