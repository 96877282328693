import { NavLink } from 'react-router-dom';
import type { ReactNode } from 'react';

interface iProps {
  hasPermission: boolean | undefined;
  to: string;
  children: ReactNode;
  [key: string]: any;
}

function NavLinkIfPermitted({ hasPermission, to, children, ...rest }: iProps) {
  return hasPermission ? (
    <NavLink to={to} {...rest}>
      {children}
    </NavLink>
  ) : (
    <div {...rest}>{children}</div>
  );
}

export default NavLinkIfPermitted;
