import { Button, Divider, IconButton, SlideOver } from '@/components/common';
import type { IBasePropsSlideOver } from '@/components/common/slideOvers/constants';
import { CloseIcon } from '@/components/icons';
import i18n from '@/utils/i18n';

interface IProps extends IBasePropsSlideOver {
  header: string;
  ctaLabel: string;
  isCancelDisabled?: boolean;
  onCtaClick: () => void;
  onCancelClick?: () => void;
  isCtaDisabled?: boolean;
  isCtaLoading?: boolean;
}

function HeaderButtonsSlideOver({
  children,
  header,
  ctaLabel,
  isCancelDisabled,
  isCtaDisabled,
  isCtaLoading,
  onCtaClick,
  onCancelClick,
  ...props
}: IProps) {
  return (
    <SlideOver {...props} withOverlay={true}>
      <div className="relative flex flex-1 flex-col ">
        <div className="absolute inset-x-0 top-0  flex flex-row items-center justify-between bg-bck-body px-4 py-2">
          <h2 className="text-lg">{header}</h2>
          <IconButton WrappedIcon={CloseIcon} onClick={() => props.setOpen(false)} />
        </div>
        <div className="hover-scrollbar absolute inset-0 mb-16 mt-12 flex flex-grow flex-col px-4">{children}</div>

        <div className="absolute inset-x-0 bottom-0 bg-bck-body pb-2">
          <Divider variant={'horizontal'} />
          <div className="flex justify-end gap-2 p-4">
            <Button
              variant="secondary"
              disabled={isCancelDisabled}
              label={i18n.t('buttons.cancel').toUpperCase()}
              onClick={() => {
                props.setOpen(false);
                onCancelClick?.();
              }}
            />
            <Button
              variant="primary"
              label={ctaLabel}
              loading={isCtaLoading}
              disabled={isCtaDisabled}
              onClick={onCtaClick}
            />
          </div>
        </div>
      </div>
    </SlideOver>
  );
}

export default HeaderButtonsSlideOver;
