import { Popover, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { Fragment } from 'react';
import { IoApps } from 'react-icons/io5';
import { NavLocationType } from '@/utils/constants';
import { useUserState } from '@/context/user/context-hooks';
import NavMenuLocationPanelOnly from '@/components/NavigationMenu/NavMenuLocationPanelOnly';

function NavMenuPrefs() {
  const { preferences } = useUserState();
  const isFloatingNavigation = preferences.navigation.isFloating;
  const navigationLocation = preferences.navigation.location;

  const isNavBarOnTop = navigationLocation === NavLocationType.TOP || !isFloatingNavigation;
  const isNavBarOnBottom = navigationLocation === NavLocationType.BOTTOM && isFloatingNavigation;
  const isNavBarOnLeft = navigationLocation === NavLocationType.LEFT && isFloatingNavigation;
  const isNavBarOnRight = navigationLocation === NavLocationType.RIGHT && isFloatingNavigation;

  return (
    <Popover className="relative flex min-h-full items-center">
      <Popover.Button className="flex h-8 w-8 items-center justify-center hover:text-txt-secondary/50 focus:outline-none">
        <IoApps className="h-3 w-3" />
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel>
          {({ close }) => (
            <>
              <div
                className={clsx(styles.expandedMenuContainer, {
                  'left-0 mt-6 -translate-x-12': isNavBarOnTop,
                  'bottom-5 left-0 -translate-x-12': isNavBarOnBottom,
                  '-left-10 bottom-12 -rotate-90': isNavBarOnLeft,
                  '-left-5 top-16 origin-top-left translate-y-full -rotate-90': isNavBarOnRight,
                })}
              >
                <NavMenuLocationPanelOnly close={close} />
              </div>
            </>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

const styles = {
  expandedMenuContainer:
    'absolute z-10 w-56 bg-paper-card p-4 drop-shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none rounded-md',
};

export default NavMenuPrefs;
