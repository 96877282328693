import { Fragment, useMemo } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import ctl from '@netlify/classnames-template-literals';
import { MdArrowDropDown } from 'react-icons/md';
import MultipleSelectionItem from './MultipleSelectionItem';
import type { IDataField } from '@/interfaces/CommonInterfaces';
import i18n from '@/utils/i18n';
import clsx from 'clsx';
import { CheckIcon } from '@/components/icons';

interface IProps {
  data: IDataField[];
  className?: string;
  selectedValues: string[];
  onChange: (value: string[]) => void;
  label?: string;
}

function MultiSelectionDropDown({ data, className = '', selectedValues, onChange, label }: IProps) {
  const nameMap = useMemo(() => {
    return data.reduce(
      (acc, role) => {
        acc[role.key!] = role.name as string;
        return acc;
      },
      {} as Record<string, string>,
    );
  }, [data]);

  const isSelected = (item: string) => {
    return selectedValues.find((selectItem: string) => selectItem === item);
  };

  function removeSelection(item: IDataField) {
    const removedSelection = selectedValues.filter((selectItem: string) => selectItem !== item.key);
    onChange(removedSelection);
  }

  const renderTrigger = () => {
    const hasSelection = selectedValues?.length > 0;
    return (
      <Listbox.Button className={styles.trigger}>
        <span className={styles.label}>{label}</span>
        {hasSelection ? (
          <span className="flex flex-wrap">
            {selectedValues.map((key: string, index: number) => (
              <MultipleSelectionItem
                key={index}
                item={{ key: key, name: nameMap[key] }}
                removeSelection={removeSelection}
              />
            ))}
          </span>
        ) : (
          <span className={styles.placeholder}>{i18n.t('input.placeholders.makeSelection')}</span>
        )}
        <span className="drop-down-button">
          <MdArrowDropDown className="drop-down-icon" aria-hidden="true" />
        </span>
      </Listbox.Button>
    );
  };

  const renderMenuOption = (item: IDataField, index: number) => {
    const isItemSelected = isSelected((item.key ?? '').toString());
    return (
      <Listbox.Option
        key={index}
        className={({ active }) => clsx(styles.menuItem, active ? styles.menuItemActive : '')}
        value={item.key}
      >
        {({ active }) => (
          <>
            <span className={`block truncate ${isItemSelected ? 'font-medium' : 'font-normal'}`}>{item.name}</span>
            {isItemSelected ? (
              <span className={clsx(styles.checkIcon, active ? styles.checkIconActive : '')}>
                <CheckIcon className="h-5 w-5" aria-hidden="true" />
              </span>
            ) : null}
          </>
        )}
      </Listbox.Option>
    );
  };

  const renderMenu = () => {
    return (
      <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
        <Listbox.Options className="drop-down-menu">
          {data.map((item, index) => renderMenuOption(item, index))}
        </Listbox.Options>
      </Transition>
    );
  };

  return (
    <div className={clsx(styles.container, className)}>
      <Listbox value={selectedValues} onChange={onChange} multiple>
        <div className={styles.content}>
          {renderTrigger()}
          {renderMenu()}
        </div>
      </Listbox>
    </div>
  );
}

const styles = {
  container: 'z-10 my-2',
  content: 'relative mt-1',
  label: 'block text-xs font-medium text-txt-secondary',
  placeholder: 'block text-lg text-txt-secondary',
  trigger: ctl(`
      min-h-[3.5rem]
      px-3
      pt-1
      pb-2
      placeholder-transparent
      block w-full
      rounded-md peer
      border-input-border
      shadow-sm
      text-md
      text-left
      border`),
  menuItem: 'relative cursor-default select-none py-2 pl-10 pr-4 text-md text-txt-secondary',
  menuItemActive: 'bg-primary-alt-dark text-white',
  checkIcon: 'absolute inset-y-0 left-0 flex items-center pl-3 text-txt-primary-alt-light',
  checkIconActive: '!text-white',
};

export default MultiSelectionDropDown;
