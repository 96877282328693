import { createContext, useContext } from 'react';

interface IInventoryState {}

const InventoryStateContext = createContext<IInventoryState | undefined>(undefined);

function useInventoryState() {
  const context = useContext(InventoryStateContext);
  if (context === undefined) {
    throw new Error('useInventoryContext must be used within a InventoryContextProvider');
  }
  return context;
}

export { InventoryStateContext, useInventoryState };
export type { IInventoryState };
