import type { IDataField } from '@/interfaces/CommonInterfaces';
import type { NotificationPreferenceDto } from '@/api/notification/notificationApiSchemas';
import type { GetUserPreferencesResponse } from '@/api/notification/notificationApiComponents';
import { notificationPreferenceOptions, NotificationPreferenceOptions, NotificationTypeKeys } from '../constants';

export const emptyDataField: IDataField = { name: '', key: null, hideName: true };

export const INITIAL_STATE: {
  hitFloor: IDataField[] | undefined;
  hitCeiling: IDataField[] | undefined;
  noComps: IDataField[] | undefined;
  isDirty: boolean;
} = {
  hitFloor: undefined,
  hitCeiling: undefined,
  noComps: undefined,
  isDirty: false,
};

export enum FieldType {
  FLOOR = 'floorValue',
  CEILING = 'ceilingValue',
  NOCOMPS = 'noCompsValue',
}

export const enum REDUCER_ACTION_TYPE {
  SET_FLOOR,
  SET_CEILING,
  SET_NOCOMPS,
  LOAD_PREFERENCES,
  RESET,
}

type SetDataFieldAction = {
  type: REDUCER_ACTION_TYPE.SET_FLOOR | REDUCER_ACTION_TYPE.SET_CEILING | REDUCER_ACTION_TYPE.SET_NOCOMPS;
  payload: IDataField;
};

type SimpleAction = {
  type: REDUCER_ACTION_TYPE.RESET;
};

type StateLoadAction = {
  type: REDUCER_ACTION_TYPE.LOAD_PREFERENCES;
  payload: GetUserPreferencesResponse | undefined;
};

export type ReducerAction = SimpleAction | SetDataFieldAction | StateLoadAction;

const getNotificationOption = (
  optionType: string | null | undefined,
  notificationPreference: NotificationPreferenceDto,
): boolean => {
  switch (optionType) {
    case NotificationPreferenceOptions.AllEvents:
      return !!notificationPreference.isOptInEmail;
    case NotificationPreferenceOptions.AssignedEvents:
      return !!notificationPreference.isUserOnly;
    case NotificationPreferenceOptions.None:
      return !notificationPreference.isOptInEmail && !notificationPreference.isUserOnly;
    default:
      return false;
  }
};

const setNotificationOption = (
  optionType: string | null | undefined,
  selectedOptionType: string | null | undefined,
): boolean => {
  switch (optionType) {
    case NotificationPreferenceOptions.AllEvents:
      return selectedOptionType === NotificationPreferenceOptions.AllEvents;
    case NotificationPreferenceOptions.AssignedEvents:
      return selectedOptionType === NotificationPreferenceOptions.AssignedEvents;
    case NotificationPreferenceOptions.None:
      return selectedOptionType === NotificationPreferenceOptions.None;
    default:
      return false;
  }
};

export const reducer = (state: typeof INITIAL_STATE, action: ReducerAction): typeof INITIAL_STATE => {
  switch (action.type) {
    case REDUCER_ACTION_TYPE.SET_FLOOR:
      state.hitFloor = state.hitFloor?.map((option) => ({
        ...option,
        selected: setNotificationOption(option.key, action.payload.key),
      }));
      return { ...state, isDirty: true };
    case REDUCER_ACTION_TYPE.SET_CEILING:
      state.hitCeiling = state.hitCeiling?.map((option) => ({
        ...option,
        selected: setNotificationOption(option.key, action.payload.key),
      }));
      return { ...state, isDirty: true };
    case REDUCER_ACTION_TYPE.SET_NOCOMPS:
      state.noComps = state.noComps?.map((option) => ({
        ...option,
        selected: setNotificationOption(option.key, action.payload.key),
      }));
      return { ...state, isDirty: true };
    case REDUCER_ACTION_TYPE.LOAD_PREFERENCES:
      const notificationPreferences = action.payload ?? [];
      if (notificationPreferences) {
        notificationPreferences.forEach((notificationPreference: NotificationPreferenceDto) => {
          switch (notificationPreference.notificationTypeKey) {
            case NotificationTypeKeys.HitFloor:
              state.hitFloor = notificationPreferenceOptions.map((option) => ({
                ...option,
                type: FieldType.FLOOR,
                selected: getNotificationOption(option.key, notificationPreference),
              }));
              break;
            case NotificationTypeKeys.HitCeiling:
              state.hitCeiling = notificationPreferenceOptions.map((option) => ({
                ...option,
                type: FieldType.CEILING,
                selected: getNotificationOption(option.key, notificationPreference),
              }));
              break;
            case NotificationTypeKeys.NoComps:
              state.noComps = notificationPreferenceOptions.map((option) => ({
                ...option,
                type: FieldType.NOCOMPS,
                selected: getNotificationOption(option.key, notificationPreference),
              }));
              break;
          }
        });
        return {
          ...state,
          isDirty: false,
        };
      } else {
        return INITIAL_STATE;
      }
    case REDUCER_ACTION_TYPE.RESET:
      return { ...INITIAL_STATE, isDirty: true };
    default:
      return state;
  }
};
