export default {
  title: 'Events',
  titleToolTip: 'Open event %{eventName}',
  eventIsUnmapped: 'Event not mapped',
  notAssignedToolTip: 'Not assigned',
  openMarketplace: 'Open with  %{marketplaceName}',
  total: 'Total: %{totalUnfiltered}',
  noResults: 'No results found',
  zeroResults: '0 results for "%{searchTerm}"',
  loaded: '%{fetched} of %{totalFiltered}',
  marketInsights: 'Market Insights',
  expand: 'Expand event panel',
  collapse: 'Collapse event panel',
  pricingInsights: {
    pricingGroups: {
      one: 'Pricing group',
      other: 'Pricing groups',
    },
    noPrice: {
      one: 'Unpriced ticket group',
      other: 'Unpriced ticket groups',
    },
    floor: 'Hit Floor',
    ceiling: 'Hit Ceiling',
    noComps: 'No comps',
    myListing: {
      one: 'Ticket group',
      other: 'Ticket groups',
    },
    sold: {
      one: 'Sold ticket',
      other: 'Sold tickets',
    },
  },
  metrics: {
    ceiling: '@ Ceiling',
    floor: '@ Floor',
    within90Days: 'T-90',
    noPrice: 'Not priced',
    noComps: 'No comps',
    availableTicketGroups: 'Show Available TicketGroups',
  },
  columns: {
    name: 'Event',
    id: 'Event ID',
    date: 'Date & Time',
    tbd: 'Time to Event',
    venue: 'Venue',
    location: 'Location',
    updated: 'Updated',
    groups: 'Groups',
    marketGroup: 'Market Groups',
    myAvgPrice: 'Avg Price (USD)',
    marketAvgPrice: 'Market Avg (USD)',
    lastSoldPrice: 'Last Sold Price (USD)',
    avgSoldPrice: 'Avg Sold (USD)',
    ceiling: 'Ceiling',
    floor: 'Floor',
    noPrice: 'Unpriced',
    noComps: 'No Comps',
    pricer: 'Pricer',
    marketplace: 'Channels',
    pricingGroupCount: 'Pricing',
    myActiveTickets: 'Tickets',
    marketActiveTickets: 'Market Tickets',
    mySoldTickets: 'Tickets Sold',
    myPercentSold: '% of Tickets',
  },
  pricerNamePlaceholder: 'Pricer name',
  pricerLabel: 'Pricer',
  notAssigned: 'Unassigned',
  startEndDate: 'Start - End date',
  predefinedDates: 'Custom date',
  search: {
    placeholder: 'Search event id, event name, venue, performer, or location',
    refresh: 'Refresh results',
    moreFilters: 'More filters',
    eventDay: 'Event Day',
    status: 'Status',
    pricer: 'Pricer',
    ACTIVE: 'Active',
    CANCELED: 'Canceled',
    INACTIVE: 'Inactive',
    POSTPONED: 'Postponed',
    RE_SCHEDULED: 'Rescheduled',
  },
  insights: {
    NoPricingGroup: {
      zero: 'No events with unpriced ticket groups',
      one: '{{count}} event with unpriced ticket groups',
      other: '{{count}} events with unpriced ticket groups',
    },
    IsFloorHit: {
      zero: 'No events with Hit Floor pricing groups',
      one: '{{count}} event with Hit Floor pricing groups',
      other: '{{count}} events with Hit Floor pricing groups',
    },
    IsCeilingHit: {
      zero: 'No events with Hit Ceiling pricing groups',
      one: '{{count}} event with Hit Ceiling pricing groups',
      other: '{{count}} events with Hit Ceiling pricing groups',
    },
    NoComparables: {
      zero: 'No events with pricing groups with No comps',
      one: '{{count}} event with pricing groups with No comps',
      other: '{{count}} events with pricing groups with No comps',
    },
    within90Days: {
      zero: 'No events within 90 days',
      one: '{{count}} event within 90 days',
      other: '{{count}} events within 90 days',
    },
  },
  filters: {
    toolTip: 'Select %{type}',
    eventDay: 'Weekday',
    status: 'Event status',
    pricer: 'Pricer',
    parking: 'Parking',
    noTickets: 'No tickets',
  },
  toolTips: {
    refreshResults: 'Refresh results',
    moreFilters: 'More filters',
    pricer: 'Select pricer',
    resetAllTooltip: 'Reset all filters to defaults',
    status: 'Select event status',
    eventDay: 'Select weekday',
    openEvent: 'Open event %{productionName}',
  },
  notification: {
    applying: {
      title: 'Assigning pricer',
      message: 'Assigning %{productionName} on %{productionDate} to %{pricerName}',
      messageUnassign: 'Unassigning %{pricerName} from %{productionName} on %{productionDate}',
    },
    success: {
      title: 'Success',
      message: '%{productionName} on %{productionDate} has been assigned to %{pricerName}.',
      messageUnassign: '%{productionName} on %{productionDate} has been unassigned from %{pricerName}.',
    },
    error: {
      title: 'Oops! Something went wrong!',
      message: '%{productionName} on %{productionDate} failed to be assigned to %{pricerName}.',
      messageUnassign: '%{productionName} on %{productionDate} failed to be assigned from %{pricerName}.',
    },
  },
};
