import type {
  HitCeilingPricingMethod,
  HitFloorPricingMethod,
  HitNoCompPricingMethod,
  InventoryStatus,
} from '@/api/pricer/pricerApiSchemas';
import type { camelCase } from 'i18n-js/typings/lodash';

export const HitCeilingPricingMethodKeys: { [key in HitCeilingPricingMethod]: camelCase<HitCeilingPricingMethod> } = {
  Standard: 'standard',
  Variable: 'variable',
  UseCeiling: 'useCeiling',
  CeilingRandomize: 'ceilingRandomize',
} as const;

export const HitFloorPricingMethodKeys: { [key in HitFloorPricingMethod]: camelCase<HitFloorPricingMethod> } = {
  Standard: 'standard',
  Variable: 'variable',
  UseFloor: 'useFloor',
  FloorRandomize: 'floorRandomize',
} as const;

export const HitNoCompPricingMethodKeys: { [key in HitNoCompPricingMethod]: camelCase<HitNoCompPricingMethod> } = {
  Standard: 'standard',
  Variable: 'variable',
  Ceiling: 'ceiling',
  FreezePricing: 'freezePricing',
  UseFloorForAnchor: 'useFloorForAnchor',
  UseCeilingForAnchor: 'useCeilingForAnchor',
  UseCeiling: 'useCeiling',
  CeilingRandomize: 'ceilingRandomize',
} as const;

export const HitOrgDefaultPricingMethod = 'useOrgDefault';

export const InventoryStatusKeys: { [key in InventoryStatus]: InventoryStatus } = {
  Available: 'Available',
  Sold: 'Sold',
  Held: 'Held',
  Deleted: 'Deleted',
} as const;
