type task = () => Promise<any>;
export function seriesAsync(tasks: task[]): Promise<any[]> {
  return tasks.reduce(async (promise, task) => {
    const results = await promise;
    const result = await task();
    return results.concat(result);
  }, Promise.resolve([]));
}

export function parallelAsync(tasks: task[]): Promise<any[]> {
  return Promise.all(tasks.map((task) => task()));
}

export function seriesAsyncParallelBatched(
  tasks: task[],
  batchSize: number = 10,
  onBatchCompleted?: (res: any[]) => void,
): Promise<any[]> {
  const batchedTasks = tasks.reduce((batches, task, index) => {
    const batchIndex = Math.floor(index / batchSize);
    if (!batches[batchIndex]) {
      batches[batchIndex] = [];
    }
    batches[batchIndex].push(task);
    return batches;
  }, [] as task[][]);

  return batchedTasks.reduce(async (promise, batch) => {
    const results = await promise;
    const batchResults = (await parallelAsync(batch)) as any[];
    onBatchCompleted?.(batchResults);
    return results.concat(...batchResults);
  }, Promise.resolve([]));
}
