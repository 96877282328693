import { BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { AppModeIsDrive } from '@/utils/constants';
// Browser check variables
// If you support IE, recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const msedge = ua.indexOf('Edge/');
const firefox = ua.indexOf('Firefox');
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito
const debugMSAL = import.meta.env.REACT_APP_B2C_MSAL_DEBUG === 'true';

export const msalConfig = {
  auth: {
    clientId: import.meta.env.REACT_APP_B2C_APP_CLIENT_ID.toString(),
    authority: `https://${import.meta.env.REACT_APP_B2C_HOSTNAME}/${import.meta.env.REACT_APP_B2C_TENANT}/${
      import.meta.env.REACT_APP_B2C_SUSI_POLICY_NAME
    }`,
    knownAuthorities: [
      `${import.meta.env.REACT_APP_B2C_SUBDOMAIN.toString()}.b2clogin.com`,
      `${import.meta.env.REACT_APP_B2C_HOSTNAME.toString()}`,
    ],
    postLogoutRedirectUri: '/signout',
    redirectUri: '/',
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
        if (containsPii) {
          return;
        }

        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            if (debugMSAL) {
              console.info(message);
            }
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            if (debugMSAL) {
              console.warn(message);
            }
            return;
          default:
            return;
        }
      },
    },
    telemetry: {
      application: {
        appName: AppModeIsDrive ? 'drive' : 'members',
        appVersion: import.meta.env.REACT_APP_VERSION,
      },
    },
  },
};
