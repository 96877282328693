import clsx from 'clsx';

function NavDelimiter({ className, isVertical = true }: { className?: string; isVertical?: boolean } = {}) {
  return (
    <div
      className={clsx('border-txt-disabled', className, {
        'h-4 border-r': isVertical,
        'mx-1 mb-2 h-1 border-b pt-2': !isVertical,
      })}
    ></div>
  );
}

export default NavDelimiter;
