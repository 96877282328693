export default {
  title: 'Inventory',
  fetched: '%{fetched} of %{totalFiltered}',
  noResults: 'No results found',
  pleaseSelectProduction: 'Please select a production',
  events: 'Events',
  unassigned: 'Unassigned inventory',
  groupNameIndividual: 'Individual pricing groups',
  columns: {
    rank: 'Rank',
    section: 'Section',
    row: 'Row',
    quantity: 'Qty',
    split: 'Split Type',
    type: 'Type',
    seat: 'Seat',
    seats: 'Seats',
    stock: 'Stock',
    attributes: 'Attributes',
    inHand: 'In Hand',
    date: 'Date',
    status: 'Status',
    shared: 'Shared',
    sharedShort: 'SH',
    cost: 'Cost (USD)',
    listPrice: 'POS Price (USD)',
    floor: 'Floor',
    ceiling: 'Ceiling',
    suggestedPrice: 'Suggested (USD)',
    pricingGroup: 'Pricing Group',
    action: 'Action',
    change: 'Change',
    ticketGroup: 'Ticket Group',
    ticketDetails: 'Ticket Details',
    parking: 'Parking',
    inventoryStatus: 'Status',
    pos: 'POS',
    seatSaver: 'Seat saver',
    info: 'info',
    internalNotes: 'Internal Notes',
    externalNotes: 'External Notes',
  },
  activeMarket: {
    listPrice: 'List Price',
    listingSection: 'Listing Sect',
    mapSection: 'Map Sect',
  },
  selectAll: {
    tickets: 'Select all ticket groups',
    pricingGroups: 'Select all pricing groups',
    deselectAllTickets: 'Deselect all ticket groups',
    deselectAllPricingGroups: 'Deselect all pricing groups',
  },
  actions: {
    audit: 'Audit',
    ticketGroupAuditHistory: 'Ticket group audit history',
    share: 'Share ticket group',
    unshare: 'Unshare ticket group',
    autoPrice: 'Enable Auto Price',
    simulation: 'Stop Auto Price',
    linkToExisting: 'Link to existing',
    unlink: 'Unlink',
    edit: 'Edit pricing group',
    delete: 'Delete pricing group',
    collapseGroup: 'Collapse group',
    expandGroup: 'Expand group',
    collapseAll: 'Collapse all',
    expandAll: 'Expand all',
    saveAsPricingTemplate: 'Save as pricing template',
    saveAsTemplate: 'Save as template',
    openPricingTemplate: 'Open pricing template',
    copyPricingCriteria: 'Copy pricing criteria',
    pastePricingCriteria: 'Paste pricing criteria - %{name}',
  },
  info: {
    autoPrice: 'Auto Pricing',
    simulation: 'Simulation',
  },
  values: {
    shared: 'Shared',
    unshared: 'Unshared',
    yes: 'Yes',
    no: 'No',
    active: 'Active',
    held: 'Held',
  },
  noCriteriaAvailable: 'No criteria available',
  productionLabels: {
    myListing: 'My listing',
    myAvgPrice: 'My AVG LP',
    sold: 'Sold',
    ceiling: 'Ceiling',
    floor: 'Floor',
    noPrice: 'Not priced',
    noComps: 'No comps',
    pricingGroups: 'Pricing Groups',
  },
  search: {
    splits: 'Splits',
    deliveryMethod: 'Delivery Method',
    excludeAttributes: 'Exclude Attributes',
    section: 'Section',
    options: 'Options',
    splitType: 'Split Type',
    splitRule: 'Split Rule',
    seatType: 'Seat Type',
    stockType: 'Stock Type',
    stockTypes: 'Stock Types',
    attributes: 'Attributes',
    pricingCriteria: 'Pricing Criteria',
    recentSearches: 'Recent Searches',
    isInHand: 'In Hand',
    isCeilingHit: 'Is Ceiling',
    isFloorHit: 'Is Floor',
    isNoActiveCompHit: 'Is No Active Comp.',
    hitFloor: 'Hit Floor',
    hitCeiling: 'Hit Ceiling',
    noComp: 'No Comp',
    status: 'Status',
    DEFAULT: 'Default',
    NO_SPLIT: 'No Split',
    NO_SINGLE: 'No Single',
    NEVER_LEAVE_ONE: 'Never Leave One',
    custom: 'custom',
    CONSECUTIVE: 'Consecutive',
    ODD_EVEN: 'Odd/Even',
    GENERAL_ADMISSION: 'General Admission',
    PIGGYBACK: 'Piggyback',
    PDF: 'PDF',
    MOBILE_TRANSFER: 'Mobile Transfer',
    PHYSICAL: 'Physical',
    ada: 'ADA',
    adaCompanion: 'ADA Companion',
    mobileTicket: 'Mobile Ticket',
    mobileTransfer: 'Mobile Transfer',
    pdf: 'PDF',
    parking: 'Parking',
    'qr code': 'QR Code',
    AVAILABLE: 'Available',
    SOLD: 'Sold',
    HOLD: 'Hold',
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
  },
  filters: {
    quantity: 'Quantity',
    status: 'Status',
    pricingGroup: 'Pricing group',
    seatType: 'Seat type',
    splitType: 'Split type',
    stockType: 'Stock type',
    attributes: 'Exclude attributes',
    IsCeilingHit: 'Hit ceiling',
    IsFloorHit: 'Hit floor',
    IsNoActiveCompHit: 'No comps',
    IsUnpriced: 'Not priced',
    IsInHand: 'In hand',
    isShared: 'Shared',
    isSpeculative: 'Speculative',
    isSeatSaver: 'Seat saver',
    options: 'Options',
    pricingGroupHasNoTickets: 'Pricing group has no ticket groups',
    youCanAddTickets: 'You can add ticket groups, Cancel to start over or Delete this pricing group.',
    deletePG: {
      title: 'Delete pricing group?',
      statement: 'This pricing group is about to be deleted',
      description: 'All of its ticket groups will be unlinked. This action cannot be undone.',
    },
  },
  splitType: {
    Default: 'Default',
    NoSplit: 'No Split',
    NoSingle: 'No Single',
    NeverLeaveOne: 'Never Leave One',
    Custom: 'Custom',
  },
  seatType: {
    Consecutive: 'Consecutive',
    OddEven: 'Odd/Even',
    GeneralAdmission: 'General Admission',
    Piggyback: 'Piggyback',
  },
  stockType: {
    MobileTransfer: 'Mobile Transfer',
    Physical: 'Physical',
    Pdf: 'PDF',
  },
  metrics: {
    myData: 'My Data',
    activeListings: 'Groups',
    totalTickets: 'Tickets',
    averageList: 'Avg POS',
    totalTicketsSold: 'SOLD',
    lastSold: 'Last sold',
    averageSold: 'Avg sold',
    marketData: 'Market Data',
    marketTotalTickets: 'Market Tickets',
    marketActiveListings: 'Market listings',
    marketAverageList: 'Avg list',
    marketLowestList: 'Lowest list',
    marketHighestList: 'Highest list',
  },
  notification: {
    success: {
      title: 'Success',
      messageStopShare: 'Ticket has stopped sharing.',
      message: 'Ticket is sharing.',
      listPrice: {
        message: 'Successfully saved the list price',
      },
      bulkSharing: 'Successfully shared %{count} ticket groups',
      bulkStopSharing: 'Successfully stopped sharing %{count} ticket groups',
      copyMetadata: 'Successfully copied the pricing criteria - %{name}',
    },
    error: {
      title: 'Oops! Something went wrong!',
      messageStopShare: 'Failed to stop sharing ticket.',
      message: 'Failed to share ticket.',
      listPrice: {
        message: 'Error saving the list price',
      },
      bulkSharing: 'Failed to share %{count} ticket groups',
      bulkStopSharing: 'Failed to stop sharing %{count} ticket groups',
      copyMetadata: 'Failed to copy the pricing criteria',
    },
    applying: {
      title: 'Applying',
      messageStopShare: 'Stopping sharing...',
      message: 'Sharing Ticket...',
      listPrice: {
        message: 'Updating the list price',
      },
      bulkSharing: 'Sharing %{count} ticket groups...',
      bulkStopSharing: 'Stopping sharing %{count} ticket groups...',
    },
  },
  tooltip: {
    unselectable: 'Ticket groups can be assigned to only one pricing group',
    sold: 'Ticket groups that have been sold cannot be selected',
    anchor: 'Anchor ticket group',
    seatSaver: 'Seat saver',
    specInventory: 'Speculative inventory',
    createNewPricingGroup: 'Create new pricing group',
    addToPricingGroup: 'Add to pricing group',
    removeFromPricingGroup: 'Remove from pricing group',
    suggestedPriceBelowCost: 'Suggested price is below cost',
    suggestedHitFloor: 'Suggested price hit floor',
    suggestedHitCeiling: 'Suggested price hit ceiling',
    floorBelowCost: 'Floor is below cost',
    noComps: 'No comps',
    lastCalculationDate: 'Last Price Calculation on %{date}',
  },

  bulkAction: {
    createNewPricingGroup: 'Create new pricing group',
    addTo: 'Add to',
    push: 'Push',
    noName: 'Untitled',
    shareAllSelected: 'Share all selected',
    unshareAllSelected: 'Unshare all selected',

    selectedPricingGroups: 'Selected pricing groups',

    updatePushOptions: {
      title: 'Update push option?',
      statement: 'Pricing group push option',
      description: 'Changing push option impacts each ticket group in the pricing group',
    },

    deletePricingGroups: {
      title: 'Delete pricing groups?',
      statement: 'Deleting pricing groups',
      description:
        'Deleting the pricing groups removes all ticket groups and destroy the pricing group. This cannot be undone.',
    },
    deletePricingGroup: {
      title: 'Delete pricing group?',
      statement: 'Deleting pricing group',
      description:
        'Deleting the pricing group removes all ticket groups and destroy the pricing group. This cannot be undone.',
    },
  },

  priorityDisplay: {
    defaultText: 'Default',
    pricingGroupFirst: 'Pricing group first',
    ticketGroupFirst: 'Ticket group first',
  },

  audit: {
    pricingGroup: {
      title: 'Pricing Group Audit History',
      columns: {
        action: 'Action',
        member: 'Member',
        dateAndTime: 'Date and Time',
        details: 'Details',
        marketComparables: 'Market Comparables',
      },
    },
    pricingGroupDetails: {
      columns: {
        rank: 'Rank',
        sections: 'Sections',
        row: 'Row',
        seats: 'Seats',
        ticketGroupAudit: 'Ticket Group Audit',
      },
    },
    ticketGroup: {
      title: 'Ticket Group Audit History',
      sectionAbbr: 'SEC',
      section: 'Section',
      row: 'Row',
      quantity: 'Qty',
      seats: 'Seats',
      posPrice: 'Pos Price',
      rank: 'Rank',
      shared: 'Shared ticket group',
      unshared: 'Unshared ticket group',
      sharedOnly: 'Shared',
      unsharedOnly: 'Unshared',
      pricingGroupName: 'Added to pricing group',
      suggested: 'Suggested',
      columns: {
        action: 'Action',
        member: 'Member',
        dateAndTime: 'Date and Time',
        details: 'Details',
      },
    },
    tabs: {
      history: {
        title: 'History',
        from: 'From',
        to: 'To',
        action: 'Action...',
        member: 'Member...',
        systemUser: 'SYSTEM',
        showOnlyModifications: 'Show only modifications',
        ticketGroupCount: {
          one: '1 ticket group',
          other: '%{count} ticket groups',
        },
        pricingGroupName: 'Pricing Group Name',
      },
      details: {
        title: 'Details',
      },
    },
  },
};
