import { useEffect, useMemo, useState } from 'react';
import type { PricingTemplateInventoryResponseDtoSelectable } from '@/routes/templates/templatePage/template-page-context-hooks';
import { fetchGetPricingTemplateInventory } from '@/api/pricer/pricerApiComponents';
import { AuthApiFetcherOptions } from '@/api/AuthApiContext';
import {
  IsTicketGroupSelectable,
  ProductionInventoryFailed,
  ProductionNoTicketsTicketId,
} from '@/routes/templates/templatePage/template-constants';
import { seriesAsyncParallelBatched } from '@/utils/seriesAsync';

interface IProps {
  sortedSelectedProductionIds: number[];
  pricingTemplateId: number | undefined;
  isRetryFlow?: boolean;
}

function useInventoryForTemplates({ sortedSelectedProductionIds, pricingTemplateId, isRetryFlow }: IProps) {
  const [inventory, setInventory] = useState<PricingTemplateInventoryResponseDtoSelectable[]>([]);
  const [inventoryForPricingGroups, setInventoryForPricingGroups] = useState<
    PricingTemplateInventoryResponseDtoSelectable[]
  >([]);
  const [isInventoryFetching, setIsInventoryFetching] = useState<boolean>(false);
  const [showUnavailableTickets, setShowUnavailableTickets] = useState<boolean>(false);
  const [productionAllTicketsSelected, setProductionAllTicketsSelected] = useState<Record<number, boolean>>({});
  const [selectedTicketsByProduction, setSelectedTicketsByProduction] = useState<Record<number, number>>({});
  const selectedInventoryTickets = useMemo(() => inventory.filter((item) => item.selected), [inventory]);

  useEffect(() => {
    if (inventory.length > 0) {
      const initialSelectedTickets: Record<number, number> = {};
      inventory.forEach((item) => {
        if (item.selected && item.masterProductionId) {
          initialSelectedTickets[item.masterProductionId] = (initialSelectedTickets[item.masterProductionId] || 0) + 1;
        }
      });
      setSelectedTicketsByProduction(initialSelectedTickets);
    }
  }, [inventory]);

  async function refetchInventoryWithProductionIdBase(productionId: number) {
    const res = await fetchGetPricingTemplateInventory({
      ...AuthApiFetcherOptions,
      pathParams: {
        pricingTemplateId: pricingTemplateId!,
        productionId,
      },
    }).catch(() => {
      // failed call
      return [
        {
          masterProductionId: productionId,
          ticketGroupId: ProductionInventoryFailed,
          loading: false,
        } as PricingTemplateInventoryResponseDtoSelectable,
      ];
    });

    const filtered = res
      .filter((ticketGroup) => {
        return showUnavailableTickets || IsTicketGroupSelectable(ticketGroup);
      })
      .map((ticketGroup) => {
        return {
          ...ticketGroup,
          loading: false,
          selected:
            IsTicketGroupSelectable(ticketGroup) &&
            ticketGroup.ticketGroupId !== ProductionInventoryFailed &&
            !isRetryFlow,
        };
      });

    if (filtered.length === 0) {
      return [
        {
          masterProductionId: productionId,
          ticketGroupId: ProductionNoTicketsTicketId,
          loading: false,
        } as PricingTemplateInventoryResponseDtoSelectable,
      ];
    }

    if (
      filtered.length > 0 &&
      !isRetryFlow &&
      filtered[0].ticketGroupId !== ProductionInventoryFailed &&
      !showUnavailableTickets
    ) {
      setProductionAllTicketsSelected((prev) => {
        return {
          ...prev,
          [productionId]: true,
        };
      });
    }
    return filtered;
  }

  async function refetchInventory() {
    setIsInventoryFetching(true);
    setInventory([]);
    setProductionAllTicketsSelected({});
    setSelectedTicketsByProduction({});

    const inventoryPromises = sortedSelectedProductionIds.map((productionId) => {
      return async () => await refetchInventoryWithProductionIdBase(productionId);
    });
    await seriesAsyncParallelBatched(inventoryPromises, 10, (batchResponses) => {
      setIsInventoryFetching(false);
      setInventory((prev) => {
        return prev
          .map((item) => {
            return { ...item, loading: false };
          })
          .concat(...batchResponses);
      });
    }).then(() => {
      setIsInventoryFetching(false);
      setInventory((prev) => {
        const newInv = [...prev];
        sortedSelectedProductionIds.forEach((productionId, index) => {
          const inventoryIndex = newInv.findIndex((item) => item.masterProductionId === productionId);
          newInv.splice(inventoryIndex, 1);
        });

        return newInv;
      });
    });
  }

  async function refetchInventoryWithProductionId(productionId: number, sortedSelectedProductionIds: number[] = []) {
    setInventory((prev) => {
      return prev.map((item) => {
        if (item.masterProductionId === productionId) {
          return { ...item, loading: true };
        }
        return item;
      });
    });
    const res = await refetchInventoryWithProductionIdBase(productionId);
    setInventory((prev) => {
      const resMap = new Map();
      res.forEach((item) => {
        if (!resMap.has(item.masterProductionId)) {
          resMap.set(item.masterProductionId, []);
        }
        resMap.get(item.masterProductionId).push(item);
      });

      const newInv = prev.map((item) => {
        if (resMap.has(item.masterProductionId)) {
          const updatedItems = resMap.get(item.masterProductionId);

          const updatedItem = updatedItems.shift();

          if (updatedItems.length === 0) {
            resMap.delete(item.masterProductionId);
          }

          return updatedItem;
        }

        return item;
      });

      resMap.forEach((items) => {
        items.forEach((item: PricingTemplateInventoryResponseDtoSelectable) => {
          newInv.push(item);
        });
      });

      if (sortedSelectedProductionIds.length > 0) {
        newInv.sort((a, b) => {
          return (
            sortedSelectedProductionIds.indexOf(a.masterProductionId) -
            sortedSelectedProductionIds.indexOf(b.masterProductionId)
          );
        });
      }
      return newInv;
    });
  }

  function recalculateRanksForProduction(
    inventoryToBeUpdated: PricingTemplateInventoryResponseDtoSelectable[],
    productionId?: number,
  ) {
    let rank = 0;
    let currentProductionId = -1;
    return inventoryToBeUpdated.map((ticket) => {
      if (currentProductionId !== ticket.masterProductionId) {
        currentProductionId = ticket.masterProductionId!;
        rank = 0;
      }
      if (
        ticket.selected &&
        ((ticket.masterProductionId === productionId && IsTicketGroupSelectable(ticket)) || productionId === undefined)
      ) {
        return {
          ...ticket,
          suggestedRank: rank++,
        };
      }
      return ticket;
    });
  }

  const toggleInventoryById = (id: number, markSelected: boolean) => {
    setInventory((prev) => {
      const invUpdated = prev.map((item) => {
        if (item.ticketGroupId === id) {
          return { ...item, selected: markSelected };
        }
        return item;
      });

      // check to select all tickets for a production
      const productionId = invUpdated.find((item) => item.ticketGroupId === id)?.masterProductionId;
      if (productionId) {
        const prodSelected = invUpdated
          .filter((item) => item.masterProductionId === productionId && IsTicketGroupSelectable(item))
          .some((item) => item.selected);

        setProductionAllTicketsSelected((prev) => {
          return {
            ...prev,
            [productionId]: prodSelected,
          };
        });

        setSelectedTicketsByProduction((prev) => {
          const selectedCount = invUpdated.filter(
            (item) => item.masterProductionId === productionId && item.selected,
          ).length;

          return {
            ...prev,
            [productionId]: selectedCount,
          };
        });
      }

      return recalculateRanksForProduction(invUpdated, productionId);
    });
  };

  const toggleAllInventoryForProduction = (productionId: number, markSelected: boolean) => {
    setInventory((prev) => {
      const invUpdated = prev.map((item) => {
        if (item.masterProductionId === productionId && IsTicketGroupSelectable(item)) {
          return { ...item, selected: markSelected };
        }
        return item;
      });

      const selectedCount = invUpdated.filter(
        (item) => item.masterProductionId === productionId && item.selected,
      ).length;

      setSelectedTicketsByProduction((prev) => ({
        ...prev,
        [productionId]: selectedCount,
      }));

      return recalculateRanksForProduction(invUpdated);
    });
    setProductionAllTicketsSelected((prev) => {
      return {
        ...prev,
        [productionId]: markSelected,
      };
    });
  };

  const resetInventoryState = () => {
    setProductionAllTicketsSelected({});
    setInventory([]);
    setInventoryForPricingGroups([]);
    setSelectedTicketsByProduction({});
  };

  function getGroupedInventory(selectedInventory: PricingTemplateInventoryResponseDtoSelectable[]) {
    return Object.groupBy(
      selectedInventory,
      (ticket: PricingTemplateInventoryResponseDtoSelectable) => ticket.masterProductionId!,
    ) as Record<number, PricingTemplateInventoryResponseDtoSelectable[]>;
  }

  return {
    refetchInventory,
    inventory,
    setInventory,
    inventoryForPricingGroups,
    setInventoryForPricingGroups,
    isInventoryFetching,
    showUnavailableTickets,
    setShowUnavailableTickets,
    productionAllTicketsSelected,
    selectedTicketsByProduction,

    toggleInventoryById,

    toggleAllInventoryForProduction,
    resetInventoryState,

    refetchInventoryWithProductionId,
    getGroupedInventory,
    selectedInventoryTickets,
  };
}

export default useInventoryForTemplates;
