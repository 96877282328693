import i18n from '@/utils/i18n';
import Button from './Button';
import clsx from 'clsx';
import { appInsights } from '@/appinsights';
import FsTrackEvent, { EventNames } from '@/services/fs-track-event';
import { useEffect } from 'react';

interface IProps {
  error: Error;
  resetErrorBoundary: () => void;
  size?: 'md' | 'sm';
}

function ErrorFallback({ error, resetErrorBoundary, size = 'md' }: IProps) {
  useEffect(() => {
    FsTrackEvent(EventNames.errorBoundary, { error });
    appInsights.trackException({
      exception: error,
    });
  }, [error]);

  return (
    <div
      className={clsx(styles.container, {
        'flex-col': size === 'md',
        'flex-row': size === 'sm',
      })}
    >
      <div className="flex flex-col items-center justify-center">
        <p className="error-desc !text-sm">{i18n.t('unknownError.title')}</p>
        <p className="error-long-desc !text-xs">{error.message}</p>
      </div>
      <Button
        label={i18n.t('buttons.refresh').toUpperCase()}
        onClick={resetErrorBoundary}
        className="m-4"
        size={size}
      />
    </div>
  );
}

const styles = {
  container: 'scrollbar flex w-full h-full flex-1 items-center justify-center border border-input-border',
};

export default ErrorFallback;
