import { createContext, useContext } from 'react';
import type { IUserPreferences } from '@/interfaces/Users/IUserPreferences';
import type { ILocalStoragePreferences } from '@/interfaces/Users/ILocalStoragePreferences';
import type { ClientUserAppProfileResponse } from '@/api/clientService/clientServiceApiSchemas';

interface IUserApi {
  update: (user: ClientUserAppProfileResponse) => void;
  setPreferences<K extends keyof IUserPreferences>(key: K, preference: IUserPreferences[K], debounce?: boolean): void;
  setLocalPreferences<K extends keyof ILocalStoragePreferences>(key: K, preference: ILocalStoragePreferences[K]): void;
  setNavigationMinimized: (isMinimized: boolean) => void;
}

interface IUserState {
  user: ClientUserAppProfileResponse | undefined;
  isError: boolean;
  error: any;
  isLoading: boolean;
  preferences: IUserPreferences;
  localPreferences: ILocalStoragePreferences;
  isNavigationMinimized: boolean;
  isLoadingUserPrefs: boolean;
}

const UserStateContext = createContext<IUserState | undefined>(undefined);
const UserApiContext = createContext<IUserApi | undefined>(undefined);

function useUserState() {
  const context = useContext(UserStateContext);
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserStateContext');
  }
  return context;
}

function useUserApi() {
  const context = useContext(UserApiContext);
  if (context === undefined) {
    throw new Error('useUserApi must be used within a UserApiContext');
  }
  return context;
}

export { UserStateContext, UserApiContext, useUserState, useUserApi };
export type { IUserState, IUserApi };
