import { FULL_DATE_TIME_USER_FORMAT, FULL_DATE_USER_FORMAT, ThreeStateOption } from './constants';
import i18n from './i18n';
import type { MenuItemDto } from '@/api/clientService/clientServiceApiSchemas';
import type { Column } from '@tanstack/react-table';
import type { CSSProperties } from 'react';
import { format, parseISO } from 'date-fns';

function numberFormat(amount: number, options?: Intl.NumberFormatOptions) {
  return new Intl.NumberFormat('en-US', options).format(amount);
}

/**
 * @param amount - Amount to be formatted
 * @param options - Options for formatting
 * @returns Formatted currency string
 * @example currencyFormat(1000) => '$1,000.00'
 */
export function currencyFormat(amount: number, options?: Intl.NumberFormatOptions) {
  const opts: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...options,
  };
  return numberFormat(amount, opts);
}

/**
 * @param amount - Amount to be formatted
 * @param options - Options for formatting
 * @returns Formatted number string
 * @example positiveNumberFormat(1000) => '1,000'
 */
export function positiveNumberFormat(amount: number | null | undefined, options?: Intl.NumberFormatOptions) {
  const opts: Intl.NumberFormatOptions = {
    style: 'decimal',
    maximumFractionDigits: 0,
    ...options,
  };

  return numberFormat(amount || 0, opts);
}

/**
 * @param amount - Amount to be formatted
 * @param options - Options for formatting
 * @returns Formatted number string
 * @example positiveNumberFormatCompact(1000) => '1K'
 */
export function positiveNumberFormatCompact(amount: number | null | undefined, options?: Intl.NumberFormatOptions) {
  const opts: Intl.NumberFormatOptions = {
    style: 'decimal',
    maximumFractionDigits: 0,
    notation: 'compact',
    compactDisplay: 'short',
    ...options,
  };
  return numberFormat(amount || 0, opts);
}

/**
 * @param amount - Amount to be formatted
 * @param options - Options for formatting
 * @returns Formatted number string
 * @example positiveFractionalNumberFormat(1000) => '1,000.00'
 */
export function positiveFractionalNumberFormat(amount: number, options?: Intl.NumberFormatOptions) {
  const opts: Intl.NumberFormatOptions = {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...options,
  };
  return numberFormat(amount, opts);
}

/**
 * @param amount - Amount to be formatted
 * @param options - Options for formatting
 * @returns Formatted percentage string
 * @example percentFormat(1000) => '100%'
 */
export function percentFormat(amount: number, options?: Intl.NumberFormatOptions) {
  const opts: Intl.NumberFormatOptions = {
    style: 'percent',
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
    signDisplay: 'always',
    ...options,
  };
  return numberFormat(amount / 100, opts);
}

/**
 * @param amount - Amount to be formatted
 * @param showSign - Whether to show currency or not
 * @returns Formatted currency string
 * @example getCurrencyString(1000) => '$1,000.00'
 */
export function getCurrencyString(amount: number | null | undefined, showSign = true): string {
  return amount === null || amount === undefined || isNaN(Number(amount))
    ? i18n.t('noAmount')
    : currencyFormat(amount, { style: showSign ? 'currency' : 'decimal' });
}

/**
 * @param value - Value string to be formatted
 * @param max - Maximum value
 * @returns Formatted currency string
 * @example formatCurrencyInput('1000', 100) => '100.00'
 */
export function formatCurrencyInput(value: string, max?: number) {
  try {
    const numberValue = max ? (parseFloat(value) > max ? max : parseFloat(value)) : parseFloat(value);
    if (isNaN(numberValue)) return '';
    return Math.abs(numberValue).toFixed(2);
  } catch (e) {
    return '';
  }
}

/**
 * @param value - Value string to be formatted
 * @returns Formatted currency string
 * @example formatInputTrimDecimals('1000.00') => '1000'
 * @example formatInputTrimDecimals('1000.01') => '1000.01'
 */
export function formatInputTrimDecimals(value: string) {
  try {
    const numberValue = parseFloat(value);
    if (isNaN(numberValue)) return '';
    const valueWithDecimals = value.split('.');
    if (!valueWithDecimals[1] || Number(valueWithDecimals[1]) === 0) return valueWithDecimals[0];
    return numberValue.toFixed(2);
  } catch (e) {
    return '';
  }
}

/**
 * This works as a replacer function for JSON.stringify when we need to serialize Maps and Sets
 * @param key - Key string
 * @param value = Value to be stringified
 * @returns Stringified value
 */
export function stringifyReplacer(key: string, value: any) {
  if (typeof value === 'object' && value !== null) {
    if (value instanceof Map) {
      return {
        _meta: { type: 'map' },
        value: Array.from(value.entries()),
      };
    } else if (value instanceof Set) {
      return {
        _meta: { type: 'set' },
        value: Array.from(value.values()),
      };
    } else if ('_meta' in value) {
      return {
        ...value,
        _meta: {
          type: 'escaped-meta',
          value: value['_meta'],
        },
      };
    }
  }
  return value;
}

/**
 * This works as a replacer function for JSON.parse when we need to deserialize/parse Maps and Sets
 * @param key - Key string
 * @param value = Value to be parsed
 * @returns Parsed value
 */
export function parseReviver(key: string, value: any) {
  if (typeof value === 'object' && value !== null) {
    if ('_meta' in value) {
      if (value._meta.type === 'map') {
        return new Map(value.value);
      } else if (value._meta.type === 'set') {
        return new Set(value.value);
      } else if (value._meta.type === 'escaped-meta') {
        return {
          ...value,
          _meta: value._meta.value,
        };
      } else {
        console.warn('Unexpected meta', value._meta);
      }
    }
  }
  return value;
}

/**
 * @param a - Array of strings
 * @param b - Array of strings
 * @returns Boolean value
 * @example compareStringArray(['a', 'b'], ['a', 'b']) => true
 */
export function compareStringArray(a: string[], b: string[]) {
  if (a.length !== b.length) return false;
  return a.every((value, index) => value === b[index]);
}

/**
 * @param a - String
 * @param b - String
 * @returns Boolean value
 * @example compareStringsCaseAgnostic('a', 'A') => true
 */

export const compareStringsCaseAgnostic = (a: string | undefined, b: string | undefined) =>
  a?.toLowerCase?.() === b?.toLowerCase?.();

/**
 * @param rbacMenu - Menu items
 * @param routeKey - Route key
 * @returns Boolean value is path authorized or not
 */
export const IsPathAuthorized = (rbacMenu: MenuItemDto[] | null | undefined, routeKey: string) => {
  if (!rbacMenu || rbacMenu.length === 0) return false;
  const found = rbacMenu.find((item) => item.key === routeKey);
  return !!found;
};

/**
 * These are used to get the CSS when we want to pin a column in the table
 * @param column - Column object
 * @returns CSS properties for common pinning styles
 */
export const getCommonPinningStyles = <T>(column: Column<T>): CSSProperties => {
  const isPinned = column.getIsPinned();
  const isLastLeftPinnedColumn = isPinned === 'left' && column.getIsLastColumn('left');
  const isFirstRightPinnedColumn = isPinned === 'right' && column.getIsFirstColumn('right');

  return {
    boxShadow: isLastLeftPinnedColumn
      ? '-3px 0 4px -4px gray inset'
      : isFirstRightPinnedColumn
        ? '3px 0 4px -4px gray inset'
        : undefined,
    left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
    right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
    opacity: isPinned ? 0.95 : 1,
    position: isPinned ? 'sticky' : 'relative',
    width: column.getSize(),
    zIndex: isPinned ? 1 : 'auto',
    background: isPinned ? 'inherit' : undefined,
  };
};

/**
 * @param value - Value to be converted to boolean
 * @returns Boolean value
 * @example getBooleanValueFromThreeState(ThreeStateOption.ONLY) => true
 * @example getBooleanValueFromThreeState(ThreeStateOption.ANY) => undefined
 * @example getBooleanValueFromThreeState(ThreeStateOption.HIDE) => false
 */
export const getBooleanValueFromThreeState = (value: ThreeStateOption | undefined | null): boolean | undefined => {
  if (!value) return undefined;
  return ThreeStateOption.ANY === value ? undefined : value === ThreeStateOption.ONLY;
};

/**
 * @param args - Arguments to be joined
 * @returns Joined string of a venue address
 */
export function venueAddress(...args: any[]) {
  return args.filter(Boolean).join(', ');
}

export function productionDateFormatted(
  productionDate: string | undefined,
  productionLocalTime: string | undefined,
  isScheduled: boolean,
) {
  let date;
  try {
    const parsedDate = parseISO(`${productionDate}T${productionLocalTime}`);
    date = isScheduled ? format(parsedDate, FULL_DATE_TIME_USER_FORMAT) : format(parsedDate, FULL_DATE_USER_FORMAT);
  } catch (e) {
    date = isScheduled ? `${productionDate} • ${productionLocalTime}` : productionDate;
  }

  return date;
}

export const getCanReset = <T>(defaultValue: T | undefined, value: T): boolean => {
  if (defaultValue === undefined) {
    return false;
  } else {
    return defaultValue !== value;
  }
};

export type IDMap<T> = Record<keyof T, string>;
/**
 * This is used to create an IDMap object from an array of keys of a certain type e.g. Used to create column ids
 * @param keys - Array of keys
 * @returns IDMap object
 */
export function createIDMap<T, K extends keyof T>(keys: K[]): Pick<IDMap<T>, K> {
  return keys.reduce(
    (acc, key) => {
      acc[key] = key as string;
      return acc;
    },
    {} as Pick<IDMap<T>, K>,
  );
}

/**
 * On keyDownCapture event, this method prevents typing of - sign, should be used with inputs with Min {0} or inputs
 * with positive numbers only.
 * @param e
 */
export const allowPositiveIntegersOnly = (e: React.KeyboardEvent<HTMLInputElement>) => {
  const allowedKeys = ['Backspace', 'Tab', 'Enter', 'Escape', 'ArrowLeft', 'ArrowRight', '.'];

  if (allowedKeys.includes(e.key)) {
    return;
  }

  const inputValue = e.currentTarget.value + e.key;

  if (!/^\d*\.?\d*$/.test(inputValue)) {
    e.preventDefault();
  }
};

export function UniqueArrayPrimitives<T>(a: T[]) {
  return [...new Set(a)];
}
export function UniqueArrayObjects(a: any[], key: string) {
  return [...new Map(a.map((item) => [item[key], item])).values()];
}

export function memoize<F extends (...args: any[]) => any>(
  callable: F,
  createIdentifier: (...args: Parameters<F>) => string,
): F {
  const MEMORY: Map<string, ReturnType<F>> = new Map();

  // @ts-expect-error: Cannot assign (...args: Parameters<F>) => ReturnType<F> to F
  return (...args: Parameters<F>): ReturnType<F> => {
    const ID: string = createIdentifier(...args);

    if (!MEMORY.has(ID)) {
      MEMORY.set(ID, callable(...args));
    }

    // @ts-expect-error: Cannot infer that the value exist.
    return MEMORY.get(ID);
  };
}

/**
 *
 * @param col table column definition
 * @returns sort direction of the column
 */
export function getSortDirection<T>(col: Column<T>): 'Asc' | 'Desc' | undefined {
  if (!col.getIsSorted()) return undefined;
  return col.getIsSorted().valueOf() === 'asc' ? 'Asc' : 'Desc';
}

/**
 *
 * @param column table column definition
 * @param index index of the column
 * @param totalCount total number of columns
 * @returns if the resize handle should be shown or not
 */
export function getShowResizeHandle<T>(column: Column<T>, index: number, totalCount: number) {
  const isLastColumn = index === totalCount - 1;
  const canResize = column.getCanResize();
  return !isLastColumn || (isLastColumn && canResize);
}
