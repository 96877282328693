import React, { useEffect, useMemo, useReducer } from 'react';
import { NotificationPreferenceApiContext, NotificationPreferenceStateContext } from './context-hooks';
import { useGetUserPreferences, useUpsertUserPreferences } from '@/api/notification/notificationApiComponents';
import { getErrorNotification, getLoadingNotification, getSuccessNotification } from '@/utils/notifications';
import i18n from '@/utils/i18n';
import { useNotificationsApi } from '../notifications/context-hooks';
import type { INotification } from '@/interfaces/CommonInterfaces';
import type { NotificationPreferenceUpsertDto } from '@/api/notification/notificationApiSchemas';
import { INITIAL_STATE, reducer, REDUCER_ACTION_TYPE } from './reducers/notificationPreferencesReducer';
import { NotificationPreferenceOptions, NotificationTypeKeys } from './constants';

function NotificationPreferenceContextProvider({ children }: { children: React.ReactNode }) {
  const [notificationPreferenceState, notificationPreferenceDispatch] = useReducer(reducer, INITIAL_STATE);
  const { pushNotification } = useNotificationsApi();

  const {
    data: notificationPreferencesResponse,
    error,
    isFetching,
    refetch: refetchNotificationPreferences,
  } = useGetUserPreferences({});

  useEffect(() => {
    if (notificationPreferencesResponse) {
      notificationPreferenceDispatch({
        type: REDUCER_ACTION_TYPE.LOAD_PREFERENCES,
        payload: notificationPreferencesResponse,
      });
    }
  }, [notificationPreferencesResponse]);

  const { mutate: updateNotificationPreferences } = useUpsertUserPreferences({
    onMutate: () => {
      const notification = getLoadingNotification(
        'notificationsUpdate',
        i18n.t('profile.notifications.toast.applying.title'),
        i18n.t('profile.notifications.toast.applying.message'),
      );
      pushNotification(notification);
      return { notification: notification };
    },
    onSuccess: (orgDefault, variables, context) => {
      const { notification } = context as { notification: INotification };
      pushNotification(
        getSuccessNotification(
          notification.id,
          i18n.t('profile.notifications.toast.success.title'),
          i18n.t('profile.notifications.toast.success.message'),
          true,
        ),
      );
      refetchNotificationPreferences();
    },
    onError: (error, variables, context) => {
      const { notification } = context as { notification: INotification };
      pushNotification(
        getErrorNotification(notification.id, error, true, i18n.t('profile.notifications.toast.error.title')),
      );
    },
  });

  const handleSaveNotificationPreferences = (notificationPreferenceRequest: typeof INITIAL_STATE) => {
    const { hitCeiling, hitFloor, noComps } = notificationPreferenceRequest;
    const notificationPreferenceUpsertDto: NotificationPreferenceUpsertDto[] = [
      {
        notificationTypeKey: NotificationTypeKeys.HitFloor,
        isOptInEmail: hitFloor?.find((field) => field.selected)?.key === NotificationPreferenceOptions.AllEvents,
        isUserOnly: hitFloor?.find((field) => field.selected)?.key === NotificationPreferenceOptions.AssignedEvents,
      },
      {
        notificationTypeKey: NotificationTypeKeys.HitCeiling,
        isOptInEmail: hitCeiling?.find((field) => field.selected)?.key === NotificationPreferenceOptions.AllEvents,
        isUserOnly: hitCeiling?.find((field) => field.selected)?.key === NotificationPreferenceOptions.AssignedEvents,
      },
      {
        notificationTypeKey: NotificationTypeKeys.NoComps,
        isOptInEmail: noComps?.find((field) => field.selected)?.key === NotificationPreferenceOptions.AllEvents,
        isUserOnly: noComps?.find((field) => field.selected)?.key === NotificationPreferenceOptions.AssignedEvents,
      },
    ];
    updateNotificationPreferences({ body: notificationPreferenceUpsertDto });
  };

  const api = useMemo(
    () => ({
      handleSaveNotificationPreferences,
      notificationPreferenceDispatch,
    }),
    [],
  );

  return (
    <NotificationPreferenceStateContext.Provider
      value={{ notificationPreferencesResponse, notificationPreferenceState }}
    >
      <NotificationPreferenceApiContext.Provider value={api}>{children}</NotificationPreferenceApiContext.Provider>
    </NotificationPreferenceStateContext.Provider>
  );
}

export { NotificationPreferenceContextProvider };
