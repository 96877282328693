import { createContext, useContext } from 'react';
import type { INotification } from '@/interfaces/CommonInterfaces';

interface INotificationApi {
  pushNotification: (notification: INotification) => void;
  readNotification: (id: string) => void;
  readAllNotifications: () => void;
}

interface INotificationState {
  notifications: INotification[];
  unreadCount: number;
}

const NotificationsStateContext = createContext<INotificationState | undefined>(undefined);
const NotificationApiContext = createContext<INotificationApi | undefined>(undefined);

const useNotificationsState = () => {
  const context = useContext(NotificationsStateContext);
  if (context === undefined) {
    throw new Error('useNotificationsState must be used within a NotificationsContextProvider');
  }
  return context;
};

const useNotificationsApi = () => {
  const context = useContext(NotificationApiContext);
  if (context === undefined) {
    throw new Error('useNotificationsApi must be used within a NotificationsContextProvider');
  }
  return context;
};

export { NotificationsStateContext, NotificationApiContext, useNotificationsState, useNotificationsApi };
export type { INotificationState, INotificationApi };
