import { useGetInventoryPropertiesByProductionId } from '@/api/pricer/pricerApiComponents';
import type { InventoryProperties } from '@/api/pricer/pricerApiSchemas';
import { useMainSliceInventoryActions, useMainSliceInventoryStore } from '@/stores/inventory/inventory-store';
import { generateFilters } from '@/context/inventory/constants';

function useInventoryPropertiesQuery() {
  const selectedProduction = useMainSliceInventoryStore('selectedProduction');

  const {
    data: inventoryProperties,
    refetch: refetchInventoryProperties,
    isRefetching,
    isLoading,
    isPending,
  } = useGetInventoryPropertiesByProductionId<InventoryProperties>(
    { pathParams: { productionId: selectedProduction?.productionId ?? 0 } },
    { enabled: !!selectedProduction?.productionId, refetchOnMount: false },
  );

  const { setInventoryFilters } = useMainSliceInventoryActions();
  const inventoryFilters = useMainSliceInventoryStore('inventoryFilters');
  const resetInventoryFilters = () => {
    setInventoryFilters(generateFilters(inventoryProperties, inventoryFilters));
  };

  return {
    inventoryProperties,
    refetchInventoryProperties,
    resetInventoryFilters,
    isLoading: isLoading || isPending || isRefetching,
  };
}

export default useInventoryPropertiesQuery;
