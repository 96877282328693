import clsx from 'clsx';
import Loading from './Loading';

interface IProps {
  size?: 'sm' | 'lg';
  text?: string;
  position?: 'absolute' | 'relative';
}

function LoadingOverlay({ size = 'sm', text, position = 'absolute' }: IProps) {
  return (
    <div className={clsx(styles.container, position)}>
      <Loading size={size} />
      {text && <div className={styles.text}>{text}</div>}
    </div>
  );
}

const styles = {
  container:
    'flex flex-col top-0 bottom-0 left-0 z-10 right-0 bg-overlay justify-center items-center h-full zoom-out-100',
  text: 'mt-2 text-xs font-light text-txt-primary',
};

export default LoadingOverlay;
