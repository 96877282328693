export default {
  labels: {
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email Address',
    fullName: 'Full Name',
    company: 'Company',
    companyName: 'Company Name',
    companyKnownAs: 'Company known as - Optional',
    companyEmail: 'Email address',
    phone: 'Mobile Phone (text notifications)',
    role: 'Role',
    roles: 'Roles',
    companyPhone: 'Phone Number',
    yourName: 'Your Name',
    yourPhone: 'Your Phone',
    contactName: 'Primary contact name',
    streetAddress: 'Street address',
    streetAddressExtra: 'Additional street address info - Optional',
    city: 'City',
    postalCode: 'Postal Code',
    companyCountry: 'Company Country',
    currentPassword: 'Current Password',
    newPassword: 'New Password',
    confirmPassword: 'Confirm New Password',
    inventorySearch: 'Filter ticket groups',
    accountID: 'Account ID',
    apiToken: 'API Token',
    brokerID: 'Broker ID',
    brokerToken: 'Broker Token',
    officeID: 'Office ID',
    password: 'Password',
  },
  error: {
    required: 'This field is required',
    name: 'Please enter a valid name',
    email: 'Please enter a valid email',
    phone: 'Please enter a valid phone number',
    companyName: 'Please enter a valid company name',
    contactName: 'Please enter a valid contact name',
    username:
      'Username must be 5 to 30 characters long, consists of letters or numbers. Can also contain (-),(.) or (_), but not at the beginning or end',
    streetAddress: 'Please enter a valid address',
    city: 'Please enter a valid city',
    zipCode: 'Please enter a valid zip code',
    password:
      'Password must be a minimum of 8 characters containing at least 1 upper case letter, 1 number and 1 special character',
    passwordMatch: 'Passwords do not match',
    newPassword: 'Invalid password. Please meet the password requirements.',
    confirmPassword: 'Passwords do not match. Please try again.',
  },
  placeholders: {
    makeSelection: 'Select...',
    companyName: 'Company name',
    companyKnownAs: 'Company known as',
    contactName: 'Contact name',
    phone: 'Phone number',
    phoneExample: '555-555-5555',
    email: 'Email address',
    emailExample: 'email@company.com',
    streetAddress: 'Street address',
    city: 'City',
    streetAddressExtra: 'Additional street address info',
    postalCode: 'Postal code',
    enterApiToken: 'Enter API token',
    enterAccountID: 'Enter account id',
    noData: 'No options...',
    search: 'Search',
    noDataParams: 'No %{label}',
  },
};
