import i18n from '@/utils/i18n';

export type PriorityOptionsKey = 'Default' | 'PricingGroupFirst' | 'TicketGroupFirst';

export const PriorityOptionsEnum: Record<PriorityOptionsKey, PriorityOptionsKey> = {
  Default: 'Default',
  PricingGroupFirst: 'PricingGroupFirst',
  TicketGroupFirst: 'TicketGroupFirst',
};

export const PriorityOptionsLabels: Record<PriorityOptionsKey, string> = {
  Default: i18n.t('inventory.priorityDisplay.defaultText'),
  PricingGroupFirst: i18n.t('inventory.priorityDisplay.pricingGroupFirst'),
  TicketGroupFirst: i18n.t('inventory.priorityDisplay.ticketGroupFirst'),
};
