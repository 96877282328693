import { MdInfoOutline, MdOutlineCancel, MdWarningAmber } from 'react-icons/md';
import { NotificationsTypes } from '@/utils/constants';
import { CgSpinner } from 'react-icons/cg';
import { CheckCircleOutlineIcon } from '@/components/icons';

interface IProps {
  variant: NotificationsTypes;
}

function NotificationIcon({ variant }: IProps) {
  const icon = {
    [NotificationsTypes.SUCCESS]: <CheckCircleOutlineIcon className="h-6 w-6 text-success-main" aria-hidden="true" />,
    [NotificationsTypes.ERROR]: <MdOutlineCancel className="h-6 w-6 text-error-main" aria-hidden="true" />,
    [NotificationsTypes.WARNING]: <MdWarningAmber className="h-6 w-6 text-warning-main" aria-hidden="true" />,
    [NotificationsTypes.INFO]: <MdInfoOutline className="h-6 w-6 text-info-main" aria-hidden="true" />,
    [NotificationsTypes.LOADING]: <CgSpinner className="h-6 w-6 animate-spin text-info-main" aria-hidden="true" />,
  };

  return icon[variant];
}

export default NotificationIcon;
