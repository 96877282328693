/**
 * Generated by @openapi-codegen
 *
 * @version v1
 */
import * as reactQuery from '@tanstack/react-query';
import type { NotificationApiContext } from './notificationApiContext';
import { useNotificationApiContext } from './notificationApiContext';
import type * as Fetcher from './notificationApiFetcher';
import { notificationApiFetch } from './notificationApiFetcher';
import type * as Schemas from './notificationApiSchemas';

export type GetUserPreferencesError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.ProblemDetails;
    }
  | {
      status: 404;
      payload: Schemas.ProblemDetails;
    }
>;

export type GetUserPreferencesResponse = Schemas.NotificationPreferenceDto[];

export type GetUserPreferencesVariables = NotificationApiContext['fetcherOptions'];

export const fetchGetUserPreferences = (variables: GetUserPreferencesVariables, signal?: AbortSignal) =>
  notificationApiFetch<GetUserPreferencesResponse, GetUserPreferencesError, undefined, {}, {}, {}>({
    url: '/v1/notifications/preferences/user',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetUserPreferences = <TData = GetUserPreferencesResponse>(
  variables: GetUserPreferencesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetUserPreferencesResponse, GetUserPreferencesError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useNotificationApiContext(options);
  return reactQuery.useQuery<GetUserPreferencesResponse, GetUserPreferencesError, TData>({
    queryKey: queryKeyFn({
      path: '/v1/notifications/preferences/user',
      operationId: 'getUserPreferences',
      variables,
    }),
    queryFn: ({ signal }) => fetchGetUserPreferences({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UpsertUserPreferencesError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.ProblemDetails;
    }
  | {
      status: 404;
      payload: Schemas.ProblemDetails;
    }
>;

export type UpsertUserPreferencesRequestBody = Schemas.NotificationPreferenceUpsertDto[];

export type UpsertUserPreferencesVariables = {
  body?: UpsertUserPreferencesRequestBody;
} & NotificationApiContext['fetcherOptions'];

export const fetchUpsertUserPreferences = (variables: UpsertUserPreferencesVariables, signal?: AbortSignal) =>
  notificationApiFetch<undefined, UpsertUserPreferencesError, UpsertUserPreferencesRequestBody, {}, {}, {}>({
    url: '/v1/notifications/preferences/user',
    method: 'post',
    ...variables,
    signal,
  });

export const useUpsertUserPreferences = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, UpsertUserPreferencesError, UpsertUserPreferencesVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useNotificationApiContext();
  return reactQuery.useMutation<undefined, UpsertUserPreferencesError, UpsertUserPreferencesVariables>({
    mutationFn: (variables: UpsertUserPreferencesVariables) =>
      fetchUpsertUserPreferences({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetNotificationsAdminQueryParams = {
  /**
   * @default
   */
  filter?: string;
  /**
   * @default
   */
  range?: string;
  /**
   * @default
   */
  sort?: string;
};

export type GetNotificationsAdminError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.ProblemDetails;
    }
  | {
      status: 404;
      payload: Schemas.ProblemDetails;
    }
>;

export type GetNotificationsAdminResponse = Schemas.NotificationAdminDto[];

export type GetNotificationsAdminVariables = {
  queryParams?: GetNotificationsAdminQueryParams;
} & NotificationApiContext['fetcherOptions'];

export const fetchGetNotificationsAdmin = (variables: GetNotificationsAdminVariables, signal?: AbortSignal) =>
  notificationApiFetch<
    GetNotificationsAdminResponse,
    GetNotificationsAdminError,
    undefined,
    {},
    GetNotificationsAdminQueryParams,
    {}
  >({ url: '/v1/admin/notifications', method: 'get', ...variables, signal });

export const useGetNotificationsAdmin = <TData = GetNotificationsAdminResponse>(
  variables: GetNotificationsAdminVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetNotificationsAdminResponse, GetNotificationsAdminError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useNotificationApiContext(options);
  return reactQuery.useQuery<GetNotificationsAdminResponse, GetNotificationsAdminError, TData>({
    queryKey: queryKeyFn({
      path: '/v1/admin/notifications',
      operationId: 'getNotificationsAdmin',
      variables,
    }),
    queryFn: ({ signal }) => fetchGetNotificationsAdmin({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type QueryOperation =
  | {
      path: '/v1/notifications/preferences/user';
      operationId: 'getUserPreferences';
      variables: GetUserPreferencesVariables;
    }
  | {
      path: '/v1/admin/notifications';
      operationId: 'getNotificationsAdmin';
      variables: GetNotificationsAdminVariables;
    };
