import logo_pos11 from './images/pos/logo-pos11.svg';
import logo_posnext from './images/pos/logo-posnext.svg';
import logo_skybox from './images/pos/logo-skybox.svg';
import logo_tradedesk from './images/pos/logo-tradedesk.svg';
import logo_m_tevo from './images/marketplace/logo-tevo.svg';
import logo_m_seatgeek from './images/marketplace/logo-seatgeek.svg';
import logo_m_ticketmaster from './images/marketplace/logo-ticketmaster.svg';
import logo_m_vividseats from './images/marketplace/logo-vividseats.svg';
import logo_m_stubhub from './images/marketplace/logo-stubhub.svg';
import { PosKeys, MarketplaceKeys, TNDPosKeys } from '@/utils/constants';
import type { IPos } from '@/interfaces/pos/IPos';

export const getPosLogo = (posId: string) => {
  switch (posId) {
    case TNDPosKeys.POS11:
      return logo_pos11;
    case TNDPosKeys.POS_NEXT:
      return logo_posnext;
    case PosKeys.SKYBOX:
      return logo_skybox;
    case PosKeys.TRADEDESK:
      return logo_tradedesk;
  }
};

export const getPosType = (item: IPos) => {
  if (item.sourceType === PosKeys.TICKET_NETWORK_DIRECT) {
    return item.isPos11 ? TNDPosKeys.POS11 : TNDPosKeys.POS_NEXT;
  }
  return item.sourceType;
};

export const getMarketplaceLogo = (sourceType: string) => {
  switch (sourceType) {
    case MarketplaceKeys.TEVO:
      return logo_m_tevo;
    case MarketplaceKeys.VSSkybox:
      return logo_m_vividseats;
    case 'SeatGeek': //ToDo: this is a stub, replace with real id
      return logo_m_seatgeek;
    case 'TicketMaster': //ToDo: this is a stub, replace with real id
      return logo_m_ticketmaster;
    case 'StubHub': //ToDo: this is a stub, replace with real id
      return logo_m_stubhub;
  }
};

export const getTndTypeKey = (posType: string, isPos11: boolean) => {
  if (posType === PosKeys.TICKET_NETWORK_DIRECT) {
    return isPos11 ? TNDPosKeys.POS11 : TNDPosKeys.POS_NEXT;
  }
  return;
};
