import { AuthService } from '@/services/AuthService';
import { useState } from 'react';
import useRecursiveTimeout from '@/hooks/useRecursiveTimeout';
import { useIdleTimer } from 'react-idle-timer';
import { differenceInMilliseconds } from 'date-fns';
import { logger } from '@/utils/logger';
import { UnsavedChangesBrowserManager } from '@/utils/UnsavedChangesBrowserManager';

function UserIdleTracker() {
  const forceUserLogout = async () => {
    logger.log('user signed out due to inactivity');
    await AuthService.forceLogout(() => {
      UnsavedChangesBrowserManager.detach();
    });
  };

  const ONE_MINUTE = 60_000;
  const IDLE_START_MINUTES = 2;
  const DELAY_BEFORE_LOGOUT_IN_MINUTES = 1;
  const sessionTimeoutInMinutes = Number(import.meta.env.REACT_APP_SESSION_TIMEOUT_IN_MINUTES);

  const IDLE_AFTER_IN_MILLISECONDS = (sessionTimeoutInMinutes - DELAY_BEFORE_LOGOUT_IN_MINUTES) * ONE_MINUTE;
  const [delayIsSessionActive, setDelayIsSessionActive] = useState<number | null>(null);
  useRecursiveTimeout(() => {
    return () => {
      forceUserLogout().then(() => {});
    };
  }, delayIsSessionActive);

  useIdleTimer({
    onIdle: () => {
      let timeFromLastTokenAcquiredInMilliseconds = 0;
      if (AuthService.tokenAcquiredOn) {
        timeFromLastTokenAcquiredInMilliseconds = differenceInMilliseconds(Date.now(), AuthService.tokenAcquiredOn);
      }

      const timeBeforeSignOutInMilliseconds = Math.max(
        IDLE_AFTER_IN_MILLISECONDS - timeFromLastTokenAcquiredInMilliseconds,
        0,
      );
      logger.log(
        'user idle start, will sign out in',
        (timeBeforeSignOutInMilliseconds / ONE_MINUTE).toFixed(2),
        'minutes',
      );
      setDelayIsSessionActive(timeBeforeSignOutInMilliseconds);
    },
    onActive: () => {
      logger.log('user idle end');
      setDelayIsSessionActive(null);
    },
    // idle = user inactive for X minutes:
    timeout: IDLE_START_MINUTES * ONE_MINUTE,
    crossTab: true,
    leaderElection: true,
    syncTimers: 200,
  });

  return null;
}

export default UserIdleTracker;
