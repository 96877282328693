import type { Dispatch } from 'react';
import { createContext, useContext } from 'react';
import type { CalculateDefaultResultDto, OrganizationPricingDefaultDto } from '@/api/pricer/pricerApiSchemas';
import type { ReducerAction } from './reducers/organizationDefaultsReducer';
import type { INITIAL_STATE, INITIAL_STATE_MARKET_DEFAULT } from './constants';
import type { ClientUserAppResponse, OrganizationAppResponse } from '@/api/clientService/clientServiceApiSchemas';
import type { MarketDefaultReducerAction } from './reducers/marketComparablesReducer';

interface IOrganizationState {
  organization: OrganizationAppResponse | undefined;
  update: (organization: OrganizationAppResponse) => void;
  isError: boolean;
  error: any;
  isOrganizationLoading: boolean;
  users: ClientUserAppResponse[];
  usersLoadSuccess: boolean;
  refetchUsers: () => void;
  isUsersLoading: boolean;
  isUsersFetching: boolean;
  orgDefaults: OrganizationPricingDefaultDto | undefined;
  orgDefaultState: typeof INITIAL_STATE;
  marketDefaultState: typeof INITIAL_STATE_MARKET_DEFAULT;
  calculatedOrgDefaults: CalculateDefaultResultDto | undefined;
}

export interface IOrganizationApi {
  orgDefaultDispatch: Dispatch<ReducerAction>;
  marketDefaultDispatch: Dispatch<MarketDefaultReducerAction>;
  handleSaveOrgDefaults: (orgDefault: typeof INITIAL_STATE, marketDefault: typeof INITIAL_STATE_MARKET_DEFAULT) => void;
  refetchOrgDefaults: () => void;
  refetchCalculatedOrgDefaults: () => void;
}

const OrganizationContext = createContext<IOrganizationState | undefined>(undefined);
const OrganizationApiContext = createContext<IOrganizationApi | undefined>(undefined);

function useOrganizationContext() {
  const context = useContext(OrganizationContext);
  if (context === undefined) {
    throw new Error('useOrganizationContext must be used within a OrganizationContextProvider');
  }
  return context;
}

function useOrganizationApiContext() {
  const context = useContext(OrganizationApiContext);
  if (context === undefined) {
    throw new Error('useOrganizationApi must be used within a OrganizationContextProvider');
  }
  return context;
}

export { OrganizationContext, useOrganizationContext, useOrganizationApiContext, OrganizationApiContext };
export type { IOrganizationState };
