export const telemetry = {
  appInsightsKey: import.meta.env.REACT_APP_APPINSIGHTS_INSTRUMENTATION_KEY,
};

export const AppMode: 'members' | 'drive' = (import.meta.env.REACT_APP_MODE as 'members' | 'drive') || 'members';

export const environment: string = import.meta.env.REACT_APP_ENV || '';

// if true - it means that the app is running in dev env (locally or deployed to DEV - both use DEV env variables)
export const isDevEnvironment: boolean = environment === 'dev';

// for local env flag, use import.meta.env.DEV

export const isJestTestEnvironment: boolean = environment === 'unit-test';
