import NavigationLocationIcon from '@/components/icons/navigation/NavigationLocationIcon';
import { NavLocationType } from '@/utils/constants';

export const NavLocations = [
  {
    name: 'top (Alt +  ↑)',
    key: NavLocationType.TOP,
    icon: ({ className }: { className: string }) => {
      return <NavigationLocationIcon location={NavLocationType.TOP} className={className} />;
    },
    hideName: true,
  },
  {
    name: 'left (Alt +  ←)',
    key: NavLocationType.LEFT,
    icon: ({ className }: { className: string }) => {
      return <NavigationLocationIcon location={NavLocationType.LEFT} className={className} />;
    },
    hideName: true,
  },
  {
    name: 'bottom (Alt +  ↓)',
    key: NavLocationType.BOTTOM,
    icon: ({ className }: { className: string }) => {
      return <NavigationLocationIcon location={NavLocationType.BOTTOM} className={className} />;
    },
    hideName: true,
  },
  {
    name: 'right (Alt +  →)',
    key: NavLocationType.RIGHT,
    icon: ({ className }: { className: string }) => {
      return <NavigationLocationIcon location={NavLocationType.RIGHT} className={className} />;
    },
    hideName: true,
  },
];
