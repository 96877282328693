import { PosKeys } from './constants';

export const posNameMinLength = 3;
export const posNameMaxLength = 50;
export const posShortNameMinLength = 3;
export const posShortNameMaxLength = 10;

export const marketplaceNameMinLength = 3;
export const marketplaceNameMaxLength = 50;
export const marketplaceShortNameMinLength = 3;
export const marketplaceShortNameMaxLength = 10;

class InputValidations {
  static isNameValid(firstName: string): boolean {
    const namePattern = /^[^<>]{1,100}$/;
    return namePattern.test(firstName.trim());
  }

  static isFirstNameValid(firstName: string): boolean {
    const namePattern = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/i;
    return namePattern.test(firstName.trim());
  }

  static isLastNameValid(lastName: string): boolean {
    const namePattern = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/i;
    return namePattern.test(lastName.trim());
  }

  static isUsernameValid(username: string): boolean {
    const namePattern = /^[a-zA-Z0-9]([._-](?![._-])|[a-zA-Z0-9]){3,28}[a-zA-Z0-9]$/i;
    return namePattern.test(username.trim());
  }

  static isEmailValid(email: string): boolean {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailPattern.test(email.trim());
  }

  static isPhoneValid(phone: string): boolean {
    const phoneNoWhiteSpace = phone.replace(/\s/g, '');
    const phonePattern = /^[0-9]{10}$/;
    return phonePattern.test(phoneNoWhiteSpace.trim());
  }

  static isPasswordValid(password: string): boolean {
    const passwordPattern = /^(0(\.(\d{1,2}))?|[1-9]\d*(\.\d{1,2})?)$/;
    return passwordPattern.test(password.trim());
  }

  static isNumbersOnly(s: string): boolean {
    const numbersPattern = /^[0-9]+$/i;
    return numbersPattern.test(s.trim());
  }

  static isNumberWithDecimal(s: string): boolean {
    const withDecimalPattern = /^(\d+(\.\d{0,2})?|\.\d{1,2})$/g;
    return withDecimalPattern.test(s.trim());
  }

  static isNumberWithDecimalOrScientific(s: string): boolean {
    const pattern = /^(?!-)(\d+(\.\d{0,2})?|\.\d{1,2})(e[+-]?\d+)?$/g;
    return pattern.test(s.trim());
  }

  static isNumbersOrLettersOnly(s: string): boolean {
    const numbersPattern = /^[0-9a-zA-Z]+$/i;
    return numbersPattern.test(s.trim());
  }

  static isLettersOnly(s: string): boolean {
    const numbersPattern = /^[a-zA-Z]+$/i;
    return numbersPattern.test(s.trim());
  }

  static isValidZip(s: string): boolean {
    const usZipPattern = /(^[0-9]{5}(?:-[0-9]{4})?$)/i;
    const caZipPattern = /(^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$)/i;
    return usZipPattern.test(s.trim()) || caZipPattern.test(s.trim());
  }

  static isNonNumbers(s: string): boolean {
    const namePattern = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/i;
    return namePattern.test(s.trim());
  }

  static isPosNameValid(name: string): boolean {
    const trimmedNameLength = name.trim().length;
    return trimmedNameLength >= posNameMinLength && trimmedNameLength <= posNameMaxLength;
  }

  static isPosShortNameValid(name: string): boolean {
    const trimmedNameLength = name.trim().length;
    return trimmedNameLength >= posShortNameMinLength && trimmedNameLength <= posShortNameMaxLength;
  }

  static isPricingGroupNameValid(value: string): boolean {
    const namePattern = /^[A-Za-z0-9!#$%&*()@\-+{}[\]<>?'~_`/\\ ]+$/i;
    return namePattern.test(value.trim());
  }

  static isMarketplaceNameValid(name: string): boolean {
    const trimmedNameLength = name.trim().length;
    return trimmedNameLength >= posNameMinLength && trimmedNameLength <= posNameMaxLength;
  }

  static isMarketplaceShortNameValid(name: string): boolean {
    const trimmedNameLength = name.trim().length;
    return trimmedNameLength >= posShortNameMinLength && trimmedNameLength <= posShortNameMaxLength;
  }

  static isPasswordLengthValid(password: string, minLength: number = 8): boolean {
    return password.trim().length >= minLength;
  }

  static isUpperCaseRequirementMet(password: string): boolean {
    return /[A-Z]/.test(password);
  }

  static isNumberRequirementMet(password: string): boolean {
    return /[0-9]/.test(password);
  }

  static isSpecialCharRequirementMet(password: string): boolean {
    return /[!@#$%^&*(),.?":{}|<>]/.test(password);
  }
  static isPosSourceTypeValid(name: string): boolean {
    return name === PosKeys.SKYBOX || name === PosKeys.TICKET_NETWORK_DIRECT || name === PosKeys.TRADEDESK;
  }
}

export default InputValidations;
