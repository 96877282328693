import type { GetUserSettingsResponse } from '@/api/clientService/clientServiceApiComponents';
import type { UserSettingDto } from '@/api/clientService/clientServiceApiSchemas';
import type { ILocalStoragePreferences } from '@/interfaces/Users/ILocalStoragePreferences';
import type { IUserPreferences } from '@/interfaces/Users/IUserPreferences';
import { NavLocationType } from '@/utils/constants';
import { parseReviver, stringifyReplacer } from '@/utils/helpers';
import { PriorityOptionsEnum } from '@/components/inventory/PriorityDisplay/constants';

export const DEFAULT_PREFERENCES: IUserPreferences = {
  sidebar: {
    isOpen: true,
  },
  theme: {
    isDark: true,
  },
  inventory: {
    tableColumnsOrder: [],
    tableColumnsVisibility: {},
    tableColumnPinning: {},
  },
  productions: {
    tableColumnsOrder: [],
    tableColumnsVisibility: {},
    sortColumnSetting: { columnName: 'eventDate', sortDirection: 'Asc' },
    tableColumnPinning: {},
  },
  users: {
    sortColumnSetting: { columnName: '', sortDirection: 'Asc' },
  },
  activeMarketPlace: {
    tableColumnsOrder: [],
    tableColumnsVisibility: {},
    sortColumnSetting: { columnName: '', sortDirection: 'Asc' },
    tableColumnPinning: {},
  },
  productionsSidePanel: {
    isOpen: false,
  },
  navigation: {
    isFloating: false,
    location: NavLocationType.TOP,
    autoMinimize: false,
  },
  restorePage: {
    pathname: '/',
  },
  templateSelection: {
    tableColumnsOrder: [],
    tableColumnsVisibility: {},
    tableColumnPinning: {},
  },
  templateProductionSelection: {},
  templateProductionTicketsSelection: {},
};

export const DEFAULT_LOCAL_PREFERENCES: ILocalStoragePreferences = {
  inventory: {
    tableColumnsSizes: new Map<string, number>(),
  },
  productions: {
    tableColumnsSizes: new Map<string, number>(),
  },
  users: {
    tableColumnsSizes: new Map<string, number>(),
  },
  activeMarketPlace: {
    tableColumnsSizes: new Map<string, number>(),
  },
  templateSelection: {
    tableColumnsSizes: new Map<string, number>(),
  },
  templateProductionSelection: {
    tableColumnsSizes: new Map<string, number>(),
  },
  templateProductionTicketsSelection: {
    tableColumnsSizes: new Map<string, number>(),
  },
  marketplace: {
    tableColumnsSizes: new Map<string, number>(),
  },
  auditHistory: {
    tableColumnsSizes: new Map<string, number>(),
  },
  auditPricingGroupDetails: {
    tableColumnsSizes: new Map<string, number>(),
  },
  auditTicketHistory: {
    tableColumnsSizes: new Map<string, number>(),
  },
  pos: {
    tableColumnsSizes: new Map<string, number>(),
  },
  insightsOpen: true,
  priorityDisplay: PriorityOptionsEnum.Default,
};

/**
 *
 * @param prefs Local storage preferences per app, same for all users on the same browser
 */
export function saveLocalStoragePreferences(prefs: ILocalStoragePreferences) {
  localStorage.setItem('preferences', JSON.stringify(prefs, stringifyReplacer));
}

/**
 *
 * @returns Local storage preferences per app, same for all users on the same browser
 */
export function getLocalStoragePrefs() {
  try {
    const prefs = JSON.parse(localStorage.getItem('preferences') ?? '', parseReviver);
    return prefs ?? {};
  } catch (e) {
    return {};
  }
}

/**
 *
 * @param userPrefs User preferences per user, different for each user
 */
export function saveUserSessionPreferences(userPrefs: Partial<IUserPreferences>) {
  sessionStorage.setItem('preferences', JSON.stringify(userPrefs, stringifyReplacer));
}

/**
 *
 * @returns User preferences per user, different for each user
 */
export function getUserSessionPrefs() {
  try {
    const userPrefs = JSON.parse(sessionStorage.getItem('preferences') ?? '', parseReviver);
    return userPrefs ?? {};
  } catch (e) {
    return {};
  }
}

/**
 *
 * @param userPrefs User preferences per user, different for each user
 * @param serverPreferences Server preferences per user, different for each user
 * @returns Combined preferences
 */
export const combineServerAndLocalPrefs = (
  userPrefs: Partial<IUserPreferences>,
  serverPreferences: UserSettingDto[],
) => {
  let parsedServePrefs = {};
  try {
    serverPreferences.forEach((x: UserSettingDto) => {
      const { key, value } = x;
      if (key && value === '') parsedServePrefs = { ...parsedServePrefs, [key]: value }; //why?
      if (key && value) parsedServePrefs = { ...parsedServePrefs, [key]: JSON.parse(value, parseReviver) };
    });
  } catch (e) {
    console.error(e);
  }

  return { ...userPrefs, ...parsedServePrefs };
};

/**
 *
 * @param a User preferences per user, different for each user
 * @param b User preferences per user, different for each user
 * @returns True if both preferences are equal, false otherwise
 */
export const arePrefsEqual = (a: GetUserSettingsResponse | undefined, b: GetUserSettingsResponse | undefined) => {
  if (!a && !b) return true;
  if (a) {
    if (!b) return false;

    let i = 0;
    for (i; i < a.length; i++) {
      const found = b.find((setting: UserSettingDto) => setting.key === a[i].key);
      if (!found) return false;
      if (a[i].value !== found.value) return false;
    }

    return i === a.length && i === b.length;
  } else return !b;
};
