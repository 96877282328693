import type { ITelemetryItem } from '@microsoft/applicationinsights-web';
import { ApplicationInsights, DistributedTracingModes } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { AppModeIsDrive, telemetryConfig } from './utils/constants';
import type {
  ClientUserAppProfileResponse,
  OrganizationAppResponse,
} from '@/api/clientService/clientServiceApiSchemas';
import type { ICustomProperties } from '@microsoft/applicationinsights-core-js';

const reactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({
  config: {
    connectionString: telemetryConfig.appInsightsKey,
    enableAutoRouteTracking: true,
    enableCorsCorrelation: true,
    distributedTracingMode: DistributedTracingModes.W3C,
    enableDebug: false, // if true, throws exceptions to the console and stops ajax calls
    // default is 500, when the limit is reached, the page gets reloaded. Setting the limit to -1 disables the limit.
    maxAjaxCallsPerView: -1,
    loggingLevelConsole: 2,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    extensions: [reactPlugin],
    correlationHeaderExcludedDomains: ['vividjscharts.secure-tix.com'],
  },
});
let initiated = false;
const filteredAjaxCallsDomains = ['fullstory.com'];
const baseInitializer = ({
  user,
  organization,
}: {
  user?: ClientUserAppProfileResponse;
  organization?: OrganizationAppResponse;
}) => {
  if (initiated) return;
  initiated = true;
  const telemetryInitializer = (envelope: ITelemetryItem) => {
    envelope.tags = envelope.tags || [];
    envelope.tags['ai.cloud.role'] = AppModeIsDrive ? 'skyboxdrive' : 'pricerqx';
    const customData: ICustomProperties = {};
    if (user) {
      customData.Username = user.username;
    }
    if (organization) {
      customData.OrganizationKey = organization.key;
    }
    envelope.data = customData;
    const target = envelope.baseData?.target;
    if (target && filteredAjaxCallsDomains.includes(target)) {
      return false;
    }
  };

  appInsights.addTelemetryInitializer(telemetryInitializer);
  appInsights.loadAppInsights();
};
const initiateAppInsights = ({
  user,
  organization,
}: {
  user: ClientUserAppProfileResponse;
  organization: OrganizationAppResponse;
}) => {
  baseInitializer({ user, organization });
};

const initiateAppInsightsNoUserNoOrganization = () => {
  baseInitializer({});
};
export { reactPlugin, appInsights, initiateAppInsights, initiateAppInsightsNoUserNoOrganization };
