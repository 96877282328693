/// <reference types="react/canary" />
import './App.css';
import { RouterProvider } from 'react-router-dom';
import './index.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import router from './nav/router';
import { useEffect } from 'react';
import { reactPlugin } from './appinsights';
import { AppModeIsDrive } from './utils/constants';
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { UnknownError } from './routes/errors';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      gcTime: 0,
    },
  },
});
const html = document.querySelector('html');

const getFaviconByEnv = (env: string) => {
  const favicon = document.getElementById('favicon') as HTMLLinkElement;
  switch (env) {
    //icons files public directory accessed with  prefix
    case 'members':
      return (favicon!.href = '/favicon.ico');
    case 'drive':
      return (favicon!.href = '/sb-favicon.ico');
  }
};

function App() {
  useEffect(() => {
    if (html) {
      html.classList.add('dark');
      if (AppModeIsDrive) {
        html.classList.add('app-mode-is-vvs');
      }
    }
  }, [html]);

  useEffect(() => {
    getFaviconByEnv(import.meta.env.REACT_APP_MODE);
  }, []);

  const enabledQueryDevTools = import.meta.env.REACT_APP_QUERY_DEV_TOOLS === 'true';

  return (
    <AppInsightsErrorBoundary
      appInsights={reactPlugin}
      onError={() => <UnknownError techDetails="Error boundary triggered" />}
    >
      <QueryClientProvider client={queryClient}>
        <>
          <RouterProvider router={router} />
          {enabledQueryDevTools && <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />}
        </>
      </QueryClientProvider>
    </AppInsightsErrorBoundary>
  );
}

export default App;
