import type { Action } from '@/api/audit/auditApiSchemas';

export const AuditActions: { [key in Action]: string } = {
  created: 'Created',
  edit: 'Edit',
  suggestedPriceChange: 'Suggested Price Change',
  deleted: 'Deleted',
} as const;

export const AuditPageEvents = {
  REFETCH_AUDIT: 'REFETCH_AUDIT',
  FETCH_NEXT_AUDITS_PAGE: 'FETCH_NEXT_AUDITS_PAGE',
  REFETCH_AUDIT_TICKETS: 'REFETCH_AUDIT_TICKETS',
};

export const today = new Date();
export const dateConstraints = [new Date(2023, 0, 1), today];

export const toUtcDateAtMidnight = (date: Date) => {
  const dateAtMidnight = new Date(date);
  dateAtMidnight.setHours(0, 0, 0, 0);

  return dateAtMidnight.toISOString();
};
