import { useMemo } from 'react';
import blankProfile from '@/assets/images/blank-profile.svg';
import clsx from 'clsx';

function generateUniqueColor(name: string): string {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }

  const hex = Math.abs(hash).toString(16).substring(0, 6);
  return `#${hex.padEnd(6, '0')}`;
}

interface IProps {
  name?: string | null;
  imgUrl?: string;
  variant?: 'sm' | 'md' | 'lg';
}

function Avatar({ name, imgUrl, variant = 'sm' }: IProps) {
  const src = imgUrl ?? blankProfile;
  const isSmall = variant === 'sm';
  const isMedium = variant === 'md';
  const isLarge = variant === 'lg';

  const imgStyle = clsx(styles.imageStyle, {
    'ring-white': isSmall,
  });

  const backgroundColor = useMemo(() => (name ? generateUniqueColor(name ?? '') : ''), [name]);
  const initials = useMemo(() => {
    if (!name) return '';
    return name
      .split(' ')
      .map((word) => word[0])
      .join('')
      .slice(0, 2)
      .toUpperCase();
  }, [name]);

  const renderInitials = () => {
    return (
      <div
        className={clsx(
          styles.initials,
          {
            'text-xs': isSmall,
            'text-[2.125rem]': isLarge,
          },
          imgStyle,
        )}
        style={{ backgroundColor }}
      >
        {initials}
      </div>
    );
  };

  return (
    <div
      className={clsx(styles.container, {
        'h-6 w-6': isSmall,
        'h-[2.125rem] w-[2.125rem]': isMedium,
        'h-[7.5rem] w-[7.5rem]': isLarge,
      })}
    >
      {name ? renderInitials() : <img className={imgStyle} src={src} alt="" />}
    </div>
  );
}

const styles = {
  container: 'flex -space-x-1 overflow-hidden flex-shrink-0',
  imageStyle: 'w-full h-full inline-block rounded-full',
  initials: 'flex justify-center items-center text-txt-primary w-full h-full',
};

export default Avatar;
