import { createContext, useContext } from 'react';
import type { GetPricingTemplatesQueryParams } from '@/api/pricer/pricerApiComponents';
import type { PricingTemplateSearchResponseDto } from '@/api/pricer/pricerApiSchemas';
import type { columnSortType } from '@/context/pricer/context-hooks';

interface ITemplateListState {
  pricingTemplatesQueryParams: GetPricingTemplatesQueryParams;
  pricingTemplateSearchData: PricingTemplateSearchResponseDto[] | undefined;
  isLoading: boolean;
  hasNextPage: boolean | undefined;
  totalRecords: number | undefined;
  sortColumns: columnSortType;
  searchTerm: string;
}

interface ITemplateListApi {
  setPricingTemplatesQueryParams: (params: GetPricingTemplatesQueryParams) => void;
  setSortColumns: (columns: columnSortType) => void;
  fetchNextPage: () => Promise<void>;
  deleteTemplate: (templateId: number) => Promise<void>;
  setSearchTerm: (term: string) => void;
  refetchTemplateList: () => void;
}

const TemplatesListStateContext = createContext<ITemplateListState | undefined>(undefined);
const TemplatesListApiContext = createContext<ITemplateListApi | undefined>(undefined);

function useTemplatesListState() {
  const context = useContext(TemplatesListStateContext);
  if (context === undefined) {
    throw new Error('useTemplatesListState must be used within a TemplatesListStateContext');
  }
  return context;
}

function useTemplatesListApi() {
  const context = useContext(TemplatesListApiContext);
  if (context === undefined) {
    throw new Error('useTemplatesListApi must be used within a TemplatesListApiContext');
  }
  return context;
}

export { useTemplatesListState, useTemplatesListApi, TemplatesListStateContext, TemplatesListApiContext };
export type { ITemplateListState, ITemplateListApi };
