import { MdNavigation } from 'react-icons/md';
import i18n from '@/utils/i18n';
import { Toggle } from '@/components/common';
import ButtonGroup from '@/components/common/ButtonGroup';
import { NavLocations } from '@/components/NavigationMenu/NavLocations';
import type { IDataField } from '@/interfaces/CommonInterfaces';
import type { NavLocationType } from '@/utils/constants';
import { useUserApi, useUserState } from '@/context/user/context-hooks';
import { Key } from 'ts-key-enum';

function NavMenuLocationPanelOnly({ close }: { close: () => void }) {
  const { setPreferences } = useUserApi();
  const {
    preferences: { navigation },
  } = useUserState();
  const isFloatingNavigation = navigation.isFloating;
  const navigationLocation = navigation.location;
  const isAutoMinimize = navigation.autoMinimize;

  return (
    <div className="flex flex-col gap-3 text-sm">
      <div className="mb-2 flex flex-row items-center gap-2 text-xs">
        <MdNavigation className="ml-1 h-4 w-4" />
        {i18n.t('navigation.navigationSettings')}
      </div>
      <Toggle
        isChecked={isFloatingNavigation}
        size={'sm'}
        handleSwitch={() => {
          setPreferences('navigation', { ...navigation, isFloating: !isFloatingNavigation });
        }}
        label={i18n.t('navigation.floatNavigation')}
        hotkey={Key.Alt + ' + F'}
      />
      <ButtonGroup
        data={NavLocations}
        className="ml-4"
        disabled={!isFloatingNavigation}
        onSelect={(option: IDataField) => {
          close();
          setPreferences('navigation', { ...navigation, location: option.key as NavLocationType });
        }}
        selected={NavLocations.find((l) => l.key === navigationLocation)}
      />
      <Toggle
        isChecked={isAutoMinimize}
        size={'sm'}
        disabled={!isFloatingNavigation}
        handleSwitch={() => {
          setPreferences('navigation', { ...navigation, autoMinimize: !isAutoMinimize });
        }}
        label={i18n.t('navigation.autoMinimize')}
        hotkey={Key.Alt + ' + M'}
      />
    </div>
  );
}

export default NavMenuLocationPanelOnly;
