import { useState } from 'react';
import type { INotification } from '@/interfaces/CommonInterfaces';
import i18n from '@/utils/i18n';
import { Button, ErrorFallback, TextInput } from '../../components/common';
import { getErrorNotification, getLoadingNotification, getSuccessNotification } from '@/utils/notifications';
import { useNotificationsApi } from '@/context/notifications/context-hooks';
import { useMutation } from '@tanstack/react-query';
import { apiEndpoints, postAuthAPI } from '@/utils/api';
import type { IChangePassword } from '@/interfaces/Users/IChangePassword';
import InputValidations from '@/utils/InputValidations';
import PasswordRequirement from './PasswordRequirement';
import HeaderButtonsSlideOver from '@/components/common/slideOvers/HeaderButtonsSlideOver';
import { ErrorBoundary } from 'react-error-boundary';

enum FieldType {
  Password = 'Password',
  ConfirmPassword = 'ConfirmPassword',
}

interface IProps {
  onClose: () => void;
  open: boolean;
}

function ChangePassword({ onClose, open }: IProps) {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isUpperCase, setIsUpperCase] = useState(false);
  const [isNumber, setIsNumber] = useState(false);
  const [isSpecialChar, setIsSpecialChar] = useState(false);
  const [isLength, setIsLength] = useState(false);
  const [isMatch, setIsMatch] = useState(false);

  const minLength = 8;

  interface ChangePasswordWithNotification {
    changePassword: IChangePassword;
    notification: INotification;
  }
  const { pushNotification } = useNotificationsApi();
  const { mutate, isError } = useMutation({
    mutationFn: (req: ChangePasswordWithNotification) =>
      postAuthAPI(apiEndpoints.userChangePassword, req.changePassword),
    onSuccess: (_response, variables) =>
      pushNotification(
        getSuccessNotification(
          variables.notification?.id,
          i18n.t('users.changePassword.successMessage'),
          i18n.t('users.changePassword.successTitle'),
          true,
        ),
      ),
    onError: (error, variables) => pushNotification(getErrorNotification(variables.notification?.id, error, true)),
  });
  const resetForm = () => {
    setPassword('');
    setConfirmPassword('');
    setIsUpperCase(false);
    setIsNumber(false);
    setIsSpecialChar(false);
    setIsLength(false);
    setIsMatch(false);
  };

  const checkLengthRequirement = (value: string) => {
    const isValid = InputValidations.isPasswordLengthValid(value, minLength);
    setIsLength(isValid);
  };

  const checkUpperCaseRequirement = (value: string) => {
    const isValid = InputValidations.isUpperCaseRequirementMet(value);
    setIsUpperCase(isValid);
  };

  const checkNumberRequirement = (value: string) => {
    const isValid = InputValidations.isNumberRequirementMet(value);
    setIsNumber(isValid);
  };

  const checkSpecialCharRequirement = (value: string) => {
    const isValid = InputValidations.isSpecialCharRequirementMet(value);
    setIsSpecialChar(isValid);
  };

  const checkMatchRequirement = (value: string, fieldName: FieldType) => {
    let isValid = false;
    if (fieldName === FieldType.ConfirmPassword) {
      isValid = value === password;
    } else {
      isValid = value === confirmPassword && value !== '';
    }
    setIsMatch(isValid);
  };
  const checkPassword = (value: string, fieldName: FieldType) => {
    if (fieldName === FieldType.Password) {
      checkLengthRequirement(value);
      checkUpperCaseRequirement(value);
      checkNumberRequirement(value);
      checkSpecialCharRequirement(value);
    }
    checkMatchRequirement(value, fieldName);
  };

  const handleChange = (e: any, fieldName: FieldType) => {
    if (isError) resetForm();
    switch (fieldName) {
      case FieldType.Password:
        setPassword(e.target.value);
        break;
      case FieldType.ConfirmPassword:
        setConfirmPassword(e.target.value);
        break;
    }
    checkPassword(e.target.value, fieldName);
  };

  const handleSubmit = () => {
    resetForm();
    const notification = getLoadingNotification(
      'change-password',
      i18n.t('users.changePassword.loadingMessage'),
      i18n.t('users.changePassword.loadingTitle'),
    );
    pushNotification(notification);
    const changePassword = {
      newPassword: password,
    } as IChangePassword;
    mutate({ changePassword, notification });
  };

  const handleCancel = () => {
    resetForm();
    onClose();
  };

  const renderRequirements = () => {
    return (
      <li className={styles.passwordRequirementsList}>
        <PasswordRequirement
          label={i18n.t('users.changePassword.requirements.uppercase')}
          checked={isUpperCase}
          id="uppercase"
          name="uppercase"
        />
        <PasswordRequirement
          label={i18n.t('users.changePassword.requirements.number')}
          checked={isNumber}
          id="number"
          name="number"
        />
        <PasswordRequirement
          label={i18n.t('users.changePassword.requirements.special')}
          checked={isSpecialChar}
          id="special"
          name="special"
        />
        <PasswordRequirement
          label={i18n.t('users.changePassword.requirements.length')}
          checked={isLength}
          id="length"
          name="length"
        />
        <PasswordRequirement
          label={i18n.t('users.changePassword.requirements.match')}
          checked={isMatch}
          id="length"
          name="length"
        />
      </li>
    );
  };

  const renderButtons = () => {
    return (
      <div className="flex justify-end">
        <Button label={i18n.t('buttons.cancel').toUpperCase()} variant="secondary" onClick={handleCancel} />
        <Button
          label={i18n.t('users.changePassword.submitButton').toUpperCase()}
          onClick={handleSubmit}
          disabled={!isUpperCase || !isNumber || !isSpecialChar || !isLength || !isMatch}
          className="ml-2"
        />
      </div>
    );
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <HeaderButtonsSlideOver
        open={open}
        header={i18n.t('users.changePassword.headerTitle')}
        setOpen={onClose}
        ctaLabel={i18n.t('users.changePassword.submitButton').toUpperCase()}
        isCtaDisabled={!isUpperCase || !isNumber || !isSpecialChar || !isLength || !isMatch}
        onCtaClick={handleSubmit}
        onCancelClick={handleCancel}
        responsive
      >
        <div className={styles.container}>
          <TextInput
            label={i18n.t('input.labels.newPassword')}
            variant="filled"
            name="password"
            id="password"
            type="password"
            value={password}
            onChange={(e) => handleChange(e, FieldType.Password)}
          />
          {renderRequirements()}
          <TextInput
            label={i18n.t('input.labels.confirmPassword')}
            variant="filled"
            name="confirm-password"
            id="confirm-password"
            type="password"
            value={confirmPassword}
            onChange={(e) => handleChange(e, FieldType.ConfirmPassword)}
          />
        </div>
      </HeaderButtonsSlideOver>
    </ErrorBoundary>
  );
}

const styles = {
  container: 'bg-bck-body m-4',
  title: 'text-txt-primary',
  header: 'flex-col items-center',
  passwordRequirement: '!relative m-0 mb-2 !text-sm !font-normal text-txt-secondary',
  passwordRequirementsList: 'flex flex-col',
  checkInput: 'mr-2 p-2 border-0 rounded-full bg-paper-card text-success-alt-dark',
  checkmark: 'text-success-main absolute left-0 top-0 h-5 w-5 text-opacity-80',
};

export default ChangePassword;
