import { isRouteErrorResponse, NavLink, useRouteError } from 'react-router-dom';
import { Button } from '../../components/common';
import i18n from '@/utils/i18n';
import useTitle from '@/hooks/useTitle';
import { useEffect } from 'react';
import FsTrackEvent, { EventNames } from '@/services/fs-track-event';
import { appInsights } from '@/appinsights';
import errorImage from '@/assets/images/error.svg';

interface IProps {
  errorCode: string;
  title: string;
  description: string;
  technicalDetails?: string;
  forceNotFound?: boolean;
}

function ErrorPage({ errorCode, title, description, technicalDetails, forceNotFound }: IProps) {
  const error = useRouteError();
  const isRouteException = isRouteErrorResponse(error) || forceNotFound || (error as any)?.status === 404;
  useEffect(() => {
    if (!isRouteException) {
      console.error(
        'error page> isRouteErrorResponse:',
        isRouteErrorResponse(error),
        'tech details:',
        technicalDetails,
        'location:',
        window.location.href,
        error,
      );
    }

    if (forceNotFound && import.meta.env.DEV) {
      console.log('No permission to access this page');
    }
  }, []);

  useEffect(() => {
    FsTrackEvent(EventNames.errorPage, { error: { errorCode, description, technicalDetails } });
    appInsights.trackException({
      exception: new Error(errorCode),
      properties: { errorCode, description, technicalDetails },
    });
  }, [technicalDetails]);

  useTitle({ title: isRouteException ? title : i18n.t('unknownError.title') });

  const errorText = (error as any)?.message || error;
  const errorDetails = `${technicalDetails}${errorText ? ': ' + errorText : ''}`;

  return (
    <div className="auth-background scrollbar">
      <header className="error-container p-4">
        {isRouteException ? (
          <>
            <p className="error-desc">{errorCode}</p>
            <p className="error-long-desc">{description}</p>
            <img src={errorImage} className={styles.image} alt="error" />

            <NavLink to={'/events'}>
              <Button label={i18n.t('buttons.backHome').toUpperCase()} />
            </NavLink>
          </>
        ) : null}
        {!isRouteException ? (
          <>
            <p className="error-desc">{i18n.t('unknownError.title')}</p>
            <p className="error-long-desc">{errorDetails}</p>
            <img src={errorImage} className={styles.image} alt="error" />

            <Button
              label={i18n.t('buttons.refresh').toUpperCase()}
              onClick={() => {
                window.location.reload();
              }}
            />
          </>
        ) : null}
      </header>
    </div>
  );
}

const styles = {
  image: 'xsm:my-8 h-[18.32rem] w-[31.772rem] ',
};

export default ErrorPage;
