import React, { useMemo } from 'react';
import { IoAdd, IoRemove } from 'react-icons/io5';
import clsx from 'clsx';
import ctl from '@netlify/classnames-template-literals';
import { StepperInputType } from '@/utils/constants';
import { TooltipPosition } from '@/components/common/tooltip/constants';
import Tooltip from '@/components/common/tooltip/Tooltip';

interface IProps {
  label: string;
  min?: number;
  max?: number;
  step?: number;
  value?: number;
  onChange?: (value: number) => void;
  disabled?: boolean;
  disableButtons?: boolean;
  className?: string;
  classNameLabel?: string;
  placeholder: string | number;
  error?: boolean;
  showLabel?: boolean;
  inputType?: StepperInputType;
  onKeyDownCapture?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  tootltip?: string;
  decrementTooltip?: string;
  incrementTooltip?: string;
}

function Stepper({
  label,
  min = 0,
  max,
  step = 1,
  value: initialValue,
  onChange,
  disabled = false,
  disableButtons = false,
  className,
  classNameLabel = '',
  placeholder = 0,
  error = false,
  showLabel = true,
  inputType = StepperInputType.INTEGER,
  onKeyDownCapture,
  tootltip,
  decrementTooltip,
  incrementTooltip,
}: IProps) {
  const inputStyle = useMemo(
    () => clsx(styles.input, { 'h-[1.625rem]': !showLabel, [styles.inputError]: error }),
    [error, showLabel],
  );
  const handleDecrement = (): void => {
    const newValue = sanitizedValue - step;
    if (newValue >= min) {
      onChange?.(newValue);
    }
  };

  const sanitizedValue = initialValue || 0;
  const inputDisplayValue = !initialValue && initialValue !== 0 ? '' : sanitizedValue;
  const handleIncrement = (): void => {
    const newValue = sanitizedValue + step;
    if (!max || newValue <= max) {
      onChange?.(newValue);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue =
      inputType === StepperInputType.INTEGER ? parseInt(event.target.value, 10) : parseFloat(event.target.value);
    onChange?.(newValue);
  };

  const areButtonsDisabled = disabled || disableButtons;
  const isDecrementDisabled = sanitizedValue <= min || areButtonsDisabled;
  const isIncrementDisabled = (max !== undefined && sanitizedValue >= max) || areButtonsDisabled;

  return (
    <div className={className}>
      <div className={styles.content}>
        <div className={clsx(styles.inputContainer, { 'pb-2 pt-5': showLabel })}>
          {showLabel && <label className={clsx(styles.label, classNameLabel)}>{label}</label>}
          <Tooltip message={tootltip} position={TooltipPosition.Bottom} toolTipOffset={8}>
            <input
              type="number"
              value={inputDisplayValue}
              onChange={handleChange}
              min={min}
              max={max}
              step={step}
              disabled={disabled}
              className={inputStyle}
              placeholder={placeholder.toString()}
              onKeyDownCapture={onKeyDownCapture}
            />
          </Tooltip>
          <div className={styles.buttonContainer}>
            <Tooltip message={decrementTooltip} position={TooltipPosition.Bottom} toolTipOffset={8}>
              <button
                onClick={handleDecrement}
                disabled={isDecrementDisabled}
                aria-label="Decrement"
                className={styles.button}
              >
                <IoRemove />
              </button>
            </Tooltip>
            <Tooltip message={incrementTooltip} position={TooltipPosition.Bottom} toolTipOffset={8}>
              <button
                onClick={handleIncrement}
                disabled={isIncrementDisabled}
                aria-label="Increment"
                className={styles.button}
              >
                <IoAdd />
              </button>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = {
  content: 'relative flex flex-col',
  label: ctl(`
    absolute
    -top-0.5
    left-0
    h-full
    w-full
    py-4
    text-2xs
    order-2
    transition-all
    duration-100
    ease-in-out
    origin-left
    transform
    translate-x-1
    -translate-y-3
    opacity-75
    pointer-events-none
    truncate pr-2`),
  inputContainer: ctl(`
  flex
  items-center
  bg-filled-input-bg
  pr-1
  peer
  shadow-sm
  text-md
  border-0
  focus:ring-0
  placeholder-txt-disabled
  `),
  input: ctl(`
    flex-1
    bg-paper-card/[0]
    px-2
    margin-top-2
    text-txt-primary
    peer
    text-xs
    border-0
    focus:ring-0
    hover:ring-0
    placeholder-txt-disabled
    disabled:cursor-not-allowed
    disabled:opacity-50
    selection:bg-bck-input-highlight
    `),
  inputError: 'border-error-main text-error-main focus:border-error-main focus:ring-error-main',
  button:
    'bg-txt-primary text-gray-900 h-4 hover:text-primary-main focus:outline-none focus:ring-2 focus:ring-primary-main disabled:bg-txt-disabled disabled:cursor-not-allowed',
  buttonContainer: 'flex space-x-2 ml-auto',
};

export default Stepper;
