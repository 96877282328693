import { InfoIconOutline, WarningIcon } from '@/components/icons';
import type { ReactNode } from 'react';
import { cva } from 'cva';

interface IProps {
  statement?: string;
  description?: string;
  variant?: 'primary' | 'error';
  color?: 'info' | 'warning';
  size?: 'xs' | 'sm' | 'md';
  children?: ReactNode;
  centeredIcon?: boolean;
}

function MainAlert({
  statement,
  description,
  variant = 'primary',
  color = 'info',
  size = 'md',
  centeredIcon = false,
  children,
  ...props
}: IProps) {
  return (
    <div
      className={styleVariation({
        color,
      })}
      {...props}
    >
      <div className={'flex flex-1 flex-col'}>
        {variant === 'primary' && <InfoIconOutline className={iconVariation({ color, centered: centeredIcon })} />}
        {variant === 'error' && <WarningIcon className={iconVariation({ color, centered: centeredIcon })} />}
        {statement && <p className="text-md flex gap-2 font-semibold">{statement}</p>}
        {description && <p className="mt-2 text-xs">{description}</p>}
      </div>
      <div className="flex items-center">{children}</div>
    </div>
  );
}

const iconVariation = cva('self-center text-xl absolute left-4', {
  variants: {
    color: {
      info: 'text-primary-main',
      warning: 'text-warning-main',
    },
    centered: {
      true: 'top-7',
      false: 'top-4',
    },
  },
});
const styleVariation = cva('pl-12 flex w-full flex-row rounded p-3 relative', {
  variants: {
    color: {
      info: 'bg-bck-info text-primary-main',
      warning: 'bg-bck-warning text-warning-main',
    },
  },
});

export default MainAlert;
