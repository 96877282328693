import type { Placement } from '@floating-ui/react';
import type { ReactNode } from 'react';
import React from 'react';
import { FloatingTooltip, TooltipContent, TooltipTrigger } from '@/components/common/tooltip/FloatingTooltip';
import clsx from 'clsx';

function isReactFragment(variableToInspect: { type?: string } | any): boolean {
  if (variableToInspect?.type) {
    return variableToInspect.type === React.Fragment;
  }
  return variableToInspect === React.Fragment;
}

interface IProps {
  message: string | ReactNode;
  children: ReactNode;
  delay?: number;
  disabled?: boolean;
  maxSize?: 'xs' | 'sm' | 'md';
  position?: Placement;
  toolTipOffset?: number;

  // for debugging
  openForce?: boolean;
}

function Tooltip({ message, children, delay, disabled, position, toolTipOffset, maxSize = 'md', openForce }: IProps) {
  if (!message) {
    return <>{children}</>;
  }
  if (!children) return null;

  if (import.meta.env.DEV) {
    const tooltipDebug = (typeof message === 'string' && `message "${message}"`) || 'html message';
    if (!React.isValidElement(children)) {
      console.warn(
        `Tooltip with ${tooltipDebug}: children ${children} is not a valid React element. A button will be rendered instead`,
      );
    }

    if (isReactFragment(children)) {
      console.error(
        `Tooltip with ${tooltipDebug}: child component wrapped in Fragment. Tooltip will not work as expected, wrap it in div`,
      );
    }
    if (typeof (children as any)?.type === 'function') {
      const functionName = (children as any)?.type?.name;
      console.warn(
        `Tooltip with ${tooltipDebug}: the component "${functionName}" can not be used as a child (notice forwardRef exception below), wrap it in a div`,
      );
    }
  }

  return (
    <FloatingTooltip
      placement={position}
      open={disabled ? false : openForce}
      delay={delay}
      toolTipOffset={toolTipOffset}
    >
      <TooltipTrigger asChild={true}>{children}</TooltipTrigger>
      <TooltipContent
        className={clsx({
          'max-w-64': maxSize === 'xs',
          'max-w-sm': maxSize === 'sm',
          'max-w-md': maxSize === 'md',
        })}
      >
        {message}
      </TooltipContent>
    </FloatingTooltip>
  );
}

export default Tooltip;
