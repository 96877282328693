import { useGetPendingChangesStatus } from '@/api/pricer/pricerApiComponents';
import { useEffect } from 'react';
import { useMainSliceInventoryActions, useMainSliceInventoryStore } from '@/stores/inventory/inventory-store';

function useInitialPendingChangesQuery() {
  const selectedProduction = useMainSliceInventoryStore('selectedProduction');

  const { data: initialPendingChanges, refetch: fetchInitialPendingChanges } = useGetPendingChangesStatus(
    { queryParams: { productionId: selectedProduction?.productionId ?? undefined } },
    { enabled: false, refetchOnMount: false },
  );

  useEffect(() => {
    if (!selectedProduction?.productionId) return;
    fetchInitialPendingChanges();
  }, [fetchInitialPendingChanges, selectedProduction?.productionId]);

  const { setCommittedUnsettledChanges } = useMainSliceInventoryActions();
  const commitedUnsettledChanges = useMainSliceInventoryStore('committedUnsettledChanges');

  useEffect(() => {
    if (!initialPendingChanges) return;
    const pendingTicketGroups = (initialPendingChanges as any[])
      .filter((pc) => pc.isPendingChange)
      .map((pc) => pc.ticketGroupId);

    setCommittedUnsettledChanges([...commitedUnsettledChanges, ...pendingTicketGroups]);
  }, [initialPendingChanges, selectedProduction?.productionId]);
}

export default useInitialPendingChangesQuery;
