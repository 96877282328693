import clsx from 'clsx';

interface IProps {
  label: string;
  className?: string;
  color?: 'divider' | 'border';
}

function DividerTopLabel({ label, className, color = 'divider' }: IProps) {
  return (
    <div className={className}>
      <span className={styles.groupLabel}>{label}</span>
      <div
        className={clsx(styles.divider, {
          'border-divider': color === 'divider',
          'border-bck-border/30': color === 'border',
        })}
      />
    </div>
  );
}

const styles = {
  groupLabel: 'text-txt-disabled text-xs font-medium mt-8 ml-6',
  divider: 'w-full border-t mt-1',
};

export default DividerTopLabel;
