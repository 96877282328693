/* eslint-disable max-lines */
import type { IMarketFilters } from '@/interfaces/CommonInterfaces';
import { EFilterType } from '@/interfaces/CommonInterfaces';
import type { IOptionsSet, IRadioOption } from '@/interfaces/CommonInterfaces';
import i18n from '@/utils/i18n';
import { AppModeIsDrive, YesNoOption, yesNoOptions } from '@/utils/constants';
import type {
  ComparablesQueryDto,
  LowerOutlierMethod,
  MarketplaceProperties,
  OrganizationPricingDefaultDto,
  PricingGroupResponse,
} from '@/api/pricer/pricerApiSchemas';
import type { ProductionMapGroup, ProductionSection } from '@/components/SeatMap/type';
import type { GetTicketAttributesResponse } from '@/api/pricer/pricerApiComponents';
import { combineSections } from '@/utils/filters';
import { lowerOutlierMethods, maxSplitType, splitType } from '@/context/pricing-mode/constants';

export const marketplaceFiltersBase: IMarketFilters = {
  rowRange: {
    type: EFilterType.INPUT,
    data: {
      label: i18n.t('market.filters.rowRange'),
      option: '',
    },
  },
  rowSequence: {
    type: EFilterType.INPUT,
    data: {
      label: i18n.t('market.filters.rowSequence'),
      option: '',
    },
  },
  zones: {
    type: EFilterType.OPTIONS,
    data: {
      label: i18n.t('market.filters.zones'),
      toolTipMessage: i18n.t('market.filters.zonesTooltip'),
      options: [],
    },
  },
  splits: {
    type: EFilterType.OPTIONS,
    data: {
      label: i18n.t('market.filters.splits'),
      options: splitType.map((option) => ({
        label: i18n.t(`market.validSplits.${option}`),
        value: option,
        selected: false,
      })),
    } as IOptionsSet<number>,
    dirty: false,
  },
  dynamicSplits: {
    type: EFilterType.TOGGLE,
    data: false,
    disabled: true,
    dirty: false,
  },
  sections: {
    type: EFilterType.OPTIONS,
    data: {
      label: i18n.t('market.filters.sections'),
      toolTipMessage: i18n.t('market.filters.sectionsTooltip'),
      options: [],
    } as IOptionsSet<string>,
  },
  deliveryMethod: {
    type: EFilterType.OPTIONS,
    data: {
      label: i18n.t('market.filters.deliveryMethod'),
      toolTipMessage: i18n.t('market.filters.deliveryMethodTooltip'),
      options: [],
    } as IOptionsSet<string>,
  },
  attributes: {
    type: EFilterType.OPTIONS,
    data: {
      label: i18n.t('market.filters.attributes'),
      toolTipMessage: i18n.t('market.filters.excludeAttributesTooltip'),
      options: [],
    } as IOptionsSet<string>,
  },
  lowerOutlierAmount: {
    type: EFilterType.INPUT,
    data: {
      label: i18n.t('market.filters.lowerOutlierAmount'),
      option: undefined,
    },
  },
  lowerOutlierMethod: {
    type: EFilterType.RADIO,
    data: {
      label: i18n.t('market.filters.lowerOutlierMethod'),
      options: lowerOutlierMethods,
      selected: lowerOutlierMethods[0],
    } as IRadioOption,
  },
  seatSaver: {
    type: EFilterType.YESNO,
    data: {
      label: !AppModeIsDrive ? i18n.t('market.filters.speculation') : i18n.t('market.filters.seatSaver'),
      options: yesNoOptions,
      selected: yesNoOptions[1],
    } as IRadioOption,
  },
  showMyListings: {
    type: EFilterType.TOGGLE,
    data: true,
  },
  showOutliers: {
    type: EFilterType.TOGGLE,
    data: true,
  },
};

export const populateMarketFilters = (
  filters: IMarketFilters,
  setFilters: (filters: IMarketFilters) => void,
  marketplaceProperties: MarketplaceProperties | undefined,
  mapSections: ProductionSection[],
  mapZones: ProductionMapGroup[],
  tgAttributes: GetTicketAttributesResponse | undefined,
) => {
  const { stockTypes, sectionInfos } = marketplaceProperties ?? {};
  const sections = sectionInfos?.map((sectionInfo) => sectionInfo.sectionName) as string[];
  const mappedSections =
    sectionInfos?.filter((section) => section.sourceSectionKey !== '').map((section) => section.sectionName) ?? [];
  const combinedSections = combineSections(mapSections, sections ?? []);

  const populatedFilters: IMarketFilters = {
    ...structuredClone(marketplaceFiltersBase),
    sections: {
      ...filters.sections,
      data: {
        ...filters.sections.data,
        options: (combinedSections ?? []).map((option) => ({
          label: option,
          value: option,
          selected: false,
          isMapped: mappedSections.includes(option),
          sourceSectionKey: sectionInfos?.find((section) => section.sectionName === option)?.sourceSectionKey,
        })),
      } as IOptionsSet<string>,
    },
    deliveryMethod: {
      ...filters.deliveryMethod,
      data: {
        ...filters.deliveryMethod.data,
        options: (stockTypes ?? []).map((option) => ({ label: option.name, value: option.value })),
      } as IOptionsSet<string>,
    },
    attributes: {
      ...filters.attributes,
      data: {
        ...filters.attributes.data,
        options: (tgAttributes ?? []).map((option) => ({ label: option.name, value: option.value })),
      } as IOptionsSet<string>,
    },
    zones: {
      ...filters.zones,
      data: {
        ...filters.zones.data,
        options: (mapZones ?? [])
          .filter((zone) => zone.name !== '')
          .map((zone) => ({
            label: zone.name,
            value: zone.id,
            color: zone.color,
            id: zone.id,
            selected: false,
          })),
      } as unknown as IOptionsSet<string>,
    },
  };
  setFilters(populatedFilters);
};

export const populateMarketFiltersFromPG = (
  filters: IMarketFilters,
  pricingGroupResponse: PricingGroupResponse | null | undefined,
  setFilters: (filters: IMarketFilters) => void,
  marketplaceProperties: MarketplaceProperties | undefined,
  mapSections: ProductionSection[],
  tgAttributes: GetTicketAttributesResponse | undefined,
  isNewPricingGroup: boolean,
  orgDefaults: OrganizationPricingDefaultDto | undefined,
) => {
  const {
    deliveryMethodInclude,
    sections: sectionsInclude,
    attributesExclude,
    validSplitsIncludeV2,
    rowFrom,
    rowTo,
    lowerOutlierAmount,
    lowerOutlierMethod,
    includeSpeculativeTickets,
    rowInclude,
    isSplitsDynamic,
  } = pricingGroupResponse?.marketGroupCriteria ?? {};

  const { splits: pgDynamicSplits, includeMax } = pricingGroupResponse?.dynamicMarketGroupCriteria?.validSplitsV2 ?? {};
  const { isSplitsEnabled, isSplitsDynamic: defaultSplitsDynamic } = orgDefaults?.marketCriteriaDefaults ?? {};
  let splits: number[] = [];
  splits = [...(validSplitsIncludeV2?.length ? validSplitsIncludeV2 : pgDynamicSplits ?? [])];
  if (includeMax) splits.push(maxSplitType);

  const { stockTypes, sectionInfos } = marketplaceProperties ?? {};
  const sections = sectionInfos?.map((sectionInfo) => sectionInfo.sectionName) as string[];
  const combinedSections = combineSections(mapSections, sections);
  const mappedSections =
    sectionInfos?.filter((section) => section.sourceSectionKey !== '').map((section) => section.sectionName) ?? [];

  const populatedFilters: IMarketFilters = {
    ...filters,
    sections: {
      ...filters.sections,
      data: {
        ...filters.sections.data,
        options: (combinedSections ?? []).map((option) => ({
          label: option,
          value: option,
          selected: sectionsInclude?.find((section) => section.sectionName === option) !== undefined,
          isMapped: mappedSections.includes(option),
          sourceSectionKey: sectionInfos?.find((section) => section.sectionName === option)?.sourceSectionKey,
        })),
      } as IOptionsSet<string>,
    },
    deliveryMethod: {
      ...filters.deliveryMethod,
      data: {
        ...filters.deliveryMethod.data,
        options: (stockTypes ?? []).map((option) => ({
          label: option.name,
          value: option.value,
          selected: deliveryMethodInclude?.includes(option.stringValue!),
        })),
      } as IOptionsSet<string>,
    },
    attributes: {
      ...filters.attributes,
      data: {
        ...filters.attributes.data,
        options: (tgAttributes ?? []).map((option) => ({
          label: option.name,
          value: option.value,
          selected: attributesExclude?.includes(option.value!),
        })),
      } as IOptionsSet<string>,
    },
    splits: {
      ...filters.splits!,
      data: {
        ...filters.splits!.data,
        options: splitType.map((option) => ({
          label: i18n.t(`market.validSplits.${option}`),
          value: option,
          selected: splits?.includes(option),
        })),
      } as IOptionsSet<number>,
    },
    dynamicSplits: {
      ...filters.dynamicSplits,
      data: isNewPricingGroup ? !!defaultSplitsDynamic : !!isSplitsDynamic,
      disabled: false,
      defaultEnabled: !!isSplitsEnabled,
    },
    rowRange: {
      ...filters.rowRange!,
      data: {
        ...filters.rowRange!.data,
        option: rowFrom && rowTo ? `${rowFrom}-${rowTo}` : '',
      },
    },
    rowSequence: {
      ...filters.rowSequence!,
      data: {
        ...filters.rowSequence!.data,
        option: rowInclude?.join(',') ?? '',
      },
    },
    lowerOutlierAmount: {
      ...filters.lowerOutlierAmount,
      data: {
        ...filters.lowerOutlierAmount.data,
        option: lowerOutlierAmount?.toString(),
      },
    },
    lowerOutlierMethod: {
      ...filters.lowerOutlierMethod,
      data: {
        ...filters.lowerOutlierMethod.data,
        selected: lowerOutlierMethods.find((method) => method.key.toLowerCase() === lowerOutlierMethod?.toLowerCase()),
      },
    },
    seatSaver: {
      ...filters.seatSaver!,
      data: {
        ...filters.seatSaver!.data,
        selected: {
          name: YesNoOption[includeSpeculativeTickets ? 'YES' : 'NO'],
          key: YesNoOption[includeSpeculativeTickets ? 'YES' : 'NO'],
        },
      },
    },
  };

  setFilters(populatedFilters);
};

export const getComparablesFromFilters = (
  filters: IMarketFilters,
  isNewPricingGroup?: boolean,
): ComparablesQueryDto => {
  const { deliveryMethod, splits, attributes, rowRange, rowSequence, lowerOutlierMethod } = filters;
  const { seatSaver, showMyListings, showOutliers, sections, dynamicSplits } = filters;

  const comparables: ComparablesQueryDto = {
    deliveryMethodInclude: deliveryMethod.data.options
      .filter((option) => option.selected)
      .map((option) => option.value),
    validSplitsIncludeV2: splits?.data.options
      .filter((option) => option.selected)
      .map((option) => Number(option.value)),
    validSplitsIncludeMax: splits?.data.options
      .filter((option) => option.selected)
      .map((option) => Number(option.value))
      .includes(maxSplitType),
    attributesExclude: attributes.data.options.filter((option) => option.selected).map((option) => option.value),
    rowFrom: rowRange?.data.option.split('-')[0],
    rowTo: rowRange?.data.option.split('-')[1],
    rowInclude: rowSequence?.data.option === '' ? [] : rowSequence?.data.option.split(','),
    lowerOutlierAmount: Number(filters.lowerOutlierAmount!.data.option),
    lowerOutlierMethod: lowerOutlierMethod.data.selected?.key as LowerOutlierMethod,
    includeSpeculativeTickets: seatSaver?.data.selected?.key === YesNoOption.YES,
    showMyTickets: showMyListings?.data,
    showOutliers: showOutliers?.data,
    sections: sections.data.options
      .filter((option) => option.selected)
      .map((option) => {
        return {
          sectionName: option.value,
          ...(option.sourceSectionKey && { sourceSectionKey: option.sourceSectionKey }),
        };
      }),
    isSplitsDynamic:
      dynamicSplits.defaultEnabled && isNewPricingGroup ? !!dynamicSplits.defaultEnabled : !!dynamicSplits.data,
  };

  return comparables;
};
