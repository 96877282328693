import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect, useMemo, useState } from 'react';
import type { INotification } from '@/interfaces/CommonInterfaces';
import { getErrorNotification, getLoadingNotification, getSuccessNotification } from '@/utils/notifications';
import i18n from '@/utils/i18n';
import { ConnectionTestStatus } from '@/utils/constants';
import type {
  MarketplaceCredentialRequestDto,
  MarketplaceCredentialResponseDto,
} from '@/api/marketplace/marketplaceApiSchemas';
import type { IMarketplaceConnectionTest } from '@/interfaces/marketplace/IMarketplaceConnectionTest';
import {
  fetchCreateCredential,
  fetchDeleteCredential,
  fetchGetAllMarketplaces,
  fetchTestCredential,
  fetchUpdateCredential,
} from '@/api/marketplace/marketplaceApiComponents';
import { useMarketplaceApiContext } from '@/api/marketplace/marketplaceApiContext';
import { MarketplaceStateContext, MarketplaceApiContext } from './context-hooks';
import { useNotificationsApi } from '../notifications/context-hooks';

function MarketplaceContextProvider({ children }: { children: React.ReactNode }) {
  const [marketplaceIntegrations, setMarketplaceIntegrations] = useState<MarketplaceCredentialResponseDto[]>([]);
  const [pendingConnectionsTests, setPendingConnectionsTests] = useState<IMarketplaceConnectionTest[]>([]);
  const { pushNotification } = useNotificationsApi();
  const { fetcherOptions } = useMarketplaceApiContext();
  const [pendingDeletions, setPendingDeletions] = useState<number[]>([]);

  const {
    data: marketplaceData,
    isPending,
    refetch: refetchMarketplaceAll,
  } = useQuery({
    queryKey: ['marketplace'],
    queryFn: () => fetchGetAllMarketplaces({ ...fetcherOptions }),
  });

  const handleSetConnectionTest = (marketplaceOrganizationId: number, status: ConnectionTestStatus) => {
    const connectionTest = { marketplaceOrganizationId, status };
    setPendingConnectionsTests((connectionTests) => {
      const index = connectionTests.findIndex(
        (n) => n.marketplaceOrganizationId === connectionTest.marketplaceOrganizationId,
      );
      if (index > -1) {
        connectionTests[index] = connectionTest;
        return [...connectionTests];
      }
      connectionTests.push(connectionTest);
      return [...connectionTests];
    });
  };

  const { mutate: testExistingMarketplace } = useMutation({
    mutationFn: (req: {
      marketplace: MarketplaceCredentialResponseDto;
      notification: INotification;
      callback?: (isSuccess: boolean) => void;
    }) => {
      const { marketplace } = req;
      handleSetConnectionTest(marketplace.id ?? 0, ConnectionTestStatus.PENDING);
      return fetchTestCredential({
        ...fetcherOptions,
        pathParams: { organizationMarketplaceId: marketplace.id ?? 0 },
      });
    },
    onSuccess: (response, variables) => {
      if (variables.callback) variables.callback(true);
      const { notification, marketplace } = variables;
      pushNotification(
        getSuccessNotification(
          notification.id,
          i18n.t(`marketplace.notifications.connection.success.title`),
          i18n.t(`marketplace.notifications.connection.success.message`, {
            name: marketplace.abbreviation,
          }),
          true,
        ),
      );
      const isConnectionValid = response.isValid;
      handleSetConnectionTest(
        marketplace.id ?? 0,
        isConnectionValid ? ConnectionTestStatus.VALID : ConnectionTestStatus.INVALID,
      );
    },
    onError: (error, variables) => {
      if (variables.callback) variables.callback(false);
      const { notification, marketplace } = variables;
      pushNotification(
        getErrorNotification(
          notification.id,
          error,
          true,
          i18n.t(`marketplace.notifications.connection.error.title`),
          i18n.t(`marketplace.notifications.connection.error.message`, {
            name: marketplace.abbreviation,
          }),
        ),
      );
      handleSetConnectionTest(marketplace.id ?? 0, ConnectionTestStatus.FAILED);
    },
  });

  const { mutate: deleteMarketplace } = useMutation({
    mutationFn: (req: {
      marketplace: MarketplaceCredentialResponseDto;
      notification: INotification;
      callback?: (isSuccess: boolean) => void;
    }) => {
      const { marketplace } = req;
      setPendingDeletions([...pendingDeletions, marketplace.id!]);
      return fetchDeleteCredential({
        ...fetcherOptions,
        pathParams: { organizationMarketplaceId: marketplace.id ?? 0 },
      });
    },
    onSuccess: (response, variables) => {
      if (variables.callback) variables.callback(true);
      const { notification, marketplace } = variables;
      pushNotification(
        getSuccessNotification(
          notification.id,
          i18n.t(`marketplace.notifications.delete.success.title`),
          i18n.t(`marketplace.notifications.delete.success.message`, {
            name: marketplace.abbreviation,
          }),
          true,
        ),
      );
      refetchMarketplaceAll();
      setPendingDeletions(pendingDeletions.filter((item) => item !== marketplace.id!));
    },
    onError: (error, variables) => {
      if (variables.callback) variables.callback(false);
      const { notification, marketplace } = variables;
      pushNotification(
        getErrorNotification(
          notification.id,
          error,
          true,
          i18n.t(`marketplace.notifications.delete.error.title`),
          i18n.t(`marketplace.notifications.delete.error.message`, {
            name: marketplace.abbreviation,
          }),
        ),
      );
      refetchMarketplaceAll();
    },
  });

  const {
    mutate: addMarketplace,
    isError: isSavingError,
    reset,
  } = useMutation({
    mutationFn: (req: {
      reqArgs: MarketplaceCredentialRequestDto;
      notification: INotification;
      callback: (isSuccess: boolean) => void;
      isAnUpdate: boolean;
      id?: number;
    }) => {
      const { reqArgs, isAnUpdate, id } = req;

      if (isAnUpdate)
        return fetchUpdateCredential({
          ...fetcherOptions,
          pathParams: { organizationMarketplaceId: id ?? 0 },
          body: reqArgs,
        });
      else return fetchCreateCredential({ ...fetcherOptions, body: reqArgs });
    },
    onSuccess: (response: any, variables) => {
      const { notification, reqArgs } = variables;
      const isConnectionValid = response.isValid;
      if (variables.callback) {
        variables.callback(
          (reqArgs as MarketplaceCredentialRequestDto).testConnectionOnly ? !!isConnectionValid : true,
        );
      }

      const { testConnectionOnly, abbreviation } = reqArgs;

      testConnectionOnly
        ? pushNotification(
            isConnectionValid
              ? getSuccessNotification(
                  notification.id,
                  i18n.t(`marketplace.notifications.connection.success.title`),
                  i18n.t(`marketplace.notifications.connection.success.message`, {
                    name: abbreviation,
                  }),
                  true,
                )
              : getErrorNotification(
                  notification.id,
                  null,
                  true,
                  i18n.t(`marketplace.notifications.connection.invalid.title`),
                  i18n.t(`marketplace.notifications.connection.invalid.message`, {
                    name: abbreviation,
                  }),
                ),
          )
        : pushNotification(
            getSuccessNotification(
              notification.id,
              i18n.t(`marketplace.notifications.save.success.title`),
              i18n.t(`marketplace.notifications.save.success.message`, {
                name: abbreviation,
              }),
              true,
            ),
          );

      refetchMarketplaceAll();
    },
    onError: (error, variables) => {
      if (variables.callback) variables.callback(false);
      const { notification, reqArgs } = variables;
      const { testConnectionOnly, abbreviation } = reqArgs;
      const callType = testConnectionOnly ? 'connection' : 'save';
      pushNotification(
        getErrorNotification(
          notification.id,
          error,
          true,
          i18n.t(`marketplace.notifications.${callType}.error.title`),
          i18n.t(`marketplace.notifications.${callType}.error.message`, {
            name: abbreviation,
          }),
        ),
      );
    },
  });

  useEffect(() => {
    if (marketplaceData) {
      setPendingConnectionsTests([]);
      setMarketplaceIntegrations([marketplaceData]);
    }
  }, [marketplaceData]);

  const handleAddMarketplace = (
    marketplace: MarketplaceCredentialRequestDto,
    callback: (isSuccess: boolean) => void = () => {},
    isAnUpdate: boolean = false,
    id?: number,
  ) => {
    const notification = getLoadingNotification(
      'save-marketplace',
      i18n.t('marketplace.notifications.save.loading.title'),
      i18n.t('marketplace.notifications.save.loading.message', { name: marketplace.abbreviation }),
    );
    addMarketplace({ reqArgs: { ...marketplace, testConnectionOnly: false }, notification, callback, isAnUpdate, id });
    pushNotification(notification);
  };

  const handleTestMarketplace = (
    marketplace: MarketplaceCredentialRequestDto,
    callback: (isSuccess: boolean) => void,
  ) => {
    const notification = getLoadingNotification(
      'test-marketplace',
      i18n.t('marketplace.notifications.connection.loading.title'),
      i18n.t('marketplace.notifications.connection.loading.message', { name: marketplace.abbreviation }),
    );
    addMarketplace({
      reqArgs: { ...marketplace, testConnectionOnly: true },
      notification,
      callback,
      isAnUpdate: false,
    });
    pushNotification(notification);
  };

  const handleTestExistingMarketplace = (
    marketplace: MarketplaceCredentialResponseDto,
    callback?: (isSuccess: boolean) => void,
  ) => {
    const notification = getLoadingNotification(
      'test-marketplace',
      i18n.t('marketplace.notifications.connection.loading.title'),
      i18n.t('marketplace.notifications.connection.loading.message', { name: marketplace.abbreviation }),
    );
    testExistingMarketplace({ marketplace, notification, callback });
    pushNotification(notification);
  };

  const handleDeleteMarketplace = (
    marketplace: MarketplaceCredentialResponseDto,
    callback?: (isSuccess: boolean) => void,
  ) => {
    const notification = getLoadingNotification(
      'delete-marketplace',
      i18n.t('marketplace.notifications.delete.loading.title'),
      i18n.t('marketplace.notifications.delete.loading.message', { name: marketplace.abbreviation }),
    );
    deleteMarketplace({ marketplace, notification, callback });
    pushNotification(notification);
  };

  const api = useMemo(
    () => ({
      refetchMarketplaceAll,
      addMarketplace: (
        marketplace: MarketplaceCredentialRequestDto,
        callback: (isSuccess: boolean) => void,
        isAnUpdate?: boolean,
        id?: number,
      ) => handleAddMarketplace(marketplace, callback, isAnUpdate, id),
      updateMarketplace: (
        marketplace: MarketplaceCredentialRequestDto,
        callback: (isSuccess: boolean) => void,
        id: number,
      ) => handleAddMarketplace(marketplace, callback, true, id),
      testMarketplace: handleTestMarketplace,
      testExistingMarketplace: handleTestExistingMarketplace,
      resetMutationState: reset,
      deleteMarketplace: handleDeleteMarketplace,
    }),
    [],
  );

  return (
    <MarketplaceStateContext.Provider
      value={{
        marketplaceIntegrations,
        isLoading: isPending,
        isSavingError,
        noMarketplace: marketplaceIntegrations?.length === 0,
        pendingConnectionsTests,
        pendingDeletions,
      }}
    >
      <MarketplaceApiContext.Provider value={api}>{children}</MarketplaceApiContext.Provider>
    </MarketplaceStateContext.Provider>
  );
}

export { MarketplaceContextProvider };
