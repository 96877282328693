import { FullStory } from '@fullstory/browser';
import { useEffect } from 'react';
import { useUserState } from '@/context/user/context-hooks';
import { useOrganizationContext } from '@/context/organization/context-hooks';
import type {
  ClientUserAppProfileResponse,
  OrganizationAppResponse,
} from '@/api/clientService/clientServiceApiSchemas';

function getOrgEmail(user: ClientUserAppProfileResponse, org: OrganizationAppResponse) {
  let email = user.email;

  const cleanUp = (str: string) => str.replace(/[^a-zA-Z0-9_-]/g, '');

  if (!email) {
    email = `${cleanUp(user.name || '')}@${cleanUp(org.name)}.com`;
  }

  return email;
}

const useFSUserIdentity = () => {
  const { user } = useUserState();
  const { organization } = useOrganizationContext();

  useEffect(() => {
    if (import.meta.env.DEV || !user || !user.username || !organization) {
      return;
    }

    const FSUser = {
      uid: user.username,
      properties: {
        displayName: user.name,
        email: getOrgEmail(user, organization),
        orgKey: organization.key,
        organizationName: organization.name,
      },
    };

    FullStory('setIdentity', FSUser);
  }, [user, organization]);
};

export default useFSUserIdentity;
