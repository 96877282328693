import * as React from 'react';
import { TemplatesListApiContext, TemplatesListStateContext } from './templates-list-context-hooks';
import type { ITemplateListApi } from './templates-list-context-hooks';
import { fetchGetPricingTemplates, useDeletePricingTemplate } from '@/api/pricer/pricerApiComponents';
import type { GetPricingTemplatesQueryParams } from '@/api/pricer/pricerApiComponents';
import { keepPreviousData, useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import type { PagedPricingTemplateResponseDto } from '@/api/pricer/pricerApiSchemas';
import { AuthApiFetcherOptions } from '@/api/AuthApiContext';
import { useEffect, useMemo, useState } from 'react';
import type { columnSortType } from '@/context/pricer/context-hooks';
import { columnIds } from './constants';
import { getErrorNotification, getSuccessNotification } from '@/utils/notifications';
import i18n from '@/utils/i18n';
import { useNotificationsApi } from '@/context/notifications/context-hooks';
import useDebounce from '@/hooks/useDebounce';

function TemplatesListContextProvider(props: React.PropsWithChildren<{}>) {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [sortColumns, setSortColumns] = useState<columnSortType>({
    columnName: columnIds.createdDate,
    sortDirection: 'Asc',
  });

  const { pushNotification } = useNotificationsApi();
  const queryClient = useQueryClient();
  const { mutate } = useDeletePricingTemplate({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['templates-list'] }).then(() => {
        pushNotification(
          getSuccessNotification(
            'update-pricing-template',
            i18n.t('pricingTemplatesListPage.notifications.delete.successTitle'),
            i18n.t('pricingTemplatesListPage.notifications.delete.successMessage'),
          ),
        );
      });
    },
    onError: (error) => {
      pushNotification(getErrorNotification('update-pricing-template', error));
    },
  });

  const deleteTemplate = async (templateId: number): Promise<void> => {
    mutate({
      pathParams: { pricingTemplateId: templateId },
    });
  };

  const [pricingTemplatesQueryParams, setPricingTemplatesQueryParams] = useState<GetPricingTemplatesQueryParams>({
    PageNumber: 1,
    PageSize: 50,
    SearchText: '',

    SortDirection: sortColumns.sortDirection,
    SortBy: sortColumns.columnName,
  });

  const {
    data,
    isFetching,
    hasNextPage,
    fetchNextPage,
    refetch: refetchTemplateList,
  } = useInfiniteQuery<PagedPricingTemplateResponseDto>({
    queryKey: ['templates-list', { ...pricingTemplatesQueryParams }],
    queryFn: ({ signal, pageParam }: any) => {
      return fetchGetPricingTemplates(
        {
          ...AuthApiFetcherOptions,
          queryParams: {
            ...pricingTemplatesQueryParams,
            PageNumber: pageParam || pricingTemplatesQueryParams.PageNumber,
          },
        },
        signal,
      );
    },
    getNextPageParam: (lastPage, allPages) => {
      if (allPages.length < (lastPage.totalPages || 0)) {
        return allPages.length + 1;
      } else return null;
    },
    initialPageParam: 1,
    placeholderData: keepPreviousData,
  });

  const templatesData = useMemo(() => {
    if (!data) {
      return [];
    }
    const { pages } = data;

    return pages?.flatMap((page: any) => page.data) ?? [];
  }, [data]);

  useEffect(() => {
    setPricingTemplatesQueryParams({
      ...pricingTemplatesQueryParams,
      SearchText: debouncedSearchTerm,
      PageNumber: 1,
    });
  }, [debouncedSearchTerm]);

  const api = useMemo<ITemplateListApi>(
    () => ({
      setPricingTemplatesQueryParams,
      setSortColumns: (columns: columnSortType) => {
        setSortColumns(columns);
        setPricingTemplatesQueryParams((prev) => {
          return {
            ...prev,
            SortDirection: columns.sortDirection,
            SortBy: columns.columnName,
            PageNumber: 1,
          };
        });
      },
      fetchNextPage: async () => {
        if (hasNextPage) {
          await fetchNextPage();
        }
      },
      deleteTemplate: deleteTemplate,
      setSearchTerm,
      refetchTemplateList,
    }),
    [
      setPricingTemplatesQueryParams,
      setSortColumns,
      hasNextPage,
      fetchNextPage,
      deleteTemplate,
      pricingTemplatesQueryParams,
    ],
  );

  const totalRecords = data?.pages[0].totalRecords;
  return (
    <TemplatesListStateContext.Provider
      value={{
        isLoading: isFetching,
        totalRecords,
        pricingTemplateSearchData: templatesData,
        pricingTemplatesQueryParams,
        sortColumns,
        hasNextPage,
        searchTerm,
      }}
    >
      <TemplatesListApiContext.Provider value={api}>{props.children}</TemplatesListApiContext.Provider>
    </TemplatesListStateContext.Provider>
  );
}

export default TemplatesListContextProvider;
