import useInitialPendingChangesQuery from '@/hooks/inventory/queries/singletones/useInitialPendingChangesQuery';
import usePendingChangesQuery from '@/hooks/inventory/queries/singletones/usePendingChangesQuery';
import useGetMultipleTicketGroupsUpdatedQuery from '@/hooks/inventory/queries/singletones/useGetMultipleTicketGroupsUpdatedQuery';
import useInventoryStoreEffects from '@/hooks/inventory/useInventoryStoreEffects';

function useInventoryData() {
  useInitialPendingChangesQuery();
  usePendingChangesQuery();
  useGetMultipleTicketGroupsUpdatedQuery();

  useInventoryStoreEffects();
}

export default useInventoryData;
