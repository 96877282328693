import { useEffect, useState } from 'react';
import { ErrorPage } from './errors';
import i18n from '@/utils/i18n';
import type { PredefinedMenuItem } from '@/interfaces/CommonInterfaces';
import { IsPathAuthorized } from '@/utils/helpers';
import { useCommonContext } from '@/context/common/context-hooks';

interface IProps {
  routeProps: PredefinedMenuItem;
  isRouteDenied?: boolean;
}

function RoleBasedAccRoute({ routeProps, isRouteDenied }: IProps) {
  const [returnedRoute, setReturnedRoute] = useState<JSX.Element | null>(null);
  const { rbacFeatures } = useCommonContext();

  useEffect(() => {
    if (!rbacFeatures?.menuItems || rbacFeatures?.menuItems.length === 0) {
      return;
    }
    const { component: Component, key: routeKey } = routeProps;

    setReturnedRoute(
      !isRouteDenied && IsPathAuthorized(rbacFeatures?.menuItems, routeKey) ? (
        Component
      ) : (
        <ErrorPage
          errorCode={i18n.t('pageNotFound.code')}
          title={i18n.t('pageNotFound.title')}
          description={i18n.t('pageNotFound.description')}
          forceNotFound={true}
        />
      ),
    );
  }, [routeProps, rbacFeatures?.menuItems, isRouteDenied]);

  return returnedRoute;
}

export default RoleBasedAccRoute;
