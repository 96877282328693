export type AuthApiHeaders = {
  Authorization?: string;
};

export const AuthApiFetcherOptions = {
  headers: {
    Authorization: undefined,
  },
};

export const queryKeyFn = <
  QueryOperation extends {
    path: string;
    operationId: string;
    variables: any;
  },
>(
  operation: QueryOperation,
) => {
  const queryKey: unknown[] = hasPathParams(operation)
    ? operation.path
        .split('/')
        .filter(Boolean)
        .map((i) => resolvePathParam(i, operation.variables.pathParams))
    : operation.path.split('/').filter(Boolean);

  if (hasQueryParams(operation)) {
    queryKey.push(operation.variables.queryParams);
  }

  if (hasBody(operation)) {
    queryKey.push(operation.variables.body);
  }

  return queryKey;
};
// Helpers
const resolvePathParam = (key: string, pathParams: Record<string, string>) => {
  if (key.startsWith('{') && key.endsWith('}')) {
    return pathParams[key.slice(1, -1)];
  }
  return key;
};

interface IQueryOperation {
  path: string;
  operationId: string;
  variables: any;
}
const hasPathParams = <QueryOperation extends IQueryOperation>(
  operation: QueryOperation,
): operation is QueryOperation & {
  variables: { pathParams: Record<string, string> };
} => {
  return Boolean((operation.variables as any).pathParams);
};

const hasBody = <QueryOperation extends IQueryOperation>(
  operation: QueryOperation,
): operation is QueryOperation & {
  variables: { body: Record<string, unknown> };
} => {
  return Boolean((operation.variables as any).body);
};

const hasQueryParams = <QueryOperation extends IQueryOperation>(
  operation: QueryOperation,
): operation is QueryOperation & {
  variables: { queryParams: Record<string, unknown> };
} => {
  return Boolean((operation.variables as any).queryParams);
};
