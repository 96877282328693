import { Outlet, useLocation, useNavigation } from 'react-router-dom';
import { ToastsNotificationOverlay, WarningDialog } from './common/notifications';
import i18n from '@/utils/i18n';
import clsx from 'clsx';
import { useUserApi, useUserState } from '@/context/user/context-hooks';
import { useCommonContextApi, useCommonContext } from '@/context/common/context-hooks';
import NavigationMenu from '@/components/NavigationMenu';
import { useEffect } from 'react';

function Layout() {
  const routerNavigation = useNavigation();
  const { pathname } = useLocation();

  const { setUserLeavesEditMode, runUserDropChangesFn } = useCommonContextApi();
  const { stateHasUnsavedChanges, userLeavesEditMode, routerBlocker } = useCommonContext();
  const {
    preferences: { navigation, restorePage },
  } = useUserState();
  const { setPreferences } = useUserApi();
  const isFloatingNavigation = navigation.isFloating;

  useEffect(() => {
    setPreferences('restorePage', { ...restorePage, pathname });
  }, [pathname]);

  const renderPageContent = () => {
    return (
      <div id="page" className={routerNavigation.state === 'loading' ? 'loading' : 'h-full w-full'}>
        <Outlet />
      </div>
    );
  };

  return (
    <div className="max-h-screen w-screen bg-bck-nav selection:bg-overlay selection:text-txt-primary">
      <NavigationMenu />

      <div
        className={clsx(styles.content, {
          'h-[calc(100%-2.25rem)] translate-y-9': !isFloatingNavigation,
          'translate-y-0': isFloatingNavigation,
        })}
      >
        {renderPageContent()}
      </div>
      <ToastsNotificationOverlay />

      <WarningDialog
        title={i18n.t('dialogs.pricingProgressWarning.title')}
        description={i18n.t('dialogs.pricingProgressWarning.description')}
        statement={i18n.t('dialogs.pricingProgressWarning.statement')}
        proceedCTA={i18n.t('dialogs.pricingProgressWarning.okCTA').toUpperCase()}
        isOpen={(stateHasUnsavedChanges && userLeavesEditMode.userLeaves) || routerBlocker?.state === 'blocked'}
        onCancel={() => {
          setUserLeavesEditMode({ userLeaves: false });
          if (routerBlocker?.state === 'blocked') {
            routerBlocker.reset?.();
          }
        }}
        onProceed={() => {
          runUserDropChangesFn();
          if (routerBlocker?.state === 'blocked') {
            routerBlocker.proceed?.();
          }
        }}
      />

      <form id="noValidateForm" noValidate />
    </div>
  );
}

const styles = {
  content: 'fixed bottom-0 top-0 left-0 right-0 flex flex-col transition-all duration-300 ease-in-out',
  sidebarOpen: 'left-48',
};

export default Layout;
