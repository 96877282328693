import { reducer as pricingCriteriaReducer } from '@/context/pricing-mode/reducers/pricingCriteriaReducer';

import { INITIAL_STATE } from '@/context/pricing-mode/reducer-constants';
import type {
  CustomSharingMethod,
  HitCeilingPricingMethod,
  HitFloorPricingMethod,
  HitNoCompPricingMethod,
  RuleDto,
  ShareOptionTypes,
} from '@/api/pricer/pricerApiSchemas';
import type { ActionsSlice, ImmerStateCreator } from '@/utils/constants';
import { groupShareOptionsTypes } from '@/utils/constants';
import type { InventoryState } from '@/stores/inventory/inventory-store';

export type PricingSlice = typeof INITIAL_STATE;

type PricingActions = {
  dispatch: (payload: any) => void;
};

export type PricingSliceState = PricingSlice & ActionsSlice<PricingActions>;

export const createPricingSlice: ImmerStateCreator<InventoryState, PricingSliceState> = (set) => ({
  ...INITIAL_STATE,
  actions: {
    dispatch: (payload) =>
      set(
        (state) => {
          state.pricingSlice = {
            ...state.pricingSlice,
            ...pricingCriteriaReducer(state.pricingSlice, payload),
          };
        },
        false,
        {
          type: `inv:pricing/dispatch:action-${payload.type}`,
        },
      ),
  },
});

export const INTERNAL_generateRuleSet = (slice: PricingSlice): RuleDto => {
  const {
    adjustment,
    unit,
    adjustmentAmount,
    groupUnit,
    groupAdjustment,
    groupAdjustmentAmount,
    groupShareOption,
    isSharingActive,
    isAnchorShared,
    floorPricingMethod,
    ceilingPricingMethod,
    noCompPricingMethod,
    shareTicketGroupsUnit,
    shareTicketGroupsAmount,
  } = slice;

  const hasCustomSharing = groupShareOption.key === groupShareOptionsTypes.CUSTOM;

  return {
    increment: adjustment.key,
    incrementMethod: unit.key,
    amount: Number(adjustmentAmount.value),
    offsetAmount: Number(groupAdjustmentAmount.value ?? 0),
    offsetIncrement: groupAdjustment.key,
    offsetIncrementMethod: groupUnit.key,
    shareOptionsType: isSharingActive ? (groupShareOption.key as ShareOptionTypes) : undefined,
    enableShareOptions: isSharingActive,
    customSharingMethod:
      hasCustomSharing && shareTicketGroupsUnit.key ? (shareTicketGroupsUnit.key as CustomSharingMethod) : undefined,
    customSharingAmount:
      hasCustomSharing && shareTicketGroupsAmount.value ? Number(shareTicketGroupsAmount.value) : undefined,
    shareAnchor: isSharingActive && isAnchorShared,
    floorPricingMethod: floorPricingMethod as HitFloorPricingMethod,
    ceilingPricingMethod: ceilingPricingMethod as HitCeilingPricingMethod,
    noCompPricingMethod: noCompPricingMethod as HitNoCompPricingMethod,
  };
};
