import type { ComponentType, InputHTMLAttributes } from 'react';
import React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5';
import type { IconBaseProps } from 'react-icons';
import clsx from 'clsx';
import type { VariantProps } from 'cva';
import { cva } from 'cva';
import Tooltip from '@/components/common/tooltip/Tooltip';
import { WarningFillIcon } from '@/components/icons';
import type { Placement } from '@floating-ui/react';
import { TooltipPosition } from '@/components/common/tooltip/constants';

export type TextInputVariantsType = 'outline' | 'filled' | 'clean' | 'wrapped';
export type TextInputSizesType = 'xs' | 'sm' | 'md' | 'lg';
export type TextInputColor = 'main' | 'error' | 'warning' | 'info';
export type TextInputMargin = 'none' | 'dense' | 'normal';

const numericPadProps = {
  pattern: '[0-9]*',
  inputMode: 'numeric',
} as const;

export interface TextInputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'>,
    VariantProps<typeof textInputVariants> {
  label?: string;
  LeadingIcon?: ComponentType<IconBaseProps>;
  LeadingContent?: ComponentType;
  TrailingPeerContent?: ComponentType;
  classNameContent?: string;
  classNameInput?: string;
  classNameIcon?: string;
  isUnderlineVisible?: boolean;
  onEnter?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onEscape?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  inputRef?: React.Ref<HTMLInputElement>;
  componentRef?: React.Ref<HTMLDivElement>;
  tooltip?: string;
  labelInline?: boolean;
  toolTipPosition?: Placement;
  color?: TextInputColor;
  colorText?: string;
  colorTextAsTooltip?: boolean;
  margin?: TextInputMargin;
  fullWidth?: boolean;
  preventBrowserValidation?: boolean;
}

function TextInput({
  label,
  LeadingIcon,
  LeadingContent,
  TrailingPeerContent,
  classNameContent = '',
  classNameInput = '',
  classNameIcon = '',
  isUnderlineVisible = true,
  onEnter,
  onEscape,
  inputRef,
  componentRef,
  tooltip,
  labelInline,
  size = 'lg',
  variant = 'filled',
  color = 'main',
  colorText,
  colorTextAsTooltip = true,
  margin,
  fullWidth = true,
  textRight,
  optional,
  className = '',
  toolTipPosition = TooltipPosition.Bottom,
  preventBrowserValidation = true,
  ...props
}: TextInputProps) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const { id, disabled, onChange, type, autoComplete = 'off' } = props;

  const isOutlined = variant === 'outline' || variant === 'wrapped';
  const showUnderline = isUnderlineVisible && !isOutlined;

  const inputStyle = useMemo(
    () => clsx(classNameInput, { 'pl-8': LeadingIcon, [styles.inputPassword]: type === 'password' }),
    [classNameInput, type, LeadingIcon],
  );

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select();
  };

  const overwrittenType = useMemo(() => {
    let useType = type;
    if (isPasswordVisible) {
      useType = 'text';
    }
    // fixes multiple issues with numeric types
    if (type === 'number') {
      useType = 'text';
    }
    return useType;
  }, [isPasswordVisible, type]);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (disabled) return;
      if (onEnter && (e.key === 'Enter' || e.key === 'Return')) {
        onEnter(e);
        return;
      } else if (onEscape && e.key === 'Escape') {
        onEscape(e);
        return;
      }

      // allow clipboard shortcuts
      if (/|c|v|x/.test(e.key) && (e.ctrlKey || e.metaKey)) return;

      if (type === 'number' && !/[0-9]|\.|Backspace|Tab|Enter|Escape|Delete|ArrowLeft|ArrowRight/.test(e.key)) {
        e.preventDefault();
      }
    },
    [onEnter, onEscape, type],
  );

  const tooltipMessage = color !== 'main' && colorTextAsTooltip && colorText ? colorText : tooltip;
  const enabledColor = disabled ? 'main' : color;

  return (
    <>
      <div ref={componentRef} className={clsx({ 'cursor-not-allowed': disabled }, className)}>
        <div className={contentVariants({ margin, className: classNameContent })}>
          <div className={clsx('relative flex h-full items-center', { 'pointer-events-none': disabled })}>
            {LeadingContent && <LeadingContent />}
            {LeadingIcon && (
              <div className={styles.leadingIconContainer}>
                <LeadingIcon className={clsx(styles.leadingIcon, classNameIcon)} aria-hidden="true" />
              </div>
            )}
            <Tooltip message={tooltipMessage} position={toolTipPosition} disabled={!tooltipMessage}>
              <div
                className={clsx('flex', {
                  'flex-row items-center gap-1 px-2': labelInline,
                  'flex-col': !labelInline,
                  'w-full': fullWidth,
                })}
              >
                <input
                  ref={inputRef}
                  autoComplete={autoComplete}
                  // ignores 1Password autofill
                  {...(autoComplete === 'off' && { ['data-1p-ignore']: 'true' })}
                  {...(type === 'number' && numericPadProps)}
                  onKeyDown={onKeyDown}
                  onChange={(e) => !disabled && onChange && onChange(e)}
                  onWheel={(e) => (e.target as HTMLElement).blur()}
                  onFocus={handleFocus}
                  className={textInputVariants({
                    variant,
                    size,
                    color: enabledColor,
                    textRight,
                    optional,
                    className: inputStyle,
                  })}
                  {...props}
                  {...(preventBrowserValidation && { form: 'noValidateForm' })}
                  type={overwrittenType}
                />
                {label && (
                  <label htmlFor={id} className={labelVariants({ variant, color: enabledColor, size })}>
                    {label}
                  </label>
                )}
                {showUnderline && <div className={underLineVariants({ color })} aria-hidden="true" />}
                {TrailingPeerContent && (
                  <div className="order-3">
                    <TrailingPeerContent />
                  </div>
                )}
              </div>
            </Tooltip>
            {type === 'password' && (
              <div className={styles.passwordButton} onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
                {isPasswordVisible ? (
                  <IoEyeOffOutline className={styles.passwordIcon} aria-hidden="true" />
                ) : (
                  <IoEyeOutline className={styles.passwordIcon} aria-hidden="true" />
                )}
              </div>
            )}
          </div>
          {!colorTextAsTooltip && colorText && (
            <div className={colorTextVariants({ color })} id="input-error">
              <WarningFillIcon className={'mr-1 h-3.5 w-3.5'} />
              {colorText}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

const styles = {
  inputPassword: 'pr-10',
  passwordButton: 'cursor-pointer absolute inset-y-0 right-0 flex items-center pr-3',
  passwordIcon: 'h-6 w-6 text-action-active',
  leadingIcon: 'h-5 w-5 text-txt-secondary mt-4 fill-txt-disabled',
  leadingIconContainer: 'pointer-events-none absolute inset-y-0 left-0 flex items-center pl-1',
};

const contentVariants = cva('relative flex flex-col', {
  variants: {
    margin: {
      none: 'my-0',
      dense: 'mt-1',
      normal: 'my-3',
    },
  },
  defaultVariants: {
    margin: 'normal',
  },
});

const textInputVariants = cva(
  'block order-1 w-full text-txt-primary placeholder-txt-disabled disabled:opacity-50 focus:outline-none focus:ring-0 peer selection:bg-bck-input-highlight',
  {
    variants: {
      variant: {
        outline: 'bg-transparent py-0.5 px-0.5 rounded-sm border focus:box-shadow-none ',
        filled: 'bg-filled-input-bg rounded-t-md shadow-sm border-0',
        clean: 'bg-transparent px-0 border-0 hover:shadow-none',
        wrapped:
          'flex items-center px-0 border-0 focus:border-0 focus:ring-0 hover:border-0 hover:ring-0 outline-none focus:outline-none',
      },
      size: {
        xs: 'h-5 text-xs',
        sm: 'h-[1.625rem] px-1 text-xs',
        md: 'h-8 px-3 text-md',
        lg: 'h-[3.25rem] px-3 text-md',
      },
      color: {
        main: 'border-input-border focus:border-primary-main',
        error: 'border-error-main focus:border-error-main',
        warning: 'border-warning-main focus:border-warning-main',
        info: 'border-info-main focus:border-info-main',
      },
      textRight: {
        true: 'text-right',
      },
      optional: {
        true: 'border-dashed',
      },
    },
    compoundVariants: [
      { variant: 'filled', size: 'xs', className: 'px-1' },
      { variant: 'filled', size: 'lg', className: 'pt-6 pb-1' },
    ],
    defaultVariants: {
      variant: 'filled',
      size: 'lg',
      color: 'main',
    },
  },
);

const labelVariants = cva('block text-xs leading-3 mb-1 font-normal whitespace-nowrap', {
  variants: {
    variant: {
      outline: '',
      filled: '',
      clean: '',
      wrapped: '',
    },
    size: {
      xs: '',
      sm: '',
      md: '',
      lg: '',
    },
    color: {
      main: 'text-txt-secondary peer-focus:text-primary-main',
      error: 'text-error-main',
      warning: 'text-warning-main',
      info: 'text-info-main',
    },
  },
  compoundVariants: [
    {
      variant: 'filled',
      size: 'lg',
      className:
        'absolute -top-0.5 left-0 h-full px-2 py-5 order-2 transition-all duration-100 ease-in-out origin-left transform translate-x-1 -translate-y-3 opacity-75 pointer-events-none',
    },
  ],
  defaultVariants: {},
});

const underLineVariants = cva('absolute inset-x-0 bottom-0 border-t', {
  variants: {
    color: {
      main: 'border-input-border peer-focus:border-t-2 peer-focus:border-primary-main',
      error: 'border-error-main peer-focus:border-error-main',
      warning: 'border-warning-main peer-focus:border-warning-main',
      info: 'border-info-main peer-focus:border-info-main',
    },
  },
});

const colorTextVariants = cva('flex items-center text-xs truncate text-wrap', {
  variants: {
    variant: {
      outline: 'mt-1',
      filled: '',
      clean: '',
    },
    color: {
      main: 'hidden',
      error: 'text-error-main',
      warning: 'text-warning-main',
      info: 'hidden',
    },
  },
});

export default TextInput;
