import { lazy } from 'react';
import ResetPasswordHandler from './ResetPasswordHandler';
import RoleBasedAccRoute from './RoleBasedAccRoute';
const Events = lazy(() => import('./Events'));
const Inventory = lazy(() => import('./Inventory'));
const OrganizationRequest = lazy(() => import('./OrganizationRequest'));
const ValidateInvitation = lazy(() => import('./ValidateInvitation'));
const ForgotPassword = lazy(() => import('./ForgotPassword'));
const Profile = lazy(() => import('./profile'));
const Settings = lazy(() => import('./settings'));
const PaymentsAndBilling = lazy(() => import('./PaymentsAndBilling'));
const TemplatesList = lazy(() => import('./templates/TemplatesList'));
const POSPage = lazy(() => import('./settings/POSPage'));
const Billing = lazy(() => import('./settings/Billing'));
const Marketplace = lazy(() => import('./settings/Marketplace'));
const TemplatePage = lazy(() => import('./templates/TemplatePage'));
const Users = lazy(() => import('./settings/Users/Users'));
const Subscription = lazy(() => import('./Subscription'));
const CompanyInfo = lazy(() => import('./settings/CompanyInfo'));
const MarketComparables = lazy(() => import('./OrgDefault/MarketComparables'));
const Pricing = lazy(() => import('./OrgDefault/Pricing'));
const OrgDefaults = lazy(() => import('./OrgDefault'));
const Components = lazy(() => import('./Components'));

export {
  Events,
  PaymentsAndBilling,
  ResetPasswordHandler,
  ForgotPassword,
  Inventory,
  OrganizationRequest,
  ValidateInvitation,
  Profile,
  Settings,
  TemplatesList,
  POSPage,
  Billing,
  Marketplace,
  TemplatePage,
  Users,
  Subscription,
  CompanyInfo,
  RoleBasedAccRoute,
  MarketComparables,
  Pricing,
  OrgDefaults,
  Components,
};
