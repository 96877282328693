import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { init as initFullStory } from '@fullstory/browser';

import SbLoginApp from './utils/SbLoginApp';
import { AppModeIsDrive } from './utils/constants';
import { appInsights } from '@/appinsights';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const url = new URL(window.location.href);

window.addEventListener('vite:preloadError', () => {
  appInsights.trackEvent({ name: 'vite:preloadError' });
  window.location.reload(); // in case new version is released, we will need to refresh the page. see https://vitejs.dev/guide/build#load-error-handling
});

if (AppModeIsDrive && url.pathname === '/sblogin') {
  root.render(
    <React.StrictMode>
      <SbLoginApp />
    </React.StrictMode>,
  );
} else {
  const orgId = import.meta.env.REACT_APP_FULLSTORY_ORG;

  initFullStory(
    {
      orgId: orgId ?? '',
      devMode: import.meta.env.DEV,
    },
    ({ sessionUrl }: { sessionUrl: string }) => console.log(`Started session: ${sessionUrl}`),
  );

  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
}
