import React, { createContext } from 'react';
import type { IPos } from '@/interfaces/pos/IPos';
import type { IPosConnectionTest } from '@/interfaces/pos/IPosConnectionTest';
import type { IPosCredential } from '@/interfaces/pos/IPosCredential';

interface IPosApi {
  refetchPosAll: () => void;
  addPos: (pos: IPosCredential, callback: (isSuccess: boolean) => void, isAnUpdate?: boolean) => void;
  updatePos: (pos: IPosCredential, callback: (isSuccess: boolean) => void) => void;
  resetMutationState: () => void;
  testPos: (pos: IPosCredential, callback: (isSuccess: boolean) => void) => void;
  testExistingPos: (pos: IPos, callback?: (isSuccess: boolean) => void) => void;
  deletePOS: (pos: IPos, callback?: (isSuccess: boolean) => void) => void;
}

interface IPosState {
  posIntegrations: IPos[];
  posIntegrationMap: Map<string, IPos>;
  isLoading: boolean;
  isSavingError: boolean;
  noPos: boolean;
  pendingConnectionsTests: IPosConnectionTest[];
}

const PosStateContext = React.createContext<IPosState | undefined>(undefined);
const PosApiContext = createContext<IPosApi | undefined>(undefined);

function usePosStateContext() {
  const context = React.useContext(PosStateContext);
  if (context === undefined) {
    throw new Error('usePosStateContext must be used within a PosStateContext');
  }
  return context;
}

function usePosApiContext() {
  const context = React.useContext(PosApiContext);
  if (context === undefined) {
    throw new Error('usePosApiContext must be used within a PosApiContext');
  }
  return context;
}

export { PosStateContext, PosApiContext, usePosStateContext, usePosApiContext };
export type { IPosState, IPosApi };
