import { useEffect, useMemo, useState } from 'react';
import { fetchGetPricingTemplateProductions, useGetPricingTemplate } from '@/api/pricer/pricerApiComponents';
import type { GetPricingTemplateProductionsQueryParams } from '@/api/pricer/pricerApiComponents';
import type { columnSortType } from '@/context/pricer/context-hooks';
import { columnIds } from '@/routes/templates/templatePage/productionsForTemplateColumnIds';
import { DefaultDateRangeForEvents, SERVER_DATE_FORMAT } from '@/utils/constants';
import { format } from 'date-fns';
import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import type {
  PricingTemplateProductionResponseDto,
  PricingTemplateProductionResponseDtoPagedDto,
  ProductionRequestSortColumn,
} from '@/api/pricer/pricerApiSchemas';
import { AuthApiFetcherOptions } from '@/api/AuthApiContext';
import { clone } from '@/utils/clone';
import useDebounce from '@/hooks/useDebounce';

function useProductionsForTemplate() {
  const [selectedProductionIds, setSelectedProductionIds] = useState<number[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debounceSearchTerm = useDebounce(searchTerm, 500);

  const [pricingTemplateId, setPricingTemplateId] = useState<number | undefined>(0);
  const {
    data: pricingTemplate,
    isFetching: isPricingTemplateFetching,
    isError: pricingTemplateError,
  } = useGetPricingTemplate(
    {
      pathParams: {
        pricingTemplateId: pricingTemplateId || 0,
      },
    },
    {
      enabled: !!pricingTemplateId,
    },
  );
  const [showUnavailableProductions, setShowUnavailableProductions] = useState<boolean>(false);
  const [sortColumns, setSortColumnsState] = useState<columnSortType>({
    columnName: columnIds.eventDate.dbName,
    sortDirection: 'Asc',
  });

  const [selectedDateRange, setSelectedDateRangeState] = useState<Date[]>(DefaultDateRangeForEvents);
  const [pricingTemplateProductionsQueryParams, setPricingTemplateProductionsQueryParams] =
    useState<GetPricingTemplateProductionsQueryParams>({
      PageNumber: 1,
      // one page for 500 productions per venue. This is intentional, we want to:
      // - fetch all templates at once to have the
      // - Select all + filter on not allowed productions
      PageSize: 500,
      SortColumn: 'EventDate',
      SortOrder: 'Asc',
      StartDate: format(selectedDateRange[0], SERVER_DATE_FORMAT),
      EndDate: format(selectedDateRange[1], SERVER_DATE_FORMAT),
    });

  const {
    data,
    isFetching: isProductionsPerTemplateIdFetching,
    hasNextPage: hasNextPageProductionsPerTemplateId,
    fetchNextPage: fetchNextPageProductionsPerTemplateId,
    refetch: refetchPricingTemplateProductions,
  } = useInfiniteQuery<PricingTemplateProductionResponseDtoPagedDto>({
    queryKey: [
      'pricing-templates-productions',
      {
        pricingTemplateId: pricingTemplateId || 0,
        ...pricingTemplateProductionsQueryParams,
      },
    ],
    queryFn: ({ signal, pageParam }: any) => {
      return fetchGetPricingTemplateProductions(
        {
          ...AuthApiFetcherOptions,
          pathParams: {
            pricingTemplateId: pricingTemplateId || 0,
          },
          queryParams: {
            ...pricingTemplateProductionsQueryParams,
            PageNumber: pageParam || pricingTemplateProductionsQueryParams.PageNumber,
          },
        },
        signal,
      );
    },
    getNextPageParam: (lastPage, allPages) => {
      if (allPages.length < (lastPage.totalPages || 0)) {
        return allPages.length + 1;
      } else return null;
    },
    initialPageParam: 1,
    placeholderData: keepPreviousData,
    enabled: !!pricingTemplateId,
  });
  const productionsPerTemplateId = useMemo(() => {
    if (!data) {
      return [];
    }
    const { pages } = data;

    return (
      pages
        ?.flatMap((page: any) => page.data)
        ?.filter((p) => showUnavailableProductions || p.allowPricingGroupForTemplate) ??
      ([] as PricingTemplateProductionResponseDto[])
    );
  }, [data, showUnavailableProductions]);

  const totalRecords = data?.pages[0].totalFilteredRecords;

  const setSelectedDateRange = (range: Date[]) => {
    setSelectedDateRangeState(range);
    setPricingTemplateProductionsQueryParams((prev) => {
      return {
        ...prev,
        StartDate: format(range[0], SERVER_DATE_FORMAT),
        EndDate: format(range[1], SERVER_DATE_FORMAT),
        PageNumber: 1,
      };
    });
  };

  const setSortColumns = (columns: columnSortType) => {
    setSortColumnsState(columns);
    setPricingTemplateProductionsQueryParams((prev) => {
      const queryParams: GetPricingTemplateProductionsQueryParams =
        clone<GetPricingTemplateProductionsQueryParams>(prev);
      for (const key in prev) {
        if (key.startsWith('Sorting')) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          delete queryParams[key];
        }
      }

      return {
        ...queryParams,
        SortColumn: columns.columnName as ProductionRequestSortColumn,
        SortOrder: columns.sortDirection,
        PageNumber: 1,
      };
    });
  };

  const sortedSelectedProductionIds = useMemo(() => {
    return productionsPerTemplateId
      .filter((p) => selectedProductionIds.includes(p.productionId))
      .map((p) => p.productionId);
  }, [selectedProductionIds]);

  useEffect(() => {
    setPricingTemplateProductionsQueryParams({
      ...pricingTemplateProductionsQueryParams,
      Filter: debounceSearchTerm,
      PageNumber: 1,
    });
  }, [debounceSearchTerm]);

  return {
    productionsPerTemplateId,
    totalRecords,
    pricingTemplateId,
    setPricingTemplateId,
    setPricingTemplateProductionsQueryParams,
    setSortColumns,

    hasNextPageProductionsPerTemplateId,
    fetchNextPageProductionsPerTemplateId,
    refetchPricingTemplateProductions,

    setSelectedDateRange,

    setShowUnavailableProductions,

    pricingTemplate,
    isPricingTemplateFetching,
    pricingTemplateProductionsQueryParams,
    isProductionsPerTemplateIdFetching,
    pricingTemplateError,
    sortColumns,
    selectedDateRange,
    showUnavailableProductions,
    setSelectedProductionIds,
    searchTerm,
    setSearchTerm,

    sortedSelectedProductionIds,
    selectedProductionIds,
  };
}

export default useProductionsForTemplate;
