import { useGetClientUser } from '@/api/clientService/clientServiceApiComponents';

function useGetOrganizationUsers() {
  const {
    data: usersData,
    isPending: isUsersLoading,
    isFetching: isUsersFetching,
    isSuccess: usersLoadSuccess,
    refetch: refetchUsers,
  } = useGetClientUser({}, { refetchOnMount: false });

  return { usersData, isUsersLoading, isUsersFetching, usersLoadSuccess, refetchUsers };
}

export default useGetOrganizationUsers;
