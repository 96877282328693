import type { PricingTemplateInventoryResponseDto } from '@/api/pricer/pricerApiSchemas';

export const ProductionNoTicketsTicketId = -1;
export const ProductionInventoryFailed = -2;
export const PricingGroupRequestRanksFailedToLoad = -3;
export const ProductionInventoryLoading = -4;

export const IsTicketGroupSelectable = (ticketGroup: PricingTemplateInventoryResponseDto) => {
  return (
    'suggestedRank' in ticketGroup &&
    ticketGroup.suggestedRank !== null &&
    ticketGroup.ticketGroupId !== ProductionNoTicketsTicketId
  );
};
