import { useCallback, useEffect, useRef } from 'react';
import { environment } from '@/utils/envConfig';

interface ITitleProps {
  title?: string;
  setOldTitleOnUnmount?: boolean;
}

interface ITitleResponse {
  title: string | undefined;
  setTitle: (newTitle: string) => void;
}

const useTitle: (props?: ITitleProps) => ITitleResponse = (
  props = { title: undefined, setOldTitleOnUnmount: true },
) => {
  const { title, setOldTitleOnUnmount } = props;
  const initialTitle = useRef<string>();

  let envTitle = '';
  if (!!environment && environment !== 'prod') {
    envTitle = `[${environment}] `;
  }
  const setTitle = useCallback((title: string | undefined) => {
    document.title = `${envTitle}${title || ''}`;
  }, []);

  useEffect(() => {
    initialTitle.current = document.title;
  }, []);

  useEffect(() => {
    if (title) setTitle(title);
    return () => {
      if (setOldTitleOnUnmount) setTitle(initialTitle.current);
    };
  }, [setTitle, title, initialTitle.current, setOldTitleOnUnmount]);

  return {
    title,
    setTitle,
  };
};

export default useTitle;
