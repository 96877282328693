import PageLayout from '../../components/PageLayout';

function Notifications() {
  return (
    <PageLayout>
      <div>Notifications</div>
    </PageLayout>
  );
}

export default Notifications;
