import { useEffect, useRef } from 'react';

function useRecursiveTimeout<T>(callback: () => Promise<T> | (() => void), delay: number | null) {
  const savedCallback = useRef(callback);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  // Set up the timeout loop.
  useEffect(() => {
    let id: NodeJS.Timeout;
    const cancel = () => {
      id && clearTimeout(id);
    };
    const callAfterDelay = () => {
      if (delay !== null) {
        id = setTimeout(tick, delay);
      } else {
        cancel();
      }
    };

    function tick() {
      const ret = savedCallback.current();

      if (ret instanceof Promise) {
        ret.then(() => {
          callAfterDelay();
        });
      } else {
        ret();
        callAfterDelay();
      }
    }

    if (delay !== null) {
      callAfterDelay();
      return () => cancel();
    } else {
      cancel();
    }
  }, [delay]);
}

export default useRecursiveTimeout;
