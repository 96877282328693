import useGetOrganization from '@/hooks/organization/useGetOrganization';
import useGetLoggedUser from '@/hooks/user/useGetLoggedUser';
import { useEffect, useState } from 'react';
import { initiateAppInsights } from '@/appinsights';

function useAppInsights() {
  const orgQuery = useGetOrganization();
  const userProfile = useGetLoggedUser();

  const [isInitiated, setIsInitiated] = useState(false);
  useEffect(() => {
    if (isInitiated) return;
    if (orgQuery.organization && userProfile.user) {
      setIsInitiated(true);

      initiateAppInsights({ user: userProfile.user, organization: orgQuery.organization });
    }
  }, [orgQuery.organization, userProfile.user]);
}

export default useAppInsights;
