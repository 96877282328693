import type { QueryKey, UseQueryOptions } from '@tanstack/react-query';
import type { QueryOperation } from './notificationApiComponents';
import type { AuthApiHeaders } from '../AuthApiContext';
import { queryKeyFn } from '../AuthApiContext';
import { AuthApiFetcherOptions } from '../AuthApiContext';

export type NotificationApiContext = {
  fetcherOptions: {
    /**
     * Headers to inject in the fetcher
     */
    headers?: AuthApiHeaders;
    /**
     * Query params to inject in the fetcher
     */
    queryParams?: {};
  };
  queryOptions: {
    /**
     * Set this to `false` to disable automatic refetching when the query mounts or changes query keys.
     * Defaults to `true`.
     */
    enabled?: boolean | ((query: any) => boolean);
  };
  /**
   * Query key manager.
   */
  queryKeyFn: (operation: QueryOperation) => QueryKey;
};

/**
 * Context injected into every react-query hook wrappers
 *
 * @param queryOptions options from the useQuery wrapper
 */
export function useNotificationApiContext<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryOptions?: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>,
): NotificationApiContext {
  return {
    fetcherOptions: AuthApiFetcherOptions,
    queryOptions: {
      enabled: queryOptions?.enabled,
    },
    queryKeyFn: queryKeyFn<QueryOperation>,
  };
}
