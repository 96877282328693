import type { LegacyRef, ReactNode } from 'react';
import { forwardRef } from 'react';

interface iProps {
  children: ReactNode;
  zIndex?: number;
}
const NavMenuInvisiblePositioner = forwardRef(({ children, zIndex = 10 }: iProps, ref) => {
  return (
    <div ref={ref as unknown as LegacyRef<HTMLDivElement>} className={`pointer-events-none fixed inset-0 z-${zIndex}`}>
      {children}
    </div>
  );
});

export default NavMenuInvisiblePositioner;
