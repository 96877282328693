import type { IUserUpdateRequest } from '@/interfaces/Users/IUserUpdateRequest';
import type { INotification } from '@/interfaces/CommonInterfaces';
import type { IUserPreferences } from '@/interfaces/Users/IUserPreferences';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useMemo, useState } from 'react';
import { apiEndpoints, putAuthAPI } from '@/utils/api';
import { stringifyReplacer } from '@/utils/helpers';
import i18n from '@/utils/i18n';
import { getErrorNotification, getLoadingNotification, getSuccessNotification } from '@/utils/notifications';
import { useNotificationsApi } from '../notifications/context-hooks';
import { UserApiContext, UserStateContext } from './context-hooks';
import { useDebounce, usePrevious } from '@/hooks';
import { useGetUserSettings, useSaveUserSettings } from '@/api/clientService/clientServiceApiComponents';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  DEFAULT_LOCAL_PREFERENCES,
  DEFAULT_PREFERENCES,
  arePrefsEqual,
  combineServerAndLocalPrefs,
  getLocalStoragePrefs,
  getUserSessionPrefs,
  saveLocalStoragePreferences,
  saveUserSessionPreferences,
} from './constants';
import type { ILocalStoragePreferences } from '@/interfaces/Users/ILocalStoragePreferences';
import type { ClientUserAppProfileResponse } from '@/api/clientService/clientServiceApiSchemas';
import useGetLoggedUser from '@/hooks/user/useGetLoggedUser';

function UserContextProvider({ children }: { children: React.ReactNode }) {
  const [userChosenPreferences, setUserChosenPreferences] = useState<Partial<IUserPreferences>>(getUserSessionPrefs);
  const [localStoragePreferences, setLocalStoragePreferences] =
    useState<ILocalStoragePreferences>(getLocalStoragePrefs);
  const [userPrefsDebounceFlag, setUserPrefsDebounceFlag] = useState(false);

  const { pushNotification } = useNotificationsApi();

  const preferences = useMemo(() => {
    return { ...DEFAULT_PREFERENCES, ...userChosenPreferences };
  }, [userChosenPreferences]);
  const localPreferences = useMemo(() => {
    return { ...DEFAULT_LOCAL_PREFERENCES, ...localStoragePreferences };
  }, [localStoragePreferences]);
  const [isNavigationMinimized, setNavigationMinimized] = useState(preferences.navigation.autoMinimize);

  const debouncedPreferences = useDebounce(userChosenPreferences, 3000);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const loggedUserQuery = useGetLoggedUser();

  const { mutate, isSuccess, isError, reset, error } = useMutation({
    mutationFn: (req: { profileRequest: IUserUpdateRequest; notification: INotification }) => {
      const { notification, profileRequest } = req;
      pushNotification(notification);
      return putAuthAPI(apiEndpoints.profile, profileRequest);
    },
    onSuccess: (_response, variables) => {
      const { notification } = variables;
      pushNotification(
        getSuccessNotification(
          notification.id,
          i18n.t('profile.notifications.success.title'),
          i18n.t('profile.notifications.success.message'),
          true,
        ),
      );
    },
    onError: (error, variables) => {
      const { notification } = variables;
      pushNotification(getErrorNotification(notification.id, error, true, i18n.t('profile.notifications.error.title')));
    },
  });

  const prevMutateSuccess = usePrevious(isSuccess);
  useEffect(() => {
    if (isSuccess && !prevMutateSuccess) {
      loggedUserQuery.refetch();
    }
  }, [isSuccess]);

  const {
    data: userServerPrefsData,
    refetch: fetchUserServerPrefs,
    isLoading: isLoadingUserPrefs,
    isFetchedAfterMount: isFetchedUserPrefsAfterMount,
  } = useGetUserSettings({}, { enabled: false });
  const { mutate: saveUserServerPrefs } = useSaveUserSettings({});

  useEffect(() => {
    fetchUserServerPrefs();
  }, []);

  useEffect(() => {
    saveLocalStoragePreferences(localStoragePreferences);
  }, [localStoragePreferences]);

  useEffect(() => {
    if (!userServerPrefsData) return;

    const newPrefs = combineServerAndLocalPrefs(userChosenPreferences, userServerPrefsData);
    const { restorePage } = newPrefs;
    if (pathname === '/' && restorePage?.pathname && restorePage?.pathname !== '/') {
      navigate(restorePage.pathname);
    }

    setUserChosenPreferences(newPrefs);
  }, [userServerPrefsData]);

  useEffect(() => {
    saveUserSessionPreferences(userChosenPreferences);
  }, [userChosenPreferences]);

  const savePrefsToServer = (newPrefs: { [x: string]: any }) => {
    const serverPreparedPrefs = Object.keys(newPrefs).map((key: string) => ({
      key: key,
      value: JSON.stringify(newPrefs[key], stringifyReplacer),
    }));

    if (arePrefsEqual(userServerPrefsData, serverPreparedPrefs)) return;

    saveUserServerPrefs({
      body: serverPreparedPrefs,
    });
  };

  const prevDebounceFlag = usePrevious(userPrefsDebounceFlag);
  useEffect(() => {
    if (!isFetchedUserPrefsAfterMount || !debouncedPreferences) return;
    if (prevDebounceFlag === userPrefsDebounceFlag) savePrefsToServer(debouncedPreferences);
  }, [debouncedPreferences]);

  const update = (user: ClientUserAppProfileResponse) => {
    reset();

    const { email, name, phone, username } = user;
    const profileRequest = {
      email,
      name,
      phone,
      username,
    } as IUserUpdateRequest;

    const notification = getLoadingNotification(
      'profileInfo',
      i18n.t('profile.notifications.applying.title'),
      i18n.t('profile.notifications.applying.message'),
    );

    mutate({ profileRequest, notification });
  };

  const api = useMemo(
    () => ({
      update,
      setPreferences: <K extends keyof IUserPreferences>(key: K, preference: IUserPreferences[K], debounce = true) => {
        setUserChosenPreferences((prevPrefs) => {
          const newPrefs = { ...prevPrefs, [key]: preference };
          if (!debounce) {
            setUserPrefsDebounceFlag((flag) => !flag);
            savePrefsToServer(newPrefs);
          }
          return newPrefs;
        });
      },
      setLocalPreferences: <K extends keyof ILocalStoragePreferences>(
        key: K,
        preference: ILocalStoragePreferences[K],
      ) => setLocalStoragePreferences({ ...localStoragePreferences, [key]: preference }),
      setNavigationMinimized,
    }),
    [],
  );
  return (
    <UserStateContext.Provider
      value={{
        user: loggedUserQuery.user,
        isError,
        error,
        isLoading: loggedUserQuery.isLoading,
        preferences,
        localPreferences,
        isNavigationMinimized,
        isLoadingUserPrefs,
      }}
    >
      <UserApiContext.Provider value={api}> {children}</UserApiContext.Provider>
    </UserStateContext.Provider>
  );
}

export { UserContextProvider };
