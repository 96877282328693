import type { IPos } from '@/interfaces/pos/IPos';
import type { INotification } from '@/interfaces/CommonInterfaces';
import type { IPosConnectionTest } from '@/interfaces/pos/IPosConnectionTest';
import type { IPosCredential } from '@/interfaces/pos/IPosCredential';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect, useMemo, useState } from 'react';
import {
  apiEndpoints,
  getPOSIntegrationAPI,
  postPOSIntegrationAPI,
  putPOSIntegrationAPI,
  deletePOSIntegrationAPI,
} from '@/utils/api';
import { getErrorNotification, getLoadingNotification, getSuccessNotification } from '@/utils/notifications';
import i18n from '@/utils/i18n';
import { ConnectionTestStatus } from '@/utils/constants';

import { useNotificationsApi } from '../notifications/context-hooks';
import { PosStateContext, PosApiContext } from './context-hooks';

function PosContextProvider({ children }: { children: React.ReactNode }) {
  const [posIntegrations, setPosIntegrations] = useState<IPos[]>([]);
  const [posIntegrationMap, setPosIntegrationMap] = useState<Map<string, IPos>>(new Map());
  const [pendingConnectionsTests, setPendingConnectionsTests] = useState<IPosConnectionTest[]>([]);
  const { pushNotification } = useNotificationsApi();

  const {
    data: posData,
    isPending,
    refetch: refetchPosAll,
  } = useQuery({
    queryKey: ['pos'],
    queryFn: () => getPOSIntegrationAPI(apiEndpoints.pos.all),
  });

  const handleSetConnectionTest = (posOrganizationId: number, status: ConnectionTestStatus) => {
    const connectionTest = { posOrganizationId, status };
    setPendingConnectionsTests((connectionTests) => {
      const index = connectionTests.findIndex((n) => n.posOrganizationId === connectionTest.posOrganizationId);
      if (index > -1) {
        connectionTests[index] = connectionTest;
        return [...connectionTests];
      }
      connectionTests.push(connectionTest);
      return [...connectionTests];
    });
  };

  const { mutate: testExistingPos } = useMutation({
    mutationFn: (req: { pos: IPos; notification: INotification; callback?: (isSuccess: boolean) => void }) => {
      const { pos } = req;
      handleSetConnectionTest(pos.posOrganizationId, ConnectionTestStatus.PENDING);
      return getPOSIntegrationAPI(apiEndpoints.pos.testConnection(pos.posOrganizationId));
    },
    onSuccess: (response, variables) => {
      if (variables.callback) variables.callback(true);
      const { notification, pos } = variables;
      pushNotification(
        getSuccessNotification(
          notification.id,
          i18n.t(`pos.notifications.connection.success.title`),
          i18n.t(`pos.notifications.connection.success.message`, {
            name: pos.abbreviation,
          }),
          true,
        ),
      );
      const isConnectionValid = response.isValid;
      handleSetConnectionTest(
        pos.posOrganizationId,
        isConnectionValid ? ConnectionTestStatus.VALID : ConnectionTestStatus.INVALID,
      );
    },
    onError: (error, variables) => {
      if (variables.callback) variables.callback(false);
      const { notification, pos } = variables;
      pushNotification(
        getErrorNotification(
          notification.id,
          error,
          true,
          i18n.t(`pos.notifications.connection.error.title`),
          i18n.t(`pos.notifications.connection.error.message`, {
            name: pos.abbreviation,
          }),
        ),
      );
      handleSetConnectionTest(pos.posOrganizationId, ConnectionTestStatus.FAILED);
    },
  });

  const { mutate: deletePos } = useMutation({
    mutationFn: (req: { pos: IPos; notification: INotification; callback?: (isSuccess: boolean) => void }) => {
      const { pos } = req;
      return deletePOSIntegrationAPI(apiEndpoints.pos.deletePOS(pos.posOrganizationId));
    },
    onSuccess: (response, variables) => {
      if (variables.callback) variables.callback(true);
      const { notification, pos } = variables;
      pushNotification(
        getSuccessNotification(
          notification.id,
          i18n.t(`pos.notifications.delete.success.title`),
          i18n.t(`pos.notifications.delete.success.message`, {
            name: pos.abbreviation,
          }),
          true,
        ),
      );
      refetchPosAll();
    },
    onError: (error, variables) => {
      if (variables.callback) variables.callback(false);
      const { notification, pos } = variables;
      pushNotification(
        getErrorNotification(
          notification.id,
          error,
          true,
          i18n.t(`pos.notifications.delete.error.title`),
          i18n.t(`pos.notifications.delete.error.message`, {
            name: pos.abbreviation,
          }),
        ),
      );
      refetchPosAll();
    },
  });

  const {
    mutate: addPos,
    isError: isSavingError,
    reset,
  } = useMutation({
    mutationFn: (req: {
      reqArgs: IPosCredential;
      notification: INotification;
      callback: (isSuccess: boolean) => void;
      isAnUpdate: boolean;
    }) => {
      const { reqArgs, isAnUpdate } = req;
      if (isAnUpdate) return putPOSIntegrationAPI(apiEndpoints.pos.credentials, reqArgs);
      else return postPOSIntegrationAPI(apiEndpoints.pos.credentials, reqArgs);
    },
    onSuccess: (response, variables) => {
      const { notification, reqArgs } = variables;
      const isConnectionValid = response.isPosValid;
      if (variables.callback) {
        //checking only if the POS credentials are valid
        variables.callback(reqArgs?.testConnectionOnly ? !!isConnectionValid : true);
      }

      const { testConnectionOnly, abbreviation } = reqArgs;

      testConnectionOnly
        ? pushNotification(
            isConnectionValid
              ? getSuccessNotification(
                  notification.id,
                  i18n.t(`pos.notifications.connection.success.title`),
                  i18n.t(`pos.notifications.connection.success.message`, {
                    name: abbreviation,
                  }),
                  true,
                )
              : getErrorNotification(
                  notification.id,
                  null,
                  true,
                  i18n.t(`pos.notifications.connection.invalid.title`),
                  i18n.t(`pos.notifications.connection.invalid.message`, {
                    name: abbreviation,
                  }),
                ),
          )
        : pushNotification(
            getSuccessNotification(
              notification.id,
              i18n.t(`pos.notifications.save.success.title`),
              i18n.t(`pos.notifications.save.success.message`, {
                name: abbreviation,
              }),
              true,
            ),
          );

      refetchPosAll();
    },
    onError: (error, variables) => {
      if (variables.callback) variables.callback(false);
      const { notification, reqArgs } = variables;
      const { testConnectionOnly, abbreviation } = reqArgs;
      const callType = testConnectionOnly ? 'connection' : 'save';
      pushNotification(
        getErrorNotification(
          notification.id,
          error,
          true,
          i18n.t(`pos.notifications.${callType}.error.title`),
          i18n.t(`pos.notifications.${callType}.error.message`, {
            name: abbreviation,
          }),
        ),
      );
    },
  });

  useEffect(() => {
    if (posData) {
      setPendingConnectionsTests([]);
      setPosIntegrations(posData);
      setPosIntegrationMap(new Map(posData.map((pos: IPos) => [pos.posOrganizationId.toString(), pos])));
    }
  }, [posData]);

  const handleAddPos = (
    pos: IPosCredential,
    callback: (isSuccess: boolean) => void = () => {},
    isAnUpdate: boolean = false,
  ) => {
    const notification = getLoadingNotification(
      'save-pos',
      i18n.t('pos.notifications.save.loading.title'),
      i18n.t('pos.notifications.save.loading.message', { name: pos.abbreviation }),
    );
    addPos({ reqArgs: { ...pos, testConnectionOnly: false }, notification, callback, isAnUpdate });
    pushNotification(notification);
  };

  const handleTestPos = (pos: IPosCredential, callback: (isSuccess: boolean) => void) => {
    const notification = getLoadingNotification(
      'test-pos',
      i18n.t('pos.notifications.connection.loading.title'),
      i18n.t('pos.notifications.connection.loading.message', { name: pos.abbreviation }),
    );
    addPos({ reqArgs: { ...pos, testConnectionOnly: true }, notification, callback, isAnUpdate: false });
    pushNotification(notification);
  };

  const handleTestExistingPos = (pos: IPos, callback?: (isSuccess: boolean) => void) => {
    const notification = getLoadingNotification(
      'test-pos',
      i18n.t('pos.notifications.connection.loading.title'),
      i18n.t('pos.notifications.connection.loading.message', { name: pos.abbreviation }),
    );
    testExistingPos({ pos, notification, callback });
    pushNotification(notification);
  };

  const handleDeletePOS = (pos: IPos, callback?: (isSuccess: boolean) => void) => {
    const notification = getLoadingNotification(
      'test-pos',
      i18n.t('pos.notifications.delete.loading.title'),
      i18n.t('pos.notifications.delete.loading.message', { name: pos.abbreviation }),
    );
    deletePos({ pos, notification, callback });
    pushNotification(notification);
  };

  const api = useMemo(
    () => ({
      refetchPosAll,
      addPos: (pos: IPosCredential, callback: (isSuccess: boolean) => void, isAnUpdate?: boolean) =>
        handleAddPos(pos, callback, isAnUpdate),
      updatePos: (pos: IPosCredential, callback: (isSuccess: boolean) => void) => handleAddPos(pos, callback),
      testPos: handleTestPos,
      testExistingPos: handleTestExistingPos,
      resetMutationState: reset,
      deletePOS: handleDeletePOS,
    }),
    [],
  );

  return (
    <PosStateContext.Provider
      value={{
        posIntegrations,
        posIntegrationMap,
        isLoading: isPending,
        isSavingError,
        noPos: posData?.length === 0,
        pendingConnectionsTests,
      }}
    >
      <PosApiContext.Provider value={api}>{children}</PosApiContext.Provider>
    </PosStateContext.Provider>
  );
}

export { PosContextProvider };
