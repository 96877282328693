import type * as Schemas from '@/api/pricer/pricerApiSchemas';
import type { ComparablesDto } from '@/api/pricer/pricerApiSchemas';
import FsTrackEvent, { EventNames } from '@/services/fs-track-event';
import type { PricingGroupRequestExtended } from '@/interfaces/CommonInterfaces';
import { CREATE_PRICING_GROUP_ID } from '@/utils/constants';

export function trackComparablesUsage(
  selectedProduction: Schemas.ProductionResponseDto | null,
  updated: ComparablesDto | undefined,
) {
  if (!selectedProduction?.productionId) {
    return;
  }
  const pricingFilterName = Object.keys(updated || {}).filter((key) => {
    const value = updated?.[key as keyof ComparablesDto];
    return value && value.toString().length;
  });
  FsTrackEvent(EventNames.comparableFiltersUsed, {
    productionID: selectedProduction?.productionId,
    productionName: selectedProduction?.eventName,
    pricingFilterName,
  });
}

export const INTERNAL_isCreatePricingGroup = (activePricingGroup: PricingGroupRequestExtended | null) =>
  Boolean(activePricingGroup) && activePricingGroup?.requestId === CREATE_PRICING_GROUP_ID;
export const INTERNAL_isEditPricingGroup = (activePricingGroup: PricingGroupRequestExtended | null) =>
  Boolean(activePricingGroup) &&
  !!activePricingGroup?.requestId &&
  activePricingGroup.requestId !== CREATE_PRICING_GROUP_ID;
