import { useGetInventoryV2 } from '@/api/pricer/pricerApiComponents';
import { useMainSliceInventoryStore } from '@/stores/inventory/inventory-store';

function useGetInventoryResponseQuery() {
  const selectedProduction = useMainSliceInventoryStore('selectedProduction');
  const {
    data: inventoryResponseData,
    isPending: isInventoryLoading,
    refetch: refetchInventory,
    isFetching: isInventoryFetching,
  } = useGetInventoryV2(
    { pathParams: { productionId: Number(selectedProduction?.productionId) } },
    { enabled: Boolean(selectedProduction?.productionId), refetchOnMount: false },
  );

  return {
    inventoryResponseData,
    isInventoryLoading,
    refetchInventory,
    isInventoryFetching,
    inventoryTotalCount: inventoryResponseData?.length,
  };
}

export default useGetInventoryResponseQuery;
