import { useGetMarketplacePropertiesByProductionId } from '@/api/pricer/pricerApiComponents';
import { useMainSliceInventoryActions, useMainSliceInventoryStore } from '@/stores/inventory/inventory-store';
import { usePricerState } from '@/context/pricer/context-hooks';
import { populateMarketFilters } from '@/context/pricing-mode/filter-constants';

function useMarketplacePropertiesQuery() {
  const selectedProduction = useMainSliceInventoryStore('selectedProduction');

  const { data: marketplaceProperties, isPending } = useGetMarketplacePropertiesByProductionId(
    {
      pathParams: { productionId: selectedProduction?.productionId ?? 0 },
    },
    {
      enabled: !!selectedProduction?.productionId,
      refetchOnMount: false,
    },
  );

  const mapSections = useMainSliceInventoryStore('mapSections');
  const mapZones = useMainSliceInventoryStore('mapZones');
  const filters = useMainSliceInventoryStore('marketFilters');
  const { setMarketFilters } = useMainSliceInventoryActions();
  const { tgAttributes } = usePricerState();
  const resetMarketFilters = () => {
    populateMarketFilters(filters, setMarketFilters, marketplaceProperties, mapSections, mapZones, tgAttributes);
  };

  return { marketplaceProperties, resetMarketFilters, isPending };
}

export default useMarketplacePropertiesQuery;
