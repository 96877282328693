import { AuthService } from '@/services/AuthService';
import { HttpStatusCodes } from '@/utils/HttpStatusCodes';
import { UnsavedChangesBrowserManager } from '@/utils/UnsavedChangesBrowserManager';
import { isJestTestEnvironment } from '@/utils/envConfig';

export type ErrorWrapper<TError> = TError | { status: 'unknown'; payload: string };

export type AuthApiFetcherOptions<TBody, THeaders, TQueryParams, TPathParams> = {
  baseUrl?: string;
  url: string;
  method: string;
  body?: TBody;
  headers?: THeaders;
  queryParams?: TQueryParams;
  pathParams?: TPathParams;
  signal?: AbortSignal;
  includeAuth?: boolean;
};

export async function AuthApiFetch<
  TData,
  TError,
  TBody extends {} | FormData | undefined | null,
  THeaders extends {},
  TQueryParams extends {},
  TPathParams extends {},
>({
  baseUrl,
  url,
  method,
  body,
  headers,
  pathParams,
  queryParams,
  signal,
  includeAuth = !isJestTestEnvironment,
}: AuthApiFetcherOptions<TBody, THeaders, TQueryParams, TPathParams>): Promise<TData> {
  try {
    const requestHeaders: HeadersInit = {
      'Content-Type': 'application/json',
      ...headers,
    };

    /**
     * As the fetch API is being used, when multipart/form-data is specified
     * the Content-Type header must be deleted so that the browser can set
     * the correct boundary.
     * https://developer.mozilla.org/en-US/docs/Web/API/FormData/Using_FormData_Objects#sending_files_using_a_formdata_object
     */
    if (requestHeaders['Content-Type'].toLowerCase().includes('multipart/form-data')) {
      delete requestHeaders['Content-Type'];
    }

    if (includeAuth) {
      const accessToken = await AuthService.getAccessToken();
      requestHeaders.Authorization = accessToken ? `Bearer ${accessToken}` : '';
    }

    const response = await window.fetch(`${baseUrl}${resolveUrl(url, queryParams, pathParams)}`, {
      signal,
      method: method.toUpperCase(),
      body: body ? (body instanceof FormData ? body : JSON.stringify(body)) : undefined,
      headers: requestHeaders,
    });

    if (response.status === HttpStatusCodes.Unauthorized) {
      // https://pricerqx.atlassian.net/browse/PRQXDEV-1505
      // no prompt for unsaved changes if session expired
      UnsavedChangesBrowserManager.detach();
      AuthService.logout();
    }

    if (!response.ok) {
      let error: ErrorWrapper<TError>;
      try {
        // as per https://github.com/fabien0102/openapi-codegen/issues/113#issue-1419897599
        error = {
          status: response.status,
          payload: await response.json(),
        } as TError;
      } catch (e) {
        error = {
          status: 'unknown' as const,
          payload: e instanceof Error ? `Unexpected error (${e.message})` : 'Unexpected error',
        };
      }

      throw error;
    }

    if (response.headers.get('content-type')?.includes('json')) {
      return await response.json();
    } else {
      // if it is not a json response, assume it is a blob and cast it to TData
      return (await response.blob()) as unknown as TData;
    }
  } catch (e) {
    const errorObject: Error = {
      name: 'unknown' as const,
      message: e instanceof Error ? `Network error (${e.message})` : 'Network error',
      stack: e as string,
    };
    throw errorObject;
  }
}

const resolveUrl = (
  url: string,
  queryParams: Record<string, string> | string = {},
  pathParams: Record<string, string> = {},
) => {
  let query = '';

  if (queryParams) {
    let params = '';
    if (typeof queryParams === 'string' || typeof queryParams === URLSearchParams.name) {
      params = queryParams.toString();
    } else {
      const urlParams = new URLSearchParams(queryParams);
      for (const [key, value] of Object.entries(queryParams)) {
        if (Array.isArray(value)) {
          urlParams.delete(key);
          value.forEach((v) => urlParams.append(key, v));
        }
      }
      params = urlParams.toString();
    }
    if (params) {
      query = `?${params}`;
    }
  }
  return url.replace(/\{\w*\}/g, (key) => pathParams[key.slice(1, -1)]) + query;
};
