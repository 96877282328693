import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { devtools } from 'zustand/middleware';

import { isDevEnvironment } from '@/utils/envConfig';
import type { MainSliceState, MainState } from '@/stores/inventory/main-slice';
import { createMainSlice } from '@/stores/inventory/main-slice';
import { useMemo } from 'react';
import { getFilterCount } from '@/utils/filters';
import type { IInventoryFilters, IMarketFilters } from '@/interfaces/CommonInterfaces';
import { marketplaceFiltersBase } from '@/context/pricing-mode/filter-constants';
import type { PricingSlice, PricingSliceState } from '@/stores/inventory/pricing-slice';
import { createPricingSlice, INTERNAL_generateRuleSet } from '@/stores/inventory/pricing-slice';
import { inventoryFiltersBase } from '@/context/inventory/constants';
import { INTERNAL_isCreatePricingGroup, INTERNAL_isEditPricingGroup } from '@/stores/inventory/store-utils';
import type { SelectionModeSlice, SelectionModeSliceState } from '@/stores/inventory/selection-mode-slice';
import { createSelectionModeSlice } from '@/stores/inventory/selection-mode-slice';
import { enableMapSet } from 'immer';
import type { MutationsSlice, MutationsSliceState } from '@/stores/inventory/mutations-slice';
import { createMutationsSlice } from '@/stores/inventory/mutations-slice';
import type { AuditPopUpSlice, AuditPopUpSliceState } from '@/stores/inventory/audit-popup-slice';
import { createAuditPopUpSlice } from '@/stores/inventory/audit-popup-slice';

enableMapSet();

export type InventoryState = {
  mainSlice: MainSliceState;
  pricingSlice: PricingSliceState;
  selectionModeSlice: SelectionModeSliceState;
  mutationsSlice: MutationsSliceState;
  auditPopUpSlice: AuditPopUpSliceState;
};

const useInventoryStore = create<InventoryState>()(
  immer(
    devtools(
      (...args) => ({
        mainSlice: createMainSlice(...args),
        pricingSlice: createPricingSlice(...args),
        selectionModeSlice: createSelectionModeSlice(...args),
        mutationsSlice: createMutationsSlice(...args),
        auditPopUpSlice: createAuditPopUpSlice(...args),
      }),
      { enabled: isDevEnvironment, name: 'inventory-store', anonymousActionType: 'inv:unknown' },
    ),
  ),
);

// based on https://tkdodo.eu/blog/working-with-zustand

// access simple properties from main slice
export const useMainSliceInventoryStore = <K extends keyof MainState>(key: K) =>
  useInventoryStore((state) => state.mainSlice[key]);

// compound states
export const useIsCreatePricingGroup = () => {
  const activePricingGroup = useMainSliceInventoryStore('activePricingGroup');
  return useMemo(() => INTERNAL_isCreatePricingGroup(activePricingGroup), [activePricingGroup?.requestId]);
};

export const useIsEditPricingGroup = () => {
  const activePricingGroup = useMainSliceInventoryStore('activePricingGroup');
  return useMemo(() => INTERNAL_isEditPricingGroup(activePricingGroup), [activePricingGroup?.requestId]);
};

export const useAppliedMarketplaceFiltersCount = () => {
  const filters = useMainSliceInventoryStore('marketFilters');
  return useMemo(() => {
    return getFilterCount<IMarketFilters>(filters, marketplaceFiltersBase);
  }, [filters]);
};

export const useAppliedInventoryFiltersCount = () => {
  const inventoryFilters = useMainSliceInventoryStore('inventoryFilters');
  return useMemo(() => {
    return getFilterCount<IInventoryFilters>(inventoryFilters, inventoryFiltersBase);
  }, [inventoryFilters]);
};

export const useMainSliceInventoryActions = () => useInventoryStore((state) => state.mainSlice.actions);

// pricing slice
export const usePricingSliceInventoryStore = <K extends keyof PricingSlice>(key: K) =>
  useInventoryStore((state) => state.pricingSlice[key]);

export const usePricingSliceInventoryActions = () => useInventoryStore((state) => state.pricingSlice.actions);

export const useGenerateRuleSet = () => {
  const pricingSlice = useInventoryStore.getState().pricingSlice;

  return useMemo(() => INTERNAL_generateRuleSet(pricingSlice), [pricingSlice]);
};

// selection mode slice
export const useSelectionSliceInventoryStore = <K extends keyof SelectionModeSlice>(key: K) =>
  useInventoryStore((state) => state.selectionModeSlice[key]);

export const useSelectionSliceInventoryActions = () => useInventoryStore((state) => state.selectionModeSlice.actions);

// mutations slice
export const useMutationsSliceInventoryStore = <K extends keyof MutationsSlice>(key: K) =>
  useInventoryStore((state) => state.mutationsSlice[key]);

export const useMutationsSliceInventoryActions = () => useInventoryStore((state) => state.mutationsSlice.actions);

// audit popup slice
export const useAuditPopUpSliceInventoryStore = <K extends keyof AuditPopUpSlice>(key: K) =>
  useInventoryStore((state) => state.auditPopUpSlice[key]);

export const useAuditPopUpSliceInventoryActions = () => useInventoryStore((state) => state.auditPopUpSlice.actions);
