import { CgSpinner } from 'react-icons/cg';
import { forwardRef } from 'react';
import { cva } from 'cva';

export type ButtonVariant = 'primary' | 'secondary' | 'legacy' | 'transparent';
export type ButtonColor = 'main' | 'success' | 'error' | 'warning' | 'info';
export type ButtonSize = 'xs' | 'sm' | 'md';

interface IProps {
  label?: string;
  type?: 'button' | 'submit' | 'reset';
  children?: React.ReactNode;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  variant?: ButtonVariant;
  color?: ButtonColor;
  disabled?: boolean;
  size?: ButtonSize;
  loading?: boolean;
  tabIndex?: number;
}

const Button = forwardRef<HTMLButtonElement, IProps>(
  (
    {
      label,
      type = 'button',
      children,
      className = '',
      onClick,
      variant = 'primary',
      color = 'main',
      disabled,
      size = 'md',
      loading,
      tabIndex,
    },
    ref,
  ) => {
    const renderLoading = () => {
      return (
        <div className="mr-2 flex w-full flex-1 items-center justify-center">
          <CgSpinner className="h-5 w-5 animate-spin text-txt-primary" />
        </div>
      );
    };

    return (
      <button
        type={type}
        className={styleVariation({
          variant,
          size,
          color,
          className,
        })}
        onClick={onClick}
        disabled={disabled}
        tabIndex={tabIndex}
        ref={ref}
      >
        {loading && renderLoading()}
        {label}
        {children}
      </button>
    );
  },
);

const styleVariation = cva(
  'inline-flex justify-center items-center rounded-md font-medium whitespace-nowrap truncate focus:outline-none focus:ring-0 hover:opacity-80 disabled:cursor-not-allowed disabled:text-action-disabled disabled:border-input-border',
  {
    variants: {
      variant: {
        primary:
          'shadow-none from-primary-main to-primary-alt-med bg-gradient-to-r disabled:from-transparent disabled:bg-action-disabled-bg',
        secondary: 'border shadow-sm',
        legacy: 'bg-primary-main shadow-sm disabled:bg-action-disabled-bg',
        transparent: 'shadow-none',
      },
      size: {
        xs: 'px-2 py-0 text-sm',
        sm: 'py-1 px-[0.813rem] text-xsm leading-snug',
        md: 'py-[0.438rem] px-[1.375rem] text-sm leading-normal',
      },
      color: {
        main: 'border-input-border',
        success: 'text-success-main border-success-main hover:text-success-main/80',
        error: 'text-error-main border-error-main hover:text-error-main/80',
        warning: 'text-warning-main border-warning-main hover:text-warning-main/80',
        info: 'text-info-main border-info-main hover:text-info-main/80',
      },
    },
    compoundVariants: [
      { variant: ['primary', 'secondary', 'legacy'], color: ['main'], className: 'text-txt-primary' },
      { variant: 'transparent', color: ['main'], className: 'text-primary-main' },
    ],
    defaultVariants: {
      variant: 'primary',
      size: 'md',
      color: 'main',
    },
  },
);

export default Button;
