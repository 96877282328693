import { format } from 'date-fns';
/**
 * @param date - Date string
 * @returns Number of days remaining in the format 'T-5', where 5 is the number of days remaining
 * @example getTBD('2023-12-07T00:00:00Z') => 'T-5'
 */
export function getTBD(date: string): string {
  const difference = new Date(date).getTime() - Date.now();
  const days = Math.floor(difference / (1000 * 60 * 60 * 24));
  return `T-${days}`;
}

/**
 * Returns the formatted date string in UTC timezone
 * @param date - Date string
 * @returns Formatted date string in UTC timezone
 * @example getLongDate('2024-04-13T00:00:00Z') => 'APR 13 2024'
 */
export function getLongDate(date: string | undefined | null): string {
  if (!date) return '';
  const dateObj = new Date(date);
  const formatter = Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    timeZone: 'UTC',
  });
  const formattedDate = formatter.format(dateObj).replace(',', '');
  return formattedDate.toLocaleUpperCase();
}

/**
 * Returns the formatted date string in UTC timezone
 * @param date - data string
 * @returns Formatted date string in UTC timezone
 * @example getDateDashed('2023-12-07T00:00:00Z') => '12/07/2023'
 */
export function getDateDashed(date: string | undefined | null): string {
  if (!date) return '';
  const dateObj = new Date(date);
  const formatter = Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: 'UTC',
  });
  const formattedDate = formatter.format(dateObj).replace(',', '');
  return formattedDate.toLocaleUpperCase();
}

/**
 * @param time - Time string
 * @returns Formatted time string
 * @example getTime('2023-12-07T19:31:00Z') => '7:31pm'
 */
export function get12Time(time: string | undefined | null): string {
  if (!time) return '';
  const splitTime = time.split(':');
  const meridian = Number(splitTime[0]) > 12 ? 'pm' : 'am';

  return `${Number(splitTime[0]) % 12}:${splitTime[1]}${meridian}`;
}

/**
 * @param date - Date string
 * @returns Day of the week
 * @example getWeekDay('2023-12-07T00:00:00Z') => 'Sat'
 */
export function getWeekDay(date: string | undefined | null): string {
  if (!date) return '';
  const formattedDate = new Date(date.replace(/-/g, '/'));
  const dayOfWeek = new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
  }).format(formattedDate);

  return dayOfWeek;
}

/**
 * @param lastModifiedDate - Last modified date
 * @param currentDate - Current date
 * @returns Formatted time ago string
 * @example formatTimeAgo(new Date('2023-12-07T00:00:00Z'), new Date('2023-12-07T03:00:00Z')) => '3h ago'
 */
export function formatTimeAgo(lastModifiedDate: Date, currentDate: Date): string {
  const timeDifference = currentDate.getTime() - lastModifiedDate.getTime();
  if (isNaN(timeDifference)) return '';
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  if (seconds < 60) {
    return '<1m ago';
  } else if (minutes < 60) {
    return `${minutes}m ago`;
  } else if (hours < 24) {
    return `${hours}h ago`;
  } else {
    return `>${Math.floor(hours / 24)}d ago`;
  }
}

/**
 * @param originalDate - Date to be converted
 * @returns Date with time set to 00:00:00
 * @example convertDateToDateOnly(new Date('2023-12-07T19:00:00Z')) => '2023-12-07T00:00:00Z'
 */
export function convertDateToDateOnly(originalDate: Date): Date {
  return new Date(originalDate.getFullYear(), originalDate.getMonth(), originalDate.getDate());
}

/**
 * @param date - Date string
 * @returns Formatted date string with slashes and AM/PM
 * @example getSlashedDateWithAmPm('2023-12-07T19:31:00Z') => '2023/12/07 7:31 PM'
 */

export function getSlashedDateWithAmPm(date: string | undefined | null): string {
  if (!date) return '';
  const dateObj = new Date(date);

  if (isNaN(dateObj.getTime())) {
    return '';
  }

  return format(dateObj, 'yyyy/MM/dd hh:mm a');
}
