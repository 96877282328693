/**
 * Generated by @openapi-codegen
 *
 * @version v1
 */
import * as reactQuery from '@tanstack/react-query';
import { useMarketplaceApiContext, MarketplaceApiContext } from './marketplaceApiContext';
import type * as Fetcher from './marketplaceApiFetcher';
import { marketplaceApiFetch } from './marketplaceApiFetcher';
import type * as Schemas from './marketplaceApiSchemas';

export type CreateCredentialError = Fetcher.ErrorWrapper<undefined>;

export type CreateCredentialVariables = {
  body?: Schemas.MarketplaceCredentialRequestDto;
} & MarketplaceApiContext['fetcherOptions'];

export const fetchCreateCredential = (variables: CreateCredentialVariables, signal?: AbortSignal) =>
  marketplaceApiFetch<undefined, CreateCredentialError, Schemas.MarketplaceCredentialRequestDto, {}, {}, {}>({
    url: '/v1/client/marketplacecredential',
    method: 'post',
    ...variables,
    signal,
  });

export const useCreateCredential = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, CreateCredentialError, CreateCredentialVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useMarketplaceApiContext();
  return reactQuery.useMutation<undefined, CreateCredentialError, CreateCredentialVariables>({
    mutationFn: (variables: CreateCredentialVariables) => fetchCreateCredential({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpdateCredentialPathParams = {
  /**
   * @format int64
   */
  organizationMarketplaceId: number;
};

export type UpdateCredentialError = Fetcher.ErrorWrapper<undefined>;

export type UpdateCredentialVariables = {
  body?: Schemas.MarketplaceCredentialRequestDto;
  pathParams: UpdateCredentialPathParams;
} & MarketplaceApiContext['fetcherOptions'];

export const fetchUpdateCredential = (variables: UpdateCredentialVariables, signal?: AbortSignal) =>
  marketplaceApiFetch<
    undefined,
    UpdateCredentialError,
    Schemas.MarketplaceCredentialRequestDto,
    {},
    {},
    UpdateCredentialPathParams
  >({
    url: '/v1/client/marketplacecredential/{organizationMarketplaceId}',
    method: 'put',
    ...variables,
    signal,
  });

export const useUpdateCredential = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, UpdateCredentialError, UpdateCredentialVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useMarketplaceApiContext();
  return reactQuery.useMutation<undefined, UpdateCredentialError, UpdateCredentialVariables>({
    mutationFn: (variables: UpdateCredentialVariables) => fetchUpdateCredential({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type TestCredentialPathParams = {
  /**
   * @format int64
   */
  organizationMarketplaceId: number;
};

export type TestCredentialError = Fetcher.ErrorWrapper<undefined>;

export type TestCredentialVariables = {
  pathParams: TestCredentialPathParams;
} & MarketplaceApiContext['fetcherOptions'];

export const fetchTestCredential = (variables: TestCredentialVariables, signal?: AbortSignal) =>
  marketplaceApiFetch<
    Schemas.MarketplaceCredentialResponseDto,
    TestCredentialError,
    undefined,
    {},
    {},
    TestCredentialPathParams
  >({
    url: '/v1/client/marketplacecredential/{organizationMarketplaceId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useTestCredential = <TData = Schemas.MarketplaceCredentialResponseDto>(
  variables: TestCredentialVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.MarketplaceCredentialResponseDto, TestCredentialError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useMarketplaceApiContext(options);
  return reactQuery.useQuery<Schemas.MarketplaceCredentialResponseDto, TestCredentialError, TData>({
    queryKey: queryKeyFn({
      path: '/v1/client/marketplacecredential/{organizationMarketplaceId}',
      operationId: 'testCredential',
      variables,
    }),
    queryFn: ({ signal }) => fetchTestCredential({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type DeleteCredentialPathParams = {
  /**
   * @format int64
   */
  organizationMarketplaceId: number;
};

export type DeleteCredentialError = Fetcher.ErrorWrapper<undefined>;

export type DeleteCredentialVariables = {
  pathParams: DeleteCredentialPathParams;
} & MarketplaceApiContext['fetcherOptions'];

export const fetchDeleteCredential = (variables: DeleteCredentialVariables, signal?: AbortSignal) =>
  marketplaceApiFetch<undefined, DeleteCredentialError, undefined, {}, {}, DeleteCredentialPathParams>({
    url: '/v1/client/marketplacecredential/{organizationMarketplaceId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteCredential = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DeleteCredentialError, DeleteCredentialVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useMarketplaceApiContext();
  return reactQuery.useMutation<undefined, DeleteCredentialError, DeleteCredentialVariables>({
    mutationFn: (variables: DeleteCredentialVariables) => fetchDeleteCredential({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetAllMarketplacesError = Fetcher.ErrorWrapper<undefined>;

export type GetAllMarketplacesVariables = MarketplaceApiContext['fetcherOptions'];

export const fetchGetAllMarketplaces = (variables: GetAllMarketplacesVariables, signal?: AbortSignal) =>
  marketplaceApiFetch<Schemas.MarketplaceCredentialResponseDto, GetAllMarketplacesError, undefined, {}, {}, {}>({
    url: '/v1/client/marketplacecredential/marketplaces',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetAllMarketplaces = <TData = Schemas.MarketplaceCredentialResponseDto>(
  variables: GetAllMarketplacesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.MarketplaceCredentialResponseDto, GetAllMarketplacesError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useMarketplaceApiContext(options);
  return reactQuery.useQuery<Schemas.MarketplaceCredentialResponseDto, GetAllMarketplacesError, TData>({
    queryKey: queryKeyFn({
      path: '/v1/client/marketplacecredential/marketplaces',
      operationId: 'getAllMarketplaces',
      variables,
    }),
    queryFn: ({ signal }) => fetchGetAllMarketplaces({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type CredentialFormatsError = Fetcher.ErrorWrapper<undefined>;

export type CredentialFormatsVariables = MarketplaceApiContext['fetcherOptions'];

export const fetchCredentialFormats = (variables: CredentialFormatsVariables, signal?: AbortSignal) =>
  marketplaceApiFetch<undefined, CredentialFormatsError, undefined, {}, {}, {}>({
    url: '/v1/client/marketplacecredential/formats',
    method: 'get',
    ...variables,
    signal,
  });

export const useCredentialFormats = <TData = undefined>(
  variables: CredentialFormatsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, CredentialFormatsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useMarketplaceApiContext(options);
  return reactQuery.useQuery<undefined, CredentialFormatsError, TData>({
    queryKey: queryKeyFn({
      path: '/v1/client/marketplacecredential/formats',
      operationId: 'credentialFormats',
      variables,
    }),
    queryFn: ({ signal }) => fetchCredentialFormats({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type VividInventoryUpdateError = Fetcher.ErrorWrapper<undefined>;

export type VividInventoryUpdateVariables = {
  body?: Schemas.WebhookVividSeatsInventoryUpdate;
} & MarketplaceApiContext['fetcherOptions'];

export const fetchVividInventoryUpdate = (variables: VividInventoryUpdateVariables, signal?: AbortSignal) =>
  marketplaceApiFetch<undefined, VividInventoryUpdateError, Schemas.WebhookVividSeatsInventoryUpdate, {}, {}, {}>({
    url: '/v1/webhook/vs-inventory-update',
    method: 'post',
    ...variables,
    signal,
  });

export const useVividInventoryUpdate = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, VividInventoryUpdateError, VividInventoryUpdateVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useMarketplaceApiContext();
  return reactQuery.useMutation<undefined, VividInventoryUpdateError, VividInventoryUpdateVariables>({
    mutationFn: (variables: VividInventoryUpdateVariables) =>
      fetchVividInventoryUpdate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type QueryOperation =
  | {
      path: '/v1/client/marketplacecredential/{organizationMarketplaceId}';
      operationId: 'testCredential';
      variables: TestCredentialVariables;
    }
  | {
      path: '/v1/client/marketplacecredential/marketplaces';
      operationId: 'getAllMarketplaces';
      variables: GetAllMarketplacesVariables;
    }
  | {
      path: '/v1/client/marketplacecredential/formats';
      operationId: 'credentialFormats';
      variables: CredentialFormatsVariables;
    };
