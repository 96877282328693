import type { IColumnHeaderLabel } from '@/interfaces/CommonInterfaces';
import i18n from '@/utils/i18n';

export const columnIds = {
  name: 'name',
  venueName: 'venueName',
  primaryPerformerName: 'primaryPerformerName',
  createdDate: 'createdDate',
  lastModifiedDate: 'lastModifiedDate',
  lastUsedDate: 'lastUsedDate',
  pricingGroupCount: 'pricingGroupCount',
  ruleSet: 'ruleSet',
  marketGroupCriteria: 'marketGroupCriteria',
  lastModifiedBy: 'lastModifiedBy',
  action: 'action',
};

type ColumnsIdKeysType = keyof typeof columnIds;
type ColumnLabelsType = Record<ColumnsIdKeysType, IColumnHeaderLabel>;

export const columnLabels: ColumnLabelsType = {
  name: {
    label: i18n.t('pricingTemplatesListPage.columns.name'),
    tooltip: i18n.t('pricingTemplatesListPage.columns.name'),
  },
  venueName: {
    label: i18n.t('pricingTemplatesListPage.columns.venue'),
    subLabel: i18n.t('pricingTemplatesListPage.columns.location'),
    tooltip: i18n.t('pricingTemplatesListPage.columns.venue'),
  },
  primaryPerformerName: {
    label: i18n.t('pricingTemplatesListPage.columns.performer'),
    tooltip: i18n.t('pricingTemplatesListPage.columns.performer'),
  },
  createdDate: {
    label: i18n.t('pricingTemplatesListPage.columns.created'),
    subLabel: i18n.t('pricingTemplatesListPage.columns.time'),
    tooltip: i18n.t('pricingTemplatesListPage.columns.created'),
  },
  lastModifiedDate: {
    label: i18n.t('pricingTemplatesListPage.columns.updated'),
    subLabel: i18n.t('pricingTemplatesListPage.columns.time'),
    tooltip: i18n.t('pricingTemplatesListPage.columns.updated'),
  },
  lastUsedDate: {
    label: i18n.t('pricingTemplatesListPage.columns.lastTimeUsed'),
    subLabel: i18n.t('pricingTemplatesListPage.columns.time'),
    tooltip: i18n.t('pricingTemplatesListPage.columns.lastTimeUsed'),
  },
  pricingGroupCount: {
    label: i18n.t('pricingTemplatesListPage.columns.pricingGroups'),
    tooltip: i18n.t('pricingTemplatesListPage.columns.pricingGroups'),
  },
  ruleSet: {
    label: i18n.t('pricingTemplatesListPage.columns.pricingCriteria'),
    tooltip: i18n.t('pricingTemplatesListPage.columns.pricingCriteria'),
  },
  marketGroupCriteria: {
    label: i18n.t('pricingTemplatesListPage.columns.marketComparables'),
    tooltip: i18n.t('pricingTemplatesListPage.columns.marketComparables'),
  },
  lastModifiedBy: {
    label: i18n.t('pricingTemplatesListPage.columns.updatedBy'),
    tooltip: i18n.t('pricingTemplatesListPage.columns.updatedBy'),
  },
  action: {
    label: i18n.t('pricingTemplatesListPage.columns.action'),
    tooltip: i18n.t('pricingTemplatesListPage.columns.action'),
  },
};
