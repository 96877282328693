import logo_m_vividseats from '@/assets/images/marketplace/logo-vividseats.svg';
import logo_p_skybox from '@/assets/images/pos/logo-skybox.svg';
import logo_sos from '@/assets/images/base/sos-logo.svg';
import ib_logo from '@/assets/images/base/ib-logo-2.svg';

export const QuickLinks = [
  {
    icon: logo_p_skybox,
    label: 'Skybox POS',
    url: 'https://skybox.vividseats.com',
    showInDrive: true,
  },
  {
    icon: logo_m_vividseats,
    label: 'Broker Portal',
    url: 'https://brokers.vividseats.com/',
    showInDrive: true,
  },
  {
    icon: logo_m_vividseats,
    label: 'Marketplace',
    url: 'https://www.vividseats.com/',
    showInDrive: true,
  },
  {
    icon: logo_sos,
    label: 'SOS',
    url: 'https://www.showsonsale.com/',
    showInDrive: false,
  },
  {
    icon: ib_logo,
    label: 'Insomniac',
    url: 'https://www.insomniacbrowser.com/',
    showInDrive: false,
  },
];
