import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { useUserState } from '@/context/user/context-hooks';
import type { IBasePropsSlideOver } from '@/components/common/slideOvers/constants';

function SlideOver({
  children,
  open,
  setOpen,
  className = '',
  direction = 'right',
  afterTransition,
  withOverlay = false,
  coverNav = true,
  responsive = false,
}: IBasePropsSlideOver) {
  const { preferences } = useUserState();
  const isFloatingNavigation = preferences.navigation.isFloating;

  const openLeft = direction === 'left';
  const openRight = direction === 'right';
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <div className={styles.fixed}>
          <div className={styles.absolute}>
            {withOverlay && (
              <Transition.Child
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-overlay" aria-hidden="true" />
              </Transition.Child>
            )}
            <div className={clsx(styles.pointer, { 'left-0': openLeft, 'right-0': openRight })}>
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500"
                enterFrom={openLeft ? '-translate-x-full' : 'translate-x-full'}
                enterTo={openLeft ? '-translate-x-0' : 'translate-x-0'}
                leave="transform transition ease-in-out duration-500"
                leaveFrom={openLeft ? '-translate-x-0' : 'translate-x-0'}
                leaveTo={openLeft ? '-translate-x-full' : 'translate-x-full'}
                afterEnter={afterTransition}
              >
                <Dialog.Panel
                  className={clsx(styles.panel, className, {
                    'sm:max-w-md': responsive,
                    'max-w-md': !responsive,
                  })}
                  style={{ marginTop: !isFloatingNavigation && !coverNav ? '2.2rem' : 0 }}
                >
                  <div className={styles.container}>
                    <div className={styles.content}>{children}</div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

const styles = {
  container: 'flex h-full flex-col overflow-y-auto scrollbar-hide bg-bck-body shadow-xl',
  content: 'relative flex-1 flex',
  fixed: 'fixed inset-0 overflow-hidden',
  absolute: 'absolute inset-0 overflow-hidden',
  pointer: 'pointer-events-none fixed inset-y-0 flex max-w-full',
  panel: 'pointer-events-auto [:where(&)]:w-screen ',
};

export default SlideOver;
