import { useNotificationsApi, useNotificationsState } from '@/context/notifications/context-hooks';
import ToastNotification from './ToastNotification';

function ToastsNotificationOverlay() {
  const { notifications } = useNotificationsState();
  const { readNotification } = useNotificationsApi();

  return (
    <div aria-live="assertive" className={styles.liveRegion}>
      <div className={styles.container}>
        {notifications.map((notification) => (
          <ToastNotification
            notification={notification}
            key={notification.id}
            onMarkRead={(id: string) => readNotification(id)}
          />
        ))}
      </div>
    </div>
  );
}

const styles = {
  liveRegion: 'pointer-events-none fixed inset-0 flex items-start px-2 py-20 z-20',
  container: 'flex w-full flex-col  space-y-1 items-end',
};

export default ToastsNotificationOverlay;
