export default {
  save: 'Save',
  saveChanges: 'Save Changes',
  cancel: 'Cancel',
  close: 'Close',
  cancelInvite: 'Cancel Invite',
  deactivate: 'Deactivate',
  delete: 'Delete',
  notify: 'Notify',
  back: 'Back',
  backHome: 'Back to Home',
  backSignIn: 'Back to sign in',
  reset: 'Reset',
  continue: 'Continue',
  ok: 'OK',
  apply: 'Apply',
  clearAll: 'Clear all',
  resetAll: 'Reset all',
  resetAllTooltip: 'Reset all filters to defaults',
  resetPassword: 'Reset password',
  clearSelection: 'Reset',
  refresh: 'Refresh',
  next: 'Next',
  retry: 'Retry',
  backToHome: 'Back to Home',
  restoreSystemDefaults: 'Restore Defaults',
  update: 'Update',
  export: 'Export',
};
