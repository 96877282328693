import { MsalProvider } from '@azure/msal-react';
import { AuthService } from '@/services/AuthService';
import { useEffect, useState } from 'react';

interface IProps {
  children: React.ReactNode;
}
function MsalProviderInitializer(props: IProps) {
  const msalInstance = AuthService.getInstance();
  const [isInitialized, setIsInitialized] = useState(false);
  useEffect(() => {
    (async () => {
      await msalInstance.initialize();
      setIsInitialized(true);
    })();
  }, []);

  return isInitialized ? <MsalProvider instance={msalInstance}>{props.children}</MsalProvider> : null;
}

export default MsalProviderInitializer;
