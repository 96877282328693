import type { ActionsSlice, ImmerStateCreator } from '@/utils/constants';
import type { InventoryState } from '@/stores/inventory/inventory-store';
import type { PricingResultDto, ValidateComparablesResponse } from '@/api/pricer/pricerApiSchemas';

export type MutationsSlice = {
  calculatedPrice: PricingResultDto | undefined;
  validatedComparables: ValidateComparablesResponse | undefined;
  isPricingCreating: boolean;
  isPriceCalculating: boolean;
  isPricingGroupUpdating: boolean;
  isPricingGroupDeleting: boolean;
  pricingGroupPatchInProgress: boolean;
  isComparablesValidating: boolean;
};

type SelectionModeActions = {
  setCalculatedPrice: (calculatedPrice: PricingResultDto | undefined) => void;
  setValidatedComparables: (validatedComparables: ValidateComparablesResponse | undefined) => void;
  setIsPricingCreating: (isPricingCreating: boolean) => void;
  setIsPriceCalculating: (isPriceCalculating: boolean) => void;
  setIsPricingGroupUpdating: (isPricingGroupUpdating: boolean) => void;
  setIsPricingGroupDeleting: (isPricingGroupDeleting: boolean) => void;
  setIsPricingGroupPatchInProgress: (pricingGroupPatchInProgress: boolean) => void;
  setIsComparablesValidating: (isComparablesValidating: boolean) => void;
};

export type MutationsSliceState = MutationsSlice & ActionsSlice<SelectionModeActions>;

export const createMutationsSlice: ImmerStateCreator<InventoryState, MutationsSliceState> = (set, get) => ({
  calculatedPrice: undefined,
  validatedComparables: undefined,
  isPricingCreating: false,
  isPriceCalculating: false,
  isPricingGroupUpdating: false,
  isPricingGroupDeleting: false,
  pricingGroupPatchInProgress: false,
  isComparablesValidating: false,
  actions: {
    setCalculatedPrice: (calculatedPrice) => {
      set(({ mutationsSlice }) => {
        mutationsSlice.calculatedPrice = calculatedPrice;
      });
    },
    setIsPricingCreating: (isPricingCreating) => {
      set(({ mutationsSlice }) => {
        mutationsSlice.isPricingCreating = isPricingCreating;
      });
    },
    setIsPriceCalculating: (isPriceCalculating) => {
      set(({ mutationsSlice }) => {
        mutationsSlice.isPriceCalculating = isPriceCalculating;
      });
    },
    setIsPricingGroupUpdating: (isPricingGroupUpdating) => {
      set(({ mutationsSlice }) => {
        mutationsSlice.isPricingGroupUpdating = isPricingGroupUpdating;
      });
    },
    setIsPricingGroupDeleting: (isPricingGroupDeleting) => {
      set(({ mutationsSlice }) => {
        mutationsSlice.isPricingGroupDeleting = isPricingGroupDeleting;
      });
    },
    setIsPricingGroupPatchInProgress: (pricingGroupPatchInProgress) => {
      set(({ mutationsSlice }) => {
        mutationsSlice.pricingGroupPatchInProgress = pricingGroupPatchInProgress;
      });
    },
    setValidatedComparables: (validatedComparables) => {
      set(({ mutationsSlice }) => {
        mutationsSlice.validatedComparables = validatedComparables;
      });
    },
    setIsComparablesValidating: (isComparablesValidating) => {
      set(({ mutationsSlice }) => {
        mutationsSlice.isComparablesValidating = isComparablesValidating;
      });
    },
  },
});
