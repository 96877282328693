import {
  AutoPriceSimulateType,
  CustomSharingMethod,
  DisplayHideOptionType,
  EnableDisabledType,
  LowerOutlierMethodAccessible,
  PriceAdjustmentMethodType,
  PriceAdjustmentType,
  PricingGroupRequestType,
  RequiredOptionType,
  SectionZoneOptionType,
  ShareUnshareType,
} from '@/utils/constants';
import i18n from '@/utils/i18n';

import type { PricingGroupRequestExtended, TField } from '@/interfaces/CommonInterfaces';
import type {
  HitCeilingPricingMethod,
  HitFloorPricingMethod,
  HitNoCompPricingMethod,
  LowerOutlierMethod,
  PricingGroupResponse,
} from '@/api/pricer/pricerApiSchemas';
import {
  ArrowDownwardIcon,
  ArrowUpwardIcon,
  DollarIcon,
  KeyboardDoubleArrowDownIcon,
  KeyboardDoubleArrowUpIcon,
  LowPriorityIcon,
  PercentIcon,
  TagIcon,
} from '@/components/icons';
import {
  HitCeilingPricingMethodKeys,
  HitFloorPricingMethodKeys,
  HitNoCompPricingMethodKeys,
} from '@/api/pricer/pricerApiSchemaExtensions';
import { AnchorRank } from '@/components/inventory/table/constants';
import type React from 'react';

export const adjustments = [
  { name: 'Increase amount', key: PriceAdjustmentType.INCREASE, icon: ArrowUpwardIcon, hideName: true },
  { name: 'Decrease amount', key: PriceAdjustmentType.DECREASE, icon: ArrowDownwardIcon, hideName: true },
  { name: 'Match lowest', key: PriceAdjustmentType.MATCH, icon: LowPriorityIcon, hideName: true },
];

export const lowerOutlierMethods: {
  name: string;
  key: LowerOutlierMethod;
}[] = [
  { name: 'Quantity', key: LowerOutlierMethodAccessible.Quantity },
  { name: 'Currency', key: LowerOutlierMethodAccessible.Currency },
  { name: 'Percentage', key: LowerOutlierMethodAccessible.Percentage },
];
export const lowerOutlierMethodsFull: {
  name: string;
  key: LowerOutlierMethod;
  icon: React.FC;
  hideName: boolean;
}[] = [
  { name: 'Quantity', key: LowerOutlierMethodAccessible.Quantity, icon: TagIcon, hideName: true },
  { name: 'Currency', key: LowerOutlierMethodAccessible.Currency, icon: DollarIcon, hideName: true },
  { name: 'Percentage', key: LowerOutlierMethodAccessible.Percentage, icon: PercentIcon, hideName: true },
];
export const units = [
  { name: 'Use currency', key: PriceAdjustmentMethodType.DOLLAR, icon: DollarIcon, hideName: true },
  { name: 'Use percentage', key: PriceAdjustmentMethodType.PERCENTAGE, icon: PercentIcon, hideName: true },
];

export const customSharingUnits = [
  { name: '#', key: CustomSharingMethod.QUANTITY, icon: TagIcon, hideName: true },
  { name: '%', key: CustomSharingMethod.PERCENTAGE, icon: PercentIcon, hideName: true },
];
export const requiredOption = [
  { name: 'Required', key: RequiredOptionType.REQUIRED, hideName: false },
  { name: 'Optional', key: RequiredOptionType.OPTIONAL, hideName: false },
];
export const enableDisableOption = [
  { name: 'Enable', key: EnableDisabledType.ENABLED, hideName: false },
  { name: 'Disable', key: EnableDisabledType.DISABLED, hideName: false },
];
export const shareUnshareOption = [
  { name: 'Share', key: ShareUnshareType.SHARE, hideName: false },
  { name: 'Unshare', key: ShareUnshareType.UNSHARE, hideName: false },
];
export const autoPriceSimulateOption = [
  { name: 'Auto Price', key: AutoPriceSimulateType.AUTO_PRICE, hideName: false },
  { name: 'Simulate', key: AutoPriceSimulateType.SIMULATE, hideName: false },
];
export const sectionZoneOption = [
  { name: 'Section', key: SectionZoneOptionType.SECTION, hideName: false },
  { name: 'Zone', key: SectionZoneOptionType.ZONE, hideName: false },
];
export const displayHideOption = [
  { name: 'Display', key: DisplayHideOptionType.DISPLAY, hideName: false },
  { name: 'Hide', key: DisplayHideOptionType.HIDE, hideName: false },
];

export const groupAdjustments = [
  { name: 'Increase', key: PriceAdjustmentType.INCREASE, icon: KeyboardDoubleArrowUpIcon, hideName: true },
  { name: 'Decrease', key: PriceAdjustmentType.DECREASE, icon: KeyboardDoubleArrowDownIcon, hideName: true },
];

export const advancedNoCompsOptions = [
  { name: i18n.t('pricing.advancedOptions.options.freeze'), key: 'Standard' },
  { name: i18n.t('pricing.advancedOptions.options.fix'), key: 'Ceiling' },
  { name: i18n.t('pricing.advancedOptions.options.randomize'), key: 'Variable' },
];

export const splitType = [1, 2, 3, 4, 5, 6, 7, 8];

export const maxSplitType = 8;

export const initialInputValue: TField = { value: '', valid: undefined };
export const zeroInputValue: TField = { value: '0.00', valid: true };

export function createPricingGroupRequestFromPricingGroupResponse(
  pricingGroupResponse: PricingGroupResponse,
): PricingGroupRequestExtended {
  const {
    id,
    name,
    ceilingPrice,
    floorPrice,
    ruleSet,
    marketGroupCriteria,
    productionId,
    pricingGroupTickets,
    isAutoPricingEnabled,
  } = pricingGroupResponse;

  return {
    name,
    ceilingPrice,
    floorPrice,
    ruleSet,
    marketGroupCriteria,
    productionId,
    pricingGroupRequestRanks: pricingGroupTickets?.map((ticket) => ({
      ticketGroupId: ticket.ticketGroupId,
      rank: ticket.rank,
    })),
    pricingGroupRequestType: isAutoPricingEnabled
      ? PricingGroupRequestType.AUTO_PRICE
      : PricingGroupRequestType.SIMULATION_ONLY,
    requestId: id!,
    placeholderAnchorTicketId: pricingGroupTickets?.find((ticket) => ticket.rank === AnchorRank)?.ticketGroupId,
  };
}

export const mapCeilingPricingMethod = (method: HitCeilingPricingMethod | undefined): HitCeilingPricingMethod => {
  if (!method) {
    return HitCeilingPricingMethodKeys.UseCeiling;
  }
  switch (method) {
    case HitCeilingPricingMethodKeys.Standard:
      return HitCeilingPricingMethodKeys.UseCeiling;
    case HitCeilingPricingMethodKeys.Variable:
      return HitCeilingPricingMethodKeys.CeilingRandomize;
    case HitCeilingPricingMethodKeys.UseCeiling:
      return HitCeilingPricingMethodKeys.UseCeiling;
    case HitCeilingPricingMethodKeys.CeilingRandomize:
      return HitCeilingPricingMethodKeys.CeilingRandomize;
    default:
      return HitCeilingPricingMethodKeys.UseCeiling;
  }
};

export const mapFloorPricingMethod = (method: HitFloorPricingMethod | undefined): HitFloorPricingMethod => {
  if (!method) {
    return HitFloorPricingMethodKeys.UseFloor;
  }
  switch (method) {
    case HitFloorPricingMethodKeys.Standard:
      return HitFloorPricingMethodKeys.UseFloor;
    case HitFloorPricingMethodKeys.Variable:
      return HitFloorPricingMethodKeys.FloorRandomize;
    case HitFloorPricingMethodKeys.UseFloor:
      return HitFloorPricingMethodKeys.UseFloor;
    case HitFloorPricingMethodKeys.FloorRandomize:
      return HitFloorPricingMethodKeys.FloorRandomize;
    default:
      return HitFloorPricingMethodKeys.UseFloor;
  }
};

export const mapNoCompPricingMethod = (method: HitNoCompPricingMethod | undefined): HitNoCompPricingMethod => {
  if (!method) {
    return HitNoCompPricingMethodKeys.FreezePricing;
  }
  switch (method) {
    case HitNoCompPricingMethodKeys.Standard:
      return HitNoCompPricingMethodKeys.FreezePricing;
    case HitNoCompPricingMethodKeys.Variable:
      return HitNoCompPricingMethodKeys.CeilingRandomize;
    case HitNoCompPricingMethodKeys.Ceiling:
      return HitNoCompPricingMethodKeys.UseCeiling;
    case HitNoCompPricingMethodKeys.FreezePricing:
      return HitNoCompPricingMethodKeys.FreezePricing;
    case HitNoCompPricingMethodKeys.UseFloorForAnchor:
      return HitNoCompPricingMethodKeys.UseFloorForAnchor;
    case HitNoCompPricingMethodKeys.UseCeilingForAnchor:
      return HitNoCompPricingMethodKeys.UseCeilingForAnchor;
    case HitNoCompPricingMethodKeys.UseCeiling:
      return HitNoCompPricingMethodKeys.UseCeiling;
    case HitNoCompPricingMethodKeys.CeilingRandomize:
      return HitNoCompPricingMethodKeys.CeilingRandomize;
    default:
      return HitNoCompPricingMethodKeys.FreezePricing;
  }
};
