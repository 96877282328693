import clsx from 'clsx';
import { AppModeIsDrive } from '@/utils/constants';
import i18n from '@/utils/i18n';
import { BookmarkAddedIcon } from '../icons';
import Tooltip from '@/components/common/tooltip/Tooltip';

const seatSaver = i18n.t('inventory.tooltip.seatSaver');
const specInventory = i18n.t('inventory.tooltip.specInventory');

interface IProps {
  className?: string;
}

function SpeculativeSymbol({ className }: IProps) {
  return (
    <Tooltip message={AppModeIsDrive ? seatSaver : specInventory}>
      <div>
        <BookmarkAddedIcon className={clsx('h-5 w-5 fill-warning-alt-light', className)} />
      </div>
    </Tooltip>
  );
}

export default SpeculativeSymbol;
