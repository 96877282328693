import { useGetPendingChangesStatus } from '@/api/pricer/pricerApiComponents';
import { useEffect } from 'react';
import useRecursiveTimeout from '@/hooks/useRecursiveTimeout';
import { useMainSliceInventoryActions, useMainSliceInventoryStore } from '@/stores/inventory/inventory-store';

function usePendingChangesQuery() {
  const committedUnsettledChanges = useMainSliceInventoryStore('committedUnsettledChanges');
  const { data: pendingChanges, refetch: refetchPendingChanges } = useGetPendingChangesStatus(
    { queryParams: { ticketGroupId: committedUnsettledChanges } },
    { enabled: committedUnsettledChanges.length > 0, refetchOnMount: false },
  );

  const { setCommittedUnsettledChanges, setPosSettledChanges, setProcessedInventory, setToBeUpdated, setDelay } =
    useMainSliceInventoryActions();
  const reducePendingChanges = (changes: { ticketGroupId: number; isPendingChange: boolean }[]) => {
    const reducedPendings = changes.reduce(
      (acc, change) => {
        change.isPendingChange ? acc.unsettled.push(change.ticketGroupId) : acc.settled.push(change.ticketGroupId);
        return acc;
      },
      { settled: [] as number[], unsettled: [] as number[] },
    );
    setCommittedUnsettledChanges(reducedPendings.unsettled);
    setPosSettledChanges(reducedPendings.settled);
  };

  const delay = useMainSliceInventoryStore('delay');
  const processedInventory = useMainSliceInventoryStore('processedInventory');
  const toBeUpdated = useMainSliceInventoryStore('toBeUpdated');

  useEffect(() => {
    reducePendingChanges(pendingChanges ?? []);

    const localPendingChanges = pendingChanges ? (pendingChanges as any[]) : [];

    const settled: number[] = [];
    const updatedInventory = processedInventory?.map((item) => {
      if (!item.ticketGroupId) return item;

      const ticketGroupId = item.ticketGroupId.toString();

      if (toBeUpdated.get(ticketGroupId)) {
        if (!localPendingChanges.some((change) => change.ticketGroupId === item.ticketGroupId)) {
          const groupRequests = toBeUpdated.get(ticketGroupId);
          const updatedItem = { ...item };
          if (groupRequests) {
            if (groupRequests.listPrice !== null && groupRequests.listPrice !== undefined)
              updatedItem.listPrice = groupRequests.listPrice;
            if (groupRequests.isShared !== null && groupRequests.isShared !== undefined)
              updatedItem.isShared = groupRequests.isShared;
            if (groupRequests.ticketGroupId) settled.push(groupRequests.ticketGroupId);
          }
          return updatedItem;
        }
      }
      return item;
    });

    const newToBeUpdated = new Map(toBeUpdated);
    settled.forEach((ticketGroupId) => newToBeUpdated.delete(ticketGroupId.toString()));

    setToBeUpdated(newToBeUpdated);
    setProcessedInventory(updatedInventory ?? []);

    if ((pendingChanges as any)?.find((pc: any) => pc.isPendingChange)) {
      setDelay(Math.min(6000, delay ? delay * 2 : 1000));
    } else {
      setDelay(null);
    }
  }, [pendingChanges, delay]);

  useRecursiveTimeout(async () => {
    return refetchPendingChanges();
  }, delay);
}

export default usePendingChangesQuery;
