import type { InventoryProperties, InventoryResponseDtoV2 } from '@/api/pricer/pricerApiSchemas';
import type {
  IInventoryFilters,
  IOptionsSet,
  IRadioOption,
  PricingGroupRequestExtended,
} from '@/interfaces/CommonInterfaces';
import { EFilterType } from '@/interfaces/CommonInterfaces';
import { AppModeIsDrive, CREATE_PRICING_GROUP_ID, InventoryStatusOption, ThreeState } from '@/utils/constants';
import i18n from '@/utils/i18n';
import { DeletedTicketsPricingGroupFakeTicketId } from '@/components/inventory/table/constants';

export const inventoryFiltersBase: IInventoryFilters = {
  status: {
    type: EFilterType.OPTIONS,
    data: {
      label: i18n.t(`inventory.filters.status`),
      toolTipMessage: i18n.t(`inventory.filters.status`),
      options: [].map((item) => ({
        label: item,
        value: item,
        selected: false,
      })),
    } as IOptionsSet<string>,
  },
  quantity: {
    type: EFilterType.OPTIONS,
    data: {
      label: i18n.t(`inventory.filters.quantity`),
      toolTipMessage: i18n.t(`inventory.filters.quantity`),
      options: [].map((item) => ({
        label: item,
        value: item,
        selected: false,
      })),
    } as IOptionsSet<number>,
  },
  pricingCriteriaGroup: {
    type: EFilterType.OPTIONS,
    data: {
      label: i18n.t(`inventory.filters.pricingGroup`),
      toolTipMessage: i18n.t(`inventory.filters.pricingGroup`),
      options: [].map((item) => ({
        label: item,
        value: item,
        selected: false,
      })),
    } as IOptionsSet<string>,
  },
  seatType: {
    type: EFilterType.OPTIONS,
    data: {
      label: i18n.t(`inventory.filters.seatType`),
      toolTipMessage: i18n.t(`inventory.filters.seatType`),
      options: [].map((item) => ({
        label: item,
        value: item,
        selected: false,
      })),
    } as IOptionsSet<string>,
  },
  splitRule: {
    type: EFilterType.OPTIONS,
    data: {
      label: i18n.t(`inventory.filters.splitType`),
      toolTipMessage: i18n.t(`inventory.filters.splitType`),
      options: [].map((item) => ({
        label: item,
        value: item,
        selected: false,
      })),
    } as IOptionsSet<string>,
  },
  stockType: {
    type: EFilterType.OPTIONS,
    data: {
      label: i18n.t(`inventory.filters.stockType`),
      toolTipMessage: i18n.t(`inventory.filters.stockType`),
      options: [].map((item) => ({
        label: item,
        value: item,
        selected: false,
      })),
    } as IOptionsSet<string>,
  },
  attributes: {
    type: EFilterType.OPTIONS,
    data: {
      label: i18n.t(`inventory.filters.attributes`),
      toolTipMessage: i18n.t(`inventory.filters.attributes`),
      options: [].map((item) => ({
        label: item,
        value: item,
        selected: false,
      })),
    } as IOptionsSet<string>,
  },
  optionsFilter: {
    type: EFilterType.OPTIONS,
    data: {
      label: i18n.t(`inventory.filters.options`),
      toolTipMessage: i18n.t(`inventory.filters.options`),
      options: [].map((item) => ({
        label: item,
        value: item,
        selected: false,
      })),
    } as IOptionsSet<string>,
  },
  isShared: {
    type: EFilterType.RADIO,
    data: {
      label: i18n.t(`inventory.filters.isShared`),
      options: ThreeState,
      selected: ThreeState[0],
    } as IRadioOption,
  },
  isInHand: {
    type: EFilterType.RADIO,
    data: {
      label: i18n.t(`inventory.filters.IsInHand`),
      options: ThreeState,
      selected: ThreeState[0],
    } as IRadioOption,
  },
  isSpeculation: {
    type: EFilterType.RADIO,
    data: {
      label: AppModeIsDrive ? i18n.t('inventory.filters.isSeatSaver') : i18n.t('inventory.filters.isSpeculative'),
      options: ThreeState,
      selected: ThreeState[0],
    } as IRadioOption,
  },
};

export const generateFilters = (inventoryProperties: InventoryProperties | undefined, filters: IInventoryFilters) => {
  const { attributes, stockTypes, splitRules, seatTypes, statuses, pricingCriteria, options, quantities } =
    inventoryProperties ?? {};
  const populatedFilterData: IInventoryFilters = {
    ...structuredClone(inventoryFiltersBase),
    quantity: {
      ...filters.quantity,
      data: {
        ...filters.quantity.data,
        options: (quantities ?? []).map((quantity) => ({
          label: quantity.toString(),
          value: quantity,
          selected: false,
        })),
      },
    },
    status: {
      ...filters.status,
      data: {
        ...filters.status.data,
        options: (statuses ?? []).map((item) => ({
          label: item,
          value: item,
          selected: item === InventoryStatusOption.AVAILABLE,
        })),
      } as IOptionsSet<string>,
    },
    pricingCriteriaGroup: {
      ...filters.pricingCriteriaGroup,
      data: {
        ...filters.pricingCriteriaGroup.data,
        options: (pricingCriteria ?? []).map((item) => ({ label: item, value: item })),
      } as IOptionsSet<string>,
    },
    seatType: {
      ...filters.seatType,
      data: {
        ...filters.seatType.data,
        options: (seatTypes ?? []).map((item) => ({
          label: i18n.t(`inventory.seatType.${item}`),
          value: item,
        })),
      } as IOptionsSet<string>,
    },
    splitRule: {
      ...filters.splitRule,
      data: {
        ...filters.splitRule.data,
        options: (splitRules ?? []).map((item) => ({
          label: i18n.t(`inventory.splitType.${item}`),
          value: item,
        })),
      } as IOptionsSet<string>,
    },
    stockType: {
      ...filters.stockType,
      data: {
        ...filters.stockType.data,
        options: (stockTypes ?? []).map((item) => ({
          label: i18n.t(`inventory.stockType.${item}`),
          value: item,
        })),
      } as IOptionsSet<string>,
    },
    attributes: {
      ...filters.attributes,
      data: {
        ...filters.attributes.data,
        options: (attributes ?? []).map((item) => ({ label: item, value: item })),
      } as IOptionsSet<string>,
    },
    optionsFilter: {
      ...filters.optionsFilter,
      data: {
        ...filters.optionsFilter.data,
        options: (options ?? []).map((item) => ({
          label: i18n.t(`inventory.filters.${item}`),
          value: item,
        })),
      } as IOptionsSet<string>,
    },
  };

  return { ...populatedFilterData };
};

export function addTicketsToPricingGroupForInventory({
  addedTicketsForNewPricingGroup,
  actionAddTickets,
  processedInventory,
  activePricingGroupId,
}: {
  addedTicketsForNewPricingGroup: number[];
  actionAddTickets?: number[] | undefined;
  processedInventory: InventoryResponseDtoV2[] | undefined;
  activePricingGroupId: number | null | undefined;
}): InventoryResponseDtoV2[] | undefined {
  if (addedTicketsForNewPricingGroup.length === 0 && actionAddTickets?.length === 0) {
    return processedInventory;
  }
  const updatedInventory = processedInventory?.map((item) => {
    if (
      addedTicketsForNewPricingGroup.includes(item.ticketGroupId!) ||
      actionAddTickets?.includes(item.ticketGroupId!)
    ) {
      const updatedItem = { ...item };
      updatedItem.pricingGroupId = activePricingGroupId || CREATE_PRICING_GROUP_ID;
      return updatedItem;
    }
    return item;
  });

  return updatedInventory;
}

export function updateRanksInInventoryAfterPricingGroupUpdate(
  activePricingGroup: PricingGroupRequestExtended,
  processedInventory: InventoryResponseDtoV2[] | undefined,
): InventoryResponseDtoV2[] | undefined {
  const pricingGroupRanksMap = new Map(
    activePricingGroup?.pricingGroupRequestRanks?.map((tg) => [tg.ticketGroupId, tg.rank]),
  );

  let result = processedInventory?.map((item) => {
    if (item.pricingGroupId === activePricingGroup.requestId) {
      const itemUpdated = { ...item };
      if (pricingGroupRanksMap.has(item.ticketGroupId)) {
        itemUpdated.rank = pricingGroupRanksMap.get(item.ticketGroupId);
      } else {
        itemUpdated.pricingGroupId = null;
        itemUpdated.pricingGroupName = null;
        itemUpdated.rank = undefined;
      }
      return itemUpdated;
    }
    return item;
  });
  if (pricingGroupRanksMap.size === 0) {
    const ghostedAnchor = processedInventory?.find(
      (item) => item.ticketGroupId === activePricingGroup.placeholderAnchorTicketId,
    );
    const fakeTicketsDeleted: InventoryResponseDtoV2 = {
      // keeps the PG in the current position on screen
      ...ghostedAnchor,
      ticketGroupId: DeletedTicketsPricingGroupFakeTicketId,
      pricingGroupId: activePricingGroup.requestId,
    };

    result = result?.concat([fakeTicketsDeleted]);
  } else {
    result = result?.filter((item) => item.ticketGroupId !== DeletedTicketsPricingGroupFakeTicketId);
  }
  return result;
}

export const InventoryPropertiesOptions: { [key: string]: string } = {
  PricingGroups: 'PricingGroups',
  IsCeilingHit: 'IsCeilingHit',
  IsFloorHit: 'IsFloorHit',
  IsNoActiveCompHit: 'IsNoActiveCompHit',
  IsUnpriced: 'IsUnpriced',
} as const;

export const getRequestIdForTGShare = (ticketGroupId: number | undefined) => `${ticketGroupId}-TS`;
export const getRequestIdForTGPriceChange = (ticketGroupId: number | undefined) => `${ticketGroupId}-LP`;
