import type { ActionsSlice, ImmerStateCreator } from '@/utils/constants';
import { getEmptyArray } from '@/utils/constants';
import type { InventoryState } from '@/stores/inventory/inventory-store';
import { add } from 'date-fns';
import type { Action, PricingGroupHistoryDto } from '@/api/audit/auditApiSchemas';
import type { IOptionItem } from '@/interfaces/CommonInterfaces';
import { AuditActions } from '@/components/inventory/audit/constants';
import type { ClientUserAppResponse } from '@/api/clientService/clientServiceApiSchemas';

export type AuditPopUpSlice = {
  dateRange: Date[];
  auditActionFilters: IOptionItem<Action>[];
  auditMembers: IOptionItem<ClientUserAppResponse['username']>[] | undefined;
  showModificationsOnly: boolean;
  audits: PricingGroupHistoryDto[];
  totalFilteredItems: number;
  error: Error | null;
  isFetching: boolean;
  hasNextPage: boolean;
};

type AuditPopUpActions = {
  setDateRange: (dateRange: Date[]) => void;
  setAuditActionFilters: (auditActionFilters: IOptionItem<Action>[]) => void;
  setAuditMembers: (auditMembers: IOptionItem<ClientUserAppResponse['username']>[] | undefined) => void;
  setShowModificationsOnly: (showModificationsOnly: boolean) => void;
  setAudits: (audits: PricingGroupHistoryDto[]) => void;
  setTotalFilteredItems: (totalItems: number) => void;
  setError: (error: Error | null) => void;
  setIsFetching: (isFetching: boolean) => void;
  setHasNextPage: (hasNextPage: boolean) => void;
  resetFilters: () => void;
};

export type AuditPopUpSliceState = AuditPopUpSlice & ActionsSlice<AuditPopUpActions>;

const today = new Date();
const rangeDays = 3;
export const createAuditPopUpSlice: ImmerStateCreator<InventoryState, AuditPopUpSliceState> = (set, get) => ({
  dateRange: AuditDefaultDateRange(),
  auditActionFilters: AuditActionFilters(),
  auditMembers: undefined,
  showModificationsOnly: true,
  audits: getEmptyArray<PricingGroupHistoryDto>(),
  totalFilteredItems: 0,
  error: null,
  isFetching: false,
  hasNextPage: false,
  actions: {
    setDateRange: (dateRange: Date[]) => {
      set(({ auditPopUpSlice }) => {
        auditPopUpSlice.dateRange = dateRange;
      });
    },
    setAuditActionFilters: (auditActionFilters: IOptionItem<Action>[]) => {
      set(({ auditPopUpSlice }) => {
        auditPopUpSlice.auditActionFilters = auditActionFilters;
      });
    },
    setAuditMembers: (auditMembers: IOptionItem<ClientUserAppResponse['username']>[] | undefined) => {
      set(({ auditPopUpSlice }) => {
        auditPopUpSlice.auditMembers = auditMembers;
      });
    },
    setShowModificationsOnly: (showModificationsOnly: boolean) => {
      set(({ auditPopUpSlice }) => {
        auditPopUpSlice.showModificationsOnly = showModificationsOnly;
      });
    },
    setAudits: (audits: PricingGroupHistoryDto[]) => {
      set(({ auditPopUpSlice }) => {
        auditPopUpSlice.audits = audits;
      });
    },
    setTotalFilteredItems: (totalItems: number) => {
      set(({ auditPopUpSlice }) => {
        auditPopUpSlice.totalFilteredItems = totalItems;
      });
    },
    setError: (error: Error | null) => {
      set(({ auditPopUpSlice }) => {
        auditPopUpSlice.error = error;
      });
    },
    setIsFetching: (isFetching: boolean) => {
      set(({ auditPopUpSlice }) => {
        auditPopUpSlice.isFetching = isFetching;
      });
    },
    setHasNextPage: (hasNextPage: boolean) => {
      set(({ auditPopUpSlice }) => {
        auditPopUpSlice.hasNextPage = hasNextPage;
      });
    },

    resetFilters: () => {
      set(({ auditPopUpSlice }) => {
        auditPopUpSlice.dateRange = AuditDefaultDateRange();
        auditPopUpSlice.auditActionFilters = AuditActionFilters();
        auditPopUpSlice.auditMembers = undefined;
        auditPopUpSlice.showModificationsOnly = true;
      });
    },
  },
});

export const AuditDefaultDateRange = () => {
  return [
    add(today, {
      days: -rangeDays,
    }),
    today,
  ];
};

export const AuditActionFilters = () => {
  return Object.keys(AuditActions).map((action) => ({
    value: action as Action,
    label: AuditActions[action as Action],
    selected: false,
  }));
};
