import { NavLocationType } from '@/utils/constants';
import clsx from 'clsx';

interface IProps {
  width?: number;
  height?: number;
  className?: string;
  location: NavLocationType;
}

function NavigationLocationIcon({ location = NavLocationType.TOP, width, height, className = '' }: IProps) {
  return (
    <svg
      className={clsx(className, '!fill-transparent')}
      width={width || 19}
      height={height || 18}
      viewBox="0 0 19 18"
      fill="none"
    >
      {location === NavLocationType.TOP ? (
        <>
          <mask
            id="mask0_2179_48475"
            style={{
              maskType: 'alpha',
            }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="19"
            height="18"
          >
            <rect x="0.875" width="18" height="18" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_2179_48475)">
            <rect x="2.375" y="1.5" width="15" height="15" stroke="currentColor" />
            <line x1="7.875" y1="3" x2="11.875" y2="3" stroke="currentColor" strokeWidth={2} />
          </g>
        </>
      ) : null}

      {location === NavLocationType.LEFT ? (
        <>
          <mask
            id="mask0_2179_48481"
            style={{
              maskType: 'alpha',
            }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="19"
            height="18"
          >
            <rect x="0.625" width="18" height="18" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_2179_48481)">
            <rect x="2.125" y="1.5" width="15" height="15" stroke="currentColor" />
            <line x1="3.625" y1="11" x2="3.625" y2="7" stroke="currentColor" strokeWidth="2" />
          </g>
        </>
      ) : null}
      {location === NavLocationType.RIGHT ? (
        <>
          <mask
            id="mask0_2179_48493"
            style={{
              maskType: 'alpha',
            }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="19"
            height="18"
          >
            <rect x="0.125" width="18" height="18" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_2179_48493)">
            <rect x="1.625" y="1.5" width="15" height="15" stroke="currentColor" />
            <line x1="15.125" y1="11" x2="15.125" y2="7" stroke="currentColor" strokeWidth="2" />
          </g>
        </>
      ) : null}

      {location === NavLocationType.BOTTOM ? (
        <>
          <mask
            id="mask0_2179_48487"
            style={{
              maskType: 'alpha',
            }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="19"
            height="18"
          >
            <rect x="0.375" width="18" height="18" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_2179_48487)">
            <rect x="1.875" y="1.5" width="15" height="15" stroke="currentColor" />
            <line x1="7.375" y1="15" x2="11.375" y2="15" stroke="currentColor" strokeWidth="2" />
          </g>
        </>
      ) : null}
    </svg>
  );
}

export default NavigationLocationIcon;
