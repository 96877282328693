import React, { createContext } from 'react';
import type {
  MarketplaceCredentialRequestDto,
  MarketplaceCredentialResponseDto,
} from '@/api/marketplace/marketplaceApiSchemas';
import type { IMarketplaceConnectionTest } from '@/interfaces/marketplace/IMarketplaceConnectionTest';

interface IMarketplaceApi {
  refetchMarketplaceAll: () => void;
  addMarketplace: (
    marketplace: MarketplaceCredentialRequestDto,
    callback: (isSuccess: boolean) => void,
    isAnUpdate?: boolean,
    id?: number,
  ) => void;
  updateMarketplace: (
    marketplace: MarketplaceCredentialRequestDto,
    callback: (isSuccess: boolean) => void,
    id: number,
  ) => void;
  resetMutationState: () => void;
  testMarketplace: (marketplace: MarketplaceCredentialRequestDto, callback: (isSuccess: boolean) => void) => void;
  testExistingMarketplace: (
    marketplace: MarketplaceCredentialRequestDto,
    callback?: (isSuccess: boolean) => void,
  ) => void;
  deleteMarketplace: (marketplace: MarketplaceCredentialRequestDto, callback?: (isSuccess: boolean) => void) => void;
}

interface IMarketplaceState {
  marketplaceIntegrations: MarketplaceCredentialResponseDto[];
  isLoading: boolean;
  isSavingError: boolean;
  noMarketplace: boolean;
  pendingConnectionsTests: IMarketplaceConnectionTest[];
  pendingDeletions: number[];
}

const MarketplaceStateContext = React.createContext<IMarketplaceState | undefined>(undefined);
const MarketplaceApiContext = createContext<IMarketplaceApi | undefined>(undefined);

function useMarketplaceStateContext() {
  const context = React.useContext(MarketplaceStateContext);
  if (context === undefined) {
    throw new Error('useMarketplaceStateContext must be used within a MarketplaceStateContext');
  }
  return context;
}

function useMarketplaceIntegrationApiContext() {
  const context = React.useContext(MarketplaceApiContext);
  if (context === undefined) {
    throw new Error('useMarketplaceApiContext must be used within a MarketplaceApiContext');
  }
  return context;
}

export {
  MarketplaceStateContext,
  MarketplaceApiContext,
  useMarketplaceStateContext,
  useMarketplaceIntegrationApiContext,
};
export type { IMarketplaceState, IMarketplaceApi };
