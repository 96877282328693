import React, { useEffect, useMemo, useState } from 'react';
import type { INotification } from '@/interfaces/CommonInterfaces';
import { NotificationsStateContext, NotificationApiContext } from './context-hooks';

const getUnreadCount = (notifications: INotification[]) => {
  return notifications.filter((notification) => !notification.isRead).length;
};

function NotificationsContextProvider({ children }: { children: React.ReactNode }) {
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [unreadCount, setUnreadCount] = useState<number>(0);

  useEffect(() => {
    setUnreadCount(getUnreadCount(notifications));
  }, [notifications]);

  const api = useMemo(
    () => ({
      pushNotification: (notification: INotification) => {
        setNotifications((notifications) => {
          const index = notifications.findIndex((n) => n.id === notification.id);
          if (index > -1) {
            notifications[index] = notification;
            return [...notifications];
          }

          notifications.push(notification);
          return [...notifications];
        });
      },

      readNotification: (id: string) => {
        setNotifications((notifications) => {
          notifications[notifications.findIndex((notification) => notification.id === id)].isRead = true;
          return [...notifications];
        });
      },

      readAllNotifications: () => {
        setNotifications((notifications) => {
          notifications.forEach((notification) => (notification.isRead = true));
          return [...notifications];
        });
      },
    }),
    [setNotifications],
  );

  return (
    <NotificationsStateContext.Provider value={{ notifications, unreadCount }}>
      <NotificationApiContext.Provider value={api}>{children}</NotificationApiContext.Provider>
    </NotificationsStateContext.Provider>
  );
}

export { NotificationsContextProvider };
