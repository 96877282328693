import { createContext, useContext } from 'react';
import type { Dispatch, SetStateAction } from 'react';
import type { GetPricingTemplateProductionsQueryParams } from '@/api/pricer/pricerApiComponents';
import type {
  PricingGroupTicketsResult,
  PricingTemplateInventoryResponseDto,
  PricingTemplateProductionResponseDto,
  PricingTemplateSearchResponseDto,
} from '@/api/pricer/pricerApiSchemas';
import type { columnSortType } from '@/context/pricer/context-hooks';

interface Step {
  label: string;
  isSelected: boolean;
  count: string;
  index: number;
  hidden?: boolean;
}

enum StepNames {
  SelectProductions = 0,
  SelectTicketGroups = 1,
  ReviewPricingGroups = 2,
  ApplyHiddenStep = 3,
}

interface PricingTemplateInventoryResponseDtoSelectable extends PricingTemplateInventoryResponseDto {
  selected?: boolean;
  loading?: boolean;
  createdPG?: boolean;
  failedCalculatePrice?: boolean;
  pricingGroupRequestRankResults?: PricingGroupTicketsResult;
}

interface ITemplatePageState {
  pricingTemplate: PricingTemplateSearchResponseDto | undefined;
  productionsPerTemplateId: PricingTemplateProductionResponseDto[] | undefined;
  pricingTemplateProductionsQueryParams: GetPricingTemplateProductionsQueryParams;
  isPricingTemplateFetching: boolean | undefined;
  isProductionsPerTemplateIdFetching: boolean | undefined;
  pricingTemplateError: boolean | undefined;
  hasNextPageProductionsPerTemplateId: boolean | undefined;
  totalRecords: number | undefined;
  sortColumns: columnSortType;
  selectedDateRange: Date[];
  selectedProductionIds: number[];
  steps: Step[];
  currentStepIndex: number;
  showUnavailableProductions: boolean;
  pricingTemplateInventory: PricingTemplateInventoryResponseDtoSelectable[] | undefined;
  inventoryForPricingGroups: PricingTemplateInventoryResponseDtoSelectable[] | undefined;
  isInventoryFetching: boolean | undefined;
  showUnavailableTickets: boolean;
  productionAllTicketsSelected: Record<number, boolean>;
  searchTerm: string;

  groupsFinishedCount: number;
  groupsTotalCount: number;
  pricingGroupsCreationFinished: boolean;
  isSuccessfulCreation: boolean;
  isFailedCreation: boolean;
  createPricingGroupsEnabled: boolean;
  selectedInventoryTickets: PricingTemplateInventoryResponseDtoSelectable[];
  selectedTicketsByProduction: Record<number, number>;
}

interface ITemplatePageApi {
  setPricingTemplateProductionsQueryParams: (params: GetPricingTemplateProductionsQueryParams) => void;
  setSortColumns: (columns: columnSortType) => void;
  setPricingTemplateId: (pricingTemplateId: number | undefined) => void;
  setSelectedDateRange: (range: Date[]) => void;
  setSelectedProductionIds: (ids: number[]) => void;

  nextStep: () => void;
  previousStep: () => void;
  setShowUnavailableProductions: (show: boolean) => void;
  fetchNextPageProductionsPerTemplateId: () => Promise<void>;
  refetchPricingTemplateProductions: () => void;
  setShowUnavailableTickets: (show: boolean) => void;
  toggleInventoryById: (id: number, markSelected: boolean) => void;
  toggleAllInventoryForProduction: (productionId: number, markSelected: boolean) => void;
  setInventory: Dispatch<SetStateAction<PricingTemplateInventoryResponseDtoSelectable[]>>;
  refetchInventoryWithProductionId: (productionId: number) => void;
  setSearchTerm: (term: string) => void;

  startRetryFlow: () => void;
  cancelFlow: () => void;
  getPricingGroupCalculationForProduction: (productionId: number) => void;
}

const TemplatePageStateContext = createContext<ITemplatePageState | undefined>(undefined);
const TemplatePageApiContext = createContext<ITemplatePageApi | undefined>(undefined);

function useTemplatePageState() {
  const context = useContext(TemplatePageStateContext);
  if (context === undefined) {
    throw new Error('useTemplatesListState must be used within a TemplatePageStateContext');
  }
  return context;
}

function useTemplatePageApi() {
  const context = useContext(TemplatePageApiContext);
  if (context === undefined) {
    throw new Error('useTemplatesListApi must be used within a TemplatePageApiContext');
  }
  return context;
}

export { useTemplatePageState, useTemplatePageApi, TemplatePageStateContext, TemplatePageApiContext, StepNames };
export type { ITemplatePageState, ITemplatePageApi, Step, PricingTemplateInventoryResponseDtoSelectable };
