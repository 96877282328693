import { FullStory } from '@fullstory/browser';
import { appInsights } from '../appinsights';
import type { PublicVarScope } from '@fullstory/snippet';

function FSRegisterPageProperties(pageName: string) {
  if (import.meta.env.DEV) {
    return;
  }

  const pageProperties = {
    type: 'page' as PublicVarScope,
    properties: {
      pageName,
      appInsightsTrackId: appInsights.getTraceCtx()?.getTraceId(),
    },
  };

  FullStory('setProperties', pageProperties);
}

export default FSRegisterPageProperties;
