import { Fragment, useState } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { MdArrowDropDown } from 'react-icons/md';
import ctl from '@netlify/classnames-template-literals';
import clsx from 'clsx';
import { CheckIcon } from '@/components/icons';
import { cva } from 'cva';
import type { DropDownSizesType, DropDownVariantsType } from '../variations';

interface IProps {
  data: (string | null | undefined)[];
  className?: string;
  value?: string;
  setValue: (value: string) => void;
  label?: string;
  placeholder?: string;
  variant?: DropDownVariantsType;
  size?: DropDownSizesType;
  disabled?: boolean;
  isUnderlineVisible?: boolean;
}

function SelectionDropSearch({
  data,
  className = '',
  value,
  setValue,
  label,
  placeholder,
  variant = 'filled',
  size = 'lg',
  disabled = false,
  isUnderlineVisible = true,
}: IProps) {
  const [query, setQuery] = useState('');

  const filterItems =
    query === ''
      ? data
      : data.filter(
          (item) => item?.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, '')),
        );

  const showUnderline = isUnderlineVisible && variant !== 'outline' && variant !== 'clean';
  const showLabel = size === 'xl' && label;

  const renderTrigger = () => {
    return (
      <div className={styles.inputContainer}>
        <Combobox.Input
          displayValue={(item: string) => item}
          onChange={(event) => setQuery(event.target.value)}
          className={variants({
            variant,
            size,
          })}
          placeholder={placeholder}
          aria-disabled={disabled}
        />
        {showLabel && (
          <label className={styles.label} aria-disabled={disabled}>
            {label}
          </label>
        )}
        <Combobox.Button className="drop-down-button">
          <MdArrowDropDown className="drop-down-icon" aria-hidden="true" />
        </Combobox.Button>
        {showUnderline && <div className={styles.underline} aria-hidden="true" />}
      </div>
    );
  };

  const renderMenuOption = (item: string | null | undefined) => {
    return (
      <Combobox.Option
        key={item}
        className={({ active }) => clsx(styles.menuItem, active ? styles.menuItemActive : '')}
        value={item}
      >
        {({ selected, active }) => (
          <>
            <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>{item}</span>
            {selected ? (
              <span className={clsx(styles.checkIcon, active ? styles.checkIconActive : '')}>
                <CheckIcon className="h-5 w-5" aria-hidden="true" />
              </span>
            ) : null}
          </>
        )}
      </Combobox.Option>
    );
  };

  const renderMenu = () => {
    return (
      <Transition
        as={Fragment}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        afterLeave={() => setQuery('')}
      >
        <Combobox.Options className="drop-down-menu">
          {filterItems.length === 0 && query !== '' ? (
            <div className={styles.nothing}>Nothing found.</div>
          ) : (
            filterItems.map((item) => renderMenuOption(item))
          )}
        </Combobox.Options>
      </Transition>
    );
  };

  return (
    <div className={clsx('z-10 my-2', className)}>
      <Combobox value={value} onChange={setValue} disabled={disabled}>
        <div className="relative">
          {renderTrigger()}
          {renderMenu()}
        </div>
      </Combobox>
    </div>
  );
}

const styles = {
  inputContainer: ctl(`
    relative 
    w-full
    cursor-default
    overflow-hidden
    text-left
    text-sm`),
  underline:
    'absolute inset-x-0 bottom-0 border-t border-input-border peer-focus:border-t-2 peer-focus:border-primary-alt-med',
  nothing: 'relative cursor-default select-none py-2 px-4 text-txt-secondary text-sm',
  menuItem: 'relative cursor-default select-none py-2 pl-10 pr-4 text-md text-txt-secondary',
  menuItemActive: 'bg-primary-alt-light text-white',
  checkIcon: 'absolute inset-y-0 left-0 flex items-center pl-3 text-txt-secondary',
  checkIconActive: '!text-white',
  label: ctl(`
    block 
    text-sm 
    font-medium 
    text-txt-secondary
    absolute 
    -top-0.5 
    left-0 
    h-full 
    px-3 
    py-5 
    order-2 
    transition-all 
    duration-100 
    ease-in-out 
    origin-left 
    transform 
    scale-75 
    translate-x-1 
    -translate-y-3 
    opacity-75 
    pointer-events-none 
    peer-placeholder-shown:opacity-75 
    peer-focus:opacity-75 
    peer-placeholder-shown:scale-75 
    peer-focus:scale-75 
    peer-placeholder-shown:-translate-y-3 
    peer-focus:-translate-y-3 
    peer-placeholder-shown:translate-x-1
    peer-focus:translate-x-1
    peer-focus:text-primary-main
    aria-disabled:text-txt-disabled`),
};

const variants = cva(
  'block w-full peer border-input-border shadow-sm text-txt-primary text-md text-left min-w-24 focus:outline-none focus:ring-0 aria-disabled:bg-action-disabled-bg',
  {
    variants: {
      variant: {
        filled: 'bg-filled-input-bg rounded-t-md shadow-sm border-0 focus:box-shadow-none',
        outline:
          'bg-transparent py-0.5 px-0.5 rounded-sm border focus:box-shadow-none selection:bg-action-selected aria-disabled:border-0',
        clean: 'bg-filled-input-bg border-0 hover:shadow-none ',
      },
      size: {
        sm: 'h-5 text-xs px-1',
        md: 'h-[1.625rem] px-1 text-xs',
        lg: 'h-8 px-3 text-md',
        xl: 'h-[3.25rem] px-3 text-md pt-6',
      },
    },
    defaultVariants: {
      variant: 'filled',
      size: 'xl',
    },
  },
);

export default SelectionDropSearch;
