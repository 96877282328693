class InputFormatting {
  static formatPhoneNumber(phone: string): string {
    if (!phone || phone === '') return '';
    const numberSanitized = this.removeNonNumber(phone.trim()).substring(0, 10);
    if (numberSanitized.length === 0) return '';
    let formatted = `(${numberSanitized.substring(0, 3)}`;
    if (numberSanitized.length > 3) formatted = formatted + ') ';
    formatted = formatted + numberSanitized.substring(3, 6);
    if (numberSanitized.length > 6) formatted = formatted + '-';
    formatted = formatted + numberSanitized.substring(6);
    return formatted;
  }

  static removeNonNumber = (v: string = ''): string => (!v ? '' : v.replace(/[^\d]/g, ''));
}

export default InputFormatting;
