import { createContext, useContext } from 'react';
import type { IDataField } from '@/interfaces/CommonInterfaces';

const ConfigurationContext = createContext<{ countries: IDataField[]; regions: IDataField[] }>({
  countries: [],
  regions: [],
});

function useConfigurationContext() {
  const context = useContext(ConfigurationContext);
  if (context === undefined) {
    throw new Error('useConfigurationContext must be used within a ConfigurationContextProvider');
  }
  return context;
}

export { useConfigurationContext, ConfigurationContext };
