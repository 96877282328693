import type { ReactNode } from 'react';

interface iProps {
  hotkey: ReactNode;
  description: string;
}

function KeyboardMapItem({ hotkey, description }: iProps) {
  return (
    <div className="flex flex-row items-center gap-1">
      <span className="inline-flex flex-row font-bold">Alt + {hotkey}</span>
      <span className="text-xs">{description}</span>
    </div>
  );
}

export default KeyboardMapItem;
