import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useBlocker } from 'react-router-dom';
import type { unstable_BlockerFunction as BlockerFunction } from 'react-router-dom';
import { useGetMenuItems, useGetRolesApp } from '@/api/clientService/clientServiceApiComponents';
import type { RoleDto } from '@/api/clientService/clientServiceApiSchemas';
import { CommonContext, CommonContextApiContext } from './context-hooks';
import type { IUserLeavesEditMode } from './context-hooks';
import { UnsavedChangesBrowserManager } from '@/utils/UnsavedChangesBrowserManager';

function CommonContextProvider(props: React.PropsWithChildren<{}>) {
  const { data: rolesData, isLoading: isRolesLoading } = useGetRolesApp({});
  const { data: featuresData, isLoading: isFeaturesLoading } = useGetMenuItems({});

  const roles = React.useMemo(() => {
    return rolesData ? rolesData : [];
  }, [rolesData]);

  const [stateHasUnsavedChanges, setStateHasUnsavedChanges] = useState<boolean>(false);
  const [userLeavesEditMode, setUserLeavesEditMode] = useState<IUserLeavesEditMode>({
    userLeaves: false,
  });

  const runUserDropChangesFn = useCallback(() => {
    setStateHasUnsavedChanges(false);
    setUserLeavesEditMode({ userLeaves: false });

    // leave function should be the last one to be able to set the Unsaved changes = true
    userLeavesEditMode.leaveFunction?.();
  }, [userLeavesEditMode]);

  // proceeds with leave if all changes are saved
  useEffect(() => {
    if (userLeavesEditMode.userLeaves && !stateHasUnsavedChanges) {
      // resets the state to 'not leaving' and runs the leave function
      setUserLeavesEditMode({ userLeaves: false });

      // leave function should be the last one to be able to set the Unsaved changes = true
      userLeavesEditMode.leaveFunction?.();
    }
  }, [userLeavesEditMode]);

  // shows browser alert when user tries to leave page with unsaved changes
  useEffect(() => {
    if (stateHasUnsavedChanges) {
      UnsavedChangesBrowserManager.attach();
      return () => {
        UnsavedChangesBrowserManager.detach();
      };
    } else {
      UnsavedChangesBrowserManager.detach();
    }
  }, [stateHasUnsavedChanges]);

  // react-router navigation block
  const shouldBlock = React.useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) => {
      return (
        stateHasUnsavedChanges &&
        `${currentLocation.pathname}${currentLocation.search}` !== `${nextLocation.pathname}${nextLocation.search}`
      );
    },
    [stateHasUnsavedChanges],
  );
  const blocker = useBlocker(shouldBlock);
  useEffect(() => {
    if (blocker.state === 'blocked' && !stateHasUnsavedChanges) {
      blocker.reset();
    }
  }, [blocker, stateHasUnsavedChanges]);

  const hasAccessToFeature = (feature: string) => {
    return featuresData?.menuItems?.some((item) => item.key === feature);
  };

  return (
    <CommonContext.Provider
      value={{
        rbacFeatures: featuresData,
        roles: roles as RoleDto[],
        isLoading:
          isRolesLoading || isFeaturesLoading || !featuresData?.menuItems || featuresData?.menuItems?.length === 0,
        stateHasUnsavedChanges,
        userLeavesEditMode,
        routerBlocker: blocker,
        hasAccessToFeature,
      }}
    >
      <CommonContextApiContext.Provider
        value={{
          // to be called when user tries to leave page (toggle pricing mode)
          setUserLeavesEditMode,
          runUserDropChangesFn,

          // marks page as dirty
          setStateHasUnsavedChanges,
        }}
      >
        {props.children}
      </CommonContextApiContext.Provider>
    </CommonContext.Provider>
  );
}

export { CommonContextProvider };
