import { useMutation } from '@tanstack/react-query';
import type { ComparablesQueryDto } from '@/api/pricer/pricerApiSchemas';
import { fetchPostComparablesValidation } from '@/api/pricer/pricerApiComponents';
import { usePricerApiContext } from '@/api/pricer/pricerApiContext';
import { useMutationsSliceInventoryActions, useMutationsSliceInventoryStore } from '@/stores/inventory/inventory-store';

export const ValidateComparablesMutationKey = ['fetchPostComparablesValidation'];

function useValidateComparablesQuery() {
  const { fetcherOptions } = usePricerApiContext();
  const { setValidatedComparables, setIsComparablesValidating } = useMutationsSliceInventoryActions();
  const isComparablesValidating = useMutationsSliceInventoryStore('isComparablesValidating');
  const validatedComparables = useMutationsSliceInventoryStore('validatedComparables');

  const { mutate: validateComparables } = useMutation({
    mutationKey: ValidateComparablesMutationKey,
    mutationFn: async (req: ComparablesQueryDto) => {
      setIsComparablesValidating(true);
      setValidatedComparables(undefined);
      return fetchPostComparablesValidation({
        ...fetcherOptions,
        body: req,
      });
    },
    onSettled: (data, error, variables, context) => {
      if (error) {
        setValidatedComparables(undefined);
      }
      if (data) {
        setValidatedComparables(data);
      }
      setIsComparablesValidating(false);
    },
  });

  return {
    validateComparables,
    validatedComparables,
    isComparablesValidating,
  };
}

export default useValidateComparablesQuery;
