import { FullStory } from '@fullstory/browser';
import { appInsights } from '@/appinsights';

function FsTrackEvent(name: string, properties: Record<string, any> = {}) {
  if (!name) {
    console.error('FullStory event name is required');
    return;
  }
  if (import.meta.env.DEV) {
    return;
  }

  const options = {
    name,
    properties: {
      ...properties,
      correlationID: appInsights.getTraceCtx()?.getTraceId(),
    },
  };

  FullStory('trackEvent', options);
}

export const EventNames = {
  PricingMode: 'pricingModeToggle',
  appliedPricingGroup: 'appliedPricingGroup',
  comparableFiltersUsed: 'comparableFiltersUsed',
  createdTemplate: 'createdTemplate',
  applyTemplate: 'applyTemplate',
  deleteTemplate: 'deleteTemplate',
  completeTemplate: 'completeTemplate',
  unknownError: 'unknownError',
  errorPage: 'errorPage',
  errorBoundary: 'errorBoundary',
  productionAlreadyMappedDataSaysNo: 'productionAlreadyMappedDataSaysNo',
};

export default FsTrackEvent;
