export default {
  noResults: 'No POS data found',
  completedCredentialsTitle: 'Completed testing credentials',
  completedCredentialsDesc: 'To commit the change, save the credentials.',
  PosNextSelectedTitle: 'Integration is in the works',
  PosNextSelectedDesc: "We will let you know when it's ready.",
  validCredentialsTitle: 'Valid Credentials',
  validCredentialsDesc: 'Credentials are encrypted. You can enter new credentials and test.',
  invalidCredentialsTitle: 'Invalid Credentials',
  buttons: {
    testCredentials: 'Test Credentials',
    addNew: 'Add new POS',
    change: 'Change',
    close: 'Close',
  },
  labels: {
    pos: 'Point of Sale (POS)',
    authentication: 'Authentication credentials',
    infoTitle: 'You can configure Point of Sale credentials to operate PRQX system.',
    infoBody: 'Only one POS can be used.',
    connected: 'Connected',
    notConnected: 'Not Connected',
    addNewPos: 'Add new POS',
    editPos: 'Edit POS',
    posDashed: 'Point-of-Sale (POS)',
    posSystem: 'POS system',
    posName: 'POS name',
    shortName: 'Short name',
    selectTndPosType: 'Select TND POS Version',
  },
  placeholders: {
    posName: 'Enter POS name',
    shortName: 'Enter short name',
    selectOne: 'Select one',
    enterAccountID: 'Enter account ID',
    enterApiToken: 'Enter API token',
    enterBrokerToken: 'Enter broker token',
    enterBrokerID: 'Enter broker id',
  },
  columns: {
    sourceType: 'POS system',
    name: 'POS name',
    abbreviation: 'Short name',
    posValidDate: 'Updated',
    isPosValid: 'Credentials',
    action: 'Action',
  },
  status: {
    valid: 'Valid',
    invalid: 'Invalid',
    testConnection: 'Test connection',
  },
  errors: {
    editPosLoadTitle: 'Edit POS failed',
    editPosLoader: 'Error loading POS data, please try again later.',
    name: 'Please enter a valid POS name that is between %{minLength} and %{maxLength} characters long',
    shortName: 'Please enter a valid short name that is between %{minLength} and %{maxLength} characters long',
    accountId: 'Account ID must be numeric',
    brokerId: 'Broker ID must be numeric',
  },
  hint: {
    comingSoon: 'PosNext is coming soon.',
  },
  menu: {
    testConnection: 'Test connection',
    editDetails: 'Edit details',
    delete: 'Delete POS',
  },
  notifications: {
    connection: {
      loading: {
        title: 'Testing connection',
        message: 'Testing connection to POS %{name}.',
      },
      success: {
        title: 'Success',
        message: 'Connection test to POS %{name} was successful.',
      },
      error: {
        title: 'Oops! Something went wrong!',
        message: 'Connection test to POS %{name} failed.',
      },
      invalid: {
        title: 'Invalid Credentials',
        message: 'Please enter valid credentials.',
      },
    },
    save: {
      loading: {
        title: 'Saving POS',
        message: 'Saving POS %{name}.',
      },
      success: {
        title: 'Success',
        message: 'POS %{name} was successfully saved.',
      },
      error: {
        title: 'Oops! Something went wrong!',
        message: 'Failed to save POS %{name}.',
      },
    },
    delete: {
      loading: {
        title: 'Deleting',
        message: 'Deleting from POS %{name}.',
      },
      success: {
        title: 'Success',
        message: 'POS %{name} was successfully deleted.',
      },
      error: {
        title: 'Oops! Something went wrong!',
        message: 'Failed to delete POS %{name}.',
      },
    },
    deleteModal: {
      title: 'Delete selected POS?',
      statement: 'You have selected to delete POS.',
      description: 'This action will invalidate data and pricing criteria associated to the POS.',
      confirmCTA: 'Confirm Delete',
      inputTitle: 'Type in the POS name to delete',
      inputLabel: 'POS name',
      name: 'POS name',
      shortName: 'Short name',
      updated: 'Updated',
      noMatch: 'POS name does not match',
      inputPlaceholder: 'Enter POS name',
    },
  },
};
