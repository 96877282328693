import { createContext, useContext } from 'react';
import type { Dispatch, SetStateAction } from 'react';
import type { unstable_Blocker as Blocker } from 'react-router';
import type { ClientUserAppMenuItemsResponse, RoleDto } from '@/api/clientService/clientServiceApiSchemas';

interface IUserLeavesEditMode {
  userLeaves: boolean;
  leaveFunction?: () => void;
}

interface ICommonContextState {
  rbacFeatures: ClientUserAppMenuItemsResponse | undefined;
  roles: RoleDto[] | never[];
  isLoading: boolean;

  stateHasUnsavedChanges: boolean;
  userLeavesEditMode: IUserLeavesEditMode;
  routerBlocker: Blocker | null;
  hasAccessToFeature: (feature: string) => boolean | undefined;
}

interface ICommonContextApi {
  setUserLeavesEditMode: Dispatch<SetStateAction<IUserLeavesEditMode>>;
  runUserDropChangesFn: () => void;
  setStateHasUnsavedChanges: Dispatch<SetStateAction<boolean>>;
}

const CommonContext = createContext<ICommonContextState | undefined>(undefined);
const CommonContextApiContext = createContext<ICommonContextApi | undefined>(undefined);

function useCommonContext() {
  const context = useContext(CommonContext);
  if (context === undefined) {
    throw new Error('useCommonContext must be used within a CommonContext');
  }
  return context;
}

function useCommonContextApi() {
  const context = useContext(CommonContextApiContext);
  if (context === undefined) {
    throw new Error('useCommonContextApi must be used within a CommonContextApiContext');
  }
  return context;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DEFAULT_CONTEXT_VALUES: { state: any; selectors: any; dispatch: any } = {
  state: {},
  selectors: {},
  dispatch: {},
};

export { useCommonContext, useCommonContextApi, CommonContext, CommonContextApiContext };
export type { IUserLeavesEditMode, ICommonContextState, ICommonContextApi };
