import { isValid, parse } from 'date-fns';

// no leading zeros
const dateFormatFullYearNoZerosDashes = 'M/d/yyyy';
const dateFormat2DigitYearNoZerosDashes = 'M/d/yy';
const dateFormatFullYearNoZerosNoDashes = 'Mdyyyy';
const dateFormat2DigitYearNoZerosNoDashes = 'Mdyy';

// two digit year
const dateFormat2DigitYearZerosDashes = 'MM/dd/yy';
const dateFormat2DigitYearZerosNoDashes = 'MMddyy';

// four digit year
const dateFormatFullYearZerosNoDashes = 'MMddyyyy';
export const dateFormatFullYear = 'MM/dd/yyyy';

const today = new Date();
const invalidDate = new Date('');

export const isValidDate = (date: Date): boolean => {
  return isValid(date);
};

export function ParseInputDate(inputValue: string): Date {
  const input = inputValue.replace(/\/+/g, '/').replace(/^\//g, '').replace(/\/$/g, '');
  if (input.length < 4) {
    return invalidDate;
  }
  if (!input.includes('/')) {
    if (input.length === 4) {
      const dateYear = parse(input.substring(2), 'yy', today);
      const dateDayMonth = parse(input.substring(0, 2), 'Md', today);

      dateDayMonth.setFullYear(dateYear.getFullYear());
      return dateDayMonth;
    }

    const newDateNoDashes2DigitYear = parse(input, dateFormat2DigitYearZerosNoDashes, today);
    const newDateNoDashesFullYear = parse(input, dateFormatFullYearZerosNoDashes, today);
    const newDateNoDashes2DigitYearNoZeros = parse(input, dateFormat2DigitYearNoZerosNoDashes, today);
    const newDateNoDashesFullYearNoZeros = parse(input, dateFormatFullYearNoZerosNoDashes, today);

    // from the smallest format to the largest
    if (isValidDate(newDateNoDashes2DigitYearNoZeros)) {
      return newDateNoDashes2DigitYearNoZeros;
    }
    if (isValidDate(newDateNoDashesFullYearNoZeros)) {
      return newDateNoDashesFullYearNoZeros;
    }
    if (isValidDate(newDateNoDashes2DigitYear)) {
      return newDateNoDashes2DigitYear;
    }
    if (isValidDate(newDateNoDashesFullYear)) {
      return newDateNoDashesFullYear;
    }

    return newDateNoDashesFullYear;
  } else {
    const newDateFullYear = parse(input, dateFormatFullYear, today);
    const newDate2DigitYear = parse(input, dateFormat2DigitYearZerosDashes, today);
    const newDate2DigitYearNoZeros = parse(input, dateFormat2DigitYearNoZerosDashes, today);
    const newDateFullYearNoZeros = parse(input, dateFormatFullYearNoZerosDashes, today);

    // from the smallest format to the largest
    if (isValidDate(newDate2DigitYearNoZeros)) {
      return newDate2DigitYearNoZeros;
    }

    if (isValidDate(newDateFullYearNoZeros)) {
      return newDateFullYearNoZeros;
    }

    if (isValidDate(newDate2DigitYear)) {
      return newDate2DigitYear;
    }

    if (isValidDate(newDateFullYear)) {
      return newDateFullYear;
    }

    return newDateFullYear;
  }
}
