import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import type { RedirectRequest } from '@azure/msal-browser';
import Layout from '../components/Layout';
import { LoadingPage, UnknownError } from './errors';
import i18n from '@/utils/i18n';
import { useEffect } from 'react';
import { useUserState } from '@/context/user/context-hooks';
import { PricerContextProvider } from '@/context/pricer/context';
import { NotificationsContextProvider } from '@/context/notifications/context';
import { AppModeIsDrive } from '@/utils/constants';
import { AuthService } from '@/services/AuthService';
import MsalProviderInitializer from './MsalProviderInitializer';
import { MarketplaceContextProvider } from '@/context/marketplace/context';
import UserIdleTracker from './UserIdleTracker';
import useFSUserIdentity from '@/services/fs-user-identity';
import { useCommonContext } from '@/context/common/context-hooks';
import { OrganizationContextProvider } from '@/context/organization/context';
import { useOrganizationContext } from '@/context/organization/context-hooks';
import { PosContextProvider } from '@/context/pos/context';
import { UserContextProvider } from '@/context/user/context';
import { CommonContextProvider } from '@/context/common/context';
import { NotificationPreferenceContextProvider } from '@/context/notificationPreferences/context';
import useAppInsights from '@/hooks/useAppInsights';

function ErrorComponent({ error }: any) {
  const isCanceledByUser = error.errorMessage?.includes('AADB2C90091');

  useEffect(() => {
    if (isCanceledByUser) window.location.replace('/');
  }, []);

  return isCanceledByUser ? (
    <LoadingPage text={i18n.t('auth.inProgress')} />
  ) : (
    <UnknownError
      onHome={() => window.location.replace('/')}
      text={i18n.t('buttons.backHome').toUpperCase()}
      techDetails={error.errorMessage}
    />
  );
}

function LoadingComponent() {
  return <LoadingPage text={i18n.t('auth.inProgress')} />;
}

function LayoutLoader() {
  useAppInsights();
  const { isLoading: isCommonLoading } = useCommonContext();
  const { isLoading: isUserLoading, isLoadingUserPrefs } = useUserState();
  const { isOrganizationLoading } = useOrganizationContext();

  useFSUserIdentity();

  return (
    <>
      <UserIdleTracker />
      {isCommonLoading || isUserLoading || isOrganizationLoading || isLoadingUserPrefs ? (
        <LoadingPage text={i18n.t('loading')} />
      ) : (
        <Layout />
      )}
    </>
  );
}

function Root() {
  let authRequest = undefined;
  if (AppModeIsDrive) {
    const response = AuthService.sbDriveLogin();

    const redirector = response as { redirecting: boolean };
    authRequest = response as RedirectRequest;

    if ((redirector && redirector.redirecting) || authRequest === null) {
      return <></>;
    }
  }

  return (
    <MsalProviderInitializer>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
        errorComponent={ErrorComponent}
        loadingComponent={LoadingComponent}
      >
        <NotificationsContextProvider>
          <UserContextProvider>
            <PosContextProvider>
              <MarketplaceContextProvider>
                <CommonContextProvider>
                  <NotificationPreferenceContextProvider>
                    <OrganizationContextProvider>
                      <PricerContextProvider>
                        <LayoutLoader />
                      </PricerContextProvider>
                    </OrganizationContextProvider>
                  </NotificationPreferenceContextProvider>
                </CommonContextProvider>
              </MarketplaceContextProvider>
            </PosContextProvider>
          </UserContextProvider>
        </NotificationsContextProvider>
      </MsalAuthenticationTemplate>
    </MsalProviderInitializer>
  );
}

export default Root;
