import type { IDataField } from '@/interfaces/CommonInterfaces';
import i18n from '@/utils/i18n';

export enum NotificationPreferenceOptions {
  AllEvents = 'isOptInEmail',
  AssignedEvents = 'isUserOnly',
  None = 'none',
}

export enum NotificationTypeKeys {
  HitFloor = 'pricer_ticket_group_hit_floor',
  HitCeiling = 'pricer_ticket_group_hit_ceiling',
  NoComps = 'pricer_ticket_group_hit_no_comp',
}

export const notificationPreferenceOptions: IDataField[] = [
  {
    name: i18n.t('profile.notifications.allEvents'),
    key: NotificationPreferenceOptions.AllEvents,
    selected: false,
  },
  {
    name: i18n.t('profile.notifications.myAssigned'),
    key: NotificationPreferenceOptions.AssignedEvents,
    selected: false,
  },
  {
    name: i18n.t('profile.notifications.none'),
    key: NotificationPreferenceOptions.None,
    selected: false,
  },
];
