import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { StepNames, TemplatePageApiContext, TemplatePageStateContext } from './template-page-context-hooks';
import type { ITemplatePageApi, PricingTemplateInventoryResponseDtoSelectable } from './template-page-context-hooks';
import { usePostPricingGroup } from '@/api/pricer/pricerApiComponents';
import { AuthApiFetcherOptions } from '@/api/AuthApiContext';
import type { PricingGroupRequest } from '@/api/pricer/pricerApiSchemas';
import useProductionsForTemplate from '@/routes/templates/templatePage/useProductionsForTemplate';
import useStepWizardForTemplates from '@/routes/templates/templatePage/useStepWizardForTemplates';
import useInventoryForTemplates from '@/routes/templates/templatePage/useInventoryForTemplates';
import { seriesAsync } from '@/utils/seriesAsync';
import useCalculatePriceForInventory from '@/routes/templates/templatePage/useCalculatePriceForInventory';
import { ProductionInventoryLoading } from './template-constants';

function TemplatePageContextProvider(props: React.PropsWithChildren<{}>) {
  const {
    totalRecords,
    productionsPerTemplateId,
    pricingTemplateId,
    setSortColumns,
    setPricingTemplateId,
    setPricingTemplateProductionsQueryParams,
    setShowUnavailableProductions,
    setSelectedDateRange,
    hasNextPageProductionsPerTemplateId,
    fetchNextPageProductionsPerTemplateId,
    refetchPricingTemplateProductions,
    pricingTemplate,
    isPricingTemplateFetching,
    pricingTemplateProductionsQueryParams,
    isProductionsPerTemplateIdFetching,
    pricingTemplateError,
    sortColumns,
    selectedDateRange,
    showUnavailableProductions,
    sortedSelectedProductionIds,
    selectedProductionIds,
    setSelectedProductionIds,
    setSearchTerm,
    searchTerm,
  } = useProductionsForTemplate();

  const { nextStep, previousStep, currentStepIndex, visibleSteps } = useStepWizardForTemplates();
  const [isRetryFlow, setIsRetryFlow] = useState<boolean>(false);

  const {
    refetchInventory,
    inventory,
    setInventory,
    inventoryForPricingGroups,
    setInventoryForPricingGroups,
    isInventoryFetching,
    showUnavailableTickets,
    setShowUnavailableTickets,
    productionAllTicketsSelected,

    toggleInventoryById,
    toggleAllInventoryForProduction,
    resetInventoryState,
    refetchInventoryWithProductionId,

    getGroupedInventory,
    selectedInventoryTickets,
    selectedTicketsByProduction,
  } = useInventoryForTemplates({
    sortedSelectedProductionIds,
    pricingTemplateId,
    isRetryFlow,
  });

  useEffect(() => {
    setInventory([]);
  }, [selectedProductionIds]);

  useEffect(() => {
    if (currentStepIndex === StepNames.SelectTicketGroups) {
      setInventory([]);
      selectedProductionIds.forEach((productionId) => {
        refetchInventoryWithProductionId(productionId, selectedProductionIds);
      });
    }
  }, [showUnavailableTickets, selectedProductionIds]);

  const { fetchCalculatePrice, getPricingGroupCalculationForProduction } = useCalculatePriceForInventory({
    currentStepIndex,
    pricingTemplate,
    setInventoryForPricingGroups,
  });

  const createPricingGroupMutation = usePostPricingGroup({});
  const [groupsTotalCount, setGroupsTotalCount] = useState<number>(0);
  const [groupsFinishedCount, setGroupsFinishedCount] = useState<number>(0);
  const [createPricingGroupsEnabled, setCreatePricingGroupsEnabled] = useState<boolean>(true);
  const [pricingGroupsCreationFinished, setPricingGroupsCreationFinished] = useState<boolean>(false);
  const [failedProductionIds, setFailedProductionIds] = useState<number[]>([]);

  function updateInventoryForPricingGroups(
    ticketGroups: PricingTemplateInventoryResponseDtoSelectable[],
    createdPG: boolean,
  ) {
    setInventoryForPricingGroups((prev) => {
      return prev.map((ticket) => {
        if (ticketGroups.find((t) => t.ticketGroupId === ticket.ticketGroupId)) {
          return {
            ...ticket,
            loading: false,
            createdPG,
          };
        }
        return ticket;
      });
    });
  }
  async function createPricingGroups(
    groupsByProduction: Record<number, PricingTemplateInventoryResponseDtoSelectable[]>,
  ) {
    const seriesPromises = Object.values(groupsByProduction).map((ticketGroups = []) => {
      return async () => {
        if (!createPricingGroupsEnabled) {
          updateInventoryForPricingGroups(ticketGroups, false);
          return;
        }
        const { ceilingPrice, floorPrice, ruleSet, marketGroupCriteria } = pricingTemplate!;

        const pricingGroupRequest: PricingGroupRequest = {
          name: pricingTemplate?.name,
          pricingGroupRequestRanks: ticketGroups.map((ticket) => {
            return {
              ticketGroupId: ticket.ticketGroupId!,
              rank: ticket.suggestedRank || 0,
            };
          }),
          ceilingPrice,
          floorPrice,
          ruleSet,
          marketGroupCriteria,
          productionId: ticketGroups[0]?.masterProductionId,
          pricingGroupRequestType: 'SimulationOnly',
          pricingTemplateId: pricingTemplateId!,
        };
        await createPricingGroupMutation
          .mutateAsync({
            ...AuthApiFetcherOptions,
            body: pricingGroupRequest,
          })
          .then(() => {
            setGroupsFinishedCount((prev) => prev + 1);
            updateInventoryForPricingGroups(ticketGroups, true);
          })
          .catch((e) => {
            updateInventoryForPricingGroups(ticketGroups, false);

            setFailedProductionIds([...failedProductionIds, ticketGroups[0]?.masterProductionId!]);
            return e;
          });
      };
    });
    await seriesAsync(seriesPromises);
    setPricingGroupsCreationFinished(true);
  }

  // orchestration of steps
  useEffect(() => {
    if (currentStepIndex === StepNames.SelectTicketGroups && inventory.length === 0) {
      refetchInventory();
      const selectedInventory = selectedProductionIds.map((productionId) => {
        return {
          masterProductionId: productionId,
          loading: true,
          ticketGroupId: ProductionInventoryLoading,
        } as PricingTemplateInventoryResponseDtoSelectable;
      });
      setInventory(selectedInventory);
    }

    if (currentStepIndex === StepNames.ReviewPricingGroups) {
      const selectedInventory = inventory
        .filter((x) => x.selected)
        .map((x) => {
          return { ...x, loading: true };
        });

      setInventoryForPricingGroups(selectedInventory);
      fetchCalculatePrice(getGroupedInventory(selectedInventory));
    }

    if (currentStepIndex === StepNames.ApplyHiddenStep) {
      setInventoryForPricingGroups((prev) => {
        return prev.map((ticket) => {
          return {
            ...ticket,
            loading: true,
          };
        });
      });
      const groups = getGroupedInventory(inventoryForPricingGroups);
      setGroupsTotalCount(Object.keys(groups).length);
      createPricingGroups(getGroupedInventory(inventoryForPricingGroups));
    }
  }, [currentStepIndex]);

  const startRetryFlow = useCallback(() => {
    if (!failedProductionIds.length) {
      console.error('no failed productions pricing groups', failedProductionIds);
      return;
    }
    setPricingGroupsCreationFinished(false);
    setGroupsFinishedCount(0);
    setGroupsTotalCount(0);

    setSelectedProductionIds(failedProductionIds);
    resetInventoryState();
    setIsRetryFlow(true);
    setCreatePricingGroupsEnabled(true);

    previousStep(StepNames.SelectTicketGroups + 1);
  }, [failedProductionIds]);

  const cancelFlow = useCallback(() => {
    setCreatePricingGroupsEnabled(false);
  }, []);

  const api = useMemo<ITemplatePageApi>(
    () => ({
      setPricingTemplateId,
      setPricingTemplateProductionsQueryParams,
      setSortColumns,
      fetchNextPageProductionsPerTemplateId: async () => {
        if (hasNextPageProductionsPerTemplateId) {
          await fetchNextPageProductionsPerTemplateId();
        }
      },
      setSelectedDateRange,
      setSelectedProductionIds,
      nextStep,
      previousStep,
      setShowUnavailableProductions,
      setShowUnavailableTickets,
      toggleInventoryById,
      toggleAllInventoryForProduction,
      setInventory,
      setSearchTerm,
      startRetryFlow,
      cancelFlow,
      refetchInventoryWithProductionId,
      getPricingGroupCalculationForProduction,
      refetchPricingTemplateProductions,
    }),
    [
      setSortColumns,
      hasNextPageProductionsPerTemplateId,
      fetchNextPageProductionsPerTemplateId,
      setSelectedDateRange,
      currentStepIndex,
      startRetryFlow,
      inventoryForPricingGroups,
    ],
  );

  const isSuccessfulCreation = useMemo(
    () => pricingGroupsCreationFinished && groupsTotalCount === groupsFinishedCount,
    [pricingGroupsCreationFinished, groupsTotalCount, groupsFinishedCount],
  );
  const isFailedCreation = useMemo(
    () => pricingGroupsCreationFinished && groupsTotalCount !== groupsFinishedCount,
    [pricingGroupsCreationFinished, groupsTotalCount, groupsFinishedCount],
  );

  return (
    <TemplatePageStateContext.Provider
      value={{
        pricingTemplate,
        isPricingTemplateFetching,
        pricingTemplateProductionsQueryParams,
        productionsPerTemplateId,
        isProductionsPerTemplateIdFetching,
        pricingTemplateError,
        sortColumns,
        totalRecords,
        hasNextPageProductionsPerTemplateId,
        selectedDateRange,
        selectedProductionIds,
        steps: visibleSteps,
        currentStepIndex,
        showUnavailableProductions,
        pricingTemplateInventory: inventory,
        inventoryForPricingGroups,
        isInventoryFetching,
        showUnavailableTickets,
        productionAllTicketsSelected,
        searchTerm,

        groupsTotalCount,
        groupsFinishedCount,
        pricingGroupsCreationFinished,

        isSuccessfulCreation,
        isFailedCreation,
        createPricingGroupsEnabled,
        selectedInventoryTickets,
        selectedTicketsByProduction,
      }}
    >
      <TemplatePageApiContext.Provider value={api}>{props.children}</TemplatePageApiContext.Provider>
    </TemplatePageStateContext.Provider>
  );
}

export default TemplatePageContextProvider;
